import React from 'react'
import { keyframes } from 'styled-components'
import { get as _get } from 'lodash'

import styled from '~utils/themed'

const loaderColors = {
  alpha: '#fff',
}

const rotate = keyframes`
 100% {
    transform: rotate(360deg);
  }
`

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`

const color = keyframes`
  100%, 0% {
    stroke: ${loaderColors.alpha};
  }
  40% {
    stroke: ${loaderColors.alpha};
  }
  66% {
    stroke: ${loaderColors.alpha};
  }
  80%, 90% {
    stroke: ${loaderColors.alpha};
  }
`


const spinnerSizeChart = {
  xxs: {
    width: '10px',
    strokeWidth: '10',
  },
  xs: {
    width: '15px',
    strokeWidth: '8',
  },
  sm: {
    width: '30px',
    strokeWidth: '6',
  },
  md: {
    width: '50px',
    strokeWidth: '4',
  },
  lg: {
    width: '70px',
    strokeWidth: '3',
  },
  xl: {
    width: '100px',
    strokeWidth: '2',
  },
}

const ButtonSpinner = styled.div.attrs({
  width: ({ size, width }) => _get(spinnerSizeChart, `${size}.width`, width),
  strokeWidth: ({ size, strokeWidth }) => _get(spinnerSizeChart, `${size}.strokeWidth`, strokeWidth),
})`
  position: relative;
  margin: ${({ marginTopBotton }) => `${marginTopBotton}px` || '0'} auto;
  width: @@width;
  float: ${({ theme, spinBtn }) => spinBtn && theme.dirFloat};
  margin-${({ theme }) => theme.dirFloatRev}:${({ spinBtn }) => spinBtn && '10px'};
  display: ${({ display }) => display || 'block'};
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  .circular {
    animation: ${() => rotate} 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: ${() => dash} 1.5s ease-in-out infinite, ${() => color} 6s ease-in-out infinite;
    stroke-linecap: round;
  }

`.withComponent(({ className, strokeWidth }) => {
    return (
      <div className={className}>
        <svg className="circular" viewBox="25 25 50 50">
          <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth={strokeWidth} strokeMiterlimit="10"/>
        </svg>
      </div>
    )
  })


export default ButtonSpinner
