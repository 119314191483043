import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function raghiAccounts() {
  return new Promise((resolve, reject) => {
    request
      .get(`${API_URL}/api/v1/global-config/rajhi-users`)
      .set('Authorization', `bearer ${getToken()}`)
      .then((resp) => {
        const newResp = {
          ...resp?.body.value,
        }
        resolve(newResp)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
