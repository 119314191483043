/* eslint-disable no-unused-vars */
import chroma from 'chroma-js'
import { darken, lighten } from 'polished'

import styled from '../../utils/themed'
import {
  // withModes,
  withAccents,
} from '../utils'

/* ***************************************************
    __ _         _
   / _\ |_ _   _| | ___  ___
   \ \| __| | | | |/ _ \/ __|
   _\ \ |_| |_| | |  __/\__ \
   \__/\__|\__, |_|\___||___/
           |___/
*************************************************** */

export default function loadStyles({ colors }) {
  const styles = {}


  return styles
}

/* ************************************************ */
