import styled from '~utils/themed'
import spinnerGif from '~plumbing/assets/img/spinner.gif'

export const Spinner = styled.img.attrs({
  src: spinnerGif,
  width: ({ size }) => `${size || 15}px`,
  height: ({ size }) => `${size || 15}px`,
})`
  width: @@width;
  height: @@height;
  @margin-dir: 10px;
  margin-bottom: -4px;
`
