import { lighten, darken, transparentize } from 'polished'

import { switchDir } from './utils'


export default (currentTheme) => {
  const {
    colors: $colors,
  } = currentTheme.var

  // ---- colors (usage only within this file)
  const colors = {
    brandMain: $colors.brand.alpha,
    brandAlt: $colors.helper.gamma,
    helperLighter: $colors.helper.alpha,
    helperDark: $colors.helper.gamma,
    helperDarker: $colors.brand.beta,
    helperAlt: $colors.brand.psi,
    // test color
    // x: '#f700a9',
    //  test color for demo
    x: 'rgba(0, 0, 0, 0)',
  }

  const theme = {
    name: 'legacy_vars',

    ...colors,

    // ---- typo colors
    titleNormal: colors.helperDarker,
    // @title-dark:  ;
    titleLight: colors.helperLighter,

    titleHighlight: colors.brandMain,
    // @title-sub-normal:  darkorange ;

    textNormal: colors.helperDark,
    textDark: colors.helperDarker,
    textAlt: lighten(0.4, colors.brandAlt),
    textLight: lighten(0.95, colors.helperDarker),
    textHighlight: colors.brandMain,
    textHighlightBg: colors.helperLighter,

    labelHeaderNormal: colors.brandAlt,
    labelNormal: lighten(0.1, colors.helperDark),

    // ---- input  colors
    inputSelected: lighten(0.4, colors.brandAlt),
    inputChecked: colors.helperDarker,
    inputNormal: lighten(0.5, colors.helperDark),
    inputBackground: colors.helperLighter,
    inputDisabled: lighten(0.5, colors.helperDark),
    inputDropdownHover: $colors.input.list.hover.background,
    inputDropdownLabel: colors.helperDark,
    inputDropdownHoverLabel: colors.brandMain,
    inputShadow: `0px 0px 6px ${lighten(0.2, colors.brandAlt)}`,
    tooltipBackground: lighten(0.55, colors.brandAlt),

    // ---- table colors
    tableHead: colors.brandAlt,
    tableHeadLabel: colors.helperLighter,
    tableHeadSelected: lighten(0.2, colors.brandAlt),
    tableBody: colors.helperLighter,
    tableBodyHover: lighten(0.95, colors.brandAlt),
    tableBodyLabel: $colors.table.body.label,
    tableBorder: colors.bgContrast,

    // ---- btn  colors

    btnDisabled: lighten(0.5, colors.helperDark),
    btnDisabledLabel: lighten(0.35, colors.helperDark),

    btnLink: colors.brandMain,
    btnLinkHover: darken(0.1, colors.brandMain),
    btnLinkDisabled: lighten(0.85, colors.helperDarker),

    btnPrimary: colors.brandMain,
    btnPrimaryHover: darken(0.1, colors.brandMain),
    btnPrimaryLabel: colors.helperLighter,

    btnAlt: colors.helperLighter,
    btnAltHover: $colors.btn.beta.hover.background,
    btnAltLabel: colors.brandMain,

    btnLight: colors.helperLighter,
    btnLightHover: lighten(0.5, colors.brandMain),
    btnLightLabel: colors.brandMain,

    btnSub: lighten(0.55, colors.brandAlt),
    btnSubHover: lighten(0.45, colors.brandAlt),
    btnSubLabel: colors.brandMain,

    btnAction: colors.helperLighter,
    btnActionHover: darken(0.1, colors.helperLighter),
    btnActionLabel: colors.helperDarker,

    menuNav: colors.brandMain,
    menuHover: lighten(0.1, colors.brandMain),
    menuNavSub: colors.helperLighter,
    menuNavSubHover: colors.helperLighter,
    menuActiveLabel: colors.helperLighter,

    // ---- background colors
    brandBg: colors.brandMain,
    bgHighlight: colors.brandMain,
    bgHoverHighlight: lighten(0.4, colors.brandMain),
    bgOverlay: transparentize(0.9, colors.helperLighter),
    bgLighter: colors.helperLighter,
    bgContrast: lighten(0.7, colors.helperDarker),
    bgBodyNormal: $colors.layout.body,
    bgBodyDarker: $colors.layout.body,
    containerBg: colors.helperLighter,
    tagBg: lighten(0.7, colors.helperDarker),

    // ---- hr colors
    hrNormal: lighten(0.5, colors.helperDark),
    hrlight: lighten(0.65, colors.helperDark),

    // ---- hint colors
    hintBgNormal: $colors.hint.defult.background,
    hintBorderNormal: $colors.hint.defult.border,

    hintGreenBg: $colors.hint.success.background,
    hintGreenBorder: '#b8deb9',

    hintRedBg: '#F3D8DB',
    hintRedBorder: '#f5c6cb',
    hintRedTxtColor: '#721C24',

    hintYellowBg: '#FFF6CC',
    hintYellowBorder: '#FFF6CC',
    hintYellowTxtColor: '#b4960f',

    // ---- footer colors
    footerBgNormal: $colors.footer.background,
    footerBorderNormal: colors.helperLighter,
    // ---- Card colors
    cardBgNormal: $colors.card.defult.background,
    cardBorderNormal: $colors.card.border,
    cardIconNormal: colors.helperLighter,
    cardIconBgNormal: colors.helperDarker,

    // ---- Badge colors
    badgeBgNormal: colors.helperLighter,
    badgeColorNormal: colors.brandMain,

    // ---- round corner
    roundCornerXl: '20px',
    roundCornerLg: '5px',
    roundCorner: '3px',
    roundCornerLeft: '3px 0px 0px 3px',
    roundCornerRight: '0px 3px 3px 0px',
    roundCornerTop: '3px 3px 0px 0px',
    roundCornerBottom: '0px 0px 3px 3px',

    // ---- shadow colors
    shadowPrimary: transparentize(0.1, colors.helperDarker),
    shadowNormal: $colors.shadow.beta,
    shadowPopBg: $colors.shadow.psi,
    lightPopBg: $colors.shadow.gamma,

    // ---- shadow colors
    chartPrimary: colors.brandMain,

    // ---- indicator colors
    // redColor: '#e3363c ',
    redColor: $colors.state.error,
    greenColor: $colors.state.success,
    orangeColor: $colors.state.warning,

    // ---- indicator colors
    notneeded: '#e3363c ',

    color: {
      brand: {
        primary: {
          // defualt: colors.x,
          defualt: colors.x,
          white: colors.x,
          red: colors.x,
          light: {
            // hover: colors.x,
            hover: colors.x,
            // extra: colors.x,
            extra: colors.x,
            title: colors.x,
            offWhite: colors.x,
            grey1: colors.x,
            grey2: colors.x,
            grey3: colors.x,
            grey4: colors.x,
            grey5: colors.x,
            grey6: colors.x,

          },
        },
      },
      accent: {},
      other: {},
    },
    // added colors
    brandWhite: colors.x,
    dummyGrey: colors.x,
    dummyGrey2: colors.x,
    dummyGrey3: colors.x,
    dummyGrey4: colors.x,
    dummyGrey5: colors.x,
    // dummyGrey6: colors.x,
    dummyGrey6: colors.x,
    dummyGrey7: colors.x,
    dummyGrey8: colors.x,
    dummyGrey9: colors.x,
    dummyGrey10: colors.x,
    dummyGrey11: colors.x,
    dummyGrey12: colors.x,

    brandDarkTitles: colors.x,
    brandPrimary: colors.x,
    brandPrimaryHover: colors.x,
    brandGrey: colors.x,
    // brandGrey1: colors.x,
    brandGrey1: colors.x,
    brandGrey3Lighter: colors.x,
    brandGrey4: colors.x,
    brandGrey2Lighter: colors.x,
    brandGrey2: colors.x,
    brandGrey6: colors.x,
    brandGrey3: colors.x,
    brandPrimaryLightRgba: colors.x,
    Red: colors.x,
    brandDanger: colors.x,
    brandWarn: colors.x,

    fontSize: {
      small: switchDir({ rtl: '12px', ltr: '14px' }),
      mid: switchDir({ rtl: '14px', ltr: '16px' }),
    },

    lineHeight: {
      mid: switchDir({ rtl: '17px', ltr: '16px' }),
    },
    dirFloatRev: switchDir({ rtl: 'left', ltr: 'right' }),
    dirFloat: switchDir({ rtl: 'right', ltr: 'left' }),
    buttonFloat: switchDir({ rtl: 'left', ltr: 'right' }),

    switchDir,
    toggleDir: switchDir,
    isLTR: switchDir({ rtl: false, ltr: true }),
    isRTL: switchDir({ rtl: true, ltr: false }),
  }

  return theme
}
