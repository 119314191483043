/* eslint-disable no-underscore-dangle, import/no-extraneous-dependencies */
import { createStore, compose, applyMiddleware } from 'redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { createEpicMiddleware } from 'redux-observable'
// import thunk from 'redux-thunk'

// import bugsnagMiddleware from './bugsnagMiddleware'
import rootReducer from './reducers'
import rootEpic from './epics'

const epicMiddleware = createEpicMiddleware(rootEpic)

if (module.hot) {
  module.hot.accept('~CON/plumbing/store/epics', () => {
    epicMiddleware.replaceEpic(require('~CON/plumbing/store/epics').default) // eslint-disable-line
  })
}

function configureStoreProd(initialState) {
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    // thunk,

    // root Epic, used to create an instance of the actual
    // redux-observable middleware.
    epicMiddleware,

    // Bugsnag
    // bugsnagMiddleware,
  ]

  /* eslint-disable */
  return createStore(rootReducer, initialState, compose(
      applyMiddleware(...middlewares)
    )
  )
  /* eslint-enable */
}

function configureStoreDev(initialState) {
  const middlewares = [
    // Add other middleware on this line...

    // Redux middleware that spits an error on you
    // when you try to mutate your state either inside
    // a dispatch or between dispatches.
    reduxImmutableStateInvariant(),

    // thunk middleware can also accept an extra argument
    // to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    // thunk,

    // root Epic, used to create an instance of the actual
    // redux-observable middleware.
    epicMiddleware,

    // Bugsnag
    // bugsnagMiddleware,
  ]

  // add support for Redux dev tools
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  /* eslint-disable */
  const store = createStore(rootReducer, initialState, composeEnhancers(
    applyMiddleware(...middlewares)
    )
  )
  /* eslint-enable */

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('~CON/plumbing/store/reducers', () => {
      const nextReducer = require('~CON/plumbing/store/reducers').default // eslint-disable-line global-require
      store.replaceReducer(nextReducer)
    })
  }

  return store
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev

export default configureStore
