import getPrefixes from './FetchPrefixs'
import updatePrefix from './UpdatePrefix'
import deletePrefixes from './DeletePrefix'
import getCountries from './ListContries'
import getNetwork from './GetNetwork'
import CustomerConfig from './GetCustomerConfig'
import addCustomerDestnation from './AddDestnation'
import extractPrefixes from './extractPrefixes'
import defaultConfig from './GetDefaultDest'

export default {
  getPrefixes,
  updatePrefix,
  deletePrefixes,
  getCountries,
  getNetwork,
  CustomerConfig,
  addCustomerDestnation,
  extractPrefixes,
  defaultConfig,
}
