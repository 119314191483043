/* eslint-disable no-unused-vars */
import chroma from 'chroma-js'
import { darken, lighten } from 'polished'

import {
  withAccents,
} from '../utils'


/* ***************************************************
   /\   /\__ _ _ __ ___
   \ \ / / _` | '__/ __|
    \ V / (_| | |  \__ \
     \_/ \__,_|_|  |___/
*************************************************** */

export default function typoVars(vars) {
  const typo = vars?.typo || {}

  typo.fontSize = {
    xxs: '8px',
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '20',
    xl: '26',
    xxl: '30',
  }

  typo.lineHeight = {
    xxs: '8px',
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '20',
    xl: '26',
    xxl: '30',
  }

  return typo
}

/* ************************************************ */
