import {
  get as _get,
  isObject as _isObject,
  isFunction as _isFunction,
} from 'lodash'

import { inCase } from '~plumbing/utils'

import dirCase from './dirCase'

export const themeUtils = {
  inCase,
  dirCase,
  isLTR: dirCase.RTL(false).LTR(true),
  isRTL: dirCase.RTL(true).LTR(false),
}


/**
 * reformat theme object to { css, var } top level
 *  add utils to theme object
 *
 * @param {string} name - theme name used in storybook
 * @param {object} theme - theme objects
 *
 * @return {{ var, css, utils, name }}
 */
export function createThemeX(name, theme) {
  const themeObj = Object
    .entries(theme)
    .reduce((c, [k, v]) => {
      if (_isObject(v)) {
        /* eslint-disable no-param-reassign */
        c.css[k] = _get(v, 'css', {})
        c.var[k] = _get(v, 'var', {})
        /* eslint-enable no-param-reassign */
      }

      return c
    }, { css: {}, var: {} })


  return Object.assign(themeObj, themeUtils, { name })
}

export default function createTheme(themeName) {
  /* eslint-disable no-param-reassign */
  const themeObj = { themeName }
  let chainAccess = {}

  const addCss = (themeCss = {}) => {
    delete chainAccess.css

    themeObj.themeCss = Object.entries(themeCss)
      .reduce((accum, [tn, tv]) => {
        if (_isFunction(tv)) {
          accum[tn] = tv?.(themeObj?.themeVars || {}) || tv
        } else {
          accum[tn] = tv
        }

        return accum
      }, {})

    chainAccess.create = create
    return chainAccess
  }

  chainAccess = {
    extends: (extendTheme = {}) => {
      delete chainAccess.extends

      themeObj.extendTheme = extendTheme

      return chainAccess
    },

    var: (themeVars = {}) => {
      delete chainAccess.var

      themeObj.themeVars = Object.entries(themeVars)
        .reduce((accum, [tn, tv]) => {
          if (_isFunction(tv)) {
            accum[tn] = tv?.(themeObj?.extendTheme?.vars || {}) || tv
          } else {
            accum[tn] = tv
          }

          return accum
        }, {})

      chainAccess.css = addCss
      chainAccess.create = create
      return chainAccess
    },
  }

  return chainAccess

  /* ****************************************** */

  function create() {
    const themeVars = createThemeVar()
    const themeCss = createThemeCss(themeVars)

    const theme = {
      name: themeObj.themeName,
      var: themeVars,
      css: themeCss,
      utils: themeUtils,
      _getCss: () => ({ ...themeObj.__css }),
      _getVars: () => ({ ...themeObj.__vars }),
    }
    return theme
  }

  /* ****************************************** */

  function createThemeVar() {
    const extendVars = getExtendedVars()
    const themeVars = getThemeVars(extendVars)

    themeObj.__vars = {
      ...extendVars,
      ...themeVars,
    }

    return themeObj.__vars
  }

  function createThemeCss(themeVars) {
    const extendCss = getExtendedCss()
    const combineCss = getThemeCss(themeVars, extendCss)

    themeObj.__css = combineCss

    return Object
      .entries(combineCss)
      .reduce((c, [k, v]) => {
        if (_isObject(v)) {
          c[k] = v
        }

        return c
      }, {})
  }

  /* ****************************************** */

  function getExtendedVars() {
    const getVars = _get(themeObj, 'extendTheme._getVars', () => ({}))
    return getVars()
  }

  function getExtendedCss() {
    const getCss = _get(themeObj, 'extendTheme._getCss', () => ({}))
    return getCss()
  }

  /* ****************************************** */

  function getThemeVars() {
    const themeVars = _get(themeObj, 'themeVars', {})

    return Object
      .entries(themeVars)
      .reduce((c, [k, v]) => {
        if (_isObject(v)) {
          c[k] = v
        }

        return c
      }, {})
  }

  function getThemeCss(themeVars, extendCss) {
    const themeCss = _get(themeObj, 'themeCss', {})

    const combineCss = {
      ...extendCss,
      ...themeCss,
    }

    return combineCss
  }

  /* ****************************************** */

  /* eslint-enable no-param-reassign */
}
