import { inCase } from '~plumbing/utils'
import { actions as profileActions, actionsTypes } from './profileActions'

const defaultState = {
  profile: {
    profileData: [],
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
    },
  },
}
const profiles = inCase
  .curry('profiles')
  .check((s, action) => action.type)
  .otherwise((state) => state || defaultState)
  .cases({
    [actionsTypes.CHANGE_PASSWORD]: changePassword,
    [actionsTypes.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
    [actionsTypes.CHANGE_PASSWORD_FAILED]: changePasswordFailed,
  })

function changePassword(state, action) {
  return {
    ...state,
  }
}
function changePasswordSuccess(state, action) {
  return {
    ...state,
    profile: {
      profileData: action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
    errCode: '',
  }
}
function changePasswordFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
export default profiles
