import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function updateMnp(payload) {
  const mnp = {
    customers: {
      [payload.customer]: payload.flag,
    },
  }
  return new Promise((resolve, reject) => {
    request
      .put(`${API_URL}/api/v1/global-config/mnp-plus/customers`)
      .set('Authorization', `bearer ${getToken()}`)
      .send(mnp)
      .then((resp) => {
        resolve(resp?.body?.customers)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
