/* global FS */
import Rx from 'rxjs' // eslint-disable-line

import { observableFromPromiseProperties } from 'CQS/plumbing/utils'
import { actions as appActions } from '~CON/App/appActions'

import { actionsTypes, actions as globalmanagementActions } from './GlobalManagementActions'
import apis from '../apis'

const globalmanagementApis = observableFromPromiseProperties(apis, Rx)

export default [
  getRaghiAccounts,
  getDefaultAccount,
  getRaghiDatabase,
  getHlrDatabase,
  getKsaDatabase,
  getMnpDatabase,
  updateRaghiAccounts,
  updateDefaultAccount,
  updateHlr,
  updateKsa,
  updateMnp,
  updateRaghi,
  getMnpPlus,
  setMnpPlus,
]

export function getMnpPlus(action$, { getState }) {
  return action$
    .ofType(actionsTypes.FETCH_MNP_PLUS)
    .switchMap(({ action }) => {
      return globalmanagementApis
        .mnpPlus()
        .map((res) => {
          return globalmanagementActions.fetchMnpPlusSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_MNP_PLUS_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function setMnpPlus(action$, { getState }) {
  return action$
    .ofType(actionsTypes.UPDATE_MNP_PLUS)
    .switchMap(({ payload }) => {
      return globalmanagementApis
        .updateMnpPlus(payload)
        .map((res) => {
          return globalmanagementActions.updateMnpPlusSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.UPDATE_MNP_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getRaghiAccounts(action$, { getState }) {
  return action$
    .ofType(actionsTypes.FETCH_ALL_RaghiAccounts)
    .switchMap(({ action }) => {
      return globalmanagementApis
        .raghiAccounts()
        .map((res) => {
          return globalmanagementActions.fetchAllRaghiAccountsSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_ALL_RaghiAccounts_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getDefaultAccount(action$, { getState }) {
  return action$
    .ofType(actionsTypes.FETCH_DEFAULT_ACCOUNT)
    .switchMap(({ action }) => {
      return globalmanagementApis
        .defaultAccount()
        .map((res) => {
          return globalmanagementActions.fetchDefaultAccountSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_DEFAULT_ACCOUNT_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getRaghiDatabase(action$, { getState }) {
  return action$
    .ofType(actionsTypes.FETCH_RAGHI_DATABASE)
    .switchMap(({ action }) => {
      return globalmanagementApis
        .fetchRaghiDatebase()
        .map((res) => {
          return globalmanagementActions.fetchRaghiDatabaseSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_RAGHI_DATABASE_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getHlrDatabase(action$, { getState }) {
  return action$
    .ofType(actionsTypes.FETCH_HLR_DATABASE)
    .switchMap(({ action }) => {
      return globalmanagementApis
        .fetchHLRDatabase()
        .map((res) => {
          return globalmanagementActions.fetchHlrDatabaseSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_HLR_DATABASE_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getMnpDatabase(action$, { getState }) {
  return action$
    .ofType(actionsTypes.FETCH_MNP_DATABASE)
    .switchMap(({ action }) => {
      return globalmanagementApis
        .fetchMnpDatabase()
        .map((res) => {
          return globalmanagementActions.fetchMnpDatabaseSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_MNP_DATABASE_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getKsaDatabase(action$, { getState }) {
  return action$
    .ofType(actionsTypes.FETCH_KSA_DATABASE)
    .switchMap(({ action }) => {
      return globalmanagementApis
        .fetchKSADatabase()
        .map((res) => {
          return globalmanagementActions.fetchKsaDatabaseSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_KSA_DATABASE_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function updateRaghiAccounts(action$, { getState }) {
  return action$
    .ofType(actionsTypes.UPDATE_ALL_RAGHIACCOUNTS)
    .switchMap(({ payload }) => {
      return globalmanagementApis
        .updateRaghiAccounts(payload)
        .map((res) => {
          return globalmanagementActions.updateAllRaghiaccountsSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.UPDATE_ALL_RAGHIACCOUNTS_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function updateDefaultAccount(action$, { getState }) {
  return action$
    .ofType(actionsTypes.UPDATE_DEFAULT__ACCOUNT)
    .switchMap(({ payload }) => {
      return globalmanagementApis
        .updatedefaultAccount(payload)
        .map((res) => {
          return globalmanagementActions.updateDefaultAccountSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.UPDATE_DEFAULT__ACCOUNT_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function updateHlr(action$, { getState }) {
  return action$
    .ofType(actionsTypes.UPDATE_HLR)
    .switchMap(({ payload }) => {
      return globalmanagementApis
        .updatehlr(payload)
        .map((res) => {
          return globalmanagementActions.updateHlrSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.UPDATE_HLR_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function updateMnp(action$, { getState }) {
  return action$
    .ofType(actionsTypes.UPDATE_MNP)
    .switchMap(({ payload }) => {
      return globalmanagementApis
        .updatemnp(payload)
        .map((res) => {
          return globalmanagementActions.updateMnpSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.UPDATE_MNP_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function updateKsa(action$, { getState }) {
  return action$
    .ofType(actionsTypes.UPDATE_KSA)
    .switchMap(({ payload }) => {
      return globalmanagementApis
        .updateksa(payload)
        .map((res) => {
          return globalmanagementActions.updateKsaSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.UPDATE_KSA_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function updateRaghi(action$, { getState }) {
  return action$
    .ofType(actionsTypes.UPDATE_RAGHI)
    .switchMap(({ payload }) => {
      return globalmanagementApis
        .updateraghi(payload)
        .map((res) => {
          return globalmanagementActions.updateRaghiSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.UPDATE_RAGHI_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
