import styled from 'styled-components'

/**
 *
 * @param {object} props
 * @param {boolen} props.warn - warning highlight
 * @param {boolen} props.error - Error highlight
 *
 * @example
 * <Input warn />
 */

export const SuccessMsg = styled.div`
  margin-top:70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.containerBg} ;
    position: relative;
    z-index: 3;
  p{
    font-size: 18px;
    text-align:center;
    color: ${({ theme }) => theme.textDark};
  }
  i{
    text-align: center;
    display:block;
    margin:10px auto;
    font-size:50px;
    color: ${({ theme }) => theme.textHighlight};
  }
  button{
    display:none !important;
  }
`
