import { camelCase as _camelCase, get as _get } from 'lodash'
import { renameFunc } from 'CQS/plumbing/utils'

export const actionsTypes = {
  GET_USAGE: 'discovery/Usage/GetUsage',
  GET_USAGE_SUCCESS: 'discovery/Usage/GetUsageSuccess',
  GET_USAGE_FAILED: 'discovery/Usage/GetUsageFailed',
}
// helper function automatically generate ACTION CREATORS!
export const actions = Object.keys(actionsTypes).reduce((accum, id) => {
  const creatorName = _camelCase(id)

  const creatorFunction = function _(payload, meta) {
    return {
      type: _get(actionsTypes, id),
      payload,
      meta,
    }
  }

  // eslint-disable-next-line no-param-reassign
  accum[creatorName] = renameFunc(creatorFunction, creatorName)
  return accum
}, {})
