import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function updatehlr(payload) {
  return new Promise((resolve, reject) => {
    request
      .post(`${API_URL}/api/v1/global-config/ttls/short`)
      .set('Authorization', `bearer ${getToken()}`)
      .send(payload)
      .then((resp) => {
        resolve(payload)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
