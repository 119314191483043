/* eslint-disable no-unused-vars */
import chroma from 'chroma-js'
import { darken, lighten } from 'polished'

import {
  withAccents,
} from '../utils/index'


/* ***************************************************
   /\   /\__ _ _ __ ___
   \ \ / / _` | '__/ __|
    \ V / (_| | |  \__ \
     \_/ \__,_|_|  |___/
*************************************************** */


export default function colorVars(vars) {
  const colors = vars?.colors || {}

  colors.brand = {
    primary: '#AA69D7',
    title: '#343642',
    alt: '#606970',
  }

  colors.helper = {
    lighter: '#ffffff',
    dark: '#606970',
    darker: '#25395A',
  }

  colors.denotive = {
    success: '#7FFF00',
    worn: '#ff8c00',
    error: '#ff0000',
    disabled: '#D3D3D3',
  }

  colors.typo = {
    title: withAccents(colors.helper.darker, {
      light: colors.helper.lighter,
      highlight: colors.brand.primary,
    }),

    text: withAccents(colors.helper.dark, {
      dark: colors.helper.darker,
      alt: lighten(0.4, colors.brand.alt),
      light: lighten(0.95, colors.helper.darker),
      highlight: colors.brand.primary,
      highlightBg: colors.helper.lighter,
    }),

    label: withAccents(lighten(0.1, colors.helper.dark), {
      header: colors.brandAlt,
    }),
  }

  colors.link = withAccents(colors.brand.primary, {
    hover: darken(0.1, colors.brand.primary),
    disabled: lighten(0.85, colors.helper.darker),
  })

  colors.button = {
    primary: withAccents(colors.brand.primary, {
      hover: darken(0.1, colors.brand.primary),
      label: colors.helper.lighter,
    }),

    alt: withAccents(colors.helperLighter, {
      hover: darken(0.1, '#EFEBE5'),
      label: colors.brand.primary,
    }),

    light: withAccents(colors.helperLighter, {
      hover: lighten(0.5, colors.brand.primary),
      label: colors.brand.primary,
    }),

    sub: withAccents(lighten(0.55, colors.brand.alt), {
      hover: lighten(0.45, colors.brand.alt),
      label: colors.brand.primary,
    }),

    action: withAccents(colors.helper.lighter, {
      hover: darken(0.1, colors.helper.lighter),
      label: colors.helper.darker,
    }),

    disabled: withAccents(lighten(0.5, colors.helper.dark), {
      disabled: lighten(0.35, colors.helper.dark),
    }),
  }

  return colors
}

/* ************************************************ */
