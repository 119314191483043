import { combineEpics } from 'redux-observable'
import userEpic from '~CON/User/userEpic'
import ProviderEpics from '~CON/Discovery/Provider/Redux/ProviderEpics'
import GlobalmanagementEpics from '~CON/Discovery/GlobalManagement/Redux/GlobalManagementEpics'
import CustomerManagement from '~CON/Discovery/CustomerManagement/Redux/CustomerManagementEpics'
import HLRConfig from '~CON/Discovery/ConfigList/Redux/ConfigEpics'
import MasterDetails from '~CON/Discovery/MasterDetails/Redux/MasterDetailsEpic'
import Coverage from '~CON/Discovery/CoverageTesting/Redux/CoverageEpic'
import Request from '~CON/Discovery/Requests/Redux/RequestEpic'
import Rules from '~CON/Discovery/Rules/Redux/RulesEpic'
import users from '~CON/Discovery/UserManagement/Redux/UserManagementEpics'
import Usage from '../../Discovery/Usage/Redux/UsageEpic'
import Audit from '../../Discovery/AuditTrail/Redux/AuditEpic'
import profiles from '../../Discovery/ProfileSetting/ChangePassword/Redux/profileEpic'
import cleansing from '../../Discovery/Cleansing/Redux/CleansingEpic'

export default combineEpics(...[
  ...userEpic,
  ...ProviderEpics,
  ...GlobalmanagementEpics,
  ...CustomerManagement,
  ...HLRConfig,
  ...MasterDetails,
  ...Coverage,
  ...Request,
  ...Rules,
  ...users,
  ...Usage,
  ...Audit,
  ...profiles,
  ...cleansing,
])
