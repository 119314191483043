import styled from 'styled-components'

/**
 *
 *
 * @example
 * <EmptyStateWrapper  />
 */
export const EmptyStateWrapper = styled.div`
      margin: auto;
    width: 100%;
    height: auto;
    float: none;
    text-align: center;
  img {
    display: block;
    width:40%;
    margin: auto;
  }
  h1 {
    font-size: 24px;
    color: ${({ theme }) => theme.textNormal};
    text-align: center;
    font-weight: 100;
    margin-bottom: 10px;
    display: block;
    margin-top: -10px;
  }
  h2 {
    font-size: 14px;
    color: ${({ theme }) => theme.textNormal};
    text-align: center;
    margin:10px 0;
    font-weight:600;
    margin-top: 20px;
  }
  P {
    font-size: 12px;
    color: ${({ theme }) => theme.textNormal};
    text-align: center;
    max-width:50%;
    margin: auto;
    font-weight: 100;
  }
`
