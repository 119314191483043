import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function updateMnpPlus(payload) {
  const Mnp = {
    errorExpiry: payload,
  }
  return new Promise((resolve, reject) => {
    request
      .post(`${API_URL}/api/v1/global-config/mnp-plus/default`)
      .set('Authorization', `bearer ${getToken()}`)
      .send(Mnp)
      .then((resp) => {
        resolve(payload)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
