/* eslint-disable max-len */
/**
 * Themed a `styled-components` Wrapper,
 *  to enable LESS/Sass like syntax for props and theme variables
 */
import styled, { css as _css } from 'styled-components'
import {
  get as _get,
  has as _has,
} from 'lodash'

import { inCase, renameFunc } from '~utils'

import __themed from './themed'
import {
  isRTL,
  isLTR,
  propsIs,
  propsNot,
  propsOneOf,
  propsAllOf,
  propsNoneOf,
} from './utils'


const cases = (path, _cases) => (props) => {
  const check = _get(props, path)

  return inCase
    .curry('themed_inCase')
    .check(check)
    .otherwise(_cases?.__ ?? null)
    .cases(_cases)(props)
}

/**
 * themed a `styled-component` wrapper
 * @type {Object}
 * @property {function(cssStyle: *): *} isRTL - true is user local is RTL
 * @property {function(cssStyle: *): *} isLTR - true is user local is LTR
 * @property {function} css - takes a template string
 * @property {function(propPath: string, match: *): function} is - takes a template string and is returned if `prop` is matched
 * @property {function(propPath: string, match: *): function} not - takes a template string and is returned if `prop` is not matched
 * @property {function(...propPath: string): function} oneOf - takes a template string and is returned if one of n-`prop` is truthy
 * @property {function(...propPath: string): function} allOf - takes a template string and is returned if all of n-`prop` is truthy
 * @property {function(...propPath: string): function} noneOf - takes a template string and is returned if all of n-`prop` is truthy
 */
const themed = Object
  .entries(styled)
  .reduce(
    (r, [k, v]) => {
      const isFunc = typeof v === 'function'
      const isComp = _has(v, 'attrs') && _has(v, 'withConfig')

      if (isFunc && isComp) {
        /* eslint-disable no-param-reassign */
        r[k] = __themed(v)

        if (v?.attrs) {
          r[k].attrs = (...args) => __themed(v.attrs(...args))
        }

        if (typeof v?.withConfig === 'function') {
          r[k].withConfig = (...args) => __themed(v.withConfig?.(...args))
        } else {
          r[k].withConfig = (...args) => console.error('[themed] withConfig not a function', { args, k, v })
        }
        /* eslint-enable no-param-reassign */
      }

      return r
    },
    {
      ...styled,
      isRTL,
      isLTR,
      is: propsIs,
      not: propsNot,
      oneOf: propsOneOf,
      allOf: propsAllOf,
      noneOf: propsNoneOf,
      css: __themed(_css),
      inCase: cases,
      cases,
    },
  )

export default themed

/**
 *
 * @param {string|function|object} WrappedComponent
 * @param {string} [displayName]
 * @returns {function(...[*]=)}
 */
export function withThemed(WrappedComponent, displayName) {
  const stackName = (({ stack }) => {
    try {
      const [, sn] = stack
        .split(/\r|\n/)[2]
        .match(/\/([\w\d]+)\.js/)
      return sn
    } catch (e) {
      return null
    }
  })(new Error('withthemed-stack'))

  const wrappedName = WrappedComponent?.displayName
    ?? WrappedComponent?.name


  const wrappedStackName = stackName
    ? `[${stackName}](${wrappedName})`
    : null

  const name = displayName ?? wrappedStackName ?? wrappedName

  const rename = (f, n) => renameFunc(f, `withThemed(${n ?? name})`)

  function withThemedWrappedComponen(...args) {
    const _themedWrapped_ = __themed(styled.div, WrappedComponent)(...args)

    return rename(_themedWrapped_) // foobar
  }

  return withThemedWrappedComponen
}

export const css = __themed(_css)

css.is = propsIs
css.not = propsNot
css.oneOf = propsOneOf
css.allOf = propsAllOf
css.noneOf = propsNoneOf
css.isRTL = isRTL
css.isLTR = isLTR
css.cases = cases
