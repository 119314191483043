import { inCase } from '~plumbing/utils'
import {
  actions as masterActions,
  actionsTypes,
} from './MasterDetailsAction'

const defaultState = {
  MasterDetails: {
    MasterDetailsData: {},
    ExportMasterDetailsData: {},
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
  raghiDatabase: {
    raghiDatabasedata: {},
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
  mnpDatabase: {
    mnpDatabasedata: {},
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
  ksaDatabase: {
    ksaDatabasedata: {},
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
}
const masterDetailsReducer = inCase.curry('masterDetailsReducer')
  .check((s, action) => action.type)
  .otherwise((state) => state || defaultState)
  .cases({
    [actionsTypes.FETCH_MASTERDETAILS]: fetchMasterDetails,
    [actionsTypes.FETCH_MASTERDETAILS_SUCCESS]: fetchMasterDetailsSuccess,
    [actionsTypes.FETCH_MASTERDETAILS_FAILED]: fetchMasterDetailsFailed,
    [actionsTypes.EXPORT_MASTERDETAILS]: exportMasterDetails,
    [actionsTypes.EXPORT_MASTERDETAILS_SUCCESS]: exportMasterDetailsSuccess,
    [actionsTypes.EXPORT_MASTERDETAILS_FAILED]: exportMasterDetailsFailed,
    [actionsTypes.FETCH_RAGHI_DATABASE]: fetchRaghiDatabase,
    [actionsTypes.FETCH_RAGHI_DATABASE_SUCCESS]: fetchRaghiDatabaseSuccess,
    [actionsTypes.FETCH_RAGHI_DATABASE_FAILED]: fetchRaghiDatabaseFailed,
    [actionsTypes.FETCH_KSA_DATABASE]: fetchKsaDatabase,
    [actionsTypes.FETCH_KSA_DATABASE_SUCCESS]: fetchKsaDatabaseSuccess,
    [actionsTypes.FETCH_KSA_DATABASE_FAILED]: fetchKsaDatabaseFailed,
    [actionsTypes.FETCH_MNP_DATABASE]: fetchMnpDatabase,
    [actionsTypes.FETCH_MNP_DATABASE_SUCCESS]: fetchMnpDatabaseSuccess,
    [actionsTypes.FETCH_MNP_DATABASE_FAILED]: fetchMnpDatabaseFailed,
  })

function fetchMasterDetails(state, action) {
  return {
    ...state,
  }
}
function fetchMasterDetailsSuccess(state, action) {
  return {
    ...state,
    MasterDetails: {
      MasterDetailsData: action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchMasterDetailsFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function exportMasterDetails(state, action) {
  return {
    ...state,
  }
}
function exportMasterDetailsSuccess(state, action) {
  return {
    ...state,
    MasterDetails: {
      ExportMasterDetailsData: action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function exportMasterDetailsFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchRaghiDatabase(state, action) {
  return {
    ...state,
  }
}
function fetchRaghiDatabaseSuccess(state, action) {
  return {
    ...state,
    raghiDatabase: {
      raghiDatabasedata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchRaghiDatabaseFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchKsaDatabase(state, action) {
  return {
    ...state,
  }
}
function fetchKsaDatabaseSuccess(state, action) {
  return {
    ...state,
    ksaDatabase: {
      ksaDatabasedata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchKsaDatabaseFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchMnpDatabase(state, action) {
  return {
    ...state,
  }
}
function fetchMnpDatabaseSuccess(state, action) {
  return {
    ...state,
    mnpDatabase: {
      mnpDatabasedata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchMnpDatabaseFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
export default masterDetailsReducer
