import { createTheme } from '~plumbing/themes/utils'

import colorVars from './colors.var'
import chartVars from './charts.var'
import layoutVars from './layout.var'
import typographyVars from './typography.var'


import colorStyles from './colors.css'
import layoutStyles from './layout.css'
import typographyStyles from './typography.css'

import cequensLogoRed from './images/cequensRedLogo.svg'


const images = {
  logo: cequensLogoRed,
  logoAlt: cequensLogoRed,
  logoDark: cequensLogoRed,
}


export default createTheme('cequensScarlet')
  .var({
    images,
    charts: chartVars,
    colors: colorVars,
    layout: layoutVars,
    typography: typographyVars,
  })
  .css({
    colors: colorStyles,
    layout: layoutStyles,
    typography: typographyStyles,
  })
  .create()
