import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function updatePrefix(payload, configType) {
  const country = payload.countryname
  const network = payload.networkname
  const prefix = {
    providers: payload.providers,
    error: payload.error,
    samples: payload.samples,
  }
  if (country && network) {
    if (payload.customer) {
      return new Promise((resolve, reject) => {
        request
          .post(`${API_URL}/api/v1/customers/prefixes/${configType}/${payload.customer.toLowerCase()}/${country}/${network}`)
          .set('Authorization', `bearer ${getToken()}`)
          .send(prefix)
          .then((resp) => {})
          .catch((err) => {
            reject(err)
          })
      })
    }
    return new Promise((resolve, reject) => {
      request
        .post(`${API_URL}/api/v1/global-config/prefixes/${configType}/${country}/${network}`)
        .set('Authorization', `bearer ${getToken()}`)
        .send(prefix)
        .then((resp) => {})
        .catch((err) => {
          reject(err)
        })
    })
  }
  if (payload.customer) {
    return new Promise((resolve, reject) => {
      request
        .post(`${API_URL}/api/v1/customers/prefixes/${configType}/${payload.customer.toLowerCase()}/${country}/${network}`)
        .set('Authorization', `bearer ${getToken()}`)
        .send(prefix)
        .then((resp) => {})
        .catch((err) => {
          reject(err)
        })
    })
  }
  return new Promise((resolve, reject) => {
    request
      .post(`${API_URL}/api/v1/global-config/prefixes/${configType}/${country}`)
      .set('Authorization', `bearer ${getToken()}`)
      .send(prefix)
      .then((resp) => {})
      .catch((err) => {
        reject(err)
      })
  })
}
