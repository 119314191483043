import React from 'react'
import PropTypes from 'prop-types'
import Joi from 'joi-full'
import Markdown from 'markdown-to-jsx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { get as _get } from 'lodash'
import {
  Col,
  Form,
  Button as AntButton,
  Input,
  Row,
} from 'antd'

import theme from '~plumbing/themes'
import Button from '~plumbing/elements/Button/Button'
import { Alert } from '~plumbing/components/styled'
import HLR from '~plumbing/assets/img/HLR.svg'
import Global from '~plumbing/assets/img/Global.svg'
import Provider from '~plumbing/assets/img/Provider.svg'

import { getUserToken, userSession } from '~CON/User/userHelper'
import { BodyStyle } from '~plumbing/components'
import { userActions } from '~plumbing/api/actions'

import MaintainanceState from '../MaintainanceState'

import { LoginMessages, loginErr } from './i18nStrings'

import {
  Logo,
  ErrorMsg,
  TableItem,
  Container,
  LoginContainer,
  StyledLabel,
  StyledLink,
  StyledTitle,
  StyledLogo,
  ContentLogo,
  MainContentLogo,
  CQSMainContent,
  StyledSpan,
  ContentWrapper,
  TextWrapper,
  WrapperH4,
  WrapperParagraph,
  FooterIcons,
  StyledWelcome,
  VerticalLine,
  LoginView,
  StyledAntButton,
  PasswordWithRevealWrap,
  // HorizontalLine,
} from './styled'
import './Login.less'
import jwtDecode from '~utils/jwtDecoder'
import ScarletIcon from '~plumbing/assets/scarletIcons/ScarletIcon'
import eyeIcon from '~plumbing/assets/scarletIcons/icons/eye.svg'
import eyeoffIcon from '~plumbing/assets/scarletIcons/icons/eye-off.svg'
import CQSHintMessage from '~plumbing/CQSComponents/CQSHintMessage'


const mainLogo = theme.var.images.logoDark
const { chatLogoGrey } = theme.var.images
const { sendLogo } = theme.var.images
const { whatsAppLogo } = theme.var.images
const { appleLogo } = theme.var.images
const { messengerLogo } = theme.var.images

export const errCodes = {
  1001: 'userInvalid',
  1002: 'userLocked',
  1003: 'userExpired',
  1004: 'userDeleted',
  1005: 'accountExpired',
  1006: 'accountInactive',
  1007: 'accountDeleted',
  1008: 'apiNotAllowed',
  2001: 'serverError',
  1018: 'serverError',
  1030: 'userInvalid',
  1043: 'userDeactivated',
  1046: 'accountDeactivated',
  1053: 'accountNotConfirmed',
  1056: 'userexceedResendConfirmationEmail',
  1097: 'deewanUsersNotAuthorizedHere',
}

class Login extends React.Component {
  static propTypes = {
    history: PropTypes.object, // eslint-disable-line
    isLoading: PropTypes.bool, // eslint-disable-line
    invalidCredentials: PropTypes.bool, // eslint-disable-line
    actions: PropTypes.shape({
      login: PropTypes.func, // eslint-disable-line
      clearValidationErrors: PropTypes.func, // eslint-disable-line
    }),
    errCode: PropTypes.number,
  }

  static defaultProps = {
    isLoading: false,
    invalidCredentials: false,
    actions: {
      login: () => null,
      clearValidationErrors: () => null,
    },
    errCode: null,
  }

  static validationSchema = Joi.object({
    username: Joi.string().required(),
    password: Joi.string().required(),
  })

  constructor(props) {
    super(props)
    this.state = {
      username: {
        value: null,
        validationState: '',
      },
      password: {
        value: null,
        validationState: '',
      },
      showResendVerification: false,
    }

    this.handleRedirect()
  }

  componentDidMount() {
    // HubSpot Embedded Code
    const domain = window.location.origin.match(/.sa/g)
      ? process.env.REACT_APP__CEQUENS_SA_DOMAIN
      : process.env.REACT_APP__CEQUENS_DOMAIN

    document.cookie = `consoleToken=;domain=${domain};Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    const script = document.createElement('script')

    script.src = '//js.hs-scripts.com/4060184.js'
    script.id = 'hs-script-loader'
    script.async = true

    document.body.appendChild(script)
  }

  componentWillReceiveProps() {
    this.handleRedirect()
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-unused-expressions
    document.getElementById('hubspot-messages-iframe-container')?.remove?.()
    sessionStorage.removeItem('sessionExpired')
  }

  handleRedirect = () => {
    if (getUserToken()) {
      const { prevLocation } = window.__loc__ || {}
      const { pathname } = prevLocation || {}

      const backPath = (pathname && pathname !== '/login') // eslint-disable-line
        ? pathname
        : '/'
      this.props.history.push(backPath)
    }
  }

  handleSubmit = () => {
    this.setState(({ username, password }) => {
      const result = Login
        .validationSchema
        .validate({
          username: username.value,
          password: password.value,
        })

      const isInvalid = !!result.error

      if (isInvalid) {
        return result
          .error
          .details
          .reduce((accum, err) => {
            const { key } = err.context

            return {
              ...accum,
              [key]: {
                value: '',
                validationState: 'error',
              },
            }
          }, {})
      }

      this.props.actions.login({
        username: result.value.username,
        password: result.value.password,
      })

      return undefined
    })
  }

  handleUserNameChange = (evt) => {
    this.props.actions.clearValidationErrors()

    const result = Login
      .validationSchema
      .validate({ username: evt?.target?.value?.trim?.() })

    const hasError = !!result.error
    const usernameEmpty = result?.value?.username === ''

    const isInvalid = hasError && usernameEmpty

    this.setState({
      username: {
        value: isInvalid ? null : result?.value?.username?.trim?.(),
        validationState: isInvalid ? 'error' : 'success',
      },
    })
  }

  handlePasswordChange = (evt) => {
    this.props.actions.clearValidationErrors()

    const result = Login
      .validationSchema
      .validate({ password: evt.target.value })

    const hasError = !!result.error
    const usernameEmpty = result.value.username === ''

    const isInvalid = hasError && usernameEmpty

    this.setState({
      password: {
        value: isInvalid ? null : result.value.password,
        validationState: isInvalid ? 'error' : 'success',
      },
    })
  }

  handleResendActivationEmail = () => {
    this.setState({
      showResendVerification: true,
    })
    this.props.actions.resendVerificationEmail({ login: this.state.username.value })
  }

  render() {
    userSession().unlock()
    const renderView = () => {
      if ((this.props.errCode === '1053' || this.props.errCode === '1056') || this.state.showResendVerification) {
        return (
          <Button
            type="primary"
            loading={this.props.resendVerificationEmailLoading}
            onClick={this.handleResendActivationEmail}
            className="login-form-button login-button"
          >
            {loginErr.getFormatMessage('resendEmail')}
          </Button>
        )
      }

      // Google federation //////////////////////
      const googleTokenId = this.state.federationToken
      let isAlreadyRegistered = null

      // debugger // eslint-disable-line
      if (googleTokenId) {
        const jwtToken = jwtDecode(googleTokenId)
        isAlreadyRegistered = jwtToken?.['custom:AccountID']
      }

      if (googleTokenId && isAlreadyRegistered) {
        this.props.onLoginUsingGoogleToken({
          idToken: this.state.federationToken,
          accessToken: this.state.federationAccessToken,
          refreshToken: this.state.federationRefreshToken,
        })
      }
      return (
        <LoginView>
          <UsernameInput
            onChange={this.handleUserNameChange}
            disabled={this.props.isLoading}
            validationState={this.state.username.validationState}
          />
          <PasswordInput
            onChange={this.handlePasswordChange}
            disabled={this.props.isLoading}
            validationState={this.state.password.validationState}
          />
          <Form.Item>
            <Submit
              className="pull-right"
              onClick={this.handleSubmit}
              style={{ minWidth: '120px', height: '40px' }}
              spinner={this.props.isLoading}
            />
            <StyledLink
              to="/user/forget"
              className="pull-left"
              style={{ marginTop: '12px' }}
            >
              {LoginMessages.getFormatMessage('forget_Password')}
            </StyledLink>
          </Form.Item>
          <br />
        </LoginView>
      )
    }
    return (
      <LoginPageWrapper
        errCode={this.props.errCode}
        username={this.state.username.value}
        showError={this.props.invalidCredentials || this.props.resendVerificationEmailFailed}
      >
        {renderView()}
      </LoginPageWrapper>
    )
  }
}


function mapStateToProps(store) {
  const { isUsernameInvalid, isPasswordInvalid } = false

  const {
    errCode,
    mfaType,
    isLoading,
    resendUsers,
    showOTPAuth,
    initiateMFA,
    chooseMfaView,
    initiateMfaSms,
    federationLogin,
    chooseMfaViewData,
    showOTPAuthForSMS,
    invalidCredentials,
    mfaSmsCodeValidation,
    resendVerificationEmail,
    resendVerificationEmailFailed,
    verificationCodeSentAndVerified,
  } = store.User?.current || {}

  // const userRole = store.User?.current?.role || store.User?.current?.token?.role

  const isResendSuccessful = () => {
    // eslint-disable-next-line max-len
    const value = Object.values(resendUsers || {})[0]?.meta?.isSuccess // todo: find a way to pass username directly

    return value
  }

  return {
    errCode,
    isLoading,
    federationLogin,
    chooseMfaViewData,
    isUsernameInvalid,
    isPasswordInvalid,
    showOTPAuthForSMS,
    invalidCredentials,
    resendVerificationEmail,
    chooseMfa: chooseMfaView,
    resendVerificationEmailFailed,
    isMfaSmsRequested: initiateMfaSms,
    isMfaSmsInitiated: initiateMfaSms?.meta?.isSuccess,
    resendVerificationEmailSucceeded: isResendSuccessful(),
    isSmsMfaCodeValid: mfaSmsCodeValidation?.meta?.isSuccess,
    showMFA: mfaType === 'TOTP' || initiateMFA?.meta || showOTPAuth,
    mobileVerificationCompleted: verificationCodeSentAndVerified,
    showMfaSms: mfaType === 'SMS'
      || showOTPAuthForSMS
      || initiateMfaSms?.meta,
    resendVerificationEmailLoading: Object.values(resendUsers || {})[0]?.meta?.isLoading,
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    dispatch,
    actions: {
      login: bindActionCreators(userActions.login, dispatch),
      clearValidationErrors: bindActionCreators(userActions.clearValidationErrors, dispatch),
      resendVerificationEmail: bindActionCreators(userActions.resendVerificationEmail, dispatch),
    },
    onChoosingMfaSms: (...args) => {
      dispatch(userActions.initiateMfaSms(...args))

      // eslint-disable-next-line no-unused-expressions
      ownProps?.onChoosingMfaSms?.(...args)
    },
    onLoginUsingGoogleToken: (...args) => {
      dispatch(userActions.loginUsingGoogleToken(...args))

      // eslint-disable-next-line no-unused-expressions
      ownProps?.onLoginUsingGoogleToken?.(...args)
    },
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Login)


// Extras

function LoginPageWrap(props) {
  const errType = _get(errCodes, props.errCode)  // eslint-disable-line
  const errorMD = errType && !(props.errCode === 1096)
    ? (
      <Markdown>
        {loginErr.getFormatMessage(errType)}
      </Markdown>
    ) : null

  const renderSuccessMsg = () => {
    return props.showSuccess
      ? (
        <Alert success>
          <p>
            {loginErr.getFormatMessage('resendVerificationEmailSuccess')}
          </p>
        </Alert>
      )
      : null
  }

  const renderLoginView = () => {
    return (
      <>
        {renderSuccessMsg()}
        <ErrorMsg visible={props.showError}>
          {errorMD}
        </ErrorMsg>
        <Form className="login-form ">
          <div className="form-group">
            {props.children}
          </div>
        </Form>
      </>
    )
  }
  const renderView = () => {
    // debugger // eslint-disable-line
    return renderLoginView()
  }

  const wrapperStyles = window.screen.width > 768
    ? {
      width: '110%',
      height: '100%',
      display: 'flex',
      padding: '50px 50px',
      backgroundColor: '#fff',
      borderRadius: theme.var.layout.roundCorner.md.value,
      border: `1px solid ${theme.var.colors.helper.greys.grey20}`,
    }
    : {
      width: '100%',
      margin: '55px',
      padding: '0 20px',
      display: 'flex',
      backgroundColor: 'transparent',
    }
  const isRedirectedAfterSessionExpiry = sessionStorage.getItem('sessionExpired')
  const _BLOCKED_FOR_MAINTENANCE_ = process.env.REACT_APP__FEAT__BLOCKED_FOR_MAINTAINANCE === 'true;'
  return _BLOCKED_FOR_MAINTENANCE_
    ? (
      <MaintainanceState
        title="System Maintenance"
        description="CEQUENS Communication Platform is undergoing maintenance. Services will be operational shortly. Thank you for understanding."
      />
    )
    : (
      <Container>
        <LoginContainer>
          <StyledLogo>
            <Logo
              height={20}
              src={mainLogo}
              alt="Cequens.com logo"
            />
          </StyledLogo>
          <TableItem>
            <div className={`panel panel-default login-container mian-login ${(props.showError ? 'loginWrapError' : '')}`}>
              <div style={wrapperStyles}>
                {
                  (window.screen.width > 768
                    ? (
                      <>
                        <div className="panel-body">
                          <StyledWelcome>
                            <FormattedMessage
                              {...LoginMessages.welcomeText}
                            />
                          </StyledWelcome>
                          <Row>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                              <VerticalLine>
                                {renderCQSLoginInfo()}
                              </VerticalLine>
                            </Col>
                            <Col className="LoginCol" xs={6} sm={6} md={6} lg={6} xl={6}>
                              <StyledTitle>
                                Log In
                              </StyledTitle>
                              <CQSHintMessage
                                icon
                                style={{ margin: '5px 20px' }}
                                show={isRedirectedAfterSessionExpiry}
                              >
                                Your session has expired,
                                {' '}
                                please login again
                              </CQSHintMessage>

                              {renderView()}
                            </Col>
                          </Row>
                        </div>
                      </>
                    )
                    : (
                      <>
                        <StyledTitle>
                          <FormattedMessage
                            {...LoginMessages.loginButton}
                          />
                        </StyledTitle>
                        <div className="panel-body">
                          <Col span={24}>
                            {renderView()}
                          </Col>
                        </div>
                      </>
                    )
                  )
                }
              </div>
            </div>
          </TableItem>
        </LoginContainer>
        {/* <ConnectivityStatus /> */}
      </Container>
    )
}

const LoginPageWrapper = connect(mapStateToProps, mapDispatchToProps)(LoginPageWrap)

LoginPageWrapper.propTypes = {
  children: PropTypes.any, // eslint-disable-line
  showError: PropTypes.bool,
}

LoginPageWrapper.defaultProps = {
  children: null,
  showError: false,
}


function UsernameInput(props) {
  return (
    <>
      <StyledLabel>
        Email
      </StyledLabel>
      <Form.Item
        validateStatus={props.validationState}
      >
        <Input
          disabled={props.disabled}
          onChange={props.onChange}
          placeholder={LoginMessages.getFormatMessage('usernamePlaceholder')}
        />
        <ErrorMsg visible={props.validationState === 'error'}>
          &nbsp;
          <FormattedMessage
            {...LoginMessages.usernameValidationMsg}
          />
        </ErrorMsg>
      </Form.Item>
    </>
  )
}

UsernameInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  validationState: PropTypes.string,
}

UsernameInput.defaultProps = {
  disabled: false,
  onChange: () => null,
  validationState: '',
}
function PasswordInput(props) {
  const [showPassword, setShowPassword] = React.useState()

  const inputType = showPassword ? 'text' : 'password'

  const getHandelShowPassword = (toggle) => {
    return () => {
      setShowPassword(toggle)
    }
  }

  return (
    <>
      <StyledLabel>
        <FormattedMessage
          {...LoginMessages.passwordLabel}
        />
      </StyledLabel>

      <Form.Item
        validateStatus={props.validationState}
      >

        <PasswordWithRevealWrap>
          <Input
            type={inputType}
            disabled={props.disabled}
            onChange={props.onChange}
            placeholder={LoginMessages.getFormatMessage('passwordLabel')}
          />

          <Button
            type="button"
            onClick={getHandelShowPassword(!showPassword)}
          >
            {showPassword ? (
              <ScarletIcon
                hash="eye"
                href={eyeIcon}
                iconSize={16}
              />
            )
              : (
                <ScarletIcon
                  hash="eye-off"
                  href={eyeoffIcon}
                  iconSize={16}
                />
              )}
            <ScarletIcon
              iconSize={16}
              name={showPassword ? 'eye' : 'eye-off'}
            />
          </Button>
        </PasswordWithRevealWrap>

        <ErrorMsg.Error visible={props.validationState === 'error'}>
          <FormattedMessage
            {...LoginMessages.passwordValidationMsg}
          />
        </ErrorMsg.Error>
      </Form.Item>
    </>
  )
}
/*
 <i style={{ marginRight: '120px' }}>
   <ContentLogo src={passwordLogo}/>
  </i>
*/

PasswordInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  validationState: PropTypes.string,
}

PasswordInput.defaultProps = {
  disabled: false,
  onChange: () => null,
  validationState: '',
}


function Submit(props) {
  return (
    <BodyStyle style={{ background: theme.bgBodyDarker }}>
      <React.Fragment>
        <StyledAntButton
          {...props}
          type="primary"
          htmlType="submit"
          onClick={props.onClick}
          loading={props.spinner}
        >
          <FormattedMessage
            {...LoginMessages.loginButton}
          />
        </StyledAntButton>
      </React.Fragment>
    </BodyStyle>
  )
}

Submit.propTypes = {
  onClick: PropTypes.func,
  spinner: PropTypes.bool,
}

Submit.defaultProps = {
  onClick: () => null,
  spinner: false,
}
const renderCQSLoginInfo = () => {
  return (
    <>
      <CQSMainContent>
        <StyledSpan>
          <FormattedMessage
            {...LoginMessages.contentInfo}
          />
        </StyledSpan>
        <ContentWrapper>
          <MainContentLogo src={HLR} />
          <TextWrapper>
            <WrapperH4>
              <FormattedMessage
                {...LoginMessages.campaigns}
              />
            </WrapperH4>
            <WrapperParagraph>
              <FormattedMessage
                {...LoginMessages.campaignsContent}
              />
            </WrapperParagraph>
          </TextWrapper>
        </ContentWrapper>
        <ContentWrapper>
          <MainContentLogo src={Global} />
          <TextWrapper>
            <WrapperH4>
              <FormattedMessage
                {...LoginMessages.chats}
              />
            </WrapperH4>
            <WrapperParagraph>
              <FormattedMessage
                {...LoginMessages.chatsContent}
              />
            </WrapperParagraph>
          </TextWrapper>
        </ContentWrapper>
        <ContentWrapper>
          <MainContentLogo src={Provider} />
          <TextWrapper>
            <WrapperH4>
              <FormattedMessage
                {...LoginMessages.channels}
              />
            </WrapperH4>
            <WrapperParagraph>
              <FormattedMessage
                {...LoginMessages.channelsContent}
              />
            </WrapperParagraph>
          </TextWrapper>
        </ContentWrapper>
        <FooterIcons>
          <ContentLogo src={sendLogo} />
          {/* <ContentLogo src={voiceLogo} /> */}
          {/* <ContentLogo src={emailLogo} /> */}
          {/* <ContentLogo src={notificationLogo} /> */}
          <ContentLogo src={whatsAppLogo} />
          <ContentLogo src={messengerLogo} />
          <ContentLogo src={appleLogo} />
          <ContentLogo src={chatLogoGrey} />
        </FooterIcons>
      </CQSMainContent>
    </>
  )
}
