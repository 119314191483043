import { camelCase as _camelCase, get as _get } from 'lodash'
import { renameFunc } from 'CQS/plumbing/utils'

export const actionsTypes = {
  FETCH_MASTERDETAILS: 'discovery/MasterDetails/MasterDetails',
  FETCH_MASTERDETAILS_SUCCESS: 'discovery/MasterDetails/MasterDetailsSucess',
  FETCH_MASTERDETAILS_FAILED: 'discovery/MasterDetails/MasterDetailsFail',
  EXPORT_MASTERDETAILS: 'discovery/MasterDetails/ExportMasterDetails',
  EXPORT_MASTERDETAILS_SUCCESS: 'discovery/MasterDetails/ExportMasterDetailsSucess',
  EXPORT_MASTERDETAILS_FAILED: 'discovery/MasterDetails/ExportMasterDetailsFail',
  FETCH_MNP_DATABASE: 'discovery/MasterDetails/MNPDatabase',
  FETCH_MNP_DATABASE_SUCCESS: 'discovery/MasterDetails/MNPDatabaseSucess',
  FETCH_MNP_DATABASE_FAILED: 'discovery/MasterDetails/MNPDatabaseFail',
  FETCH_KSA_DATABASE: 'discovery/MasterDetails/KSADatabase',
  FETCH_KSA_DATABASE_SUCCESS: 'discovery/MasterDetails/KSADatabaseSucess',
  FETCH_KSA_DATABASE_FAILED: 'discovery/MasterDetails/KSADatabaseFail',
  FETCH_RAGHI_DATABASE: 'discovery/MasterDetails/RaghiDatabase',
  FETCH_RAGHI_DATABASE_SUCCESS: 'discovery/MasterDetails/RaghiDatabaseSucess',
  FETCH_RAGHI_DATABASE_FAILED: 'discovery/MasterDetails/RaghiDatabaseFail',
}
export const actions = Object.keys(actionsTypes)
  .reduce((accum, id) => {
    const creatorName = _camelCase(id)

    const creatorFunction = function _(payload, meta) {
      return {
        type: _get(actionsTypes, id),
        payload,
        meta,
      }
    }

    // eslint-disable-next-line no-param-reassign
    accum[creatorName] = renameFunc(creatorFunction, creatorName)
    return accum
  }, {})
