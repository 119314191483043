import request from 'superagent'
import { from } from 'rxjs'
import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function deletePrefixes(payload, configType) {
  const country = payload.countryId
  const network = payload.networkId

  let url = `${API_URL}/api/v1`
  if (payload.customer) {
    url += `/customers/prefixes/${configType?.toLowerCase()}/${payload.customer.toLowerCase()}/`
  } else url += `/global-config/prefixes/${configType?.toLowerCase()}/`
  if (country) {
    url += `${country}/`
  }
  if (country && network) {
    url += `${network}`
  }


  return from(new Promise((resolve, reject) => {
    request
      .delete(url)
      .set('Authorization', `bearer ${getToken()}`)
      .then((resp) => {})
      .catch((err) => {
        reject(err)
      })
  }))
}
