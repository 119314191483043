import React from 'react'
import {
  get as _get,
  isObject as _isObject,
  isFunction as _isFunction,
} from 'lodash'

import type { Node as ReactNode } from 'react'

import getFeatFlags from './getFeatFlags'

// foobar

export function FeatCtrl(props) {
  const featFlags = getFeatFlags()

  const show = props?.show?.(featFlags) || false
  const hide = props?.hide?.(featFlags) || !show

  const isRenderProps = _isFunction(props.children) || _isFunction(props.render)

  if (show) {
    return props.children
  }

  if (hide) {
    return null
  }

  if (isRenderProps) {
    if (_isFunction(props.render)) {
      return props.render(featFlags)
    }

    if (_isFunction(props.children)) {
      return props.render(featFlags)
    }
  }

  console.warn('[FeatCtrl] fallthrough,\tno control props passed')
  return props.children
}

/**
 *
 * @param {function(featFlags, ownProps): *} mapFeatToProps
 * @param WrappedComponent
 * @param options
 * @returns {FeatCtrl}
 */
function withFeatCtrl(mapFeatToProps, WrappedComponent: any, options: Object = {}) : ReactNode { // eslint-disable-line
  const { classPropertyMap } = options

  const getPassedProps = (props) => {
    if (mapFeatToProps === null || mapFeatToProps === undefined) {
      return props
    }

    if (typeof mapFeatToProps === 'function') {
      const mappedProps = mapFeatToProps(getFeatFlags(), props)
      const isObjMappedProps = _isObject(mappedProps)

      if (isObjMappedProps) {
        return {
          ...props,
          ...mappedProps,
          ref: props.innerRef,
          innerRef: undefined,
        }
      }

      console.error(`withFeatCtrl mapFeatToProps returned ${typeof mappedProps} instead of Object
        for ${WrappedComponent.displayName || WrappedComponent.name}`)
      return props
    }

    console.error(`withFeatCtrl mapFeatToProps is ${typeof mapFeatToProps} should be a function for ${WrappedComponent.displayName || WrappedComponent.name}`)

    return props
  }

  // eslint-disable-next-line no-shadow
  class FeatCtrl extends React.Component {
    static displayName = `FeatCtrl(${WrappedComponent.name})`

    displayName = `FeatCtrl(${WrappedComponent.name})`

    constructor(props) {
      super(props)
      this.state = {
        passProps: getPassedProps(props),
      }
    }

    componentWillReceiveProps(nextProps) {
      this.setState({ passProps: getPassedProps(nextProps) })
    }

    get name() {
      return `FeatCtrl(${WrappedComponent.name})`
    }

    render() {
      return <WrappedComponent {...this.state.passProps} />
    }
  }

  if (Array.isArray(classPropertyMap) && classPropertyMap.length) {
    classPropertyMap.forEach((key) => {
      FeatCtrl[key] = _get(WrappedComponent, key)
    })
  }

  return FeatCtrl
}

export default withFeatCtrl

/*

 <PinnedCampaing hideOnFlag={(ft) {
  if (ft.foobar) {
    return false
  }

  if (ft.foobar === 'baz') {
    return false
  }

  return true
 }/>

 <PinnedCampaing showOnFlag="CAM_WIZ"/>

 <FeatCtrl show flag="CAM_WIZ" is={true} not={}>
    <PinnedCampaing showOnFlag="CAM_WIZ"/>
 </FeatCtrl

 <FeatCtrl show={(fl) => fl.CAM_WIZ === true}>
    <PinnedCampaing showOnFlag="CAM_WIZ"/>
 </FeatCtrl>

 */
