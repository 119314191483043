import styled from 'styled-components'

/**
 *
 *
 * @example
 * <EmptyCard  />
 */
export const EmptyCard = styled.div`
  width:100%;
  background: #f9f9f9;
  box-shadow:0px 0px 7px rgba(0,0,0,.1);
  margin-${({ theme }) => theme.dirFloatRev}:15px;
  float:${({ theme }) => theme.dirFloat};
  height:80px;
  color:#333;
  margin-top:40px;
  & > i {
    display:block;
    float:${({ theme }) => theme.dirFloat};
    margin-${({ theme }) => theme.dirFloatRev}:20px;
    color: #fff;
    background: #DADADA;
    font-size:30px;
    height:80px;
    line-height:80px;
    width:70px;
    text-align:center;
    border-${({ theme }) => theme.dirFloatRev}:1px solid #DADADA;
    height:80px;
  }
  div{
    float:${({ theme }) => theme.dirFloat};
    p{
      line-height: 0px;
      font-size: 14px;
      color: #5a626d;
    }
    h3{
      color: #5a626d;
      font-size:16px;
      font-weight: 700;
      line-height: 55px;
    }
  }
  & > button{
    float:${({ theme }) => theme.dirFloatRev};
    margin-${({ theme }) => theme.dirFloatRev}:20px;
    margin-top:20px;
  }
`
