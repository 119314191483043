import styled from '~utils/themed'

export const MobileNumberWrapper = styled.div`
  position: relative;
  border: 1px #ddd solid;
  
  img {
    top: 3px;
    z-index: 1000;
    position: absolute;
    @-dir: 8px;
  }

  input {
    @padding-dir: 36px;

    border: 0!important;
    outline: 0!important;

    &:focus, &:hover {
      box-shadow: none!important;
    }

    ${styled.isRTL`
       text-align: right;
       unicode-bidi: plaintext;
    `}
  }
`
