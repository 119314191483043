import { camelCase as _camelCase, get as _get } from 'lodash'
import { renameFunc } from 'CQS/plumbing/utils'

export const actionsTypes = {
  CHANGE_PASSWORD: 'discovery/Profile/ChangePassword',
  CHANGE_PASSWORD_SUCCESS: 'discovery/Profile/ChangePasswordSuccess',
  CHANGE_PASSWORD_FAILED: 'discovery/Profile/ChangePasswordFailed',
}
export const actions = Object.keys(actionsTypes).reduce((accum, id) => {
  const creatorName = _camelCase(id)

  const creatorFunction = function _(payload, meta) {
    return {
      type: _get(actionsTypes, id),
      payload,
      meta,
    }
  }

  // eslint-disable-next-line no-param-reassign
  accum[creatorName] = renameFunc(creatorFunction, creatorName)
  return accum
}, {})
