import React from 'react'
import styled, { css } from 'styled-components'
// import { Icon } from 'fa-shorthand.macro'


/**
 *
 * @param {object} props
 * @param {boolen} props.warn - warning highlight
 * @param {boolen} props.error - Error highlight
 *
 * @example
 * <Input warn />
 */
export function Alert(props) {
  return (
    <AlertStyled {...props}>
      {props.children}
      {
        props?.withCloseBtn && (
          <a className="close-btn pull-right" onClick={props.onClose}>
            {/* <Icon icon={Icon.solid.times}/> */}
          </a>
        )
      }
    </AlertStyled>
  )
}

const AlertStyled = styled.div`
  width: 100%;
  padding: 10px 20px;
  margin: 20px 0;
  font-size:13px;
  border-radius: ${({ theme }) => theme.roundCorner};
  border:1px solid transparent;
  .close-btn {
    color: rgba(0,0,0,.2);
    &:hover, &:focus, &:active {
      color: rgba(0,0,0,.3) !important;
    }
  }
  p{
    font-size: 12px;
    text-align: ${({ theme }) => theme.toggleDir({ rtl: 'right', ltr: 'left' })};
    color: ${({ theme }) => theme.textDark};
  }

  ${({ success }) => success
    && css`
      background: ${({ theme }) => theme.hintGreenBg};
      border-color: ${({ theme }) => theme.hintGreenBorder};
      p {
        color: ${({ theme }) => theme.hintGreenTxtColor};
      }
  `};

${({ info }) => info
  && css`
     background: ${({ theme }) => theme.hintYellowBg};
     border-color: ${({ theme }) => theme.hintYellowBorder};
     p {
        color: ${({ theme }) => theme.hintYellowTxtColor};
     }
  `};

${({ danger }) => danger
  && css`
     background: ${({ theme }) => theme.hintRedBg};
     border-color: ${({ theme }) => theme.hintRedBorder};
     color: ${({ theme }) => theme.hintRedTxtColor};
     p {
      color: ${({ theme }) => theme.hintRedTxtColor};
     }
  `};
`
