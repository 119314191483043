import { createTheme } from '../utils'

import cequensRed from '../cequensRed'

import colorsVar from './colors.var'


export default createTheme('telecomEgypt')
  .extends(cequensRed)
  .var({
    colors: colorsVar,
  })
  .create()
