import { inCase } from '~plumbing/utils'
import {
  actions as customerActions,
  actionsTypes,
} from './CustomerManagementActions'

const defaultState = {
  customers: {
    CustomerData: [],
    CustomizedData: [],
    mnpPlus: {},
    meta: {
      isLoading: false,
      isCustomLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
}
const customerReducer = inCase.curry('customerReducer')
  .check((s, action) => action.type)
  .otherwise((state) => state || defaultState)
  .cases({
    [actionsTypes.FETCH_ALL_CUSTOMERS]: fetchAllCustomers,
    [actionsTypes.FETCH_ALL_CUSTOMERS_SUCCESS]: fetchAllCustomersSuccess,
    [actionsTypes.FETCH_ALL_CUSTOMERS_FAILED]: fetchAllCustomersFailed,
    [actionsTypes.FETCH_CUSTOMIZED_CUSTOMERS]: fetchCustomizedCustomers,
    [actionsTypes.FETCH_CUSTOMIZED_CUSTOMERS_SUCCESS]: fetchCustomizedCustomersSuccess,
    [actionsTypes.FETCH_CUSTOMIZED_CUSTOMERS_FAILED]: fetchCustomizedCustomersFailed,
    [actionsTypes.UPDATE_CUSTOMERS_MNP]: setCustomersMnp,
    [actionsTypes.UPDATE_CUSTOMERS_MNP_SUCCESS]: setCustomersMnpSuccess,
    [actionsTypes.UPDATE_CUSTOMERS_MNP_FAILED]: setCustomersMnpFailed,
  })
function fetchCustomizedCustomers(state, action) {
  return {
    ...state, // Ensure you return the entire state object
    customers: {
      ...state.customers,
      meta: {
        ...state.customers.meta,
        isCustomLoading: true,
      },
    },
  }
}
function fetchCustomizedCustomersSuccess(state, action) {
  return {
    ...state,
    customers: {
      ...state.customers,
      CustomizedData: action.payload,
      meta: {
        ...state.customers.meta,
        isLoading: false,
        isCustomLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchCustomizedCustomersFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchAllCustomers(state, action) {
  return {
    ...state,
    customers: {
      ...state.customers,
    },
    meta: {
      ...state.customers.meta,
      isLoading: true,
    },
  }
}
function fetchAllCustomersSuccess(state, action) {
  return {
    customers: {
      ...state.customers,
      CustomerData:
        action.payload,
      meta: {
        isLoading: false,
        isCustomLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchAllCustomersFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function setCustomersMnp(state, action) {
  return {
    ...state,
    meta: {
      ...state.meta,
      isLoading: true,
      isCustomLoading: true,
    },
  }
}
function setCustomersMnpSuccess(state, action) {
  return {
    ...state,
    customers: {
      mnpPlus:
        action.payload,
      meta: {
        isLoading: false,
        isCustomLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function setCustomersMnpFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
export default customerReducer
