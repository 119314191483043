import { get as _get, isObject as _isObject } from 'lodash'

import { inCase } from '~plumbing/utils'

import themed from './themed'
import {
  parseCssProp,
  parseCssVal,
  renameFunc,
} from './helpers'


/**
 * @typedef {function(token: string): boolean} MatchFunc
 * @typedef {function(token: string): function} ParseFunc
 * @typedef {{match: MatchFunc, parse: ParseFunc}} TokenParse
 */


/**
 * props token match
 *
 * @example
 *  font-size: @@size;
 *
 * @type {TokenParse}
 */
const tokenProp = {
  match: (token) => token && !!match(token, /^@@/),

  parse: (token) => {
    const tokenMatch = match(token, /^@@(.*)(:|;)/)
    const path = _get(tokenMatch, '1')

    const func = (props) => {
      const prop = _get(props, path)
      return parseCssVal(prop, path)
    }

    return renameFunc(func, token)
  },
}


/**
 * theme css token match
 *
 * @example
 *  color: @css-color.primary;
 *
 * @type {{matchIsThemeCss: MatchFunc, precessIsThemeCss: ParseFunc}}
 */
const tokenThemeCss = {
  match: (token) => token && !!match(token, /^@css-/i),

  parse: (token) => {
    const path = _get(match(token, /^@css-(.*)(:|;)/), '1')

    const func = (props) => {
      const prop = _get(props, `theme.css.${path}`)

      return parseCssVal(prop, `css-${path}`)
    }

    return renameFunc(func, token)
  },
}


/**
 * theme vars token match
 *
 * @example
 *  color: @var-color.primary;
 *
 * @type {{matchIsThemeCss: MatchFunc, precessIsThemeCss: ParseFunc}}
 */
const tokenThemeVar = {
  match: (token) => token && !!match(token, /^@var-/i),

  parse: (token) => {
    const path = _get(match(token, /^@var-(.*)(:|;)/), '1')

    const func = (props) => {
      const prop = _get(props, `theme.var.${path}`)
      return parseCssVal(prop, `var-${path}`)
    }

    return renameFunc(func, token)
  },
}

/**
 * theme vars token match
 *
 * @example
 *  color: @var-color.primary;
 *
 * @type {{matchIsThemeCss: MatchFunc, precessIsThemeCss: ParseFunc}}
 */
const tokenCssPropDir = {
  match: (token) => token && !!match(token, /^@(.*)-(dir(Inv)?)/i),

  parse: (token) => {
    const cfg = match(token, /^@(.*)-(dir(Inv)?)/i) || []
    const getDir = themed.getUserLocaleDir
    const cssProp = _get(cfg, '1')
    const isDirInv = _get(cfg, '3')

    const func = () => {
      const userDir = (getDir() === 'ltr')
        ? 'left'
        : 'right'

      const userDirInv = (userDir === 'left')
        ? 'right'
        : 'left'

      const propDir = isDirInv
        ? userDirInv
        : userDir

      const propName = cssProp
        ? `${cssProp}-${propDir}`
        : propDir

      return parseCssProp(propName, token)
    }

    return renameFunc(func, token)
  },
}

/**
 * EXPERIMENTAL
 *
 * @example
 *  float: @dir;
 *  float: @dirInv;
 *
 * @type {{matchIsThemeCss: MatchFunc, precessIsThemeCss: ParseFunc}}
 */
const tokenDirVal = {
  match: (token) => {
    const data = (token && match(token, /^@(dir(Inv)?)/i)) || []

    return !!data
  },
  parse: (token) => {
    const matched = match(token, /^@(dir(Inv)?)/i) || []
    // const dirKey = _get(matched, '2', 'dir')
    const isDirInv = _get(matched, '1')
    const getDir = themed.getUserLocaleDir

    const func = () => {
      const userDir = (getDir() === 'ltr')
        ? 'left'
        : 'right'

      const userDirInv = (userDir === 'left')
        ? 'right'
        : 'left'

      const dir = isDirInv
        ? userDirInv
        : userDir

      return parseCssVal(dir, token)
    }

    return renameFunc(func, token)
  },
}

/**
 * EXPERIMENTAL
 *
 * @example
 *  display: @if_hide => none -> block;
 *  @if_right => float: @if_right => right -> clear;
 *  @if_left => float: @if_left => left;
 *
 * @type {{matchIsThemeCss: MatchFunc, precessIsThemeCss: ParseFunc}}
 */
const tokenIfEqual = {
  match: (token) => {
    const data = (token && match(token, /(^@if_[\w.-]*)\s?(=> ([\w.-]*))?\s?(-> ([\w.-]*))?/)) || []
    const propPath = _get(data, 1)
    const ifTrue = _get(data, 3)
    const ifFalse = _get(data, 5)

    this.data = { propPath, ifTrue, ifFalse }
    return (propPath && (ifTrue || ifFalse))
  },
  parse: (token) => {
    const data = match(token, /(^@if_[\w.-]*)\s?(=> ([\w.-]*))?\s?(-> ([\w.-]*))?/) || []
    const propPath = _get(data, 1)
    const ifTrue = _get(data, 3)
    const ifFalse = _get(data, 5)
    const parseCss = inCase
      .check(_get(data, 5))
      .otherwise()
      .cases({
        ';': parseCssVal,
        ':': parseCssProp,
      })
      .value()

    const func = (props) => {
      const prop = _get(props, `${propPath}`)

      const val = inCase
        .check(prop)
        .otherwise()
        .cases({
          true: ifTrue,
          false: ifFalse,
        })
        .value()

      return parseCss(val, token)
    }

    return renameFunc(func, token)
  },
}


export const expressionParsers = [
  tokenProp,
  tokenThemeCss,
  tokenThemeVar,
  tokenCssPropDir,
  tokenDirVal,
  tokenIfEqual,
]


export default function processTokens(exps) {
  const r = exps.length
    ? exps
      .map((token) => {
        if (typeof token === 'function') {
          return token
        }

        const len = expressionParsers.length

        for (let i = 0; i < len; i += 1) {
          const expr = expressionParsers[i]

          if (expr.match(token)) {
            return expr.parse(token)
          }
        }

        return renameFunc(() => {
          console
            .warn('[styled-easy]', 'unprocessed token:', token)
          return token
        }, token)
      })
    : exps

  return r
}


function match(target, ...args) {
  const val = _isObject(target)
    ? target.__value__
    : `${target}`

  return val.match(...args)
}
