import theme from '~plumbing/themes'
import styled from '~plumbing/utils/themed'
import Spinner from '~plumbing/elements/Spinner'

export const BtnTitle = styled.span`
  font-size: 13px;
`

export const BtnSubTitle = styled.div`
  font-size: 11px;
  font-weight: normal;
`

export const BaseButton = styled.button`
  padding: 2px 15px;
  height: 34px;
  border-radius: ${() => theme.var.layout.roundCorner.sm.value};
  font-size: 12px;
  font-weight: normal;

  text-align: center;
  outline: none;
  cursor: pointer;
  text-decoration: none;

  user-select: none;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;

  color:  @var-colors.btn.beta.label;
  background-color:  @var-colors.btn.beta.background;
  border: 1px solid  @var-colors.btn.beta.border;

  svg {
    margin-${() => theme.dirFloatRev}: 10px;
  }

  &:hover{
    color:  @var-colors.btn.beta.hover.label;
    background-color:  @var-colors.btn.beta.hover.background;
    border-color: @var-colors.btn.beta.hover.border;
  }

  ${Spinner}{
    display: inline-block;
  }

  ${styled.cases('variant', {
    alpha: styled.css`
      border-color: @var-colors.btn.alpha.border;
      color: @var-colors.btn.alpha.label;
      background: @var-colors.btn.alpha.background;

      &:hover {
        border-color: @var-colors.btn.alpha.hover.border;
        color: @var-colors.btn.alpha.hover.label;
        background: @var-colors.btn.alpha.hover.background;
      }
      svg, [class^="fa-"]{
          color: @var-colors.btn.alpha.label;
      }
      ${Spinner}{
        svg, [class^="fa-"]{
          color: @var-colors.btn.alpha.label;
        }
      }
    `,
    beta: styled.css`
      border-color: @var-colors.btn.beta.border;
      color: @var-colors.btn.beta.label;
      background: @var-colors.btn.beta.background;
      &:hover{
        border-color: @var-colors.btn.beta.hover.border;
        color: @var-colors.btn.beta.hover.label;
        background: @var-colors.btn.beta.hover.background;
      }
      ${Spinner}{
        svg, [class^="fa-"]{
          color: @var-colors.btn.beta.label;
        }
      }
    `,
    gamma: styled.css`
      border-color: @var-colors.btn.gamma.border;
      color: @var-colors.btn.gamma.label;
      background: @var-colors.btn.gamma.background;
      &:hover{
        border-color: @var-colors.btn.gamma.hover.border;
        color: @var-colors.btn.gamma.hover.label;
        background: @var-colors.btn.gamma.hover.background;
      }
      ${Spinner}{
        svg, [class^="fa-"]{
          color: @var-colors.btn.gamma.label;
        }
      }
    `,
    psi: styled.css`
      border-color: @var-colors.btn.psi.border;
      color: @var-colors.btn.psi.label;
      background: @var-colors.btn.psi.background;

      &:hover{
        border-color: @var-colors.btn.psi.hover.border;
        color: @var-colors.btn.psi.hover.label;
        background: @var-colors.btn.psi.hover.background;
      }
      ${Spinner}{
        svg, [class^="fa-"]{
          color: @var-colors.btn.psi.label;
        }
      }
    `,
    link: styled.css`
      color: @var-colors.btn.link.label;
      border: none;
      background:none;

      &:hover{
      color: @var-colors.btn.link.hover.label;
      border: none;
      background:none;
      }
      ${Spinner}{
        svg, [class^="fa-"]{
          color: @var-colors.btn.link.label;
        }
      }

       &:disabled{
        color:  @var-colors.btn.disabled.background !important;
        background-color: transparent !important;
        border: none !important;
        cursor: not-allowed;
        }
    `,
    icon: styled.css`
      width: auto;
      height: 34px;
      padding: 5px 10px;
      border: none;
      color: @var-colors.btn.alpha.label;
      background: @var-colors.btn.icon.background;
      svg{
          font-size: 13px;
          margin-${() => theme.dirFloatRev}: 0px;
      }
      &:hover{
        border: none;
        color: @var-colors.btn.alpha.label;
        background: @var-colors.btn.icon.hover.background;
      }
    `,
  })}

  ${styled.cases('status', {
    error: styled.css`
      border-color: @var-colors.btn.state.error.border;
      color: @var-colors.btn.state.error.label;
      background: @var-colors.btn.state.error.background;

      &:hover{
        border-color: @var-colors.btn.state.error.border;
        color: @var-colors.btn.state.error.label;
        background: @var-colors.btn.state.error.background;
      }
    `,
    success: styled.css`
      border-color: @var-colors.btn.state.success.border;
      color: @var-colors.btn.state.success.label;
      background: @var-colors.btn.state.success.background;

      &:hover{
        border-color: @var-colors.btn.state.success.border;
        color: @var-colors.btn.state.success.label;
        background: @var-colors.btn.state.success.background;
      }
    `,
    warning: styled.css`
      border-color: @var-colors.btn.state.warning.border;
      background: @var-colors.btn.state.warning.background;
      color: @var-colors.btn.state.warning.label;

      &:hover{
        border-color: @var-colors.btn.state.warning.hover.border;
        color: @var-colors.btn.state.warning.hover.label;
        background: @var-colors.btn.state.warning.hover.background;
      }
    `,
    disabled: styled.css`
      border-color: @var-colors.btn.disabled.border;
      color: @var-colors.btn.disabled.label;
      background: @var-colors.btn.disabled.background;

      &:hover{
        border-color: @var-colors.btn.disabled.hover.border;
        color: @var-colors.btn.disabled.hover.label;
        background: @var-colors.btn.disabled.hover.background;
      }
    `,
  })}

  ${styled.cases('size', {
    lg: styled.css`
      padding: 6px 14px;
      margin: 6px;
      height: 50px;
      font-size: 16px;
    `,
    md: styled.css`
      padding: 2px 10px;
      margin: 4px;
      height: 40px;
      font-size: 12px;
    `,
    sm: styled.css`
      padding: 1px 8px;
      margin: 1px;
      height: 35px;
      font-size: 10px;
      line-height: 20px;
    `,
  })}

  ${(props) => (props.isSelected
    ? styled.css`
          border-color: @var-colors.btn.selected.border;
          color: @var-colors.btn.selected.label;
          background: @var-colors.btn.selected.background;
        `
    : null)}

  ${(props) => (props.disabled
    ? styled.css`
          border-color: @var-colors.btn.disabled.border;
          color: @var-colors.btn.disabled.label;
          background: @var-colors.btn.disabled.background;
          cursor: not-allowed;

          &:hover{
            border-color: @var-colors.btn.disabled.hover.border;
            color: @var-colors.btn.disabled.hover.label;
            background: @var-colors.btn.disabled.hover.background;
          }
        `
    : null)}

  ${(props) => (props.fullWidth
    ? styled.css`
          width: 100%;
        `
    : null)}

  &:disabled{
    color:  @var-colors.btn.disabled.label;
    background-color:  @var-colors.btn.disabled.background;
    border-color: @var-colors.btn.disabled.border;
  }
`
