/* global GIT_REV, VERSION */
/* eslint-disable import/first */
import coverConsole from '~CON/plumbing/utils/debugToolsOps/coverConsole' // eslint-disable-line

coverConsole()

import bugsnag from 'bugsnag-js'
import {
  get as _get,
} from 'lodash'

import { getUserInfo } from '~CON/User/userHelper'
import { cqsKVS, conKVS } from '~utils/kvStore'

// get redux store
let getReduxStore = () => '!! NO INIT !!'

export const bugsnagInit = (store) => {
  getReduxStore = store.getState
}

const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP__BUGSNAG_API_KEY,
  maxEvents: 99,
  maxBreadcrumbs: 40,
  releaseStage: process.env.REACT_APP__ReleaseStage,
  autoBreadcrumbs: true,
  consoleBreadcrumbsEnabled: !!process.env.REACT_APP__PRD,
  navigationBreadcrumbsEnabled: true,
  interactionBreadcrumbsEnabled: true,
  // notifyReleaseStages: ['production', 'staging'],
  beforeSend: (report) => {
    const state = getReduxStore()

    const User = state.User || {}
    const Campaigns = state.Campaigns || {}

    const { smsSenders } = Campaigns.Lookups || {}

    const userData = User.current?.token || {}
    const userInfo = User.current?.info || {}

    const CampaignsState = Object
      .entries(Campaigns)
      .reduce((a, [k, v]) => {
        // eslint-disable-next-line
        a[`Campaigns:${k}`] = v
        return a
      }, {})

    const getTestCaseKey = () => {
      // tcKey and tcr report count
      const [tck, tcr] = cqsKVS.testCaseId
        .get()
        .split(';')

      if (!tck.length) { return { key: null } }

      const rCount = (parseInt(tcr, 10) || 0) + 1

      cqsKVS
        .testCaseId
        .set(`${tck};${rCount}`)

      return { key: tck, rNum: rCount }
    }

    // Make sure FullStory object exists.
    if (window.FS && window.FS.getCurrentSessionURL) {
      report.updateMetaData('fullstory', {
        urlAtTime: window.FS.getCurrentSessionURL(true),
      })
    }

    const userDisplay = getUserInfo()

    // eslint-disable-next-line
    report.user = {
      smsSenders,
      ...userInfo,
      id: userInfo.userId || userDisplay.userId,
      role: userData.role || userDisplay.roles,
      email: userData.email || userDisplay.email,
      name: userData.username || userDisplay.userName,
      accountId: userInfo.accountId || userDisplay.accountId,
      accountName: userInfo.accountName || userDisplay.accountName,
      displayName: userInfo.displayName || userDisplay.displayName,
    }

    report.updateMetaData('TC', {
      ...getTestCaseKey(), // set `key`, `num`
    })

    report.updateMetaData('state', {
      // ...state,
      User: undefined,
      QCToolbar: undefined,
      Campaigns: undefined,
      ...CampaignsState,
    })

    report.updateMetaData('app', {
      scm: {
        ...GIT_REV,
        revision: GIT_REV.short || GIT_REV.long,
      },
    })
  },
})


bugsnagClient.app.releaseStage = process.env.REACT_APP__ReleaseStage
bugsnagClient.app.version = VERSION
bugsnagClient.app.revision = _get(GIT_REV, 'long')
bugsnagClient.app.branch = _get(GIT_REV, 'branch')


if (process.env.REACT_APP__QCToolBar) {
  window.releaseStage = process.env.REACT_APP__ReleaseStage
  window.BABEL_ENV = process.env.BABEL_ENV
  window.NODE_ENV = process.env.NODE_ENV
  window.REACT_APP_ENV = { ...process.env }
}

conKVS.version.set(`v${VERSION}`)

window.getbsc = (funcPath) => _get(bugsnagClient, funcPath)
  .bind(bugsnagClient)


const notify = (...args) => bugsnagClient.notify(...args)

const startSession = () => {
  bugsnagClient.startSession()
  return undefined
}


window.__cqs__bs = {
  notify: (...args) => notify(...args),
  startSession: () => startSession(),
}


export default bugsnagClient
