import React from 'react'

export default function ScarletIcon(props) {
  return (
    <svg className={`scarlet-icon scarlet-icon-${props.iconSize || 20} scarlet-icon-text-color`} {...props}>
      <title>{props.alt ? props.alt : ''}</title>
      <use xlinkHref={`${props.href}#${props.hash}`} />
    </svg>
  )
}
