/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { Avatar, Button } from 'antd'
import { withFeatCtrl } from '../../../FeatCtrl'
import { ControlBar } from '~plumbing/components'
import { userActions } from '~plumbing/api/actions'
import './UserMenu.less'

function UserMenu(props) {
  const { info } = props.User
  const LogoutButton = () => (
    <>
      <a
        onClick={() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location = '/login'
        }}
        className="usermenu_menu_btn"
      >
        Logout
      </a>
    </>
  )
  const ProfileButton = () => (
    <>
      <a
        onClick={() => {
          window.location = '/Discovery/Profile'
        }}
        className="usermenu_menu_btn usermenu_menu_btn_profile"
      >
        Profile Settings
      </a>
    </>
  )

  const UserDrawer = (closeBar) => (
    <div className="usermenufull">
      <div className="usermenu_menu">
        <div key="1" className="usermenu_menu_btn">
          <ProfileButton />
        </div>
        <div key="3" className="usermenu_menu_btn">
          <LogoutButton />
        </div>
      </div>
    </div>
  )

  return (
    <ControlBar.Toggler loggerName="UserInfoToggle">
      {({ toggleBar, closeBar, isOpen }) => (
        <React.Fragment>
          <UserMenuToggel onClick={toggleBar} userInfo={info} isUserPromoted={props.isUserPromoted} />
          {isOpen ? (
            <ControlBar.Portal
              displayName="UserInfo"
              clearHeader
              hideClose // fixMe: close button will be in header as optional props
              clickAway
              onClose={closeBar}
              open={isOpen}
              className="userMenuContainer"
            >
              {UserDrawer(closeBar)}
            </ControlBar.Portal>
          ) : null}
        </React.Fragment>
      )}
    </ControlBar.Toggler>
  )
}

UserMenu.propTypes = {
  User: PropTypes.object, // eslint-disable-line
  drawerOpen: PropTypes.bool, // eslint-disable-line
  toggleDrawer: PropTypes.func, // eslint-disable-line
}

UserMenu.defaultProps = {
  User: {}, // eslint-disable-line
  drawerOpen: false, // eslint-disable-line
  toggleDrawer: () => null, // eslint-disable-line
}

function mapStateToProps({ User: { current } }) {
  return {
    User: current,
    usename: current.info,
    isAdmin: current?.token?.role === 'admin',
  }
}
function mapFeatToProps(flags) {
  return {
    withCreditLimitTransfer: flags?.TRANSFER_CREDIT_LIMIT || false,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withFeatCtrl(mapFeatToProps, UserMenu))

const UserMenuToggel = (props) => {
  const { onClick, userInfo, className } = props
  function UserAvatar({ className, userName, size, userImg }) {
    // eslint-disable-line
    function nameInitials(name) {
      return (name || '')
        .split(/(?=[A-Z])|[ \-_\.]/) // eslint-disable-line
        .reduce((accum, ltr, ndx, arr) => {
          const i = arr.length === 1 ? 2 : 1

          if (ndx < 2) {
            return `${accum}${[...ltr].splice(0, i).join('')}`
          }

          return accum
        }, '')
        .toUpperCase()
    }

    return (
      <StyledAvatar
        className={className}
        size={size}
        style={{
          color: '#fff',
          backgroundColor: '#979595',
        }}
      >
        {userImg ? <img src={userImg} /> : nameInitials(userName)}
        {/* {nameInitials(userName)} */}
      </StyledAvatar>
    )
  }
  return (
    <>
      <div className="usermenu">
        <a onClick={onClick} title={userInfo?.name ?? 'logging in...'}>
          <span className="usermenuLink">{userInfo?.me?.name || '--'}</span>
        </a>
        <UserAvatar userName={userInfo?.me?.name} />
      </div>
    </>
  )
}
const StyledAvatar = styled(Avatar)`
  .ant-avatar-string {
    transform: scale(1) translateX(-50%) !important;
    left: 15px !important;
  }
  img {
    width: 40px;
    height: auto;
  }
`
