import ControlBar from './ControlBar'
import Portal from './Portal'
import ControlBarToggle from './BarToggle'
import * as SideDrawer from './styled'

ControlBar.Portal = Portal
ControlBar.Toggler = ControlBarToggle

export {
  SideDrawer,
}

export default ControlBar
