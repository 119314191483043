import request from 'superagent'
// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'qs'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function samplesData(payload) {
  const query = qs.stringify(payload)
  return new Promise((resolve, reject) => {
    request
      .get(`${API_URL}/api/v1/providers/sample-queries?${query}`)
      .set('Authorization', `bearer ${getToken()}`)
      .then((resp) => {
        const newResp = resp?.body
        resolve(newResp)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
