import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function runSample(payload) {
  const { provider, ...rest } = payload
  return new Promise((resolve, reject) => {
    request
      .post(`${API_URL}/api/v1/providers/run-sample-query`)
      .set('Authorization', `bearer ${getToken()}`)
      .send(rest)
      .then((resp) => {
        resolve(resp.body.id)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
