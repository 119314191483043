/* eslint-disable consistent-return */
import request from 'superagent'
import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function getFilteredData(payload) {
  const {
    type, name, from, to,
  } = payload
  if (type === 'client') {
    return new Promise((resolve, reject) => {
      request
        .get(`${API_URL}/api/v1/usage-stats/accounts/${name}/?from=${from}&to=${to}`)
        .set('Authorization', `bearer ${getToken()}`)
        .then((resp) => {
          const newResp = resp?.body.stats
          resolve(newResp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  } if (type === 'provider') {
    return new Promise((resolve, reject) => {
      request
        .get(`${API_URL}/api/v1/usage-stats/providers/${name}/?from=${from}&to=${to}`)
        .set('Authorization', `bearer ${getToken()}`)
        .then((resp) => {
          const newResp = resp?.body.stats
          resolve(newResp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  } if (type === 'network') {
    const contryNetwork = name.split('(')
    return new Promise((resolve, reject) => {
      request
        .get(`${API_URL}/api/v1/usage-stats/networks/${contryNetwork[1]}/${contryNetwork[0]}/?from=${from}&to=${to}`)
        .set('Authorization', `bearer ${getToken()}`)
        .then((resp) => {
          const newResp = resp?.body.stats
          resolve(newResp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
