/* eslint-disable no-unused-vars */
import { css } from 'styled-components'

import {
  withAccents,
} from '../utils/index'


/* ***************************************************
    __ _         _
   / _\ |_ _   _| | ___  ___
   \ \| __| | | | |/ _ \/ __|
   _\ \ |_| |_| | |  __/\__ \
   \__/\__|\__, |_|\___||___/
           |___/
*************************************************** */

export default function loadStyles() {
  const styles = {}

  styles.elevation = {}

  styles.elevation._0 = css`
  z-index: 1;
  box-shadow: none;
  transition: box-shadow 0.3s cubic-bezier(.25,.8,.25,1);
  `

  styles.elevation._1 = css`
  z-index: 10;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: box-shadow 0.3s cubic-bezier(.25,.8,.25,1);
  `

  styles.elevation._2 = css`
  z-index: 100;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  transition: box-shadow 0.3s cubic-bezier(.25,.8,.25,1);
  `

  styles.elevation._3 = css`
  z-index: 1000;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  transition: box-shadow 0.3s cubic-bezier(.25,.8,.25,1);
  `

  styles.elevation._4 = css`
  z-index: 10000;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  transition: box-shadow 0.3s cubic-bezier(.25,.8,.25,1);
  `

  styles.elevation._5 = css`
  z-index: 100000;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  transition: box-shadow 0.3s cubic-bezier(.25,.8,.25,1);
  `

  return styles
}

/* ************************************************ */
