import debugToolsOps from './debugToolsOps'
import methodsRiders from './methodsRiders'


function consoleBanner() {
  let consoleLog = () => null
  let clearConsole = () => null

  // eslint-disable-next-line
  const recovery = methodsRiders(console, function () {
    const warnStyles = [
      'color: red',
      'display: block',
      'font-size: 40px',
      'text-align: left',
      'font-weight: bold',
    ].join(';')

    const messageStyles = [
      'color: black',
      'display: block',
      'font-size: 15px',
      'text-align: left',
      'font-weight: bold',
    ].join(';')

    clearConsole()
    consoleLog('%c ⛔️ STOP ⛔️', warnStyles)
    consoleLog(
      `%c
      This is a browser feature intended for developers.
      If someone told you to copy and paste something here
      to enable a feature or 'hack' an account, it is a scam
        and will give them access to your account.
    `,
      messageStyles
    )
  })

  consoleLog = (...args) => recovery.log(...args)
  clearConsole = () => recovery.clear()

  return {
    consoleLog,
    clearConsole,
    recoverConsole: recovery.__recover_target,
  }
}


export default function coverConsole() {
  if (process.env.REACT_APP__PRD && !process.env.REACT_APP__QCToolBar) {
    debugToolsOps({
      name: 'console',
      onSetup(ctx) {
        ctx.recover = consoleBanner()
      },
      onDisable(ctx) {
        ctx.recover = consoleBanner()
      },
      onConfirmed(ctx) {
        ctx.recover.recoverConsole()
      },
    })
  }
}
