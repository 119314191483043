/* eslint-disable max-len */
import Rx from 'rxjs' // eslint-disable-line
import moment from 'moment'

import { getToken } from '~plumbing/api/apiUtils'
import jwtDecode from '~plumbing/utils/jwtDecoder'
import bugsnag from '~CON/bugsnag'
import { dispatch } from '~plumbing/store'
import { userAPIs } from '~plumbing/api' // eslint-disable-line
import { userActions, actionTypes } from '~plumbing/api/actions'
import { actions as appActions } from '~CON/App/appActions'
import {
  getUserToken,
  setUserToken,
  setUserRefreshToken,
} from '~CON/User/userHelper'

export default [login, loginSuccess,
  logout, fetchUserInfo, userTokenValidate]

export function login(action$) {
  return action$
    .ofType(actionTypes.USER_LOGIN)
    .switchMap((action) => {
      return userAPIs
        .login(action.userCredentials)
        .map((payload) => {
          // debugger // eslint-disable-line

          setUserToken(payload.authToken)
          // setUserRefreshToken(payload.refreshToken)
          sessionStorage.removeItem('sessionExpired')
          return userActions.loginSuccess(payload, action.meta)
        })
        .catch((errCode) => {
          return Rx.Observable.of(userActions.loginError(errCode))
        }
        )
    })
}

export function loginSuccess(action$) {
  return action$
    .ofType(actionTypes.USER_LOGIN_PASS)
    .map(({ meta }) => {
      // const altPush = (url) => window.location.replace(url)

      // const { history } = window.__loc__ || { history: { push: altPush } }

      // eslint-disable-next-line no-unused-expressions
      meta?.callback?.()

      return { type: 'Login Succeeded, Good buy' }
    })
}

export function logout(action$, { getState }) {
  return action$
    .ofType(actionTypes.USER_TOKEN_SET__FAIL,
      actionTypes.USER_LOGOUT,
      actionTypes.REFRESH_TOKEN_FAILED)
    .switchMap((action) => {
      // const altPush = (url) => window.location.replace(url)
      // const { history } = window.__loc__ || { history: { push: altPush } }

      // history.push('/login')
      const domain = window.location.origin.match(/.sa/g)
        ? process.env.REACT_APP__CEQUENS_SA_DOMAIN
        : process.env.REACT_APP__CEQUENS_DOMAIN

      document.cookie = `consoleToken=;domain=${domain};Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
      const { User } = getState()
      const { isLoggedOut } = User
      if (!isLoggedOut) {
        return userAPIs
          .logout(action.idToken)
          .map(userActions.logoutSuccess)
          // .retry(3)
          .catch((err) => {
            console.error('Caught error while logging out:', err)
            return Rx
              .Observable
              .of(userActions.logoutSuccess())
          }
          )

        // return Rx.Observable.of({ type: 'user/login/ redirect' })
      }
      return Rx.Observable.of({ type: 'user/login/ redirect' })
    }
    )
}

export function logoutSuccess(action$) {
  return action$
    .ofType(actionTypes.LOGOUT_SUCCESS)
    .map(() => {
      const altPush = (url) => window.location.replace(url)
      const { history } = window.__loc__ || { history: { push: altPush } }
      window.location = '/login'
      return { type: 'Logout Succeeded, Good buy' }
    })
}

export function userTokenValidate(action$) {
  const SESSION_TIMEOUT = ((process.env.REACT_APP__LOCKSCREEN_TIMEOUT_MINUTES || 20) - 3) * 60

  return action$
    .ofType(actionTypes.USER_TOKEN_SET)
    .throttleTime(5000)
    .switchMap(({ token }) => {
      // validate user token
      const tsNow = moment().unix()
      const tsAut = token && token.iat
      const since = (tsNow - tsAut)
      const isExpired = since > SESSION_TIMEOUT
      if (isExpired) {
        bugsnag.notify('session expired')

        // TODO: enable session expiration
        // return Rx.Observable
        //   .of(userActions.authUserTokenFailed('session expired'))
      }

      return userAPIs
        .fetchUserInfo()
        .map(() => userActions.authUserTokenValid())
        .catch(() => Rx
          .Observable
          .of(userActions.authUserTokenFailed('failed to verify'))
        )
    })
}

export function fetchUserInfo(action$, { getState }) {
  return action$
    .ofType(actionTypes.USER_INFO_FETCH)
    .throttleTime(5000)
    .switchMap((action) => {
      // validate user token
      return userAPIs
        .fetchUserInfo(action?.payload?.InitialRequest)
        // handle successes
        .map((res) => {
          // const onBoarding = res?.body?.onboarding
          const token = getUserToken()
          // const jwtToken = jwtDecode(token)
          // const { sub } = jwtToken
          const dateTime = new Date()
          dateTime.setHours(dateTime.getHours() + 1)

          return userActions.fetchUserInfoSuccesses(res, action.meta)
        })
        .retry(3)
        // catch Promise error
        .catch((err) => Rx
          .Observable
          .of(appActions.throwError(err))
        )
    })
    .catch((err) => Rx
      .Observable
      .of(appActions.throwError(err))
    )
}
