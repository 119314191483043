import styled from 'styled-components'

/**
 *
 * @param {object} props
 * @param {boolen} props.warn - warning highlight
 * @param {boolen} props.error - Error highlight
 *
 * @example
 * <Input warn />
 */

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.redColor};
  font-size: 14px;
`
