import styled from 'styled-components'

export const TotalCount = styled.span`
    float: ${({ theme }) => theme.dirFloatRev};
    position: absolute;
    ${({ theme }) => theme.dirFloatRev}: 35px;
    top: 73px;
    top: ${({ top, theme }) => `${theme.isRTL ? top - 6 : top}px`};
    color: #fff;
    z-index: 9;
    font-size: ${({ theme }) => theme.fontSize.small};
`
