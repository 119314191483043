import styled from 'styled-components'

export const TextLabel = styled.div`
    display: inline-block;
    margin-${({ theme }) => theme.dirFloat}:10px;
    span {
      font-size: 10px;
      color: #bababa;
      margin-${({ theme }) => theme.dirFloatRev}: 7px;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      display: inline-block;
      color: ${({ theme }) => theme.tableBodyLabel};
    }
`
