// @flow
import { userSession } from '~CON/User/userHelper'
import * as ActionTypes from './actionTypes'

// auth user
export function authUserLogout() {
  return {
    type: ActionTypes.USER_LOGOUT,
    error: undefined,
  }
}
export function logoutSuccess() {
  return {
    type: ActionTypes.LOGOUT_SUCCESS,
    error: undefined,
  }
}
export function logoutFailed() {
  return {
    type: ActionTypes.LOGOUT_FAILED,
    error: undefined,
  }
}
export function authUserToken(
  tokenString: string | void,
  token: any | void,
) {
  return {
    type: ActionTypes.USER_TOKEN_SET,
    tokenString,
    token,
  }
}

export function authUserTokenValid() {
  return {
    type: ActionTypes.USER_TOKEN_SET__PASS,
  }
}
export function authUserTokenFailed(error: any) {
  return {
    type: ActionTypes.USER_TOKEN_SET__FAIL,
    error,
  }
}

export function fetchUserInfo(payload, meta) {
  return {
    type: ActionTypes.USER_INFO_FETCH,
    payload,
    meta,
  }
}
export function fetchUserInfoSuccesses({ body, accountServices }: any, meta) {
  return {
    type: ActionTypes.USER_INFO_FETCH_PASS,
    userInfo: body,
    accountServices,
    meta,
  }
}

export function login(payload, meta) {
  return {
    meta,
    userCredentials: payload,
    type: ActionTypes.USER_LOGIN,
  }
}
export function loginSuccess(payload, meta) {
  return {
    meta,
    payload,
    type: ActionTypes.USER_LOGIN_PASS,
  }
}
export function loginError(errCode) {
  return {
    type: ActionTypes.USER_LOGIN_FAIL,
    errCode,
  }
}

export function verificationLogin(payload, meta) {
  return {
    meta,
    userCredentials: payload,
    type: ActionTypes.USER_VERIFICATION_LOGIN,
  }
}
export function verificationLoginSuccess(payload, meta) {
  return {
    meta,
    payload,
    type: ActionTypes.USER_VERIFICATION_LOGIN_PASS,
  }
}
export function verificationLoginError(errCode) {
  return {
    type: ActionTypes.USER_VERIFICATION_LOGIN_FAIL,
    errCode,
  }
}

export function refreshUserToken(payload) {
  return {
    type: ActionTypes.REFRESH_USER_TOKEN,
    payload,
  }
}
export function refreshTokenSucceeded(payload) {
  return {
    type: ActionTypes.REFRESH_TOKEN_SUCCEEDED,
    payload,
  }
}
export function refreshTokenFailed(payload) {
  return {
    type: ActionTypes.REFRESH_TOKEN_FAILED,
    payload,
  }
}
export function clearValidationErrors() {
  return {
    type: ActionTypes.USER_LOGIN_CLEAR_VALIDATIONS,
  }
}

export function sessionRenew(userCredentials) {
  return {
    type: ActionTypes.USER_SESSION_RENEW,
    userCredentials,
  }
}

export function sessionExpires() {
  return {
    type: ActionTypes.USER_SESSION_EXPIRED,
  }
}

export function sessionActivate(payload) {
  return {
    type: ActionTypes.USER_SESSION_ACTIVATE,
    payload,
  }
}


// forget Password
export function requestForgetPassword(payload) {
  return {
    type: ActionTypes.USER_FORGET_PASSWORD_REQUEST,
    payload,
  }
}
export function requestForgetPasswordSuccess() {
  return {
    type: ActionTypes.USER_FORGET_PASSWORD_SUCCESS,
  }
}
export function requestForgetPasswordFailed(errCode) {
  return {
    type: ActionTypes.USER_FORGET_PASSWORD_FAILED,
    errCode,
  }
}


// reset Password
export function resetPassword(payload) {
  return {
    type: ActionTypes.USER_RESET_PASSWORD_REQUEST,
    payload,
  }
}
export function resetPasswordSuccess() {
  return {
    type: ActionTypes.USER_RESET_PASSWORD_SUCCESS,
  }
}
export function resetPasswordFailed(errCode) {
  return {
    type: ActionTypes.USER_RESET_PASSWORD_FAILED,
    errCode,
  }
}


// change Password
export function changePassword(payload) {
  return {
    type: ActionTypes.USER_CHANGE_PASSWORD_REQUEST,
    payload,
  }
}
export function changePasswordSuccess() {
  return {
    type: ActionTypes.USER_CHANGE_PASSWORD_SUCCESS,
  }
}
export function changePasswordFailed(err) {
  return {
    type: ActionTypes.USER_CHANGE_PASSWORD_FAILED,
    err,
  }
}

// Payment - PayPal
export function userPaymentProcessInitiation(payload) {
  return {
    type: ActionTypes.USER_PAYMENT_PROCESS_INITIATION,
    payload,
  }
}
export function userPaymentProcessInitiationSuccess() {
  return {
    type: ActionTypes.USER_CONVERT_CURRENCY_SUCCESS,
  }
}
export function userPaymentProcessInitiationFailed(err) {
  return {
    type: ActionTypes.USER_PAYMENT_PROCESS_INITIATION_FAILED,
    err,
  }
}

export function userPaypalProcessInitiation(payload) {
  return {
    type: ActionTypes.USER_PAYPAL_PROCESS_INITIATION,
    payload,
  }
}
export function userPaypalProcessInitiationSuccess() {
  return {
    type: ActionTypes.USER_PAYPAL_PROCESS_INITIATION_SUCCESS,
  }
}
export function userPaypalProcessInitiationFailed(err) {
  return {
    type: ActionTypes.USER_PAYPAL_PROCESS_INITIATION_FAILED,
    err,
  }
}

export function savePaypalOrderCreation(payload) {
  return {
    type: ActionTypes.SAVE_PAYPAL_ORDER_CREATION,
    payload,
  }
}
export function savePaypalOrderCreationSuccess() {
  return {
    type: ActionTypes.SAVE_PAYPAL_ORDER_CREATION_SUCCESS,
  }
}
export function savePaypalOrderCreationFailed(err) {
  return {
    type: ActionTypes.SAVE_PAYPAL_ORDER_CREATION_FAILED,
    err,
  }
}

export function passPayPalMessage(payload) {
  return {
    type: ActionTypes.PASS_PAY_PAL_MESSAGE,
    payload,
  }
}

export function verifyUserMobileNumber(payload) {
  return {
    type: ActionTypes.VERIFY_USER_MOBILE_NUMBER,
    payload,
  }
}
export function verifyUserMobileNumberSuccess(payload) {
  return {
    type: ActionTypes.VERIFY_USER_MOBILE_NUMBER_SUCCESS,
    payload,
  }
}
export function verifyUserMobileNumberFailed(err) {
  return {
    type: ActionTypes.VERIFY_USER_MOBILE_NUMBER_FAILED,
    err,
  }
}

export function sendVerificationCode(payload) {
  return {
    type: ActionTypes.SEND_VERIFICATION_CODE,
    payload,
  }
}
export function sendVerificationCodeSuccess(payload) {
  return {
    type: ActionTypes.SEND_VERIFICATION_CODE_SUCCESS,
    payload,
  }
}
export function sendVerificationCodeFailed(err) {
  return {
    type: ActionTypes.SEND_VERIFICATION_CODE_FAILED,
    err,
  }
}

export function resendVerificationEmail(payload) {
  return {
    type: ActionTypes.RESEND_VERIFICATION_EMAIL,
    payload,
  }
}
export function resendVerificationEmailSuccess(payload) {
  return {
    type: ActionTypes.RESEND_VERIFICATION_EMAIL_SUCCESS,
    payload,
  }
}
export function resendVerificationEmailFailed(err) {
  return {
    type: ActionTypes.RESEND_VERIFICATION_EMAIL_FAILED,
    err,
  }
}
export function flushResendVerificationEmail() {
  return {
    type: ActionTypes.FLUSH_RESEND_VERIFICATION_EMAIL,
  }
}

// Update onboarding breadcrumb
export function updateUserOnBoardingBreadCrumb(payload) {
  return {
    type: ActionTypes.UPDATE_USER_ON_BOARDING_BREAD_CRUMB,
    payload,
  }
}
export function updateUserOnBoardingBreadCrumbSuccess(payload) {
  return {
    type: ActionTypes.UPDATE_USER_ON_BOARDING_BREAD_CRUMB_SUCCESS,
    payload,
  }
}
export function updateUserOnBoardingBreadCrumbFailed(err) {
  return {
    type: ActionTypes.UPDATE_USER_ON_BOARDING_BREAD_CRUMB_FAILED,
    err,
  }
}

// request sender
export function requestSender(payload) {
  return {
    type: ActionTypes.REQUEST_SENDER_REQUEST,
    payload,
  }
}

export function requestSenderSuccess() {
  return {
    type: ActionTypes.REQUEST_SENDER_SUCCESS,
  }
}

export function requestSenderFailed(err) {
  return {
    type: ActionTypes.REQUEST_SENDER_FAILED,
    err,
  }
}

export function flushRequestSender() {
  return {
    type: ActionTypes.FLUSH_REQUEST_SENDER,
  }
}

export function fetchAllSenders(payload) {
  return {
    type: ActionTypes.FETCH_ALL_SENDERS,
    payload,
  }
}
export function fetchAllSendersSuccess(payload) {
  return {
    type: ActionTypes.FETCH_ALL_SENDERS_SUCCESS,
    payload,
  }
}
export function fetchAllSendersFailed(err) {
  return {
    type: ActionTypes.FETCH_ALL_SENDERS_FAILED,
    err,
  }
}


export function updateAccountRequested(payload) {
  return {
    type: ActionTypes.UPDATE_ACCOUNT_REQUESTED,
    payload,
  }
}

export function updateAccountSuccess(payload) {
  return {
    type: ActionTypes.UPDATE_ACCOUNT_SUCCESS,
    payload,
  }
}

export function updateAccountFailed(payload) {
  return {
    type: ActionTypes.UPDATE_ACCOUNT_FAILED,
    payload,
  }
}

export function fetchBalanceHistory(payload, meta) {
  return {
    type: ActionTypes.FETCH_BALANCE_HISTORY,
    payload,
    meta,
  }
}
export function fetchBalanceHistorySuccess(payload, meta) {
  return {
    type: ActionTypes.FETCH_BALANCE_HISTORY_SUCCESS,
    payload,
    meta,
  }
}
export function fetchBalanceHistoryFailed(err) {
  return {
    type: ActionTypes.FETCH_BALANCE_HISTORY_FAILED,
    err,
  }
}


export function fetchCoveragePricing(payload, meta) {
  return {
    type: ActionTypes.FETCH_COVERAGE_PRICING,
    payload,
    meta,
  }
}

export function fetchCoveragePricingSuccess(payload, meta) {
  return {
    type: ActionTypes.FETCH_COVERAGE_PRICING_SUCCESS,
    payload,
    meta,
  }
}

export function fetchCoveragePricingFailed(err) {
  return {
    type: ActionTypes.FETCH_COVERAGE_PRICING_FAILED,
    err,
  }
}

export function fetchAccountSubsListing(payload, meta) {
  return {
    type: ActionTypes.FETCH_ACCOUNT_SUBS_LISTING,
    payload,
    meta,
  }
}

export function fetchAccountSubsListingCompleted(payload, meta) {
  return {
    type: ActionTypes.FETCH_ACCOUNT_SUBS_LISTING_COMPLETED,
    payload,
    meta,
  }
}

export function fetchAccountSubsListingFailed(err) {
  return {
    type: ActionTypes.FETCH_ACCOUNT_SUBS_LISTING_FAILED,
    err,
  }
}

export function flushAccountSubsListingMeta() {
  return {
    type: ActionTypes.FLUSH_ACCOUNT_SUBS_LISTING_META,
  }
}

export function toggleSubAccountStatus(payload, meta) {
  return {
    type: ActionTypes.TOGGLE_SUB_ACCOUNT_STATUS,
    payload,
    meta,
  }
}

export function toggleSubAccountStatusCompleted(payload, meta) {
  return {
    type: ActionTypes.TOGGLE_SUB_ACCOUNT_STATUS_COMPLETED,
    payload,
    meta,
  }
}

export function toggleSubAccountStatusFailed(err) {
  return {
    type: ActionTypes.TOGGLE_SUB_ACCOUNT_STATUS_FAILED,
    err,
  }
}

export function toggleSubAccountCampApproval(payload, meta) {
  return {
    type: ActionTypes.TOGGLE_SUB_ACCOUNT_CAMP_APPROVAL,
    payload,
    meta,
  }
}

export function toggleSubAccountCampApprovalCompleted(payload, meta) {
  return {
    type: ActionTypes.TOGGLE_SUB_ACCOUNT_CAMP_APPROVAL_COMPLETED,
    payload,
    meta,
  }
}

export function toggleSubAccountCampApprovalFailed(err) {
  return {
    type: ActionTypes.TOGGLE_SUB_ACCOUNT_CAMP_APPROVAL_FAILED,
    err,
  }
}

export function toggle2FactorAuth(payload, meta) {
  return {
    type: ActionTypes.TOGGLE_2_FACTOR_AUTH,
    payload,
    meta,
  }
}

export function toggle2FactorAuthCompleted(payload, meta) {
  return {
    type: ActionTypes.TOGGLE_2_FACTOR_AUTH_COMPLETED,
    payload,
    meta,
  }
}

export function toggle2FactorAuthFailed(err) {
  return {
    type: ActionTypes.TOGGLE_2_FACTOR_AUTH_FAILED,
    err,
  }
}

export function fetchSubAccounts(payload, meta) {
  return {
    type: ActionTypes.FETCH_SUB_ACCOUNTS,
    payload,
    meta,
  }
}

export function fetchSubAccountsSuccess(payload, meta) {
  return {
    type: ActionTypes.FETCH_SUB_ACCOUNTS_SUCCESS,
    payload,
    meta,
  }
}

export function fetchSubAccountsFailed(err) {
  return {
    type: ActionTypes.FETCH_SUB_ACCOUNTS_FAILED,
    err,
  }
}

export function createSubAccount(payload, meta) {
  return {
    type: ActionTypes.CREATE_SUB_ACCOUNT,
    payload,
    meta,
  }
}

export function createSubAccountSuccess(payload, meta) {
  return {
    type: ActionTypes.CREATE_SUB_ACCOUNT_SUCCESS,
    payload,
    meta,
  }
}

export function createSubAccountFailed(err) {
  return {
    type: ActionTypes.CREATE_SUB_ACCOUNT_FAILED,
    err,
  }
}

export function fetchAccountInterfacesLookup(payload, meta) {
  return {
    type: ActionTypes.FETCH_ACCOUNT_INTERFACES_LOOKUP,
    payload,
    meta,
  }
}

export function fetchAccountInterfacesLookupSuccess(payload, meta) {
  return {
    type: ActionTypes.FETCH_ACCOUNT_INTERFACES_LOOKUP_SUCCESS,
    payload,
    meta,
  }
}

export function fetchAccountInterfacesLookupFailed(err) {
  return {
    type: ActionTypes.FETCH_ACCOUNT_INTERFACES_LOOKUP_FAILED,
    err,
  }
}


export function updateSubAccount(payload, meta) {
  return {
    type: ActionTypes.UPDATE_SUB_ACCOUNT,
    payload,
    meta,
  }
}

export function updateSubAccountSuccess(payload, meta) {
  return {
    type: ActionTypes.UPDATE_SUB_ACCOUNT_SUCCESS,
    payload,
    meta,
  }
}

export function updateSubAccountFailed(err) {
  return {
    type: ActionTypes.UPDATE_SUB_ACCOUNT_FAILED,
    err,
  }
}

export function transferCredit(payload, meta) {
  return {
    type: ActionTypes.TRANSFER_CREDIT,
    payload,
    meta,
  }
}

export function transferCreditSuccess(payload, meta) {
  return {
    type: ActionTypes.TRANSFER_CREDIT_SUCCESS,
    payload,
    meta,
  }
}

export function transferCreditFailed(err) {
  return {
    type: ActionTypes.TRANSFER_CREDIT_FAILED,
    err,
  }
}

export function flushTransferCredit(err) {
  return {
    type: ActionTypes.FLUSH_TRANSFER_CREDIT,
    err,
  }
}

export function updateAccountSettings(payload, meta) {
  return {
    type: ActionTypes.UPDATE_ACCOUNT_SETTINGS,
    payload,
    meta,
  }
}

export function updateAccountSettingsSuccess(payload, meta) {
  return {
    type: ActionTypes.UPDATE_ACCOUNT_SETTINGS_SUCCESS,
    payload,
    meta,
  }
}

export function updateAccountSettingsFailed(err) {
  return {
    type: ActionTypes.UPDATE_ACCOUNT_SETTINGS_FAILED,
    err,
  }
}

export function flushAccountSettings(err) {
  return {
    type: ActionTypes.FLUSH_ACCOUNT_SETTINGS,
    err,
  }
}

export function transferCreditLimit(payload, meta) {
  return {
    type: ActionTypes.TRANSFER_CREDIT_LIMIT,
    payload,
    meta,
  }
}

export function transferCreditLimitSuccess(payload, meta) {
  return {
    type: ActionTypes.TRANSFER_CREDIT_LIMIT_SUCCESS,
    payload,
    meta,
  }
}

export function transferCreditLimitFailed(err) {
  return {
    type: ActionTypes.TRANSFER_CREDIT_LIMIT_FAILED,
    err,
  }
}

export function flushTransferCreditLimit(err) {
  return {
    type: ActionTypes.FLUSH_TRANSFER_CREDIT_LIMIT,
    err,
  }
}


export function initiateMFA(payload, meta) {
  return {
    type: ActionTypes.INITIATE_MFA,
    payload,
    meta,
  }
}

export function initiateMFACompleted(payload, meta) {
  return {
    type: ActionTypes.INITIATE_MFA_COMPLETED,
    payload,
    meta,
  }
}

export function initiateMFAFailed(err) {
  return {
    type: ActionTypes.INITIATE_MFA_FAILED,
    err,
  }
}

export function disableMFA(payload, meta) {
  return {
    type: ActionTypes.DISABLE_MFA,
    payload,
    meta,
  }
}

export function disableMFACompleted(payload, meta) {
  return {
    type: ActionTypes.DISABLE_MFA_COMPLETED,
    payload,
    meta,
  }
}

export function disableMFAFailed(err) {
  return {
    type: ActionTypes.DISABLE_MFA_FAILED,
    err,
  }
}

export function verifyMFASoftwareToken(payload, meta) {
  return {
    type: ActionTypes.VERIFY_MFASOFTWARE_TOKEN,
    payload,
    meta,
  }
}

export function verifyMFASoftwareTokenCompleted(payload, meta) {
  return {
    type: ActionTypes.VERIFY_MFASOFTWARE_TOKEN_COMPLETED,
    payload,
    meta,
  }
}

export function verifyMFASoftwareTokenFailed(err) {
  return {
    type: ActionTypes.VERIFY_MFASOFTWARE_TOKEN_FAILED,
    err,
  }
}

export function verifyAuthChallengeRequested(payload, meta) {
  return {
    type: ActionTypes.VERIFY_AUTH_CHALLENGE_REQUESTED,
    payload,
    meta,
  }
}

export function verifyAuthChallengeCompleted(payload, meta) {
  return {
    type: ActionTypes.VERIFY_AUTH_CHALLENGE_COMPLETED,
    payload,
    meta,
  }
}

export function verifyAuthChallengeFailed(err) {
  return {
    type: ActionTypes.VERIFY_AUTH_CHALLENGE_FAILED,
    err,
  }
}

export function showOTPAuth(payload, meta) {
  return {
    type: ActionTypes.SHOW_OTPAUTH,
    payload,
    meta,
  }
}

export function replaceDuplicateEmail(payload, meta) {
  return {
    meta,
    userCredentials: payload,
    type: ActionTypes.REPLACE_DUPLICATE_EMAIL,
  }
}
export function replaceDuplicateEmailCompleted(payload, meta) {
  return {
    meta,
    payload,
    type: ActionTypes.REPLACE_DUPLICATE_EMAIL_COMPLETED,
  }
}
export function replaceDuplicateEmailFailed(errCode) {
  return {
    type: ActionTypes.REPLACE_DUPLICATE_EMAIL_FAILED,
    errCode,
  }
}
export function flushReplaceDuplicateEmailData(errCode) {
  return {
    type: ActionTypes.FLUSH_REPLACE_DUPLICATE_EMAIL_DATA,
    errCode,
  }
}


export function fetchAccountServices(payload, meta) {
  return {
    type: ActionTypes.FETCH_ACCOUNT_SERVICES,
    payload,
    meta,
  }
}

export function fetchAccountServicesCompleted(payload, meta) {
  return {
    type: ActionTypes.FETCH_ACCOUNT_SERVICES_COMPLETED,
    payload,
    meta,
  }
}

export function fetchAccountServicesFailed(err) {
  return {
    type: ActionTypes.FETCH_ACCOUNT_SERVICES_FAILED,
    err,
  }
}

export function fetchUserName(payload, meta) {
  return {
    type: ActionTypes.FETCH_USER_NAME,
    payload,
    meta,
  }
}

export function fetchUserNameCompleted(payload, meta) {
  return {
    type: ActionTypes.FETCH_USER_NAME_COMPLETED,
    payload,
    meta,
  }
}

export function fetchUserNameFailed(errObj) {
  return {
    type: ActionTypes.FETCH_USER_NAME_FAILED,
    errObj,
  }
}

export function showOTPAuthForSms(payload, meta) {
  return {
    type: ActionTypes.SHOW_OTPAUTH_FOR_SMS,
    payload,
    meta,
  }
}

export function chooseMfaView(payload, meta) {
  return {
    type: ActionTypes.CHOOSE_MFA_VIEW,
    payload,
    meta,
  }
}

export function chooseMfaViewCompleted(payload, meta) {
  return {
    type: ActionTypes.CHOOSE_MFA_VIEW_COMPLETED,
    payload,
    meta,
  }
}


export function initiateMfaSms(payload, meta) {
  return {
    type: ActionTypes.INITIATE_MFA_SMS,
    payload,
    meta,
  }
}

export function initiateMfaSmsCompleted(payload, meta) {
  return {
    type: ActionTypes.INITIATE_MFA_SMS_COMPLETED,
    payload,
    meta,
  }
}

export function initiateMfaSmsFailed(errObj) {
  return {
    type: ActionTypes.INITIATE_MFA_SMS_FAILED,
    errObj,
  }
}


export function disableMfaSms(payload, meta) {
  return {
    type: ActionTypes.DISABLE_MFA_SMS,
    payload,
    meta,
  }
}

export function disableMfaSmsCompleted(payload, meta) {
  return {
    type: ActionTypes.DISABLE_MFA_SMS_COMPLETED,
    payload,
    meta,
  }
}

export function disableMfaSmsFailed(errObj) {
  return {
    type: ActionTypes.DISABLE_MFA_SMS_FAILED,
    errObj,
  }
}


export function initiateMfaWithSmsStepTwo(payload, meta) {
  return {
    type: ActionTypes.INITIATE_MFA_WITH_SMS_STEP_TWO,
    payload,
    meta,
  }
}

export function initiateMfaWithSmsStepTwoCompleted(payload, meta) {
  return {
    type: ActionTypes.INITIATE_MFA_WITH_SMS_STEP_TWO_COMPLETED,
    payload,
    meta,
  }
}

export function initiateMfaWithSmsStepTwoFailed(errObj) {
  return {
    type: ActionTypes.INITIATE_MFA_WITH_SMS_STEP_TWO_FAILED,
    errObj,
  }
}

export function flushInitiateMfaWithSms(errObj) {
  return {
    type: ActionTypes.FLUSH_INITIATE_MFA_WITH_SMS,
    errObj,
  }
}


export function verifyMfaSmsCode(payload, meta) {
  return {
    type: ActionTypes.VERIFY_MFA_SMS_CODE,
    payload,
    meta,
  }
}

export function verifyMfaSmsCodeCompleted(payload, meta) {
  return {
    type: ActionTypes.VERIFY_MFA_SMS_CODE_COMPLETED,
    payload,
    meta,
  }
}

export function verifyMfaSmsCodeFailed(errObj) {
  return {
    type: ActionTypes.VERIFY_MFA_SMS_CODE_FAILED,
    errObj,
  }
}

export function loginUsingGoogleToken(payload, meta) {
  return {
    type: ActionTypes.LOGIN_USING_GOOGLE_TOKEN,
    payload,
    meta,
  }
}

export function sendFederationLoginSignUpForm(payload, meta) {
  return {
    type: ActionTypes.SEND_FEDERATION_LOGIN_SIGN_UP_FORM,
    payload,
    meta,
  }
}

export function sendFederationLoginSignUpFormCompleted(payload, meta) {
  return {
    type: ActionTypes.SEND_FEDERATION_LOGIN_SIGN_UP_FORM_COMPLETED,
    payload,
    meta,
  }
}

export function sendFederationLoginSignUpFormFailed(errObj) {
  return {
    type: ActionTypes.SEND_FEDERATION_LOGIN_SIGN_UP_FORM_FAILED,
    errObj,
  }
}

export function getFederationToken(payload, meta) {
  return {
    type: ActionTypes.GET_FEDERATION_TOKEN,
    payload,
    meta,
  }
}

export function getFederationTokenCompleted(payload, meta) {
  return {
    type: ActionTypes.GET_FEDERATION_TOKEN_COMPLETED,
    payload,
    meta,
  }
}

export function getFederationTokenFailed(errObj) {
  return {
    type: ActionTypes.GET_FEDERATION_TOKEN_FAILED,
    errObj,
  }
}

export function getTokenAfterFederationSignup(payload, meta) {
  return {
    type: ActionTypes.GET_TOKEN_AFTER_FEDERATION_SIGNUP,
    payload,
    meta,
  }
}

export function getTokenAfterFederationSignupCompleted(payload, meta) {
  return {
    type: ActionTypes.GET_TOKEN_AFTER_FEDERATION_SIGNUP_COMPLETED,
    payload,
    meta,
  }
}

export function getTokenAfterFederationSignupFailed(errObj) {
  return {
    type: ActionTypes.GET_TOKEN_AFTER_FEDERATION_SIGNUP_FAILED,
    errObj,
  }
}


export function saveLoginSession(payload, meta) {
  return {
    type: ActionTypes.SAVE_LOGIN_SESSION,
    payload,
    meta,
  }
}

export function saveLoginSessionCompleted(payload, meta) {
  return {
    type: ActionTypes.SAVE_LOGIN_SESSION_COMPLETED,
    payload,
    meta,
  }
}

export function saveLoginSessionFailed(errObj) {
  return {
    type: ActionTypes.SAVE_LOGIN_SESSION_FAILED,
    errObj,
  }
}


export function getLoginSessions(payload, meta) {
  return {
    type: ActionTypes.GET_LOGIN_SESSIONS,
    payload,
    meta,
  }
}

export function getLoginSessionsCompleted(payload, meta) {
  return {
    type: ActionTypes.GET_LOGIN_SESSIONS_COMPLETED,
    payload,
    meta,
  }
}

export function getLoginSessionsFailed(errObj) {
  return {
    type: ActionTypes.GET_LOGIN_SESSIONS_FAILED,
    errObj,
  }
}
