import React from 'react'

import styled from '~plumbing/utils/themed'

export const Header = styled.h2`
    background: #ffffff;
    border-bottom: 1px solid #dcdcdc;
    box-shadow: 0 0 6px #dcdcdc;
    float: left;
    font-size: 18px;
    margin: 0;
    padding: 9px 20px 8px;
    position: relative;
    width: 100%;
    z-index: 1;
`

export const Content = styled.div`
    background: #ffffff;
    float: left;
    height: calc(100vh - 105px);
    overflow: hidden;
    overflow-y: scroll;
    padding: 15px 20px;
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    & > ul {
       list-style: none;
    }
`

export const Search = styled.div`
  background: #f5f4f2;
  border-bottom: 1px solid #dcdcdc;
  box-shadow: 0 6px 6px -6px #dcdcdc;
  float: left;
  margin-top: -1px;
  padding: 10px 20px;
  position: relative;
  width: 100%;
  z-index: 3;

  & input {
    padding-right: 23px;
  }

  & i {
    cursor: pointer;
    font-size: 14px;
    transition: all .3s;
  }

  & > span {
    padding-bottom: 0;
  }

  & > span > span {
    color: ${({ theme }) => theme.brandMain};
    position: absolute;
    transform: translateY(-50%);
    z-index: 2;
    line-height: 0;
    right: 30px;
    top: 25px;
  }
`.withComponent(({ className, ...inputProps }) => (
  <div className={className}>
    <span>
      <input type="text" className="ant-input" placeholder="Search" {...inputProps}/>
      <span>
        <i className="anticon anticon-search ant-input-search-icon"/>
      </span>
    </span>
  </div>
  ))


export const SideBarListItem = styled.li.attrs({
  noHover: ({ noHover, disabled }) => noHover || disabled,
})`
  padding: 10px 15px;
  border-bottom: 1px #ddd solid;
  list-style: none;
  transition: all ease-in-out .3s;
  i.icon {
    display: inline-block;
    transition: all ease-in-out .3s;
    width: 15px;
  }
  .hoverIcon {
    display: none;
    transition: all ease-in-out .3s;
    width: 15px;
  }
  p {
    font-size: 12px;
  }
  h5 {
    display: inline-block;
    font-weight: 700;
    @margin-dir: 15px;
    margin-bottom: 5px;
  }

  ${styled.is('disabled')`
    & * {
      color: lightgray;
    }
  `}

  ${styled.not('noHover')`
    cursor: pointer;

    &:hover {
      background-color: #fff0d3;

      span {
        font-weight:700;
        color: ${({ theme }) => theme.titleHighlight};
        transition: all ease-in-out .3s;

        &:before {
          content: "10f4a3";
        }
      }

      i {
        font-weight:700;
        color: ${({ theme }) => theme.titleHighlight};
      }
      .hoverIcon {
        display: inline-block;
      }
      .icon {
        display: none;
      }
    }
  `}
`
