import cleansingData from './fetchLookups'
import getCountries from './fetchCountries'
import getNetwork from './fetchnetworks'
import createCleansing from './createCleansing'

export default {
  cleansingData,
  getCountries,
  getNetwork,
  createCleansing,
}
