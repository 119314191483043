import { inCase } from '~plumbing/utils'
import {
  actionsTypes,
} from './RequestAction'

const defaultState = {
  Requests: {
    NetworkDataList: [],
    FilterDataList: [],
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
}
const requestReducer = inCase.curry('requestReducer')
  .check((s, action) => action.type)
  .otherwise((state) => state || defaultState)
  .cases({
    [actionsTypes.FETCH_ALL_NETWORKS_DATA_LIST]: fetchAllNetworksList,
    [actionsTypes.FETCH_ALL_NETWORKS_DATA_LIST_SUCCESS]: fetchAllNetworksListSuccess,
    [actionsTypes.FETCH_ALL_NETWORKS_DATA_LIST_FAILED]: fetchAllNetworksListFailed,
    [actionsTypes.FETCH_FILTERED_DATA]: fetchFilterDataList,
    [actionsTypes.FETCH_FILTERED_DATA_SUCCESS]: fetchFilterDataListSuccess,
    [actionsTypes.FETCH_FILTERED_DATA_FAILED]: fetchFilterDataListFailed,
  })

function fetchAllNetworksList(state, action) {
  return {
    ...state,
  }
}
function fetchAllNetworksListSuccess(state, action) {
  return {
    ...state,
    Requests: {
      NetworkDataList: action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: false,
        errorMessage: '',
      },
    },
  }
}
function fetchAllNetworksListFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchFilterDataList(state, action) {
  return {
    ...state,
  }
}
function fetchFilterDataListSuccess(state, action) {
  return {
    ...state,
    Requests: {
      FilterDataList: action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: false,
        errorMessage: '',
      },
    },
  }
}
function fetchFilterDataListFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
export default requestReducer
