import styled from 'styled-components'

export const Hr = styled.hr`
    float: left;
    width: 100%;
    margin-top: 40px;
    /*box-shadow: 1px 1px 0px #fff;
    border: 1px solid #dddddd;
    */
   border:none;
`
