import React from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
//
import theme from '~plumbing/themes'
import { withThemed } from '~utils/themed'

export const NavMenu = withThemed(() => {
  return (
    <HeaderBrand />
  )
})`
    /* Element Styles */
    //top: 0;
    //left: 0;
    width: 200px;
    height: 100vh;
    position: fixed;
    padding-top: 2px;
    background: #fff;
    border-right: 1px #EAE4E4 solid;
    z-index: 998;

    /* Grid Item */
    grid-area: navBar;

    /* flex container */
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-content: flex-start;
    align-items: center;
`
const mainLogo = theme?.var?.images?.logoAlt

const HeaderContainer = styled.div`{
  display: flex;
  border-bottom: 1px #eae4e4c9 solid;
  height: 45px;
  a {
    &.linkLogoContainer {
      &::after{
        // content: "";
        width: 1px;
        height: 17px;
        background: #e6e6e6;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      padding-top: 5px;

      .ImgLogoContainer {
        display: flex;
        max-width: 100%;
        justify-content: center;

        img {
          width: 140px;
          margin-left: 13px;
        }
      }
    }
  }`

function HeaderBrand(props) {
  return (
    <HeaderContainer>
      <Link to="/" className="linkLogoContainer">
        <div className="ImgLogoContainer">
          <img
            height={30}
            src={mainLogo}
            alt="cequens.com logo"
          />
        </div>
      </Link>
    </HeaderContainer>
  )
}
HeaderBrand = withRouter(HeaderBrand)
