export default function getFeatFlags() {
  const env = { ...process.env }
  const flags = Object.entries(env).reduce((accum, [name, value]) => {
    const isFeatFlag = name.match(/^REACT_APP__FEAT__/)
    if (isFeatFlag) {
      const flagName = name.replace(/^REACT_APP__FEAT__/, '')
      /* eslint-disable no-param-reassign */
      accum[flagName] = parseJSON(value)
      /* eslint-enable no-param-reassign */
    }
    return accum
  }, {})
  return flags
}

function parseJSON(value) {
  try {
    return JSON.parse(value)
  } catch (e) {
    return `${value}`
  }
}
