import React, { useState, useEffect } from 'react'
import IdleTimer from 'react-idle-timer'
import { ThemeProvider } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { injectIntl } from 'react-intl'
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom'

import bugsnag from '~CON/bugsnag'
import appTheme from '~plumbing/themes'
import QCPanel from '~plumbing/components/QCToolbar'
import ControlBar from '~plumbing/components/ControlBar'

import AppRoutes, { requireAuth } from '~CON/routes'
import { reactIntl } from '~plumbing/utils'
import { userActions } from '~plumbing/api/actions'
import { ErrorPages, BodyStyle } from '~plumbing/components'
import './App.less'
import Navigator from './Navigator/Navigator'
import Login from './Login'

/**
 * App Root
 *
 * <span class="graph">
 * graph TD;
 *  App-->ThemeProvider;
 *  ThemeProvider-->Router;
 *  Router-->login;
 *  Router-->AuthProtected;
 *  AuthProtected-->Header;
 *  AuthProtected-->QCPanel;
 *  AuthProtected-->AppRoutes;
 * </span>
 *
 * @return {React.Node}
 */
const App = (props) => {
  const dispatch = useDispatch()

  const appState = useSelector((state) => state.App)
  const downloadBarState = useSelector((state) => state.DownloadBar)
  const [StateOfApp, setStateOfApp] = useState(appState)

  const { hasError } = appState
  const { errorKey } = appState

  const PrevLocation = withRouter((routeProps) => {
    if (window.location.pathname !== '/login') {
      window.prevLocation = { ...window.location }
      window.__loc__ = {
        history: routeProps.history,
        preLocation: { ...window.location },
      }
    }

    return null
  })

  const AuthProtected = requireAuth()

  const idleTimerRef = React.useRef(null)
  const MIN_MSEC = 60 * 1000
  const SESSION_TIMEOUT = (process.env.REACT_APP__LOCKSCREEN_TIMEOUT_MINUTES || 10) * MIN_MSEC

  const handleOnUserIdle = () => {
    // debugger // eslint-disable-line

    if (downloadBarState?.length) {
      // eslint-disable-next-line no-unused-expressions
      idleTimerRef?.current?.reset()
    } else {
      // this local storage flag is used to display a message in login page, and
      // will be removed from local storage on login unmount or successful login.
      sessionStorage.setItem('sessionExpired', new Date().toISOString())

      setTimeout(dispatch(userActions.authUserLogout()), 500)
    }
  }

  const protectedRoutesRenderProp = () => {
    return (
      <AuthProtected>
        <IdleTimer
          timeout={SESSION_TIMEOUT}
          onIdle={handleOnUserIdle}
          ref={idleTimerRef}
        />
        <ControlBar>
          <Navigator>
            <PrevLocation/>
            <QCPanel/>
            <BodyStyle style={{ background: appTheme.bgBodyNormal }}>
              <AppRoutes/>
            </BodyStyle>
          </Navigator>
        </ControlBar>
      </AuthProtected>
    )
  }

  const renderRoutes = () => {
    return (
      <Switch>
        <Route
          exact
          path="/logout"
          render={() => {
            dispatch(userActions.authUserLogout())
            return null
          }}
        />
        {/* <Route exact path="/terms" component={ToS}/> */}
        {/* <Route exact path="/packagesandprices" component={PackagesAndPrices}/> */}
        {/* <Route
          exact
          path="/licence-agreement/trial"
          component={LicenceAgreement}
        /> */}

        <Route exact path="/login" component={Login}/>
        {/* <Route
          exact
          path="/federationcallback"
          component={FederationCallBack}
        /> */}
        {/* <Route exact path="/abc/connect" component={AbcConnect}/>
        <Route exact path="/messenger/connect" component={MessengerConnect}/> */}
        {/* <Route exact path="/user/reset" component={ResetPassword}/> */}
        {/* <Route exact path="/user/register" component={UserRegister}/> */}
        {/* <Route exact path="/user/forget" component={ForgetPassword}/> */}

        {/* <Redirect exact from="/register" to="/account/register"/> */}
        {/* <Route exact path="/account/verify" component={AccountVerify}/> */}
        {/* <Route exact path="/account/register" component={AccountRegister}/> */}

        {/* <Route exact path="/verifysuccess" component={VerifySuccess}/>
        <Route exact path="/verifyfailure" component={VerifyFailure}/> */}

        {/* <Route
          exact
          path={fileDirectLink.path}
          component={fileDirectLink.component}
        /> */}

        <Route render={protectedRoutesRenderProp}/>
      </Switch>
    )
  }

  const renderError = () => {
    bugsnag.notify(errorKey)

    return (<ErrorPages.Server5xx/>)
  }

  reactIntl.injectIntl(props.intl)

  if (hasError) {
    renderError()
  }

  return (
    <ThemeProvider theme={appTheme}>
      <Router>
        {renderRoutes()}
      </Router>
    </ThemeProvider>
  )
}

export default injectIntl(App)
