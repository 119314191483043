import { inCase } from '~plumbing/utils'
import raghiAccounts from '../apis/FetchRaghiAccounts'
import { actions as globalmanagementActions, actionsTypes } from './GlobalManagementActions'

const defaultState = {
  mnpPlus: {
    mnpPlusdata: {},
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
  raghiAccounts: {
    raghiAccountsdata: [],
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
  defaultAccounts: {
    defaultAccountsdata: '',
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
      isUpdated: true,
      isUpdatedFailed: false,
      isupdating: false,
    },
    updateMeta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
  raghiDatabase: {
    raghiDatabasedata: {},
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
  hlrDatabase: {
    hlrDatabasedata: {},
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
  mnpDatabase: {
    mnpDatabasedata: {},
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
  ksaDatabase: {
    ksaDatabasedata: {},
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
}
const globalManagementReducer = inCase
  .curry('globalManagementReducer')
  .check((s, action) => action.type)
  .otherwise((state) => state || defaultState)
  .cases({
    [actionsTypes.FETCH_ALL_RaghiAccounts]: fetchAllRaghiAccounts,
    [actionsTypes.FETCH_ALL_RaghiAccounts_SUCCESS]: fetchAllRaghiAccountsSuccess,
    [actionsTypes.FETCH_ALL_RaghiAccounts_FAILED]: fetchAllRaghiAccountsFailed,
    [actionsTypes.FETCH_DEFAULT_ACCOUNT]: fetchDefaultAccount,
    [actionsTypes.FETCH_DEFAULT_ACCOUNT_SUCCESS]: fetchDefaultAccountSuccess,
    [actionsTypes.FETCH_DEFAULT_ACCOUNT_FAILED]: fetchDefaultAccountFailed,
    [actionsTypes.FETCH_RAGHI_DATABASE]: fetchRaghiDatabase,
    [actionsTypes.FETCH_RAGHI_DATABASE_SUCCESS]: fetchRaghiDatabaseSuccess,
    [actionsTypes.FETCH_RAGHI_DATABASE_FAILED]: fetchRaghiDatabaseFailed,
    [actionsTypes.FETCH_HLR_DATABASE]: fetchHlrDatabase,
    [actionsTypes.FETCH_HLR_DATABASE_SUCCESS]: fetchHlrDatabaseSuccess,
    [actionsTypes.FETCH_HLR_DATABASE_FAILED]: fetchHlrDatabaseFailed,
    [actionsTypes.FETCH_KSA_DATABASE]: fetchKsaDatabase,
    [actionsTypes.FETCH_KSA_DATABASE_SUCCESS]: fetchKsaDatabaseSuccess,
    [actionsTypes.FETCH_KSA_DATABASE_FAILED]: fetchKsaDatabaseFailed,
    [actionsTypes.FETCH_MNP_DATABASE]: fetchMnpDatabase,
    [actionsTypes.FETCH_MNP_DATABASE_SUCCESS]: fetchMnpDatabaseSuccess,
    [actionsTypes.FETCH_MNP_DATABASE_FAILED]: fetchMnpDatabaseFailed,
    [actionsTypes.UPDATE_ALL_RAGHIACCOUNTS]: updateRaghiAccounts,
    [actionsTypes.UPDATE_ALL_RAGHIACCOUNTS_SUCCESS]: updateRaghiAccountsSuccess,
    [actionsTypes.UPDATE_ALL_RAGHIACCOUNTS_FAILED]: updateRaghiAccountsFailed,
    [actionsTypes.UPDATE_DEFAULT__ACCOUNT]: updateDefaultAccount,
    [actionsTypes.UPDATE_DEFAULT__ACCOUNT_SUCCESS]: updateDefaultAccountSuccess,
    [actionsTypes.UPDATE_DEFAULT__ACCOUNT_FAILED]: updateDefaultAccountFailed,
    [actionsTypes.UPDATE_HLR]: updateHlr,
    [actionsTypes.UPDATE_HLR_SUCCESS]: updateHlrSuccess,
    [actionsTypes.UPDATE_HLR_FAILED]: updateHlrFailed,
    [actionsTypes.UPDATE_MNP]: updateMnp,
    [actionsTypes.UPDATE_MNP_SUCCESS]: updateMnpSuccess,
    [actionsTypes.UPDATE_MNP_FAILED]: updateMnpFailed,
    [actionsTypes.UPDATE_KSA]: updateKsa,
    [actionsTypes.UPDATE_KSA_SUCCESS]: updateKsaSuccess,
    [actionsTypes.UPDATE_KSA_FAILED]: updateKsaFailed,
    [actionsTypes.UPDATE_RAGHI]: updateRaghi,
    [actionsTypes.UPDATE_RAGHI_SUCCESS]: updateRaghiSuccess,
    [actionsTypes.UPDATE_RAGHI_FAILED]: updateRaghiFailed,
    [actionsTypes.FETCH_MNP_PLUS]: fetchMnpPLus,
    [actionsTypes.FETCH_MNP_PLUS_SUCCESS]: fetchMnpPLusSuccess,
    [actionsTypes.FETCH_MNP_PLUS_FAILED]: fetchMnpPLusFailed,
    [actionsTypes.UPDATE_MNP_PLUS]: updateMnpPlus,
    [actionsTypes.UPDATE_MNP_PLUS_SUCCESS]: updateMnpPlusSuccess,
    [actionsTypes.UPDATE_MNP_PLUS_FAILED]: updateMnpPlusFailed,
  })
function updateMnpPlus(state, action) {
  return {
    ...state,
  }
}
function updateMnpPlusSuccess(state, action) {
  return {
    ...state,
    mnpPlus: {
      mnpPlusdata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function updateMnpPlusFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchMnpPLus(state, action) {
  return {
    ...state,
  }
}
function fetchMnpPLusSuccess(state, action) {
  return {
    ...state,
    mnpPlus: {
      mnpPlusdata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchMnpPLusFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchAllRaghiAccounts(state, action) {
  return {
    ...state,
  }
}
function fetchAllRaghiAccountsSuccess(state, action) {
  return {
    ...state,
    raghiAccounts: {
      raghiAccountsdata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchAllRaghiAccountsFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchDefaultAccount(state, action) {
  return {
    ...state,
  }
}
function fetchDefaultAccountSuccess(state, action) {
  return {
    ...state,
    defaultAccounts: {
      defaultAccountsdata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchDefaultAccountFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchRaghiDatabase(state, action) {
  return {
    ...state,
  }
}
function fetchRaghiDatabaseSuccess(state, action) {
  return {
    ...state,
    raghiDatabase: {
      raghiDatabasedata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchRaghiDatabaseFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchHlrDatabase(state, action) {
  return {
    ...state,
  }
}
function fetchHlrDatabaseSuccess(state, action) {
  return {
    ...state,
    hlrDatabase: {
      hlrDatabasedata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchHlrDatabaseFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchKsaDatabase(state, action) {
  return {
    ...state,
  }
}
function fetchKsaDatabaseSuccess(state, action) {
  return {
    ...state,
    ksaDatabase: {
      ksaDatabasedata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchKsaDatabaseFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchMnpDatabase(state, action) {
  return {
    ...state,
  }
}
function fetchMnpDatabaseSuccess(state, action) {
  return {
    ...state,
    mnpDatabase: {
      mnpDatabasedata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchMnpDatabaseFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function updateRaghiAccounts(state, action) {
  return {
    ...state,
    raghiAccounts: {
      raghiAccountsdata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function updateRaghiAccountsSuccess(state, action) {
  return {
    ...state,
    raghiAccounts: {
      raghiAccountsdata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function updateRaghiAccountsFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function updateDefaultAccount(state, action) {
  return {
    ...state,
    defaultAccounts: {
      defaultAccountsdata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function updateDefaultAccountSuccess(state, action) {
  return {
    ...state,
    defaultAccounts: {
      defaultAccountsdata: action.payload,
      meta: {
        ...state.defaultAccounts.meta,
        isUpdated: true,
        isUpdatedFailed: false,
        isupdating: false,
      },
    },
  }
}
function updateDefaultAccountFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function updateHlr(state, action) {
  return {
    ...state,
    hlrDatabase: {
      hlrDatabasedata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function updateHlrSuccess(state, action) {
  return {
    ...state,
    hlrDatabase: {
      hlrDatabasedata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function updateHlrFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function updateMnp(state, action) {
  return {
    ...state,
    mnpDatabase: {
      mnpDatabasedata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function updateMnpSuccess(state, action) {
  return {
    ...state,
    mnpDatabase: {
      mnpDatabasedata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function updateMnpFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function updateKsa(state, action) {
  return {
    ...state,
    ksaDatabase: {
      ksaDatabasedata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function updateKsaSuccess(state, action) {
  return {
    ...state,
    ksaDatabase: {
      ksaDatabasedata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function updateKsaFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function updateRaghi(state, action) {
  return {
    ...state,
    raghiDatabase: {
      raghiDatabasedata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function updateRaghiSuccess(state, action) {
  return {
    ...state,
    raghiDatabase: {
      raghiDatabasedata: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function updateRaghiFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
export default globalManagementReducer
