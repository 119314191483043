import '~utils/init'
import '~utils/xhrSpy'
import '~CON/plumbing/styles/main.less'
import '~CON/plumbing/styles/fonts/ceq-icons-font.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { QueryClientProvider } from 'react-query'
import App from '~CON/App'
import IntlWrapper from '~CON/intl'
import store, { queryClient } from '~plumbing/store'
import { bugsnagInit } from '~CON/bugsnag'
import { integrateFullstory } from '~utils/analytics'
import '~CON/plumbing/assets/scarletIcons/css/style.css'
import '~CON/plumbing/assets/scarletIcons/css/colors.css'

bugsnagInit(store)

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <IntlWrapper>
        <App />
      </IntlWrapper>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root'),
)

integrateFullstory()
