import styled from '~utils/themed'


export const HintWrapper = styled.div`
  padding: 17px;
  border-radius: 6px;
  position: relative;
  border: solid 1px #e1e1e1;
  background-color: #ffffff;

  padding-${({ theme }) => theme.dirFloat}: 40px;

  h3 {
    color: #3d3d3d;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: normal;
  }

  p {
    color: #3d3d3d;
  }

  svg {
    top: 17px;
    fill: #0a87ff;
    position: absolute;
    ${({ theme }) => theme.dirFloat}: 16px;
  }

  ${(props) => (props.status === 'success'
    ? `
      svg {
        fill: #0eca0e;
      }
    `
    : null)
}
`
