import styled from 'styled-components'

/**
 *
 * @param {object} props
 * @param {boolen} props.warn - warning highlight
 * @param {boolen} props.error - Error highlight
 *
 * @example
 * <Input warn />
 */
export const Loading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
