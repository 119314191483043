import { dispatch } from '~plumbing/store'

// const dispatch = () => null

const QCTBActionsTypes = {
  QCTB_ADD_CONTOLLER: 'QCTB_ADD_CONTOLLER',
  QCTB_REMOVE_CONTOLLER: 'QCTB_REMOVE_CONTOLLER',
}

const QCTBActions = {
  qcToolbarAddController,
  qcToolbarRemoveController,
}

function qcToolbarAddController(
  key: string,
  elm: any | undefined,
) {
  return dispatch({
    type: 'QCTB_ADD_CONTOLLER',
    controller: {
      key,
      elm,
    },
  })
}

function qcToolbarRemoveController(
  key: string,
) {
  return dispatch({
    type: 'QCTB_REMOVE_CONTOLLER',
    controller: {
      key,
    },
  })
}


export {
  QCTBActions,
  QCTBActionsTypes,
}
