/* eslint-disable */
import { QCTBActionsTypes } from '~plumbing/components/QCToolbar'

const defaultStore = {
  controllers: {},
}

export default function qctbReducer(store = defaultStore, action) {
  switch (action.type) {

    case 'QCTB_ADD_CONTOLLER':
      return {
        ...store,
        controllers: {
          ...store.controllers,
          [action.controller.key]: action.controller.elm,
        },
      }

    case 'QCTB_REMOVE_CONTOLLER':
      {
        const rmControllers = { ...store.controllers }
        delete rmControllers[action.controller.key]

        return {
          ...store,
          controllers: {
            ...rmControllers,
          },
        }
      }

    default:
      return store
  }
}
