import styled from 'styled-components'

export const DuplicateToggle = styled.div`
    width:100%;
    padding-top:15px;
    overflow:hidden;
    >div {
      width: 100%;
      >label{
      text-align: center;
      font-size:  ${({ theme }) => theme.fontSize.small};
      margin:9px 0 !important;
      margin-${({ theme }) => theme.dirFloatRev}: 30px !important;
      }
    }
`
