/* eslint-disable import/no-cycle */
import Rx from 'rxjs' // eslint-disable-line

import {
  observableFromPromiseProperties,
} from 'CQS/plumbing/utils'
import { actions as appActions } from '~CON/App/appActions'

import { actionsTypes, actions as rulesActions } from './RulesAction'
import apis from '../apis'

const rulesApis = observableFromPromiseProperties(apis, Rx)

export default [
  getRulesData,
  deleteRules,
  getDefRule,
  updateDRule,
  addRule,
  refreshRule,
  updateRule,
]

export function getRulesData(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_ALL_RULES_LIST)
    .switchMap(({ payload }) => {
      return rulesApis
        .getRules()
        .map((res) => {
          return rulesActions.fetchAllRulesListSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_ALL_RULES_LIST_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function deleteRules(action$, { getState }) {
  return action$.ofType(actionsTypes.DELETE_RULE)
    .switchMap(({ payload }) => {
      return rulesApis
        .deleteRule(payload)
        .map((res) => {
          return rulesActions.deleteRuleSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.DELETE_RULE_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getDefRule(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_DEF_RULE)
    .switchMap(({ payload }) => {
      return rulesApis
        .getDefRule(payload)
        .map((res) => {
          return rulesActions.fetchDefRuleSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_DEF_RULE_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function addRule(action$, { getState }) {
  // eslint-disable-next-line max-len
  return action$.ofType(actionsTypes.ADD_RULES)
    .switchMap(({ payload }) => {
      return rulesApis
        .createRule(payload)
        .map((res) => {
          return rulesActions.addRuleSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.ADD_RULES_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function updateRule(action$, { getState }) {
  return action$.ofType(actionsTypes.EDIT_RULE)
    .switchMap(({ payload }) => {
      return rulesApis
        .editRule(payload)
        .map((res) => {
          return rulesActions.editRuleSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.EDIT_RULE_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function updateDRule(action$, { getState }) {
  return action$.ofType(actionsTypes.UPDATE_DEF_RULE)
    .switchMap(({ payload }) => {
      return rulesApis
        .updateDefRule(payload)
        .map((res) => {
          return rulesActions.updateDefRuleSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.UPDATE_DEF_RULE_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function refreshRule(action$, { getState }) {
  return action$.ofType(actionsTypes.REFRESH_RULE)
    .switchMap(({ payload }) => {
      return rulesApis
        .refRule(payload)
        .map((res) => {
          return rulesActions.refreshRuleSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.REFRESH_RULE_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
