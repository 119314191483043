import {
  toggleOptionForUserLocaleDir as toggleDir,
} from '~plumbing/utils/i18n'


/**
 * switch values between RTL/LTR for current language
 *
 * @example
 * switchDir.ltr('right').rtl('left')
 *
 * @example
 * switchDir.LTR('right').RTL('left')
 *
 * @example
 * switchDir({ ltr: 'right', rtl: 'left' })
 */
const dirCase = toggleDir

dirCase.ltr = (ltrVal) => ({ rtl: (rtlVal) => dirCase({ rtl: rtlVal, ltr: ltrVal }) })

dirCase.rtl = (rtlVal) => ({ ltr: (ltrVal) => dirCase({ rtl: rtlVal, ltr: ltrVal }) })

dirCase.LTR = (ltrVal) => ({ RTL: (rtlVal) => dirCase({ rtl: rtlVal, ltr: ltrVal }) })

dirCase.RTL = (rtlVal) => ({ LTR: (ltrVal) => dirCase({ rtl: rtlVal, ltr: ltrVal }) })

export default dirCase
