import { inCase } from '~plumbing/utils'
import {
  actionsTypes,
} from './RulesAction'

const defaultState = {
  Rules: {
    RulesList: [],
    DefRule: [],
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
}
const rulesReducer = inCase.curry('rulesReducer')
  .check((s, action) => action.type)
  .otherwise((state) => state || defaultState)
  .cases({
    [actionsTypes.FETCH_ALL_RULES_LIST]: fetchAllRulesList,
    [actionsTypes.FETCH_ALL_RULES_LIST_SUCCESS]: fetchAllRulesListSuccess,
    [actionsTypes.FETCH_ALL_RULES_LIST_FAILED]: fetchAllRulesListFailed,
    [actionsTypes.DELETE_RULE]: deleteRule,
    [actionsTypes.DELETE_RULE_SUCCESS]: deleteRuleSuccess,
    [actionsTypes.DELETE_RULE_FAILED]: deleteRuleFailed,
    [actionsTypes.FETCH_DEF_RULE]: fetchDefRule,
    [actionsTypes.FETCH_DEF_RULE_SUCCESS]: fetchDefRuleSuccess,
    [actionsTypes.FETCH_DEF_RULE_FAILED]: fetchDefRuleFailed,
    [actionsTypes.ADD_RULES]: addRule,
    [actionsTypes.ADD_RULES_SUCCESS]: addRuleSuccess,
    [actionsTypes.ADD_RULES_FAILED]: addRuleFailed,
    [actionsTypes.EDIT_RULE]: editRule,
    [actionsTypes.EDIT_RULE_SUCCESS]: editRuleSuccess,
    [actionsTypes.EDIT_RULE_FAILED]: editRuleFailed,
    [actionsTypes.UPDATE_DEF_RULE]: updateDefRule,
    [actionsTypes.UPDATE_DEF_RULE_SUCCESS]: updateDefRuleSuccess,
    [actionsTypes.UPDATE_DEF_RULE_FAILED]: updateDefRuleFailed,
    [actionsTypes.REFRESH_RULE]: refreshRule,
    [actionsTypes.REFRESH_RULE_SUCCESS]: refreshRuleSuccess,
    [actionsTypes.REFRESH_RULE_FAILED]: refreshRuleFailed,
  })

function fetchAllRulesList(state, action) {
  return {
    ...state,
  }
}
function fetchAllRulesListSuccess(state, action) {
  return {
    ...state,
    Rules: {
      RulesList: action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: false,
        errorMessage: '',
      },
    },
  }
}
function fetchAllRulesListFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function deleteRule(state, action) {
  return {
    ...state,
  }
}
function deleteRuleSuccess(state, action) {
  return {
    ...state,
  }
}
function deleteRuleFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchDefRule(state, action) {
  return {
    ...state,
  }
}
function fetchDefRuleSuccess(state, action) {
  return {
    ...state,
    Rules: {
      DefRule: action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: false,
        errorMessage: '',
      },
    },
  }
}
function fetchDefRuleFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function addRule(state, action) {
  return {
    ...state,
  }
}
function addRuleSuccess(state, action) {
  return {
    ...state,
  }
}
function addRuleFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function editRule(state, action) {
  return {
    ...state,
  }
}
function editRuleSuccess(state, action) {
  return {
    ...state,
    Rules: {
      RulesList: action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: false,
        errorMessage: '',
      },
    },
  }
}
function editRuleFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function updateDefRule(state, action) {
  return {
    ...state,
    Rules: {
      DefRule: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function updateDefRuleSuccess(state, action) {
  return {
    ...state,
    Rules: {
      DefRule: action.payload,
      meta: {
        isFailed: false,
        isLoading: true,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function updateDefRuleFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function refreshRule(state, action) {
  return {
    ...state,
  }
}
function refreshRuleSuccess(state, action) {
  return {
    ...state,
  }
}
function refreshRuleFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
export default rulesReducer
