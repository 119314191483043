/* eslint-disable max-len */
import React from 'react'
import {
  Link,
  withRouter,
} from 'react-router-dom'
import { matchPath, useLocation } from 'react-router'
import styled from 'styled-components'
import theme from '~plumbing/themes'
//
import ScarletIcon from '~plumbing/assets/scarletIcons/ScarletIcon'
import dashboardIcon from '~plumbing/assets/scarletIcons/icons/activity.svg'
import customerManagement from '~plumbing/assets/scarletIcons/icons/user-plus.svg'
import ProviderPage from '~plumbing/assets/scarletIcons/icons/users.svg'
import GlobalConfig from '~plumbing/assets/scarletIcons/icons/globe.svg'
import MasterDetails from '~plumbing/assets/scarletIcons/icons/package.svg'
import Coverage from '~plumbing/assets/scarletIcons/icons/git-branch.svg'
import Request from '~plumbing/assets/scarletIcons/icons/chart-column.svg'
import Rules from '~plumbing/assets/scarletIcons/icons/error.svg'
import Users from '~plumbing/assets/scarletIcons/icons/user.svg'
import Usage from '~plumbing/assets/scarletIcons/icons/clipboard.svg'
import Audit from '~plumbing/assets/scarletIcons/icons/columns.svg'
import Cleansing from '~plumbing/assets/scarletIcons/icons/cloud.svg'
// import Cleansing from '../../../Discovery/Cleansing/Components/Cleansing'


import { withThemed } from '~utils/themed'
import NavMenu from '../NavMenu'

export const SideMenu = withThemed(withRouter((props) => {
  return (
    <SideMenuContainer>
      <SideMenuContent path={props?.match?.path} />
    </SideMenuContainer>
  )
}))`
  height: 45px;
  display: flex;
  background:red;
  min-width: 20px;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-self: stretch;
  justify-content: flex-start;

  svg, svg [class^="fa-"] {
    color: ${() => theme.brandMain} !important;
  }
  & :hover {
    svg {
      color: #fff !important;
      [class^="fa-"] {
        color: #fff !important;
      }
    }
  }
`
const SideMenuContainer = styled.div`{

  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  /* Grid Item */

}`
const ListItem = styled.ul`{
  padding: 7% 3% 7% 7%;
  li {
    margin: 0px 0px 10px 0px;
    .itemName {
      border-radius: 4px;
      justify-content: space-between;
      padding: 3% 2%;
      display: flex;
      align-items: center;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.32px;
      color: #7b7b7b;
      a{
        width: 90%;
        cursor: pointer;
        .itemTitle{
          display: flex;
          align-items: center;
          svg{
            stroke: #787878 !important;
            fill: #787878 !important;
          }
          img{
            width: 15px;
            margin-right: 3%;
          }
          label{
            padding-left: 3px;
            font-family: TTInterphasesRegular !important;
            font-size: 10px;
            letter-spacing: normal;
            font-weight: 500;
            cursor: pointer;
            border-bottom: none !important;
            text-decoration: none !important;
          }
        }
        &:focus{
          text-decoration: none !important;
        }
      }
      &:hover {
        background-color: #f3f3f3;
      }
      &.active{
        background-color: #f3f3f3;
        .itemTitle{
          label{
            color: #343434;
            padding-left: 3px;
          }
          svg{
            stroke: #ff0000 !important;
            fill: #ff0000 !important;
          }
        }
      }
    }
  }
`
const Menu = () => {
  const location = useLocation()
  const routes = [
    {
      label: 'HLR Configuration', route: '/Discovery/HLRConfig', img: `${dashboardIcon}`, hash: 'activity',
    },
    {
      label: 'MNP Configuration', route: '/Discovery/MNPConfig', img: `${dashboardIcon}`, hash: 'activity',
    },
    {
      label: 'Customer Management', route: '/Discovery/CustomerManagement', img: `${customerManagement}`, hash: 'user-plus',
    },
    {
      label: 'Providers', route: '/Discovery/Provider', img: `${ProviderPage}`, hash: 'users',
    },
    {
      label: 'Global Configuration', route: '/Discovery/GlobalManagement', img: `${GlobalConfig}`, hash: 'globe',
    },
    {
      label: 'Number History', route: '/Discovery/NumberHistory', img: `${MasterDetails}`, hash: 'package',
    },
    {
      label: 'Coverage Testing', route: '/Discovery/CoverageTesting', img: `${Coverage}`, hash: 'git-branch',
    },
    {
      label: 'Requests', route: '/Discovery/Requests', img: `${Request}`, hash: 'chart-column',
    },
    {
      label: 'Flooding Rules', route: '/Discovery/Rules', img: `${Rules}`, hash: 'error',
    },
    {
      label: 'User Management', route: '/Discovery/UserManagement', img: `${Users}`, hash: 'user',
    },
    {
      label: 'Usage', route: '/Discovery/Usage', img: `${Usage}`, hash: 'clipboard',
    },
    {
      label: 'Audit', route: '/Discovery/Audits', img: `${Audit}`, hash: 'columns',
    },
    {
      label: 'Cleansing', route: '/Discovery/Cleansing', img: `${Cleansing}`, hash: 'cloud',
    },
  ]
  return (
    <>
      {routes.map((r, i) => (
        <li key={r.label}>
          <span className={matchPath(location.pathname, r.route) ? 'itemName active' : 'itemName'}>
            <Link
              to={r.route}
            >
              <div className="itemTitle">
                <ScarletIcon
                  hash={r.hash}
                  href={r.img}
                  // iconSize={16}
                />
                <label>{r.label}</label>
              </div>
            </Link>
          </span>
        </li>
      ))}
    </>
  )
}
const SideMenuContent = withThemed((props) => {
  return (
    <>
      <NavMenu />
      <ListItem>
        <Menu />
      </ListItem>
    </>
  )
})`
      /* Element Styles */
      //left: 0;
      width: 200px;
      height: 100vh;
      position: fixed;
      background: #fff;
      border-right: 1px #EAE4E4 solid;

      /* Grid Item */
      grid-area: navBar;

      /* flex container */
      display: flex;
      flex-direction: column;
      justify-content:flex-start;
      align-content: flex-start;
      align-items: center;
      `
