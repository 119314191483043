// import truncateMiddle from 'truncate-middle'
import request from 'superagent'
import {
  get as _get,
} from 'lodash'

import { getToken } from './apiUtils'


const API_UPLOAD_HOST = process.env.REACT_APP__API_UPLOAD_HOST || ''
const API_UPLOAD_BASE_URL = process.env.REACT_APP__API_UPLOAD_BASE_URL || ''

const API_UPLOAD_URL = `${API_UPLOAD_HOST}${API_UPLOAD_BASE_URL}`


/**
 * upload file
 *
 * @param {File} file
 * @param {string} fileName
 *
 * @returns {Promise}
 */
export function uploadFile(file, fileName) {
  const token = getToken()

  return new Promise((resolve, reject) => {
    request
      .post(API_UPLOAD_URL)
      .set('x-user-auth', `bearer ${token}`)
      .field('FileName', fileName)
      .field('fileName', fileName)
      .attach('file', file)
      .then((resp) => {
        const payload = _get(resp, 'body', {})
        const fileUrl = _get(payload, 'url')
        const fileUrlSigned = _get(payload, 'signedUrl')

        resolve({
          file,
          fileUrl,
          fileName,
          fileUrlSigned,
        })
      })
      .catch((error) => {
        error.file = file // eslint-disable-line
        error.fileName = fileName // eslint-disable-line

        reject(error)
      })
  })
}


/**
 * format recipients file name
 *
 * @param file
 * @return {string}
 */
export function formatRecipientFileName(file) {
  const count = _get(file, 'contactsCount', 0)
  const name = _get(file, 'fileName', '')
  const ext = _get(name.match(/(\..+)$/), '1', '')

  if (name.match(/.+-\[\d+\]\./gi)) {
    return name
  }

  return `${truncateRecipientFileName(file).name}-[${count}]${ext}`
}

export function truncateRecipientFileName(file, maxLen) {
  const MAX_LEN = maxLen || 50

  const count = _get(file, 'contactsCount', 0)
  const name = _get(file, 'fileName', '')

  const ext = _get(name.match(/\.([0-9a-z]+)(?:[?#]|$)/i), '1', '')
  const addLen = `${count}`.length + ext.length + 3 + 3

  function cutIndex(max, rm, end = 20) {
    const k = Math.ceil(((max / 100) * end))
    const r = Math.ceil(k + rm)
    const n = Math.ceil(max - r)
    const s = n + k
    end = end - 5 // eslint-disable-line

    if (s >= max && end >= 0) {
      return cutIndex(max, rm, end)
    }

    return {
      frontLen: (n > k) ? n : k, // frontLen always the larger val
      backLen: (n > k) ? k : n,
    }
  }

  const { frontLen, backLen } = cutIndex(MAX_LEN, addLen)


  const x = new RegExp(`(${ext})$`)
  const fn = name.replace(x, '') || name

  return {
    ext,
    name: fn,
  }
}

export function uploadAudioFileApi(audioFile) {
  return new Promise((resolve) => {
    setTimeout(
      resolve(() => {}), 3000)
  })
}
