import React from 'react'

import ScarletIcon from '~plumbing/assets/scarletIcons/ScarletIcon'

import { HintWrapper } from './styledComponents'


export default function CQSHintMessage({
  title, icon, show, status = 'hint', ...restProps
}) {
  const renderTitle = () => {
    return title ? <h3>{title}</h3> : null
  }

  const renderIcon = () => {
    switch (status) {
      case 'hint':
        return (
          <ScarletIcon
            iconSize={16}
            name="info" // should be info-inverted
          />
        )
        break

      case 'success':
        return (
          <ScarletIcon
            iconSize={16}
            name="check-circle"
          />
        )
        break

      default:
        return null
    }
  }

  return show
    ? (
      <HintWrapper status={status} {...restProps}>
        {renderIcon()}

        {renderTitle()}

        {restProps.children}
      </HintWrapper>
    )
    : null
}
