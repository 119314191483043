/* eslint-disable
  func-names,
  prefer-rest-params,
  no-unused-expressions,
  prefer-arrow-callback,
*/
import queryString from 'query-string'

import bugsnagClient from '~CON/bugsnag'


xhrSpyInit({
  filter: (url) => {
    const match = !!(url.match?.(process.env.REACT_APP__API_HOST)
      || url.match?.(process.env.REACT_APP__API_UPLOAD_HOST))

    return match
  },

  HOST: [
    `${process.env.REACT_APP__API_HOST}${process.env?.REACT_APP__API_BASE_URL || ''}`,
    `${process.env.REACT_APP__API_UPLOAD_HOST}${process.env?.REACT_APP__API_UPLOAD_BASE_URL || ''}`,
  ],

  onRespons: (req) => {
    bugsnagClient.leaveBreadcrumb(req.repr, req, 'XHR')
  },

  onErrorRespons: (req) => {
    console.error('[🌐] XHR Error Respons\t', req.repr, { ...req })
    bugsnagClient.leaveBreadcrumb(req.repr, req, 'XHR')

    bugsnagClient.notify(new XHRError('XHR Error Respons', req.repr, req))
  },

  onXhrError: (req) => {
    console.error('[🌐] XHR Error\t', req.repr, { ...req })
    bugsnagClient.leaveBreadcrumb(req.repr, req, 'XHR')
    bugsnagClient.notify(new XHRError('XHR Error', req.repr, req))
  },

  onXhrAbort: (req) => {
    console.warn('[🌐] XHR Abort\t', req.repr, { ...req })
    bugsnagClient.leaveBreadcrumb(req.repr, req, 'XHR')
    bugsnagClient.notify(new XHRError('XHR Abort', req.repr, req))
  },

  onXhrTimeout: (req) => {
    console.warn('[🌐] XHR Timeout\t', req.repr, { ...req })
    bugsnagClient.leaveBreadcrumb(req.repr, req, 'XHR')
    bugsnagClient.notify(new XHRError('XHR Timeout', req.repr, req))
  },
})


function xhrSpyInit(conf) {
  const {
    HOST,
    filter,

    onXhrAbort,
    onXhrError,
    onXhrLoad,
    onXhrTimeout,

    onRespons,
    onErrorRespons,

    on100Respons,
    on200Respons,
    on300Respons,
    on400Respons,
    on500Respons,
  } = conf

  const onXhrAbortHandlers = [
    ...(Array.isArray(onXhrAbort) ? onXhrAbort : [onXhrAbort]),
  ]

  const onXhrErrorHandlers = [
    ...(Array.isArray(onXhrError) ? onXhrError : [onXhrError]),
  ]

  const onXhrLoadHandlers = [
    ...(Array.isArray(onXhrLoad) ? onXhrLoad : [onXhrLoad]),
  ]

  const onXhrTimeoutHandlers = [
    ...(Array.isArray(onXhrTimeout) ? onXhrTimeout : [onXhrTimeout]),
  ]

  const onResponsHandlers = [
    ...(Array.isArray(onRespons) ? onRespons : [onRespons]),
  ]

  const on100ResponsHandlers = [
    ...(Array.isArray(on100Respons) ? on100Respons : [on100Respons]),
  ]

  const on200ResponsHandlers = [
    ...(Array.isArray(on200Respons) ? on200Respons : [on200Respons]),
  ]

  const on300ResponsHandlers = [
    ...(Array.isArray(on300Respons) ? on300Respons : [on300Respons]),
  ]

  const onErrorResponsHandlers = [
    ...(Array.isArray(onErrorRespons) ? onErrorRespons : [onErrorRespons]),
  ]

  const on400ResponsHandlers = [
    ...(Array.isArray(on400Respons) ? on400Respons : [on400Respons]),
  ]

  const on500ResponsHandlers = [
    ...(Array.isArray(on500Respons) ? on500Respons : [on500Respons]),
  ]

  const XHR = XMLHttpRequest.prototype

  if (XHR.__spyOn) {
    return xhrSpyInit.spyApi
  }

  // Remember references to original methods
  const { open, send } = XHR
  let xhrInstance

  // Overwrite native methods
  // Collect data: asd
  XHR.open = function (method, url) {
    this._url = url
    this._method = method
    this._appHost = HOST
    this._queryString = queryString.parse(url?.split('?')?.[1])
    this._repr = `${method}  ${HOST.reduce((a, h) => a.replace(h, ''), url?.split('?')?.[0])}`
    this._spy = filter(url, method)

    const handelOnAbort = () => {
      const resp = {
        url: this._url,
        method: this._method,
        queryString: this._queryString,
        repr: this._repr,

        status: this.status,
        timeout: this.timeout,
        response: this.response,
        statusText: this.statusText,
        responseText: this.responseText,
        responseType: this.responseType,
      }
      // eslint-disable-next-line no-unused-expressions
      onXhrAbort?.forEach?.((handler) => handler?.(resp))
    }

    const handelOnError = () => {
      const resp = {
        url: this._url,
        method: this._method,
        queryString: this._queryString,
        repr: this._repr,

        status: this.status,
        timeout: this.timeout,
        response: this.response,
        statusText: this.statusText,
        responseText: this.responseText,
        responseType: this.responseType,
      }
      // eslint-disable-next-line no-unused-expressions
      onXhrError?.forEach?.((handler) => handler?.(resp))
    }

    const handelOnLoad = () => {
      // eslint-disable-next-line no-unused-expressions
      const fpp = this // eslint-disable-line
      const ass = xhrInstance // eslint-disable-line
      this._repr = `${this.status} ${this.statusText} | ${this._repr}`

      const resp = {
        url: this._url,
        method: this._method,
        queryString: this._queryString,
        repr: this._repr,

        status: this.status,
        timeout: this.timeout,
        response: this.response,
        statusText: this.statusText,
        responseText: this.responseText,
        responseType: this.responseType,
      }

      onXhrLoadHandlers?.forEach?.((handler) => handler?.(resp))

      if (this.status >= 300) {
        onErrorResponsHandlers?.forEach?.((handler) => handler?.(resp))
      } else {
        onResponsHandlers?.forEach?.((handler) => handler?.(resp))
      }

      const __conf = {
        HOST,
        filter,
      }

      if (this.status >= 500) {
        on500ResponsHandlers?.forEach?.((handler) => handler?.(resp, __conf))
      } else if (this.status >= 400) {
        on400ResponsHandlers?.forEach?.((handler) => handler?.(resp, __conf))
      } else if (this.status >= 300) {
        on300ResponsHandlers?.forEach?.((handler) => handler?.(resp, __conf))
      } else if (this.status >= 200) {
        on200ResponsHandlers?.forEach?.((handler) => handler?.(resp, __conf))
      } else if (this.status >= 100) {
        on100ResponsHandlers?.forEach?.((handler) => handler?.(resp, __conf))
      }
    }

    const handelOnTimeout = () => {
      const resp = {
        url: this._url,
        method: this._method,
        queryString: this._queryString,
        repr: this._repr,

        status: this.status,
        timeout: this.timeout,
        response: this.response,
        statusText: this.statusText,
        responseText: this.responseText,
        responseType: this.responseType,
      }
      // eslint-disable-next-line no-unused-expressions
      onXhrTimeout?.forEach?.((handler) => handler?.(resp))
    }

    if (this._spy) {
      this.addEventListener('abort', handelOnAbort)

      this.addEventListener('error', handelOnError)

      this.addEventListener('load', handelOnLoad)

      this.addEventListener('timeout', handelOnTimeout)
    }

    return open.apply(this, arguments)
  }

  // Implement "ajaxSuccess" functionality
  XHR.send = function (postData) {
    // eslint-disable-next-line prefer-rest-params
    return send.apply(this, arguments)
  }

  XHR.__spyOn = true

  // eslint-disable-next-line consistent-return
  xhrSpyInit.spyApi = {
    addXhrAbortHandlers: (handler) => {
      onXhrAbortHandlers.push(handler)
    },
    addXhrErrorHandlers: (handler) => {
      onXhrErrorHandlers.push(handler)
    },
    addXhrLoadHandlers: (handler) => {
      onXhrLoadHandlers.push(handler)
    },
    addXhrTimeoutHandlers: (handler) => {
      onXhrTimeoutHandlers.push(handler)
    },
    addResponsHandlers: (handler) => {
      onResponsHandlers.push(handler)
    },
    add100ResponsHandlers: (handler) => {
      on100ResponsHandlers.push(handler)
    },
    add200ResponsHandlers: (handler) => {
      on200ResponsHandlers.push(handler)
    },
    add300ResponsHandlers: (handler) => {
      on300ResponsHandlers.push(handler)
    },
    addErrorResponsHandlers: (handler) => {
      onErrorResponsHandlers.push(handler)
    },
    add400ResponsHandlers: (handler) => {
      on400ResponsHandlers.push(handler)
    },
    add500ResponsHandlers: (handler) => {
      on500ResponsHandlers.push(handler)
    },
  }

  return xhrSpyInit.spyApi
}


export default xhrSpyInit.spyApi


export class XHRError extends Error {
  constructor(type, message, request) {
    super(message)
    this.name = type
    this.message = message
    this.stack = null
    this.request
  }
}
