import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { defineMessages } from 'react-intl'
// import { Icon } from 'fa-shorthand.macro'
import theme from '~plumbing/themes'

// import { Link } from 'react-router-dom'

import { reactIntl } from '~plumbing/utils'
import { BodyStyle } from '~plumbing/components'

import './ErrorPages.less'

export default function ErrorPage(props) {
  const {
    title,
    children,
    iconType,
    className,
    messageText,
  } = props

  const actionLink = (props.to && props.linkLabel)
    ? (
      <>
        <Link
          to={props.to}
          className="ant-btn ant-btn-primary"
        >
          {props.linkLabel}
        </Link>
        &nbsp;
        &nbsp;
      </>
    ) : null

  return (
    <BodyStyle style={{ background: theme.bgBodyDarker }}>
      <div
        className={`CQS-ErrorPages ${className}`}
      >
        <span className="lnr">
          {/* <Icon icon={iconType}/> */}
        </span>
        <div
          className="CQS-ErrorPages_Content"
        >
          <h3
            className="CQS-ErrorPages_Title"
          >
            {title}
          </h3>
          <div className="CQS-ErrorPages_Message">
            <p>
              {
              messageText // eslint-disable-line
                .split(/, /gi)
                .map((ln, ndx, { length }) => {
                  return (<p key={`${ndx + 1}:${length}`}>{ln}</p>)
                })
            }
            </p>
          </div>
          <div className="CQS-ErrorPages_Extra">
            <p>{children}</p>
          </div>
          <div className="CQS-ErrorPages_Btn">
            {actionLink}
            <Link className="ant-btn ant-btn-default" to="/">
              {ErrorPage.i18nStrings.getFormatMessage('back')}
            </Link>
          </div>
        </div>
      </div>
    </BodyStyle>
  )
}

ErrorPage.propTypes = {
  children: PropTypes.any, // eslint-disable-line
  title: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  messageText: PropTypes.string.isRequired,
}


ErrorPage.Server5xx = () => {
  // const title = ErrorPage.i18nStrings.unknownErrorTitle
  // const message = ErrorPage.i18nStrings.unknownErrorMessage
  return (
    <ErrorPage
      title="Something went wrong"
      messageText="Unknown Error has occurred, Please try again later"
      // iconType={Icon.duotone.dizzy}
    />
  )
}

ErrorPage.SessionExpired = () => {
  // const title = ErrorPage.i18nStrings.sessionExpiredTitle
  // const message = ErrorPage.i18nStrings.sessionExpiredMessage
  return (
    <ErrorPage
      title="Login Session Expired"
      messageText="Your login session has expired, login and try again"
      iconType="icon-cloud-lock"
    />
  )
}

ErrorPage.NotFound404 = () => {
  // const title = ErrorPage.i18nStrings.notFoundTitle
  // const message = ErrorPage.i18nStrings.notFoundMessage
  return (
    <ErrorPage
      title={ErrorPage.i18nStrings.getFormatMessage('notFoundTitle')}
      messageText={ErrorPage.i18nStrings.getFormatMessage('notFoundMessage')}
      // iconType={Icon.duotone.cactus}
    />
  )
}

ErrorPage.Unauth = () => {
  return (
    <ErrorPage
      title={ErrorPage.i18nStrings.getFormatMessage('unauthTitle')}
      messageText={ErrorPage.i18nStrings.getFormatMessage('unauthMessage')}
      // iconType={Icon.duotone.idCardAlt}
    />
  )
}

ErrorPage.AccessDenied = () => {
  return (
    <ErrorPage
      title={ErrorPage.i18nStrings.getFormatMessage('accessDeniedTitle')}
      messageText={ErrorPage.i18nStrings.getFormatMessage('accessDeniedMessage')}
      // iconType={Icon.duotone.idCardAlt}
    />
  )
}

ErrorPage.TrialDenied = () => {
  return (
    <ErrorPage
      to="/account/upgrade"
      // iconType={Icon.duotone.medal}
      linkLabel={ErrorPage.i18nStrings.getFormatMessage('trialDeniedLink')}
      title={ErrorPage.i18nStrings.getFormatMessage('trialDeniedTitle')}
      messageText={ErrorPage.i18nStrings.getFormatMessage('trialDeniedMessage')}
    />
  )
}

ErrorPage.i18nStrings = reactIntl.i18nMessages(defineMessages({
  unknownErrorTitle: {
    id: 'cqs.components.errorPages.unknownError.title',
    defaultMessage: 'Something went wrong',
  },
  unknownErrorMessage: {
    id: 'cqs.components.errorPages.unknownError.message',
    defaultMessage: 'Unknown Error has occurred,\nPlease try again later',
  },
  sessionExpiredTitle: {
    id: 'cqs.components.errorPages.sessionExpired.title',
    defaultMessage: 'Login Session Expired',
  },
  sessionExpiredMessage: {
    id: 'cqs.components.errorPages.sessionExpired.message',
    defaultMessage: 'Your login session has expired,\nlogin and try again',
  },
  notFoundTitle: {
    id: 'cqs.components.errorPages.notFound.title',
    defaultMessage: '404! Not Found',
  },
  notFoundMessage: {
    id: 'cqs.components.errorPages.notFound.message',
    defaultMessage: 'We couldn\'t Find Page,\nReview your url and try again',
  },
  back: {
    id: 'cqs.components.errorPages.notFound.back',
    defaultMessage: 'Back to Home',
  },
  unauthTitle: {
    id: 'cqs.components.errorPages.unauth.title',
    defaultMessage: 'You Are not authorized to view this page',
  },
  unauthMessage: {
    id: 'cqs.components.errorPages.unauth.message',
    defaultMessage: 'You do not have permission to view this page using the credentials that you supplied',
  },
  accessDeniedTitle: {
    id: 'cqs.components.errorPages.accessDenied.title',
    defaultMessage: 'You Are not Allowed to view this page',
  },
  accessDeniedMessage: {
    id: 'cqs.components.errorPages.accessDenied.message',
    defaultMessage: 'You do not have Allowed to view this page using the credentials that you supplied',
  },
  trialDeniedTitle: {
    id: 'cqs.components.errorPages.trialDenied.title',
    defaultMessage: 'Pro Access',
  },
  trialDeniedMessage: {
    id: 'cqs.components.errorPages.trialDenied.message',
    defaultMessage: 'Upgrade your account to gain acces to this feature and many more',
  },
  trialDeniedLink: {
    id: 'cqs.components.errorPages.trialDenied.link',
    defaultMessage: 'Upgrade',
  },
}))
