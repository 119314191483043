import request from 'superagent'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function editRule(payload) {
  const {
    ruleName,
    customers,
    destinations,
    recipients,
    blockedFrom,
    blockedTo,
    csvFile,
  } = payload
  const form = new FormData()
  if (customers && customers.length > 0) {
    for (let i = 0; i < customers.length; i += 1) {
      form.append(`customers[${i}]`, customers[i])
    }
  }
  if (destinations && destinations.length > 0) {
    for (let i = 0; i < destinations.length; i += 1) {
      if (destinations[i].countryId) {
        form.append(`destinations[${i}][countryId]`, destinations[i]?.countryId)
        form.append(`destinations[${i}][networkId]`, destinations[i]?.networkId)
      }
    }
  }
  if (recipients && recipients.length > 0) {
    for (let i = 0; i < recipients.length; i += 1) {
      form.append(`recipients[${i}]`, recipients[i])
    }
    if (csvFile) {
      form.append('csvFile', csvFile)
    }
  }
  form.append('range_time[from]', blockedFrom)
  form.append('range_time[to]', blockedTo)
  form.append('allowed', 'true')
  form.append('manageOldValues', 'true')
  return new Promise((resolve, reject) => {
    request
      .patch(`${API_URL}/api/v1/flooding-rules/${ruleName}`)
      .set('Authorization', `bearer ${getToken()}`)
      .send(form)
      .then((resp) => {
      })
      .catch((err) => {
        reject(err)
      })
  })
}
