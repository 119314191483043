import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function updatedefaultAccount(payload) {
  const defaultAccount = {
    defaultAccount: payload,
  }
  return new Promise((resolve, reject) => {
    request
      .post(`${API_URL}/api/v1/global-config/dns-default-account`)
      .set('Authorization', `bearer ${getToken()}`)
      .send(defaultAccount)
      .then((resp) => {
        resolve(defaultAccount.defaultAccount)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
