/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* global FS */
import Rx from 'rxjs' // eslint-disable-line

import {
  observableFromPromiseProperties,
} from 'CQS/plumbing/utils'
import { actions as appActions } from '~CON/App/appActions'

import { actionsTypes, actions as samplesActions } from './CoverageAction'
import apis from '../apis'

const coverageApis = observableFromPromiseProperties(apis, Rx)

export default [
  getSamplesData,
  getPrefixSamples,
  createSample,
  getSampleById,
  deleteSample,
]

export function getSamplesData(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_ALL_COVERAGES)
    .switchMap(({ payload }) => {
      return coverageApis
        .samplesData(payload)
        .map((res) => {
          return samplesActions.fetchAllCoveragesSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_ALL_COVERAGES_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getPrefixSamples(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_ALL_PREFIXES_SAMPLES)
    .switchMap(({ action }) => {
      return coverageApis
        .prefixesWithSamples()
        .map((res) => {
          return samplesActions.fetchAllPrefixesSamplesSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_ALL_PREFIXES_SAMPLES_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function createSample(action$, { getState }) {
  return action$.ofType(actionsTypes.CREATE_SAMPLE)
    .switchMap(({ payload }) => {
      return coverageApis
        .runSample(payload)
        .map((res) => {
          return samplesActions.createSampleSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.CREATE_SAMPLE_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getSampleById(action$, { getState }) {
  return action$.ofType(actionsTypes.COVERAGES_BY_ID)
    .switchMap(({ payload }) => {
      return coverageApis
        .getSamplesDataById(payload)
        .map((res) => {
          return samplesActions.coveragesByIdSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.COVERAGES_BY_ID,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function deleteSample(action$, { getState }) {
  return action$.ofType(actionsTypes.DELETE_COVERAGES)
    .switchMap(({ payload }) => {
      return coverageApis
        .deleteSamples(payload)
        .map((res) => {
          return samplesActions.deleteCoveragesSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.DELETE_COVERAGES_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
