import uuid from 'uuid/v1'
import crypto from 'crypto-js'
import {
  isNull as _isNull,
  isEmpty as _isEmpty,
  isNumber as _isNumber,
  isString as _isString,
  isObject as _isObject,
  isFunction as _isFunction,
} from 'lodash'

import base64 from '~utils/base64'

const eventHandlers = {}

window.addEventListener('storage', (evt) => {
  Object.values(eventHandlers)
    .forEach((h) => h?.(evt))
})


export default ((ls) => (prefix, options = {}) => (key, initialValue, opts = {}) => {
  /* eslint-disable no-param-reassign */
  const optScopeId = (opts.scopeId ?? options.scopeId)
  const optReadOnly = (opts.readOnly ?? options.readOnly)
  const optHintKey = withDebugMode((opts.hintKey ?? options.hintKey), true)
  const optEncrypt = withDebugMode((opts.encrypt ?? options.encrypt), false)
  const optScramble = withDebugMode((opts.scramble ?? options.scramble), false)
  const optPlainValue = withDebugMode((opts.plainValue ?? options.plainValue), true)
  const getInitialValue = () => (_isFunction(initialValue) ? initialValue() : initialValue)

  const {
    key: pass,
    phrase: scrambleKey,
  } = scramble(key, { insertKey: optHintKey })

  const __k = `${prefix}${key}`

  key = optScramble
    ? scrambleKey
    : key

  const itemKeyScoped = `${prefix}${key}+${optScopeId}_${optHintKey}`

  const itemKeyBasic = `${prefix}${key}`

  const itemKey = optScopeId
    ? itemKeyScoped
    : itemKeyBasic

  const removeValue = () => ls.removeItem(itemKey)

  const setValue = (v) => {
    try {
      let val = v

      const isEmpty = _isEmpty(val) && !_isNumber(val)

      if (isEmpty) {
        val = ''
      } else if (!_isString(val)) {
        val = JSON.stringify(val)
      }

      if (optEncrypt && !isEmpty) {
        val = encrypt(val, pass)
        val = `${val}==//`
      } else if (optPlainValue) {
        val = `${val}`
      } else {
        val = `${val}=/`
      }

      ls.setItem(itemKey, val)
    } catch (e) {
      console.error('[KVS] set', e.message)
    }
  }

  const getValue = () => {
    try {
      const d = ls.getItem(itemKey)

      if (_isEmpty(d)) {
        return d
      }

      if (optEncrypt) {
        try {
          return JSON.parse(decrypt(d, pass))
        } catch (e) {
          return decrypt(d, pass)
        }
      }

      try {
        return JSON.parse(d)
      } catch (e) {
        return d
      }
    } catch (e) {
      console.error('[KVS] get', e.message)
      return null
    }
  }

  const resetValue = () => setValue(getInitialValue())

  if (_isEmpty(getValue()) && initialValue) {
    setValue(getInitialValue())
  }

  if (opts?.removeOnBlur ?? options?.removeOnBlur) {
    window.addEventListener('blur', removeValue)
    document.addEventListener('blur', removeValue)
    document.body.addEventListener('blur', removeValue)
  }

  if (opts?.removeOnUnload ?? options?.removeOnUnload) {
    window.addEventListener('unload', removeValue)
    document.addEventListener('unload', removeValue)
    document.body.addEventListener('unload', removeValue)
  }

  if (opts?.resetOnLoad ?? options?.resetOnLoad) {
    window.addEventListener('load', resetValue)
    document.addEventListener('load', resetValue)
    document.body.addEventListener('load', resetValue)
  }

  if (opts?.resetOnFocus ?? options?.resetOnFocus) {
    window.addEventListener('focus', resetValue)
    document.addEventListener('focus', resetValue)
    document.body.addEventListener('focus', resetValue)
  }

  const actions = {
    get() {
      const preGet = (opts.preGet ?? options.preGet)
      const postGet = (opts.postGet ?? options.postGet)

      exec(preGet).args().otherwise()

      const rawVal = getValue() || ''

      const val = exec(postGet).args(rawVal).otherwise(rawVal)

      logInDebug('get', { key, raw: rawVal, val })

      return val
    },
    set(value) {
      if (!optReadOnly) {
        const preSet = (opts.preSet ?? options.preSet)
        const postSet = (opts.postSet ?? options.postSet)

        const rawVal = exec(preSet).args(value).otherwise(value)

        setValue(rawVal)

        // eslint-disable-next-line no-unused-expressions
        const val = exec(postSet).args(rawVal).otherwise(rawVal)

        logInDebug('set', { key, raw: rawVal, val })

        return val
      }

      return undefined
    },
    reset() {
      resetValue()
    },
    clear() {
      if (!optReadOnly) {
        const preClear = (opts.preClear ?? options.preClear)
        const postClear = (opts.postClear ?? options.postClear)

        const val = getValue()
        const v = exec(preClear).args(val).otherwise(val)

        setValue(v)

        return exec(postClear).args(v).otherwise()
      }

      return undefined
    },
    remove() {
      if (!optReadOnly) {
        removeValue()
      }
    },
    getAll() {
      if (!optScopeId) { return null }

      const items = Object.entries(localStorage)
        .filter(([k]) => !!k.match(RegExp(`${itemKeyBasic}.+`)))

      const thisItem = items
        .filter(([k]) => k === itemKey)
        .flat()

      return {
        items,
        count: items.length,
        isEmpty: items.length === 0,
        entries: Object.fromEntries(items),
        thisItem: thisItem.length ? thisItem : null,
      }
    },
    /* eslint-disable no-unused-expressions */
    onUpdate(currentCB, anyCB) {
      return addKVSEventHandler((evt) => {
        evt.currentKey = itemKey
        evt.isCurrentKeyMatch = itemKey === evt.key

        if (evt.key === itemKey) {
          currentCB?.(evt)
        }

        anyCB?.(evt)
      })
    },
    onChange(currentCB, anyCB) {
      return addKVSEventHandler((evt) => {
        evt.currentKey = itemKey
        evt.isCurrentKeyMatch = itemKey === evt.key

        if (evt.key === itemKey || _isNull(evt.key)) {
          currentCB?.(evt)
        }

        anyCB?.(evt)
      })
    },
    onRemove(currentCB, anyCB) {
      return addKVSEventHandler((evt) => {
        evt.currentKey = itemKey
        evt.isCurrentKeyMatch = itemKey === evt.key

        if (evt.key === itemKey && _isNull(evt.newValue)) {
          currentCB?.(evt)
        }

        anyCB?.(evt)
      })
    },
    onClear(currentCB, anyCB) {
      return addKVSEventHandler((evt) => {
        evt.currentKey = itemKey
        evt.isCurrentKeyMatch = itemKey === evt.key

        if (_isNull(evt.key)) {
          currentCB?.(evt)
          anyCB?.(evt)
        }

        return null
      })
    },
    /* eslint-enable no-unused-expressions */
  }

  const apiExtensionsArgs = {
    initialValue,
    getInitialValue,
    actions,
    itemKey: {
      active: itemKey,
      basic: itemKeyBasic,
      scopedUuid: itemKeyScoped,
    },
  }

  const apiExt = do {
    /* eslint-disable no-unused-expressions */
    if (_isFunction(opts?.extensions)) {
      Object.entries(opts?.extensions?.(apiExtensionsArgs))
    } else if (_isObject(opts?.extensions)) {
      Object.entries(opts.extensions)
    } else {
      Object.entries({})
    }
    /* eslint-enable no-unused-expressions */
  }
  const apiExtensions = Object.fromEntries(apiExt
    .map(([k, f]) => {
      return [k, (...args) => f(apiExtensionsArgs, ...args)]
    }))

  return {
    ...actions,
    ...apiExtensions,
  }
  /* eslint-enable no-param-reassign */
})(localStorage)

function scramble(phrase, options = {}) {
  /* eslint-disable no-param-reassign */
  // const text = phrase

  const ky = crypto.SHA1(phrase)
    .toString()

  const postfix = crypto.SHA3(ky)
    .toString()

  if (!options.plainKey) {
    // phrase = crypto.AES.encrypt(phrase, ky)
    //   .toString()

    phrase = base64.encodeURI(`{k:${ky},p:${postfix}}`)

    return {
      key: ky,
      phrase: `${phrase}.${postfix}/b`,
    }
  }

  return {
    key: null,
    phrase: `${phrase}.${postfix}`,
  }
  /* eslint-enable no-param-reassign */
}

function unscramble(phrase, options = {}) {
  const [, text,, b64] = phrase.match(/^(.+?)\.(.+?)(\/b)?$/)

  if (b64) {
    try {
      return base64.decode(text)
    } catch (e) {
      console.error(e)
    }
  }

  return text
}

function decrypt(ciphertext, pass) {
  // return ciphertext
  return crypto.AES.decrypt(ciphertext, pass)
    .toString(crypto.enc.Utf8)
}

function encrypt(phrase, pass) {
  // return phrase
  return crypto.AES.encrypt(phrase, pass)
    .toString()
}

function exec(fn) {
  return {
    args: (...args) => ({
      otherwise: (otherwise) => {
        if (_isFunction(fn)) {
          return fn(...args)
        }

        return otherwise
      },
    }),
  }
}

/**
 *
 * @param normalVal - value
 * @param debugVal - value if debug mode is enabled
 *
 * @returns {*}
 */
function withDebugMode(normalVal, debugVal) {
  return process.env.REACT_APP__DEV || process.env.REACT_APP__KVS_DEBUG
    ? (debugVal ?? normalVal)
    : normalVal
}

function logInDebug(action, args) {
  const {
    key,
    raw,
    val,
  } = args

  if (process.env.REACT_APP__DEV || process.env.REACT_APP__KVS_DEBUG) {
    const value = { raw, val }
  }
}

function addKVSEventHandler(handler) {
  const ts = new Date().getTime()
  eventHandlers[ts] = handler

  return () => {
    eventHandlers[ts] = null

    delete eventHandlers[ts]
  }
}
