import { element } from 'prop-types'
import { inCase } from '~plumbing/utils'
import { actions as prefixsActions, actionsTypes } from './ConfigActions'

const defaultState = {
  Prefixes: {
    PrefixsData: [],
    exportData: '',
    defaultDes: [],
    CustomerData: [],
    defaultproviders: [],
    messagedeleted: '',
    HLRData: [],
    MNPData: [],
    CountriesData: [],
    NetworkData: [],
    meta: {
      isMNPLoading: false,
      isHLRLoading: false,
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
}
const prefixReducer = inCase
  .curry('prefixReducer')
  .check((s, action) => action.type)
  .otherwise((state) => state || defaultState)
  .cases({
    [actionsTypes.FETCH_ALL_PREFIXS]: fetchAllPrefixs,
    [actionsTypes.FETCH_ALL_PREFIXS_SUCCESS]: fetchAllPrefixsSuccess,
    [actionsTypes.FETCH_ALL_PREFIXS_FAILED]: fetchAllPrefixsFailed,
    [actionsTypes.FETCH_CUSTOMER_CONFIG]: fetchAllConfig,
    [actionsTypes.FETCH_CUSTOMER_HLR_CONFIG]: fetchHLRConfig,
    [actionsTypes.FETCH_CUSTOMER_MNP_CONFIG]: fetchMNPConfig,
    [actionsTypes.FETCH_CUSTOMER_CONFIG_SUCCESS]: fetchAllConfigSuccess,
    [actionsTypes.FETCH_CUSTOMER_HLR_CONFIG_SUCCESS]: fetchHLRConfigSuccess,
    [actionsTypes.FETCH_CUSTOMER_MNP_CONFIG_SUCCESS]: fetchMNPConfigSuccess,
    [actionsTypes.FETCH_CUSTOMER_CONFIG_FAILED]: fetchAllConfigFailed,
    [actionsTypes.FETCH_CUSTOMER_HLR_CONFIG_FAILED]: fetchHLRConfigFailed,
    [actionsTypes.FETCH_CUSTOMER_MNP_CONFIG_FAILED]: fetchMNPConfigFailed,
    [actionsTypes.FETCH_ALL_COUNTRIES]: fetchAllCountries,
    [actionsTypes.FETCH_ALL_COUNTRIES_SUCCESS]: fetchAllCountriesSuccess,
    [actionsTypes.FETCH_ALL_COUNTRIES_FAILED]: fetchAllCountriesFailed,
    [actionsTypes.ALL_NETWORKS]: fetchNetworks,
    [actionsTypes.ALL_NETWORKS_SUCCESS]: fetchNetworksSuccess,
    [actionsTypes.ALL_NETWORKS_FAILED]: fetchNetworksFailed,
    [actionsTypes.UPDATE_PREFIX]: updatePrefix,
    [actionsTypes.UPDATE_PREFIX_SUCCESS]: updatePrefixSuccess,
    [actionsTypes.UPDATE_PREFIX_FAILED]: updatePrefixFailed,
    [actionsTypes.ADD_DESTNATION]: addDestnation,
    [actionsTypes.ADD_DESTNATION_SUCCESS]: addDestnationSuccess,
    [actionsTypes.ADD_DESTNATION_FAILED]: addDestnationFailed,
    [actionsTypes.DELETE_PREFIX]: deletePrefix,
    [actionsTypes.DELETE_PREFIX_SUCCESS]: deletePrefixSuccess,
    [actionsTypes.DELETE_PREFIX_FAILED]: deletePrefixFailed,
    [actionsTypes.EXPORT_PREFIXES]: exportPrefixes,
    [actionsTypes.EXPORT_PREFIXES_SUCCESS]: exportPrefixesSuccess,
    [actionsTypes.EXPORT_PREFIXES_FAILED]: exportPrefixesFailed,
    [actionsTypes.FETCH_DEFAULT_CONFIG]: fetchDefaultDestnation,
    [actionsTypes.FETCH_DEFAULT_CONFIG_SUCCESS]: fetchDefaultDestnationSuccess,
    [actionsTypes.FETCH_DEFAULT_CONFIG_FAILED]: fetchDefaultDestnationFailed,
  })

function fetchDefaultDestnation(state, action) {
  return {
    ...state,
  }
}
function fetchDefaultDestnationSuccess(state, action) {
  return {
    ...state,
    Prefixes: {
      defaultDes: action.payload,
      meta: {
        isLoading: false,
        isMNPLoading: false,
        isHLRLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchDefaultDestnationFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function exportPrefixes(state, action) {
  return {
    ...state,
  }
}
function exportPrefixesSuccess(state, action) {
  return {
    ...state,
    Prefixes: {
      exportData: action.payload,
      meta: {
        isLoading: false,
        isMNPLoading: false,
        isHLRLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function exportPrefixesFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchAllPrefixs(state, action) {
  return {
    ...state,
  }
}
function fetchAllPrefixsSuccess(state, action) {
  return {
    ...state,
    Prefixes: {
      PrefixsData: action.payload,
      meta: {
        isLoading: false,
        isMNPLoading: false,
        isHLRLoading: false,
        isFailed: false,
        isSuccess: false,
        errorMessage: '',
      },
    },
  }
}
function fetchAllPrefixsFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchAllConfig(state, action) {
  return {
    ...state,
  }
}
function fetchHLRConfig(state, action) {
  return {
    ...state,
    Prefixes: {
      ...state.Prefixes,
      meta: {
        ...state.Prefixes.meta,
        isHlrLoading: true,
      },
    },
  }
}
function fetchMNPConfig(state, action) {
  return {
    ...state,
    Prefixes: {
      meta: {
        ...state.Prefixes.meta,
        isMNPLoading: true,
      },
    },
  }
}
function fetchAllConfigSuccess(state, action) {
  return {
    ...state,
    Prefixes: {
      CustomerData: action.payload,
      meta: {
        isLoading: false,
        isMNPLoading: false,
        isHLRLoading: false,
        isFailed: false,
        isSuccess: false,
        errorMessage: '',
      },
    },
  }
}
function fetchHLRConfigSuccess(state, action) {
  let hlrdata = []
  if (action.payload) {
    hlrdata = action.payload.map((el) => { return { ...el, configType: 'hlr', id: Date.now() + Math.random() } })
  }
  return {
    ...state,
    Prefixes: {
      ...state.Prefixes,
      HLRData: hlrdata,
      meta: {
        isHLRLoading: false,
        isMNPLoading: false,
        isFailed: false,
        isSuccess: false,
        errorMessage: '',
      },
    },
  }
}
function fetchMNPConfigSuccess(state, action) {
  let Mnpdata = []
  if (action.payload) {
    Mnpdata = action.payload.map((el) => { return { ...el, configType: 'mnp', id: Date.now() + Math.random() } })
  }
  return {
    ...state,
    Prefixes: {
      ...state.Prefixes,
      MNPData: Mnpdata,
      meta: {
        isMNPLoading: false,
        isHLRLoading: false,
        isFailed: false,
        isSuccess: false,
        errorMessage: '',
      },
    },
  }
}
function fetchAllConfigFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchHLRConfigFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchMNPConfigFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchAllCountries(state, action) {
  return {
    ...state,
  }
}
function fetchAllCountriesSuccess(state, action) {
  return {
    ...state,
    Prefixes: {
      CountriesData: action.payload,
      meta: {
        isLoading: false,
        isMNPLoading: false,
        isHLRLoading: false,
        isFailed: false,
        isSuccess: false,
        errorMessage: '',
      },
    },
  }
}
function fetchAllCountriesFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchNetworks(state, action) {
  return {
    ...state,
  }
}
function fetchNetworksSuccess(state, action) {
  return {
    ...state,
    Prefixes: {
      NetworkData: action.payload,
      meta: {
        isLoading: false,
        isMNPLoading: false,
        isHLRLoading: false,
        isFailed: false,
        isSuccess: false,
        errorMessage: '',
      },
    },
  }
}
function fetchNetworksFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function updatePrefix(state, action) {
  return {
    ...state,
  }
}
function updatePrefixSuccess(state, action) {
  return {
    ...state,
  }
}
function updatePrefixFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function addDestnation(state, action) {
  return {
    ...state,
  }
}
function addDestnationSuccess(state, action) {
  return {
    ...state,
    defaultproviders: action.payload,
  }
}
function addDestnationFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function deletePrefix(state, action) {
  return {
    ...state,
  }
}
function deletePrefixSuccess(state, action) {
  let message = []
  message = action.payload.map((el) => { return { ...el, message: 'it is deleted' } })
  return {
    ...state,
    messagedeleted: message,
  }
}
function deletePrefixFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
export default prefixReducer
