import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function refRule(payload) {
  const { rec, diffMins } = payload
  const form = new FormData()
  form.append('allowed', 'true')
  form.append('manageOldValues', 'true')
  form.append('range_time', diffMins)
  return new Promise((resolve, reject) => {
    request
      .patch(`${API_URL}/api/v1/flooding-rules/${rec.ruleName}`)
      .set('Authorization', `bearer ${getToken()}`)
      .set('Access-Control-Allow-Origin', '*')
      .send(form)
      .then((resp) => {
      })
      .catch((err) => {
        reject(err)
      })
  })
}
