import styled from 'styled-components'

/**
 *
 * @param {object} props
 *
 *
 * @example
 * <Title />
 * <Title />
 */

export const TagResult = styled.div`
display:block;
float: ${({ theme }) => theme.dirFloat};
margin: 5px 0;
margin-${({ theme }) => theme.dirFloatRev}: 5px;
padding:0 5px;
color:${({ theme }) => theme.brandBg};
font-size: 12px;
line-height: 25px;
height: 25px;
margin-top:10px;
margin-bottom: ${({ marginBottom }) => marginBottom || '-5px'};

  b{
    font-weight:700;
    color: ${({ theme }) => theme.textDark};
    margin-${({ theme }) => theme.dirFloatRev}: 5px;
    line-height:25px;
  }
  span{
    color: ${({ theme }) => theme.textNormal};
    font-weight:300;
    line-height:25px;
  }
  a{
    cursor: pointer;
    font-weight: bold !important;
    font-size: 12px;
    line-height: normal;
    height: auto;
    float: ${({ theme }) => theme.dirFloat};
    padding: 0;
    margin-right: 5px;
    padding: 0px 3px;
    border-radius: ${({ theme }) => theme.roundCorner};
    font-weight: bold;
    color: ${({ theme }) => theme.btnLink};
    margin-top: 5px;
    &:hover {
      background:${({ theme }) => theme.btnAltHover};
    }
  }
`
