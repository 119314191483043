import legacyTheme from '~plumbing/styles/themes'

import kfmc from './KFMC'
import cequensRed from './cequensRed'
import telecomEgypt from './telecomEgypt'
import cequensScarlet from './cequensScarlet'


const currentTheme = getCurrentTheme()


export default {
  ...legacyTheme(currentTheme),
  ...currentTheme,
}


export {
  kfmc,
  cequensRed,
  telecomEgypt,
}


function getCurrentTheme() {
  const themesList = {
    KFMC: kfmc,
    cequensRed,
    telecomEgypt,
    cequensScarlet,
  }

  const selectedTheme = process.env.REACT_APP__THEME
    || 'cequensRed'

  const __currentTheme = themesList?.[selectedTheme]
    || cequensRed

  if (process.env.REACT_APP__DEV) {
    window.__cqs__download_legacy_LESS_File = getDownloadLegacyLess(
      selectedTheme,
      __currentTheme
    )
  }


  return __currentTheme
}


function getDownloadLegacyLess(name, theme) {
  return () => {
    if (process.env.REACT_APP__DEV) {
      import('./legacyLessVars')
        .then(({ default: getLegacyTheme }) => {
          const fileName = `variables.${name}.less`
          const fileData = getLegacyTheme(theme)

          const element = document.createElement('a')
          element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(fileData)}`)
          element.setAttribute('download', fileName)

          element.style.display = 'none'
          document.body.appendChild(element)

          element.click()

          document.body.removeChild(element)
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }
}
