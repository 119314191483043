import ReactDOM from 'react-dom'
import Joi from 'joi-full'
import { connect } from 'react-redux'
import {
  flow as _flow,
  last as _last,
  curry as _curry,
  split as _split,
  round as _round,
  isNaN as _isNaN,
  toLower as _toLower,
} from 'lodash'

// import { countryCodes } from './countryCodes'
// import { statusLookups } from './statusLookups'


import inCase from './switchCase'

// import computeSMSLength from './computeSMSLength'

export {
  inCase,
}

export { default as textDirection } from './textDirection'

export { reactIntlWrapper as reactIntl } from './i18n'

// export { resolveStringPattern } from './templateStrings'
// export { default as computeSMSLength } from './computeSMSLength'

export { default as b64 } from './base64'

export function range(start, end) {
  const result = []
  for (let i = start; i < end; i += 1) {
    result.push(i)
  }
  return result
}


export function addClassNamesToParentNode(elm, parentTraversalCount, classNames) {
  if (elm && Array.isArray(classNames)) {
    const parents = range(0, parentTraversalCount).reduce((accm) => {
      const ndx = accm.length - 1
      const node = accm[ndx]
      if (node) accm.push(node.parentNode)
      return accm
    }, [elm])

    const lastParents = parents[parents.length - 1]
    lastParents.classList.add(...classNames)
  }
}


export function setTabIndexForReactNode(elem, tabIndex, { tagName, className }) {
  const node = elem && ReactDOM // eslint-disable-line
    .findDOMNode(elem)

  if (node) {
    if (tagName) {
      node.getElementsByTagName(tagName)[0]
        .setAttribute('tabindex', tabIndex)
    } else if (className) {
      node.getElementsByClassName(className)[0]
        .setAttribute('tabindex', tabIndex)
    } else {
      node.setAttribute('tabindex', tabIndex)
    }
  }
}


export function joiIgnore(error, errorTypes) {
  if (error) {
    const err = { ...error }

    err.details = (err.details || [])
      .reduce((a, i) => {
        const n = !!(errorTypes || [])
          .find((j) => j === i.type)

        if (n) { return a }
        return [...a, i]
      }, [])

    const errEmpty = !err.details.length

    if (errEmpty) { return null }
    return err
  }

  return error
}


/**
 * extract blob-uuid from File Object
 *
 * @param {File} fileBlob
 *
 * @returns string
 */
export function getFileBlobUUID(fileBlob) {
  return _flow(
    _curry(_split, 2)(_curry.placeholder, '/'),
    _last,
  )(fileBlob.preview)
}


/**
 * make observable from object properties
 * expects all properties to be `Promise` or `thenable`.
 *
 * @param {object} obj - object to wrap
 * @param {object} rx - reference to RxJS to use
 *
 * @returns {object} obj with properties wrapped `fromPromise`
 */
export function observableFromPromiseProperties(obj, rx) {
  return Object
    .entries(obj) // eslint-disable-line
    .reduce((a, [ky, val]) => {
      a[ky] = (...args) => rx.Observable.fromPromise(val(...args)) // eslint-disable-line
      return a
    }, {})
}


export function getSafeFuncCall(fnc) {
  function runSafe(...args) {
    try {
      return fnc(...args)
    } catch (e) {
      const errStack = e.stack.split(/\n/g)
        .reduce((a, r) => {
          if (a.canPush) {
            a.stack.push(r)
          }

          if (a.stack.length) {
            const [, fName] = a?.stack?.[0]?.match(/^\s+at Object\.(.+?)\s/) || [null, null]
            a.fName = fName // eslint-disable-line
          }

          if (r.match(new RegExp(`^\\s+at ${runSafe.name || 'runSafe'}\\s`))) {
            a.canPush = true // eslint-disable-line
          }

          return a
        }, { stack: [], canPush: false, fName: null })

      e.stack = errStack.stack
      e.message = `[getSafeFuncCall] ${errStack.fName} is ${typeof fnc}`

      console.warn(e)
    }

    return undefined
  }

  runSafe.withArgs = runSafe

  return runSafe
}


export function renameFunc(func, name) {
  /* eslint-disable no-param-reassign */
  Object.defineProperty(func, 'name', { writable: true })
  func.name = name
  Object.defineProperty(func, 'name', { writable: false })

  func.displayName = name

  /* eslint-enable no-param-reassign */

  return func
}

export function redact(str, persentage = 95) {
  if (!str) { return str }

  const _str = `${str}`
  const _len = _str.length
  const keep = (_len - ((_len / 100) * persentage)) / 2
  const p1 = _str.substring(0, keep)
  const p2 = _str.substring(_len - keep, _len)

  return `${p1}*********${p2}`
}

/**
 *
 * @param Component
 * @returns {{ withMapStateToProps: Function, withMapDispatchToProps: Function }}
 */
export function withRedux(Component) {
  function _connect_(mapStateToProps, mapDispatchToProps) {
    return connect(mapStateToProps, mapDispatchToProps)(Component)
  }

  _connect_.withMapStateToProps = (mapStateToProps) => {
    return _connect_(mapStateToProps)
  }

  _connect_.withMapDispatchToProps = (mapDispatchToProps) => {
    return _connect_(undefined, mapDispatchToProps)
  }

  return _connect_
}

export function formatFileSize(size, options = {}) {
  /* eslint-disable no-param-reassign */
  const { useIEC } = options

  let {
    point,
    minValue,
  } = options || {}

  point = point || 3
  minValue = minValue || 1

  const k = useIEC
    ? 1024
    : 1000

  const kb = k
  const mb = kb * k
  const gb = mb * k
  const tb = gb * k
  const pb = tb * k

  const sizeChart = [
    {
      value: kb,
      unit: useIEC
        ? 'KiB'
        : 'kB',
    },
    {
      value: mb,
      unit: useIEC
        ? 'MiB'
        : 'MB',
    },
    {
      value: gb,
      unit: useIEC
        ? 'GiB'
        : 'GB',
    },
    {
      value: tb,
      unit: useIEC
        ? 'TiB'
        : 'TB',
    },
    {
      value: pb,
      unit: useIEC
        ? 'PiB'
        : 'PB',
    },
  ]

  return sizeChart
    .reduce((accum, { unit, value }) => {
      const sizeValue = size / value
      const isUnit = sizeValue > minValue
      if (isUnit) {
        return formatFileSize.mkSizeObj(sizeValue, unit, point, { useIEC })
      }

      return accum
    }, formatFileSize.mkSizeObj(size, 'Byte'))
  /* eslint-enable no-param-reassign */
}

formatFileSize.unitWight = inCase
  .curry('unitWight')
  .condition((u) => _toLower(u))
  .otherwise(0)
  .cases({
    kb: 1,
    kib: 1,
    mb: 2,
    mib: 2,
    gb: 3,
    gib: 3,
    tb: 4,
    tib: 4,
    pb: 5,
    pib: 5,
  })

formatFileSize.mkSizeObj = (val = 0, unit = 'byte', fixTo = 3, options = {}) => {
  const sizeVal = parseFloat(val.toFixed(fixTo))
  const { unitWight } = formatFileSize

  return {
    unit,
    size: sizeVal,
    isIEC: options.useIEC || options.isIEC,
    toString: () => `${sizeVal} ${unit}`,
    isLarger: (max, _unit) => {
      const fileUnit = unitWight(unit)
      const compUnit = unitWight(_unit)

      const isUnitLarger = compUnit < fileUnit
      const isUnitSmaller = compUnit > fileUnit

      if (isUnitLarger) {
        return true
      }

      if (isUnitSmaller) {
        return false
      }

      return sizeVal > max
    },
  }
}


export function computeSMSMeta({ text, isCTA }) {
  const messageMeta = {}

  // replace `{{` => `@`
  const re = new RegExp('{{', 'g')
  let messageText = text?.replace(re, '@')

  // remove `}}`
  const re1 = new RegExp('}}', 'g')
  messageText = text?.replace(re1, '')

  const baseCount = isCTA
    ? 18
    : 0

  const sms = messageText
  messageMeta.charCount = sms.length
  messageMeta.smsParts = sms.parts
  messageMeta.messageText = text
  // message.isUnicode = sms.isUnicode

  return messageMeta
}

export const urlSchema = Joi
  .string()
  .regex(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)

export const webhookSchema = Joi
  .string()
  .allow('')
  .regex(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|ftp:\/\/)+[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)


export function getQueryParamsInURL(url) {
  // parse query string
  const querystring = url.search
  const params = new URLSearchParams(querystring)

  const obj = {}

  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key)
    } else {
      obj[key] = params.get(key)
    }
  }

  return obj
}

export function getHashParamInURL(param) {
  const queryString = window.location.hash

  const urlParams = new URLSearchParams(queryString)

  const paramValue = urlParams.get(param)

  return paramValue
}


export function popupCenter({
  url, title, w, h,
}) {
  // eslint-disable-next-line no-restricted-globals
  const left = (screen.width / 2) - (w / 2)
  // eslint-disable-next-line no-restricted-globals
  const top = (screen.height / 2) - (h / 2)
  const popupWindow = window.open(url, title, `toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, width=${w}, height=${h}, top=${top}, left=${left}`)
  return popupWindow
}

export function abbrNumber(number, NaNValue) {
  const SI_SYMBOL = ['', 'k', 'M', 'B', 'T']

  // eslint-disable-next-line no-bitwise
  let tier = (Math.log10(number) / 3) | 0
  const maxTier = SI_SYMBOL.length - 1


  if (tier === 0) {
    const results = _round(number, 2)

    if (_isNaN(results) && NaNValue) {
      return NaNValue
    }

    return results
  }

  if (tier > maxTier) {
    tier = maxTier
  }

  const suffix = SI_SYMBOL[tier]
  const scale = Math.pow(10, tier * 3)

  const scaled = number / scale

  const results = _round(scaled, 1) + suffix

  if (_isNaN(results) && NaNValue) {
    return NaNValue
  }

  return results
}
