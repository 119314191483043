import styled from 'styled-components'

/**
 *
 * @param {object} props
 *
 *
 * @example
 * <Title />
 * <Title />
 */

export const Title = styled.h2`
margin-top: -57px;
margin-bottom:20px;
width:100%;
`
