/* eslint-disable no-shadow, import/no-cycle */
import Rx from 'rxjs' // eslint-disable-line
import request from 'superagent'
import queryString from 'query-string'
import { get as _get } from 'lodash'

import bugsnag from '~CON/bugsnag'
import jwtDecode from '~plumbing/utils/jwtDecoder' // eslint-disable-line
import { conKVS } from '~utils/kvStore'
import { getState } from '~plumbing/store'
import { getUserJWTObject, getUserRefreshToken, getUserInfo } from '~CON/User/userHelper'
import { getToken, API_URL, API_HOST } from './apiUtils'


export {
  authTokenVerify,
  fetchUserInfo,
  login,
  logout,
  saveLoginSession,
  getLoginSessions,
  refreshUserTokenApi,
  requestForgetPassword,
  resetPassword,
  changePassword,
  convertCurrency,
  recordPaymentHistory,
  payPalRequest,
  payPalCreateOrder,
  verifyUserMobileNumber,
  confirmMobileVerificationCode,
  resendVerificationEmail,
  requestSender,
  fetchSubAccounts,
  replaceDuplicateEmail,
  fetchAccountServices,
  fetchUserName,
  getFederationToken,
}

/*
 * authUser
 *    fetch all campaigns for authnticated and authorized user
 *
 * args:
 *  queries(object) => { page, limit, sort, search, ...others }
 *
 * returns:
 *  Rx.Observable(superagent(Promise))
 */
function authTokenVerify(token) {
  return Rx
    .Observable
    .fromPromise(
      request
        .get(`${API_URL}/account`)
        .set('x-user-auth', `bearer ${token}`)
        .retry(3)
    )
}

function fetchUserInfo(userId) {
  const apiPromise = new Promise((resolve, reject) => { // eslint-disable-line
    request
      .get(`${API_URL}/api/v1/auth/me`)
      .set('Authorization', `bearer ${getToken()}`)
      .retry(3)
      .then((data) => resolve(data))
      .catch((err) => reject(err))
  })
  return Rx.Observable.fromPromise(apiPromise)
}

function fetchAccountServices() {
  const apiPromise = new Promise((resolve, reject) => { // eslint-disable-line
    request
      .get(`${API_URL}/account/accountservices`)
      .set('x-user-auth', `bearer ${getToken()}`)
      .retry(3)
      .then((resp) => {
        // debugger // eslint-disable-line
        const data = {
          accountServices: resp.body,
        }

        // data.accountServices = resp.body?.[1]?.body
        resolve(data)
      })
      .catch((err) => reject(err))
  })
  return Rx.Observable.fromPromise(apiPromise)
}


function login(userCredentials) {
  const loginPromise = new Promise((resolve, reject) => { // eslint-disable-line
    const payload = {
      username: userCredentials.username,
      password: userCredentials.password,
    }

    let retry = 0

    const fetch = () => getApiToken()
      .catch((e) => {
        bugsnag.notify(e)

        if (retry) {
          retry -= 1
          return getApiToken().catch((error) => {
            bugsnag.notify(e)
            const errorCode = _get(error, 'response.body.code', '2001')
            reject(errorCode)
          })
        }

        const errorCode = _get(e, 'response.body.code', 2001)
        reject(errorCode)
        return null
      })

    // initial request
    fetch()

    function getApiToken() {
      return request
        .post(`${API_HOST}/api/v1/auth/login`)
        .set('Content-Type', 'application/json')
        .send(payload)
        .then((resp) => {
          const { idToken, refreshToken } = resp.body

          resolve(resp.body)
        })
        .catch((err) => {
          console.error('Login', err)

          bugsnag.notify(err)
          const errorCode = _get(err, 'response.body.code', '2001')
          reject(errorCode)
        })
    }
  })

  return Rx.Observable.fromPromise(loginPromise)
}

function requestForgetPassword(userInfo) {
  function getApiToken() {
    return new Promise((resolve, reject) => { // eslint-disable-line
      request
        .post(`${API_URL}/usrmngmnt/requestforgotpassword`)
        .type('application/json')
        .send({
          username: userInfo.username,
          callBackUrl: userInfo.callbackUrl,
        })
        .then(resolve)
        .catch((error) => {
          if (error.response.body.code === 1011) {
            resolve()
          }
          const errorCode = _get(error, 'response.body.code', 2001)
          reject(errorCode)
        })
    })
  }

  return Rx.Observable.fromPromise(getApiToken())
}

function refreshUserTokenApi(_refreshToken) {
  function requestRefreshApi() {
    const refreshToken = _refreshToken || getUserRefreshToken()
    const payload = {
      refreshToken,
    }
    return new Promise((resolve, reject) => { // eslint-disable-line
      request
        .post(`${API_HOST}/usrmngmnt/refreshusertokens`)
        .set('Content-Type', 'application/json')
        .send(payload)
        .then((resp) => {
          const { idToken } = resp.body
          resolve({
            idToken,
          })
        })
        .catch((error) => {
          const errorCode = _get(error, 'response.body.code', 2001)
          reject(errorCode)
        })
    })
  }

  return Rx.Observable.fromPromise(requestRefreshApi())
}

function resetPassword(userInfo) {
  const getURLParams = () => {
    const { search } = window.location
    const searchParams = queryString.parse(search) // eslint-disable-line

    return searchParams
  }

  const params = getURLParams()

  const decodedUserName = (params.u.length > 0) ? atob(params.u) : null

  const userName = decodedUserName ? JSON.parse(decodedUserName) : null

  const payload = {
    userName: userName.u,
    newPassword: userInfo.password,
    confirmationCode: params.v,
  }

  function getApiToken(payload) {
    return new Promise((resolve, reject) => { // eslint-disable-line
      request
        .put(`${API_URL}/usrmngmnt/forgotpassword`)
        .set('Content-Type', 'application/json')
        .send(payload)
        .then(resolve)
        .catch((error) => {
          const errorCode = _get(error, 'response.body.code', 2001)
          reject(errorCode)
        })
    })
  }

  return Rx.Observable.fromPromise(getApiToken(payload))
}

function changePassword(userInfo) {
  const payload = {
    oldPassword: userInfo.currentPassword,
    newPassword: userInfo.password,
  }

  function getApiToken(payload) {
    return new Promise((resolve, reject) => { // eslint-disable-line

      request
        .put(`${API_URL}/usrmngmnt/changepassword`)
        .set('x-user-auth', `bearer ${getToken()}`)
        .send(payload)
        .then(resolve)
        .catch((error) => {
          const errorCode = _get(error, 'response.body.code', 2001)
          reject(errorCode)
        })
    })
  }

  return Rx.Observable.fromPromise(getApiToken(payload))
}

function logout() {
  function getApiToken(userIdToken) {
    return new Promise((resolve, reject) => { // eslint-disable-line

      request
        .get(`${API_URL}/usrmngmnt/logout`)
        .set('x-user-auth', `bearer ${userIdToken}`)
        .send(userIdToken)
        .then(resolve)
        .catch((error) => {
          const errorCode = _get(error, 'response.body.code', 2001)
          reject(errorCode)
        })
    })
  }

  return Rx.Observable.fromPromise(getApiToken(getToken()))
}

function convertCurrency({ fromCurrency, toCurrency }) {
  function getValue() {
    const apiKey = '6aeb6332c7e678427296f1e96aefd17e'

    const url = `http://data.fixer.io/api/latest?access_key=${apiKey}`

    return new Promise((resolve, reject) => { // eslint-disable-line
      request
        .get(url)
        .then(resolve)
        .catch((resp) => {
          console.error('[userApi] convertCurrency/Error:', resp)
          const errorMessage = _get(resp, 'err.message', 'Error')
          reject(errorMessage)
        })
    })
  }

  return Rx.Observable.fromPromise(getValue())
}

function recordPaymentHistory(payload) {
  function getValue() {
    return new Promise((resolve, reject) => { // eslint-disable-line
      request
        .post(`${API_URL}/account/submitpayment`) // process.env.REACT_APP__PayPal
        .set('x-user-auth', `bearer ${getToken()}`)
        .send(payload)
        .then(resolve)
        .catch((resp) => {
          console.error('[userApi] convertCurrency/Error:', resp)
          reject(resp)
          // return _get(resp, 'err.message', 'Error')
        })
    })
  }

  return Rx.Observable.fromPromise(getValue())
}

function recordPaypalOrderCreation(payload) {
  function getValue() {
    return new Promise((resolve, reject) => { // eslint-disable-line
      request
        .post(`${API_URL}/account/ConfirmPayment`) // process.env.REACT_APP__PayPal
        .set('x-user-auth', `bearer ${getToken()}`)
        .send(payload)
        .then(resolve)
        .catch((resp) => {
          console.error('[userApi] convertCurrency/Error:', resp)
          reject(resp)
          // return _get(resp, 'err.message', 'Error')
        })
    })
  }

  return Rx.Observable.fromPromise(getValue())
}

function payPalRequest(payload) {
  function getValue() {
    return new Promise((resolve, reject) => { // eslint-disable-line
      request
        .post(`https://www.paypal.com/cgi-bin/webscr?cmd=_complete-express-checkout&token=${getToken()}`)
        .send(payload)
        .then(resolve)
        .catch((resp) => {
          console.error('[userApi] payPalRequest/Error:', resp)
          reject(resp)
        })
    })
  }

  return Rx.Observable.fromPromise(getValue())
}

function payPalCreateOrder(payload) {
    return new Promise((resolve, reject) => { // eslint-disable-line
    request
      .post(`${API_URL}/account/submitpayment`) // process.env.REACT_APP__PayPal
      .set('x-user-auth', `bearer ${getToken()}`)
      .send(payload)
      .then((resp) => {
        resolve(resp.body)
      })
      .catch((resp) => {
        console.error('[userApi] payPalCreateOrder/Error:', resp)
        reject(resp)
        // return _get(resp, 'err.message', 'Error')
      })
  })
}

function verifyUserMobileNumber(user) {
  // debugger // eslint-disable-line
  const apiPromise = new Promise((resolve, reject) => { // eslint-disable-line
    request
      .post(`${API_URL}/usrmngmnt/${user.userId || user}/mobile-verify`)
      .set('x-user-auth', `bearer ${getToken() || user.idToken}`)
      .type('application/json')
      .send(user.userId || user)
      .then(resolve)
      .catch((error) => {
        const errorCode = _get(error, 'response.body.code', 2001)
        reject(errorCode)
      })
  })
  return Rx.Observable.fromPromise(apiPromise)
}

function confirmMobileVerificationCode(payload) {
  const apiPromise = new Promise((resolve, reject) => { // eslint-disable-line
    request
      .put(`${API_URL}/usrmngmnt/${payload.userId}/mobile-verify`)
      .set('x-user-auth', `bearer ${getToken() || payload.idToken}`)
      .type('application/json')
      .send({
        txId: payload.txId,
        code: payload.code,
      })
      .then(resolve)
      .catch((err) => {
        const errorCode = _get(err, 'response.body.code', 2001)
        reject(errorCode)
      })
  })
  return Rx.Observable.fromPromise(apiPromise)
}

function resendVerificationEmail(payload) {
  const apiPromise = new Promise((resolve, reject) => {
    request
      .post(`${API_URL}/usrmngmnt/verify-resend`)
      .send(payload)
      .then(({ body }) => {
        resolve(body.email)
      })
      .catch((err) => {
        const errorCode = _get(err, 'response.body.code', 2001)
        reject(errorCode)
      })
  })
  return Rx.Observable.fromPromise(apiPromise)
}

function requestSender(payload) { // eslint-disable-line
  const { User: { current } } = getState()
  const apiPromise = new Promise((resolve, reject) => {
    request
      .post(`${API_URL}/assets/senders`)
      .set('Content-Type', 'application/json')
      .set('x-user-auth', `bearer ${getToken()}`)
      .send({
        sender: payload.senderName,
        accountName: payload.assignToAccount,
        serviceCoverage: payload.coverage,
        sampleSms: payload.SMSSample,
        orgWebsite: payload.orgWebsite,
        account: current?.info?.accountName,
        files: payload.files?.map(({ conUrl, label }) => ({ label, url: conUrl })),
      })
      .then(resolve)
      .catch((err) => {
        reject(err)
      })
  })
  return Rx.Observable.fromPromise(apiPromise)
}

function fetchSubAccounts() {
  const apiPromise = new Promise((resolve, reject) => {
    // todo: get this from store and keep in state
    const userObj = getUserJWTObject()

    const isMasterAccount = userObj?.aclRole === 'admin'
      || userObj?.['cognito:groups'][0] === 'admin'

    if (!isMasterAccount) {
      reject(new Error('Unaithridez: master account only'))
    }

    request
      .get(`${API_URL}/account/getsubaccounts`)
      .set('Content-Type', 'application/json')
      .set('x-user-auth', `bearer ${getToken()}`)
      .then(resolve)
      .catch(reject)
  })


  return Rx.Observable.fromPromise(apiPromise)
}

function replaceDuplicateEmail(payload) {
  // debugger // eslint-disable-line
  const reshapedPayload = {
    email: payload.userName,
    newemail: payload.newEmail,
    password: payload.password,
  }
  const apiPromise = new Promise((resolve, reject) => {
    request
      .post(`${API_URL}/usrmngmnt/changeduplicateemail`)
      .set('Content-Type', 'application/json')
      .send(reshapedPayload)
      .then((resp) => resolve(resp.body))
      .catch((error) => {
        const errorCode = _get(error, 'response.body.code', 2001)
        reject(errorCode)
      })
  })
  return Rx.Observable.fromPromise(apiPromise)
}

function fetchUserName(userId) {
  const apiPromise = new Promise((resolve, reject) => {
    request
      .get(`${API_URL}/usrmngmnt/${userId}`)
      .set('Content-Type', 'application/json')
      .set('x-user-auth', `bearer ${getToken()}`)
      .then((resp) => resolve(resp.body))
      .catch((error) => {
        reject(error)
      })
  })
  return Rx.Observable.fromPromise(apiPromise)
}


function getFederationToken(code) {
  const apiPromise = new Promise((resolve, reject) => {
    request
      .get(`${API_URL}/account/federationlogincallback`)
      .set('Content-Type', 'application/json')
      .set('x-user-auth', `bearer ${getToken()}`)
      .query({
        code,
      })
      .then((resp) => resolve(resp.body))
      .catch((error) => {
        reject(error)
      })
  })
  return Rx.Observable.fromPromise(apiPromise)
}


function saveLoginSession(token) {
  const apiPromise = new Promise((resolve, reject) => {
    request
      .post(`${API_URL}/sessions`)
      .set('Content-Type', 'application/json')
      .set('x-user-auth', `bearer ${token || getToken()}`)
      .send({
        country: 'EG', // temporary
      })
      .then((resp) => resolve(resp.body))
      .catch((error) => {
        const errorCode = _get(error, 'response.body.code', 2001)
        reject(errorCode)
      })
  })
  return Rx.Observable.fromPromise(apiPromise)
}

function getLoginSessions(payload) {
  const { User: { current } } = getState()
  const usrId = current?.info?.userId || current?.token?.userId
  const accId = current?.info?.accountId
  // debugger // eslint-disable-line
  const apiPromise = new Promise((resolve, reject) => {
    request
      .get(`${API_URL}/sessions/${payload?.accountId || accId}/${payload?.user || usrId}`)
      .set('Content-Type', 'application/json')
      .set('x-user-auth', `bearer ${getToken()}`)
      .query({
        to: payload?.dateTo,
        from: payload?.dateFrom,
        limit: payload?.pageSize,
        // eslint-disable-next-line radix
        offset: ((payload?.pageIndex - 1) * (payload?.pageSize)) || undefined,
      })
      .then((resp) => resolve(resp.body))
      .catch((error) => {
        const errorCode = _get(error, 'response.body.code', 2001)
        reject(errorCode)
      })
  })
  return Rx.Observable.fromPromise(apiPromise)
}
