/* global FS */
import Rx from 'rxjs' // eslint-disable-line

import { observableFromPromiseProperties } from 'CQS/plumbing/utils'
import { actions as appActions } from '~CON/App/appActions'

import { actionsTypes, actions as profileActions } from './profileActions'
import apis from '../apis'

const profileApis = observableFromPromiseProperties(apis, Rx)

export default [changePassword]

export function changePassword(action$, { getState }) {
  return action$
    .ofType(actionsTypes.CHANGE_PASSWORD)
    .switchMap(({ payload }) => {
      return profileApis
        .updatepassword(payload)
        .map((res) => {
          return profileActions.changePasswordSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.CHANGE_PASSWORD_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
