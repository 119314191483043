import request from 'superagent'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function defaultConfig(payload, meta) {
  if (!payload) return Promise.resolve()
  return new Promise((resolve, reject) => {
    request
      .get(`${API_URL}/api/v1/customers/prefixes/${meta.toLowerCase()}/${payload.toLowerCase()}/default`)
      .set('Authorization', `bearer ${getToken()}`)
      .then((resp) => {
        const newResp = resp?.body?.value
        resolve(newResp)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
