/* global location */
/* eslint no-restricted-globals: off */
import React from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import { toString as _toString } from 'lodash'
import { IntlProvider, addLocaleData } from 'react-intl'
import {
  Button,
  LocaleProvider,
} from 'antd'

import locales from '~plumbing/i18n'
import bugsnag from '~CON/bugsnag' // eslint-disable-line
import { QCToolbar } from '~plumbing/components/QCToolbar/QCToolbar'
import { QCTBActions } from '~plumbing/components/QCToolbar/QCTBActions'
import { getUserLocale } from '~plumbing/utils/i18n'

const userLocale = getUserLocale()
const localeNotDefault = userLocale !== locales.default.code
const localeExists = userLocale in locales


addLocaleData(locales.default.locale)

export default class IntlWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  }

  constructor(props) {
    super(props)

    if (localeNotDefault && localeExists) {
      this.state = {
        fetchUserLocale: userLocale,
      }

      // async load localization
      locales[userLocale]()
        .then(({ default: localImport }) => {
          const {
            locale,
            messages,
            code,
            antd,
          } = localImport

          addLocaleData(locale)

          this.setState({
            antd,
            messages,
            local: locales[code],
            localCode: code,
            userLocal: code,
          })
        })
        .catch((error) => bugsnag
          .notify(
            new Error('I18N Locale Loading Failed'),
            { error, metaData: { userLocale } }
          ))
    } else {
      this.state = {
        fetchUserLocale: userLocale,
      }
    }

    this.state = {
      ...this.state,
      local: locales.default,
      antd: locales.default.antd,
      localCode: locales.default.code,
      messages: locales.default.messages,
    }
  }

  componentDidMount() {
    this._qcToolbarAdd()
  }

  componentWillUnmount() {
    this._qcToolbarRemove()
  }

  _qcToolbarAdd() {
    const toggleRTL = () => {
      const bodyTag = document.getElementsByTagName('body')[0]
      if (bodyTag.classList.contains('rtl')) {
        bodyTag.classList.add('ltr')
        bodyTag.classList.remove('rtl')
        bodyTag.setAttribute('dir', 'ltr')
      } else {
        bodyTag.classList.add('rtl')
        bodyTag.classList.remove('ltr')
        bodyTag.setAttribute('dir', 'rtl')
      }
    }

    const localSwitch = (localeId) => {
      return () => {
        Cookies.set('userLocale', localeId)
        location.reload(true)
      }
    }

    QCTBActions.qcToolbarAddController(
      'intl:toolbar',
      (
        <QCToolbar item={{ label: 'Intl' }}>
          <Button size="small" onClick={toggleRTL}>toggle RTL</Button>
          <Button size="small" onClick={localSwitch('ar')}>Arabic</Button>
          <Button size="small" onClick={localSwitch('en')}>English</Button>
        </QCToolbar>
      ),
    )
  }

  _qcToolbarRemove() {
    QCTBActions.qcToolbarRemoveController('intl:toolbar')
  }

  _rtlBody() {
    const bodyTag = document.getElementsByTagName('body')[0]
    if (_toString(this.state.userLocal).match(/^ar/gi)) {
      bodyTag.classList.add('rtl')
      bodyTag.classList.remove('ltr')
      bodyTag.setAttribute('dir', 'rtl')
      document.documentElement.lang = 'ar'
    } else {
      bodyTag.classList.add('ltr')
      bodyTag.classList.remove('rtl')
      bodyTag.setAttribute('dir', 'ltr')
      document.documentElement.lang = 'en'
    }
  }

  render() {
    const { localCode, messages, antd } = this.state
    const { children } = this.props

    this._rtlBody()

    return (
      <LocaleProvider locale={antd}>
        <IntlProvider
          locale={localCode}
          messages={messages}
        >
          {children}
        </IntlProvider>
      </LocaleProvider>
    )
  }
}
