import getRules from './FetchRules'
import getDefRule from './FetchDefRule'
import deleteRule from './DeleteRule'
import updateDefRule from './UpdateDefRule'
import createRule from './AddRule'
import refRule from './RefreshRule'
import editRule from './EditRule'

export default {
  getRules,
  deleteRule,
  getDefRule,
  updateDefRule,
  createRule,
  refRule,
  editRule,
}
