/* eslint-disable no-confusing-arrow */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { BodyStyle } from '~plumbing/components'

const openBarControlWrapper = ({ open, width }) => (open ? '0px' : (`-${width || '25vw !important'}`))

const barPosStyle = ({ open, width, theme }) => css`
  ${theme.isRTL ? 'left' : 'right'}: ${openBarControlWrapper({ open, width })};
`

export const BarControlWrapper = styled.div`
  width: ${({ width }) => width || '25vw'};
  @media (max-width: 767px) {
    width: ${({ open }) => open && '80%'};
  }
  height: 100vh;
  position: fixed;
  top: 0;
  background: ${({ theme }) => theme.containerBg} ;
  z-index: ${({ clearHeader }) => (clearHeader ? 950 : 1_000)};
  box-shadow: ${({ theme }) => theme.isRTL ? 'rgba(0, 0, 0, 0.05) 0px -10px 20px 0px' : 'rgba(0, 0, 0, 0.05) -10px 0px 20px 0px'};
  //transition: all linear 0.3s;
  padding: 0px;
  overflow: hidden;
  max-height: 100vh;
  ${barPosStyle}

  & h2 {
    background: ${({ theme }) => theme.containerBg};
    margin: 0;
    float: left;
    width:100%;
    padding: 9px 20px;
    padding-bottom: 8px;
    box-shadow: 0px 0px 6px ${({ theme }) => theme.shadowNormal} ;
    border-bottom:1px solid ${({ theme }) => theme.shadowNormal} ;
    position: relative;
    z-index: 1;
    font-size:18px;
  }
`

const Backdrop = styled.div`
  background: ${({ blackout, theme }) => blackout ? theme.shadowPopBg : 'transparant'};
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: ${({ clearHeader }) => (clearHeader ? 900 : 999)};
  display: ${({ show }) => (show ? 'block' : 'none')};
`

export default function ControlBarContent(props) {
  const isOpen = props.render
    .reduce((accum, key) => (accum || props.config[key].open), false)

  const blackout = isOpen && props.conf.backdrop

  const showBackdrop = isOpen
    ? (props.conf.backdrop || props.conf.clickAway)
    : false

  const handleClose = blackout
    ? undefined
    : props.handleClose

  const bodyStyle = isOpen
    ? (
      <BodyStyle
        reset
        apply={isOpen}
        style={{ overflow: 'hidden' }}
        unmountStyle={{ overflow: 'visible' }}
      />
    ) : null

  return (
    <React.Fragment>

      {bodyStyle}

      <BarControlWrapper
        {...props.conf}
      >
        <div>
          {props.header}
          {
            props.render.map((key) => props.config[key].open
              ? props.config[key].children
              : null
            )
          }
          {props.footer}
        </div>
      </BarControlWrapper>

      <Backdrop
        {...props.conf}
        show={showBackdrop}
        blackout={blackout}
        clickAway={props.conf.clickAway}
        onClick={handleClose}
      />

    </React.Fragment>
  )
}

ControlBarContent.propTypes = {
  render: PropTypes.arrayOf(PropTypes.string).isRequired,
  config: PropTypes.object, //eslint-disable-line
  children: PropTypes.object, //eslint-disable-line
  conf: PropTypes.object, //eslint-disable-line
  header: PropTypes.object, // eslint-disable-line
  footer: PropTypes.object, // eslint-disable-line
  handleClose: PropTypes.func, // eslint-disable-line
}
