import debugToolsOps from 'CQS/plumbing/utils/debugToolsOps'

import configureStore from './configureStore'

export { default as queryClient } from './queryClient'

const store = configureStore()

export const {
  dispatch,
  getState,
} = store

export default store

window.__cqs_getStore = () => getState()

debugToolsOps({
  name: 'store getter',
  onConfirmed() {
    window.__cqs_getStore = () => getState()
  },
  onDisable() {
    delete window.__cqs_getStore
  },
})

debugToolsOps({
  name: 'store dispatcher',
  onConfirmed() {
    window.__cqs_dispatch = (...args) => dispatch(...args)
  },
  onDisable() {
    delete window.__cqs_dispatch
  },
})
