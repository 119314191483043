import { camelCase as _camelCase, get as _get } from 'lodash'
import { renameFunc } from 'CQS/plumbing/utils'

export const actionsTypes = {
  FETCH_ALL_PROVIDERS: 'discovery/Provider/Providers',
  FETCH_ALL_PROVIDERS_SUCCESS: 'discovery/Provider/ProvidersSucess',
  FETCH_ALL_PROVIDERS_FAILED: 'discovery/Provider/ProvidersFail',
  UPDATE_PROVIDER: 'discovery/Provider/UpdateProvider',
  UPDATE_PROVIDER_SUCCESS: 'discovery/Provider/UpdateProviderSuccess',
  UPDATE_PROVIDER_FAILED: 'discovery/Provider/UpdateProviderFail',
  EXPORT_PROVIDERS: 'discovery/Provider/ExportProviders',
  EXPORT_PROVIDERS_SUCCESS: 'discovery/Provider/ExportProvidersSuccess',
  EXPORT_PROVIDERS_FAILED: 'discovery/Provider/ExportProvidersFail',
}
// helper function automatically generate ACTION CREATORS!
export const actions = Object.keys(actionsTypes)
  .reduce((accum, id) => {
    const creatorName = _camelCase(id)

    const creatorFunction = function _(payload, meta) {
      return {
        type: _get(actionsTypes, id),
        payload,
        meta,
      }
    }

    // eslint-disable-next-line no-param-reassign
    accum[creatorName] = renameFunc(creatorFunction, creatorName)
    return accum
  }, {})
