// @flow
import React from 'react'

import { Icon } from '~plumbing/components/styled'
import { BtnTitle, BtnSubTitle, BaseButton } from './styled'
import ButtonSpinner from '~plumbing/elements/Button/ButtonSpinner'

type ButtonProps = {
  label: string,
  subLabel: string,
  leadingIcon: string,
  trailingIcon: string,
  loading: boolean,
  triggerRef: any,
  children: any,
}

export default class Button extends React.Component<ButtonProps> {
  constructor(props) {
    super(props)
    this.triggerRef = null
    this.state = {
    }
  }

  render() {
    const {
      label,
      subLabel,
      children,
      leadingIcon,
      trailingIcon,
      loading,
      ...passProps
    } = this.props

    const mainLabel = label
      ? (
        <BtnTitle>
          { label }
        </BtnTitle>
      )
      : children

    const secondaryLabel = subLabel
      ? (
        <BtnSubTitle>{subLabel}</BtnSubTitle>
      ) : null

    const IconBeforeText = leadingIcon ? <Icon beforeText name={leadingIcon} /> : null

    const IconAfterText = trailingIcon ? <Icon afterText name={trailingIcon} /> : null

    const loadingState = loading ? <i><ButtonSpinner size="xs" display="inline-block" spinBtn="10px" /></i> : null

    return (
      <BaseButton innerRef={this.props.triggerRef} {...passProps}>
        {IconBeforeText}
        {mainLabel}
        {IconAfterText}
        {loadingState}
        {secondaryLabel}
      </BaseButton>
    )
  }
}
