import React from 'react'
import styled from 'styled-components'
import { inCase } from 'CQS/plumbing/utils'

/**
 *
 * @param {object} props
 * @param {boolen} props.warn - warning highlight
 * @param {boolen} props.error - Error highlight
 *
 * @example
 * <Icon.Label name="" text="" iconLeft/>
 */

const IconWrapper = styled.span`
  display: ${({ hide }) => (hide ? 'none' : 'inline-block')};
  i, svg {
    color: ${({ type, theme }) => {
    return inCase
      .curry('indicatorType')
      .condition(type)
      .otherwise(theme.tableBodyLabel)
      .cases({
        error: `${theme.redColor} !important`,
        success: `${theme.greenColor} !important`,
        warning: `${theme.orangeColor} !important`,
        info: `${theme.neutralColor} !important`,
        brand: `${theme.brandMain} !important`,
      })
  }};
    font-size: 11px;
    margin-${({ marginDir, theme }) => ((marginDir === 'right') ? theme.dirFloatRev : theme.dirFloat)}: 10px;
    /* float:${({ theme }) => theme.dirFloat}; */
    margin-top: 6px;
    line-height: ${({ lineHeight }) => `${lineHeight}px`};
  }
  `

export const Icon = ({ name, ...props }) => ( // eslint-disable-line
  <IconWrapper {...props}>
    {
      (props.icon && props.isFAIcon)
        ? props.icon
        : (<i className={`icon-${name}`}/>)
    }
  </IconWrapper>
)

const TextWrapper = styled.span`
  font-size:12px;
  font-weight:${({ textType }) => (textType === 'bold' ? '700' : '500')};
  color: ${({ textColor, theme }) => {
    return inCase
      .curry('textColor')
      .condition(textColor)
      .otherwise(theme.tableBodyLabel)
      .cases({
        error: `${theme.redColor} !important`,
        success: `${theme.greenColor} !important`,
        warning: `${theme.orangeColor} !important`,
        info: `${theme.neutralColor} !important`,
        brand: `${theme.brandMain} !important`,
        white: '#fff !important',
      })
  }};
  line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}px` : '6px')};
  display: inline-block;
  a{
    white-space: nowrap;
    margin:0px 5px;
  }
  `

Icon.Label = ({ name, text, isFAIcon, textColor, icon, iconRight , iconLeft, lineHeight, type, title }) => ( // eslint-disable-line
  <TextWrapper textColor={textColor} title={title}>
    <Icon isFAIcon icon={icon} title={title} type={type} marginDir="right" hide={iconRight} name={name} lineHeight={lineHeight}/>
    <span title={title}>{text}</span>
    <Icon isFAIcon icon={icon} title={title} type={type} marginDir="left" hide={iconLeft} name={name} lineHeight={lineHeight}/>
  </TextWrapper>
)
