import {
  toggleOptionForUserLocaleDir as toggleDir,
} from '~plumbing/utils/i18n'


/**
 * switchDir
 *
 * switch values between RTL/LTR for current language
 *
 * @example
 * switchDir.ltr('right').rtl('left')
 *
 * @example
 * switchDir.LTR('right').RTL('left')
 *
 * @example
 * switchDir({ ltr: 'right', rtl: 'left' })
 */
const switchDir = toggleDir

switchDir.ltr = (ltrVal) => ({ rtl: (rtlVal) => switchDir({ rtl: rtlVal, ltr: ltrVal }) })

switchDir.rtl = (rtlVal) => ({ ltr: (ltrVal) => switchDir({ rtl: rtlVal, ltr: ltrVal }) })

switchDir.LTR = (ltrVal) => ({ RTL: (rtlVal) => switchDir({ rtl: rtlVal, ltr: ltrVal }) })

switchDir.RTL = (rtlVal) => ({ LTR: (ltrVal) => switchDir({ rtl: rtlVal, ltr: ltrVal }) })

export default switchDir
