/* global FS */
import Rx from 'rxjs' // eslint-disable-line

import { observableFromPromiseProperties } from 'CQS/plumbing/utils'
import { actions as appActions } from '~CON/App/appActions'

import { actionsTypes, actions as userActions } from './UserManagementActions'
import apis from '../apis'

const usersApis = observableFromPromiseProperties(apis, Rx)

export default [getUsersData, deleteUser, updateUser, addUser]

export function getUsersData(action$, { getState }) {
  return action$
    .ofType(actionsTypes.FETCH_ALL_USERS)
    .switchMap(({ payload }) => {
      return usersApis
        .usersData(payload)
        .map((res) => {
          return userActions.fetchAllUsersSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_ALL_USERS_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function deleteUser(action$, { getState }) {
  return action$
    .ofType(actionsTypes.DELETE_USER)
    .switchMap(({ payload }) => {
      return usersApis
        .deleteUser(payload)
        .map((res) => {
          return userActions.deleteUserSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.DELETE_USER_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function updateUser(action$, { getState }) {
  return action$
    .ofType(actionsTypes.UPDATE_USER)
    .switchMap(({ payload }) => {
      return usersApis
        .updateUser(payload)
        .map((res) => {
          return userActions.updateUserSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.UPDATE_USER_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function addUser(action$, { getState }) {
  return action$
    .ofType(actionsTypes.ADD_USER)
    .switchMap(({ payload }) => {
      return usersApis
        .addUser(payload)
        .map((res) => {
          return userActions.addUserSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.ADD_USER_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
