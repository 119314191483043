/* eslint-disable */
import React from 'react'
// import PropTypes from 'prop-types'
// import Draggable from 'react-draggable'
// import moment from 'moment'
import { QueryClientProvider } from 'react-query'
import { connect } from 'react-redux'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  Row,
  Col,
  Button,
  Input,
  Form,
  Tag, // eslint-disable-line
} from 'antd'

import bugsnag from '~CON/bugsnag'
import { userActions } from '~plumbing/api/actions' // eslint-disable-line
import {
  toggleOptionForUserLocaleDir as toggleForDir,
} from '~plumbing/utils/i18n'

class QCPanel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      foo: 'foobar',
      minimize: true,
      toolbarControllers: {},
      showReactQueryDevtools: false
    }
  }

  subscribeController = (ky, btn) => {
    this.setState((oldState) => {
      return {
        ...oldState,
        toolbarControllers: {
          ...oldState.toolbarControllers,
          [ky]: btn,
        },
      }
    })
  }

  unsubscribeController = (ky) => {
    this.setState((oldState) => {
      const newState = { ...oldState.toolbarControllers }
      delete newState[ky]

      return {
        ...oldState,
        toolbarControllers: {
          ...newState,
        },
      }
    })
  }

  defaultToolbars = () => {
    const deftb = [
      (
        <QCToolbar item={{ label: 'Auth' }}>
          <Input
            size="small"
            suffix="⏎"
            defaultValue="http://127.0.0.1:3000"
            style={{ width: '150px', top: '7px' }}
            onPressEnter={(e) => {
              window
                .location
                .replace(
                  `${e.currentTarget.value}?k=${this.props.store.User.current.tokenString}`
                )
            }}
          />
          <Input
            size="small"
            defaultValue={this.props.store.User.current.tokenString}
            style={{ width: '100px', top: '7px'  }}
          />
          <Button
            size="small"
            style={{ lineHeight: 1 }}
            onClick={() => this.props.dispatch(userActions.authUserTokenFailed('QCToolbar'))}
          >
            Invalidate<br/>Token
          </Button>
        </QCToolbar>
      ),(
        <QCToolbar item={{ label: 'Bugsnag' }}>
          <Input
            size="small"
            placeholder="Error Message"
            style={{ width: '100px', top: '7px'  }}
            onChange={(evt) => {
              this.bugsnagErrorMsg = evt.currentTarget.value
            }}
          />
          <Button
            size="small"
            style={{ lineHeight: 1 }}
            onClick={() => bugsnag.notify(this.bugsnagErrorMsg || 'QCToolbar Triggered Notify')}
          >
            Log To<br/>bugsnag
          </Button>
        </QCToolbar>
      ), (
        <QCToolbar item={{ label: 'ReactQuery' }}>
          <Button
            size="small"
            style={{ lineHeight: 1 }}
            onClick={() => {
              const key = 'reactQueryDevtoolsOpen'

              this.setState((s) => ({
                showReactQueryDevtools: !s.showReactQueryDevtools
              }), () => {
                // localStorage.setItem(key, this.state.showReactQueryDevtools)
              })
            }}
          >
            {this.state.showReactQueryDevtools ? 'Close' : 'Open'} ReactQuery DevTool
          </Button>
        </QCToolbar>
      )
    ]

    return deftb.reduce(
      (col, elm) => {
        return [...col, (<Row>{elm}</Row>)]
      },
      []
    )
  }

  render() {
    const tbStyles = {
      background: 'rgb(255, 231, 93)',
      top: '6vh',
      boxShadow: '0 1px 6px rgba(0,0,0,.2)',
      color: '#000',
      minHeight: '50px',
      left: 'calc(50% - 50px)',
      padding: '5px',
      position: 'absolute',
      width: '500px',
      zIndex: '1000000000',
    }

    const handleStyles = {
      padding: '0px 15px 0px 5px',
      fontSize: '14px',
      cursor: 'move',
    }

    const {
      branch, // eslint-disable-line
      date,
      long, // eslint-disable-line
      short,
      tag, // eslint-disable-line
    } = GIT_REV

    const qctbControllers = this.props.store.QCToolbar.controllers || {}

    const toolbarControllers =   Object.values(qctbControllers).reduce(
      (col, elm) => {
        return [...col, (<Row>{elm}</Row>)]
      },
      []
    )

    const devIcon = {
      position: 'absolute',
      right: '0',
      bottom: '0',
      fontSize: '80px',
      margin: '0px 10px -15px 0px',
      zIndex: '-10000',
    }

    if(this.state.minimize) {
      tbStyles.minHeight = '25px'
      tbStyles.maxHeight = '25px'
      tbStyles.minWidth = '100px'
      tbStyles.maxWidth = '100px'
      tbStyles.overflow = 'hidden'

      devIcon.display = 'none'
    }

    const hideToolbar = !process.env.REACT_APP__QCToolBar

    if (hideToolbar) {
      return <div />
    }

    const reactQueryDevtools = this.state.showReactQueryDevtools
      ? (
        <div
          style={{
            padding: 0,
            zIndex: 1000000000,
            position: 'absolute',
          }}
        >
          <ReactQueryDevtools
            initialIsOpen
            closeButtonProps={{
              style: { display: 'none' }
            }}
            toggleButtonProps={{
              style: { display: 'none' }
            }}
          />
        </div>
      ) : null

    return (
      <>
        {reactQueryDevtools}
        {/* <Draggable
          handle=".handle"
        >
          <div dir="ltr" style={tbStyles}>
            <style>{ '.handle { cursor: move; margin-left: -7px; }' }</style>
            <span className="handle" style={devIcon}>🚧</span>
            <Row>
              <Col span={this.state.minimize ? 4 : 1}>
                <span
                  className="handle"
                  style={{
                    position: 'absolute',
                    lineHeight: '10px',
                    top: '-3px',
                    left: '2px',
                  }}
                >
                  :::<br/>:::
                </span>
                <span
                  style={{ cursor: 'pointer', marginLeft: '7px' }}
                  onClick={() => {
                    this.setState((oldState) => {
                      return {
                        ...oldState,
                        minimize: !oldState.minimize
                      }
                    })
                  }}
                >
                  {this.state.minimize ? '↗️' : '↙️'}
                </span>
              </Col>
              <Col
                span={3}
                style={toggleForDir({
                  ltr: null,
                  rtl: { float: 'left' }
                })}
              >
                {short}
              </Col>
              {
                this.state.minimize ? null
                : [
                  (
                    <Col>
                      <div
                        className="handle"
                        style={{
                          float: 'left',
                          userSelect: 'none',
                          lineHeight: '10px',
                          marginTop: '-3px',
                          opacity: 0.4,
                        }}
                      >
                        <p>:::::::::::::::::::::::::::::::::::::::</p>
                        <p>:::::::::::::::::::::::::::::::::::::::</p>
                      </div>
                    </Col>
                  ), (
                    <Col>
                      <span style={{ float: 'right', background: 'rgb(255, 231, 93)' }}>
                        {moment(date).local().format("ddd, MMM Do YYYY, HH:mm")}
                      </span>
                    </Col>
                  ),
                ]
              }
            </Row>
            {this.defaultToolbars()}
            {toolbarControllers}
          </div>
        </Draggable> */}
      </>
    )
  }
}

export default connect((store) => ({ store }))(QCPanel)

export function QCToolbar(props) {
  return (
    <Form
      {...props.form}
      layout="inline"
      style={{ border: '1px solid gray' }}
    >
      <Form.Item
        {...props.item}
        label={`__${props.item.label}`}
      >
        {props.children}
      </Form.Item>
    </Form>
  )
}

QCToolbar.defaultProps = {
  form: {},
  item: {},
}
