/* eslint-disable import/no-named-as-default-member */
import store from '~plumbing/store'
import { getUserToken } from '~CON/User/userHelper'

export const API_HOST: string = process.env.REACT_APP__API_HOST || ''
export const API_BASE_URL = process.env.REACT_APP__API_BASE_URL || ''
export const API_URL = `${API_HOST}${API_BASE_URL}`
export const WAB_API_URL = process.env.REACT_APP__WAB_API_URL || ''

export default {
  API_HOST,
  API_URL,
  WAB_API_URL,
  getToken,
  getCurrentCampaignId,
}

export function getToken() {
  // const { tokenString } = store.getState().User.current
  const idToken = getUserToken()
  return idToken
}


/**
 * get Current Campaign Id from redux store
 *
 */
export function getCurrentCampaignId() {
  return store.getState().Campaigns.Current.campaignId
}
