import { camelCase as _camelCase, get as _get } from 'lodash'
import { renameFunc } from 'CQS/plumbing/utils'

export const actionsTypes = {
  FETCH_ALL_CLEANSING: 'discovery/Cleansing/Cleansing',
  FETCH_ALL_CLEANSING_SUCCESS: 'discovery/cleansing/CleansingSucess',
  FETCH_ALL_CLEANSING_FAILED: 'discovery/cleansing/CleansingFail',
  FETCH_ALL_COUNTRIES: 'discovery/cleansing/Countries',
  FETCH_ALL_COUNTRIES_SUCCESS: 'discovery/cleansing/CountriesSucess',
  FETCH_ALL_COUNTRIES_FAILED: 'discovery/cleansing/CountriesFail',
  FETCH_ALL_NETWORKS: 'discovery/cleansing/Networks',
  FETCH_ALL_NETWORKS_SUCCESS: 'discovery/cleansing/NetworksSucess',
  FETCH_ALL_NETWORKS_FAILED: 'discovery/cleansing/NetworksFail',
  CREATE_CLEANSING: 'discovery/cleansing/CreateCleansing',
  CREATE_CLEANSING_SUCCESS: 'discovery/cleansing/CreateCleansingSucess',
  CREATE_CLEANSING_FAILED: 'discovery/cleansing/CreateCleansingFail',
}
export const actions = Object.keys(actionsTypes).reduce((accum, id) => {
  const creatorName = _camelCase(id)

  const creatorFunction = function _(payload, meta) {
    return {
      type: _get(actionsTypes, id),
      payload,
      meta,
    }
  }

  // eslint-disable-next-line no-param-reassign
  accum[creatorName] = renameFunc(creatorFunction, creatorName)
  return accum
}, {})
