/* global FS */
import Rx from 'rxjs' // eslint-disable-line

import {
  observableFromPromiseProperties,
} from 'CQS/plumbing/utils'
import { actions as appActions } from '~CON/App/appActions'

import { actionsTypes, actions as providerActions } from './ProviderActions'
import apis from '../apis'

const providerApis = observableFromPromiseProperties(apis, Rx)

export default [
  getProviderData,
  updateProviderData,
  exportProvider,
]

export function getProviderData(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_ALL_PROVIDERS)
    .switchMap(({ action }) => {
      return providerApis
        .providerData()
        .map((res) => {
          return providerActions.fetchAllProvidersSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_ALL_PROVIDERS_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function exportProvider(action$, { getState }) {
  return action$.ofType(actionsTypes.EXPORT_PROVIDERS)
    .switchMap(({ action }) => {
      return providerApis
        .extractProvider()
        .map((res) => {
          return providerActions.exportProvidersSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.EXPORT_PROVIDERS_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function updateProviderData(action$, { getState }) {
  return action$.ofType(actionsTypes.UPDATE_PROVIDER)
    .switchMap(({ payload }) => {
      return providerApis
        .updateproviderData(payload)
        .map((res) => {
          return providerActions.updateProviderSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.UPDATE_PROVIDER_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
