import React from 'react'
import PropTypes from 'prop-types'
import { isEqual as _isEqual } from 'lodash'


export default class ControlBarPortal extends React.Component {
  componentWillMount() {
    this.key = this.context.ControlBar.upsertContent(this.props)
  }


  componentWillReceiveProps(nextProps) {
    const newProps = { ...nextProps }
    delete newProps.children

    const oldProps = { ...this.props }
    delete oldProps.children

    const isEqual = _isEqual(oldProps, newProps)
    if (!isEqual) {
      this.context.ControlBar.upsertContent(nextProps, this.key)
    }
  }


  componentWillUnmount() {
    this.context.ControlBar.removeContent(this.key)
  }


  render() {
    return null
  }
}

ControlBarPortal.contextTypes = {
  ControlBar: PropTypes.shape({
    upsertContent: PropTypes.func.isRequired,
    removeContent: PropTypes.func.isRequired,
  }).isRequired,
}
