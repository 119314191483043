// fetch campaigns
export const USER_INFO_FETCH = 'user/info 🐶 💬'
export const USER_INFO_FETCH_PASS = 'user/info 🐶 ✅'
export const USER_INFO_FETCH_FAIL = 'user/info 🐶 ❌'

export const USER_TOKEN_SET = 'user/token/set'
export const USER_TOKEN_SET__PASS = 'user/token/set ✅'
export const USER_TOKEN_SET__FAIL = 'user/token/ ❌'

export const REFRESH_USER_TOKEN = 'user/token/refresh'
export const REFRESH_USER_TOKEN_SCHEDULED = 'user/token/refresh ⏱️'
export const REFRESH_TOKEN_SUCCEEDED = 'user/token/refresh 🐶 ✅'
export const REFRESH_TOKEN_FAILED = 'user/token/refresh 🐶 ❌'

export const USER_LOGOUT = 'user/logout/token 🚽'
export const LOGOUT_SUCCESS = 'user/logout/token 🐶 ✅'
export const LOGOUT_FAILED = 'user/logout/token 🐶 ❌'

export const USER_LOGIN = 'user/login/get-token 🐶 '
export const USER_LOGIN_PASS = 'user/login/get-token 🐶 ✅'
export const USER_LOGIN_FAIL = 'user/login/get-token 🐶 ❌'
export const USER_LOGIN_CLEAR_VALIDATIONS = 'user/login/validation flush 🚽'

export const USER_VERIFICATION_LOGIN = 'user/verification/login/get-token 🐶 '
export const USER_VERIFICATION_LOGIN_PASS = 'user/verification/login/get-token 🐶 ✅'
export const USER_VERIFICATION_LOGIN_FAIL = 'user/verification/login/get-token 🐶 ❌'

export const USER_SESSION_RENEW = 'user/session/ renew 🔅️'
export const USER_SESSION_EXPIRED = 'user/session/ expired 🛑'
export const USER_SESSION_ACTIVATE = 'user/session/ activate ❇️'

export const USER_FORGET_PASSWORD_REQUEST = 'user/forgetPassword/ request 💬'
export const USER_FORGET_PASSWORD_SUCCESS = 'user/forgetPassword/ success ✅'
export const USER_FORGET_PASSWORD_FAILED = 'user/forgetPassword/ failed ❌'


export const USER_RESET_PASSWORD_REQUEST = 'user/resetPassword/ request 💬'
export const USER_RESET_PASSWORD_SUCCESS = 'user/resetPassword/ success ✅'
export const USER_RESET_PASSWORD_FAILED = 'user/resetPassword/ failed ❌'

export const USER_CHANGE_PASSWORD_REQUEST = 'user/changePassword/ request 💬'
export const USER_CHANGE_PASSWORD_SUCCESS = 'user/changePassword/ success ✅'
export const USER_CHANGE_PASSWORD_FAILED = 'user/changePassword/ failed ❌'

export const USER_CONVERT_CURRENCY = 'user/convertCurrency/ request 💬'
export const USER_CONVERT_CURRENCY_SUCCESS = 'user/convertCurrency/ success ✅'
export const USER_CONVERT_CURRENCY_FAILED = 'user/convertCurrency/ failed ❌'

export const USER_PAYMENT_PROCESS_INITIATION = 'user/payment/ request 💬'
export const USER_PAYMENT_PROCESS_INITIATION_SUCCESS = 'user/payment/ success ✅'
export const USER_PAYMENT_PROCESS_INITIATION_FAILED = 'user/payment/ failed ❌'

export const USER_PAYPAL_PROCESS_INITIATION = 'user/payment/paypal request 💬'
export const USER_PAYPAL_PROCESS_INITIATION_SUCCESS = 'user/payment/paypal success ✅'
export const USER_PAYPAL_PROCESS_INITIATION_FAILED = 'user/payment/paypal failed ❌'

export const SAVE_PAYPAL_ORDER_CREATION = 'user/save/paypal/orderCreation request 💬'
export const SAVE_PAYPAL_ORDER_CREATION_SUCCESS = 'user/save/paypal/orderCreation success ✅'
export const SAVE_PAYPAL_ORDER_CREATION_FAILED = 'user/save/paypal/orderCreation failed ❌'

export const PASS_PAY_PAL_MESSAGE = 'user/save/payment/paypal request'

export const VERIFY_USER_MOBILE_NUMBER = 'user/verify/mobile request 💬'
export const VERIFY_USER_MOBILE_NUMBER_SUCCESS = 'user/verify/mobile success ✅'
export const VERIFY_USER_MOBILE_NUMBER_FAILED = 'user/verify/mobile failed ❌'

export const SEND_VERIFICATION_CODE = 'user/verify/mobile/sendCode request 💬'
export const SEND_VERIFICATION_CODE_SUCCESS = 'user/verify/mobile/sendCode success ✅'
export const SEND_VERIFICATION_CODE_FAILED = 'user/verify/mobile/sendCode failed ❌'

export const RESEND_VERIFICATION_EMAIL = 'user/verification/email/resend request 💬'
export const RESEND_VERIFICATION_EMAIL_SUCCESS = 'user/verification/email/resend success ✅'
export const RESEND_VERIFICATION_EMAIL_FAILED = 'user/verification/email/resend failed ❌'
export const FLUSH_RESEND_VERIFICATION_EMAIL = 'user/verification/email/resend flush 🚽'

export const REQUEST_SENDER_REQUEST = 'user/requestSender/ request 💬'
export const REQUEST_SENDER_SUCCESS = 'user/requestSender/ success ✅'
export const REQUEST_SENDER_FAILED = 'user/requestSender/ failed ❌'
export const FLUSH_REQUEST_SENDER = 'user/requestSender/ flush 🚽'

export const FETCH_ALL_SENDERS = 'user/manage/senders/fetchAll request 💬'
export const FETCH_ALL_SENDERS_SUCCESS = 'user/manage/senders/fetchAll success ✅'
export const FETCH_ALL_SENDERS_FAILED = 'user/manage/senders/fetchAll failed ❌'

export const FETCH_BALANCE_HISTORY = 'user/balanceHistory/ request 💬'
export const FETCH_BALANCE_HISTORY_SUCCESS = 'user/balanceHistory/ success ✅'
export const FETCH_BALANCE_HISTORY_FAILED = 'user/balanceHistory/ failed ❌'

export const FETCH_COVERAGE_PRICING = 'user/coverageAndPricing/ request 💬'
export const FETCH_COVERAGE_PRICING_SUCCESS = 'user/coverageAndPricing/ success ✅'
export const FETCH_COVERAGE_PRICING_FAILED = 'user/coverageAndPricing/ failed ❌'

export const UPDATE_USER_ON_BOARDING_BREAD_CRUMB = 'user/onboarding/update request 💬'
export const UPDATE_USER_ON_BOARDING_BREAD_CRUMB_SUCCESS = 'user/onboarding/update success ✅'
export const UPDATE_USER_ON_BOARDING_BREAD_CRUMB_FAILED = 'user/onboarding/update failed ❌'

export const UPDATE_ACCOUNT_REQUESTED = 'user/account/upgrade request 💬'
export const UPDATE_ACCOUNT_SUCCESS = 'user/account/upgrade success ✅'
export const UPDATE_ACCOUNT_FAILED = 'user/account/upgrade failed ❌'

export const FETCH_ACCOUNT_SUBS_LISTING = 'account/subsidiaries/listing 🚀 💬'
export const FETCH_ACCOUNT_SUBS_LISTING_COMPLETED = 'account/subsidiaries/listing 🚀 ✅'
export const FETCH_ACCOUNT_SUBS_LISTING_FAILED = 'account/subsidiaries/listing 🚀 ❌'

export const FLUSH_ACCOUNT_SUBS_LISTING_META = 'account/subsidiaries/listing/meta/flush 🚽'

export const TOGGLE_SUB_ACCOUNT_STATUS = 'account/subsidiaries/toggle-activation 🚀 💬'
export const TOGGLE_SUB_ACCOUNT_STATUS_COMPLETED = 'account/subsidiaries/toggle-activation 🚀 ✅'
export const TOGGLE_SUB_ACCOUNT_STATUS_FAILED = 'account/subsidiaries/toggle-activation 🚀 ❌'

export const TOGGLE_2_FACTOR_AUTH = 'account/subsidiaries/toggle-mfa 🚀 💬'
export const TOGGLE_2_FACTOR_AUTH_COMPLETED = 'account/subsidiaries/toggle-mfa 🚀 ✅'
export const TOGGLE_2_FACTOR_AUTH_FAILED = 'account/subsidiaries/toggle-mfa 🚀 ❌'

export const FETCH_SUB_ACCOUNTS = 'user/subAccounts/ request 💬'
export const FETCH_SUB_ACCOUNTS_SUCCESS = 'user/subAccounts/ success ✅'
export const FETCH_SUB_ACCOUNTS_FAILED = 'user/subAccounts/ failed ❌'

export const CREATE_SUB_ACCOUNT = 'user/subAccounts/create request 💬'
export const CREATE_SUB_ACCOUNT_SUCCESS = 'user/subAccounts/create success ✅'
export const CREATE_SUB_ACCOUNT_FAILED = 'user/subAccounts/create failed ❌'

export const FETCH_ACCOUNT_INTERFACES_LOOKUP = 'account/interfaces/fetchAll request 💬'
export const FETCH_ACCOUNT_INTERFACES_LOOKUP_SUCCESS = 'account/interfaces/fetchAll success ✅'
export const FETCH_ACCOUNT_INTERFACES_LOOKUP_FAILED = 'account/interfaces/fetchAll failed ❌'

export const UPDATE_SUB_ACCOUNT = 'user/subAccounts/edit request 💬'
export const UPDATE_SUB_ACCOUNT_SUCCESS = 'user/subAccounts/edit success ✅'
export const UPDATE_SUB_ACCOUNT_FAILED = 'user/subAccounts/edit failed ❌'

export const TOGGLE_SUB_ACCOUNT_CAMP_APPROVAL = 'account/subsidiaries/toggle-allowCampApproval request 💬'
export const TOGGLE_SUB_ACCOUNT_CAMP_APPROVAL_COMPLETED = 'user/subsidiaries/toggle-allowCampApproval success ✅'
export const TOGGLE_SUB_ACCOUNT_CAMP_APPROVAL_FAILED = 'user/subsidiaries/toggle-allowCampApproval failed ❌'

export const TRANSFER_CREDIT = 'user/credit/transfer request 💬'
export const TRANSFER_CREDIT_SUCCESS = 'user/credit/transfer success ✅'
export const TRANSFER_CREDIT_FAILED = 'user/credit/transfer failed ❌'
export const FLUSH_TRANSFER_CREDIT = 'user/credit/transfer flush 🚽'

export const UPDATE_ACCOUNT_SETTINGS = 'user/accounts/settings request 💬'
export const UPDATE_ACCOUNT_SETTINGS_SUCCESS = 'user/accounts/settings success ✅'
export const UPDATE_ACCOUNT_SETTINGS_FAILED = 'user/accounts/settings failed ❌'
export const FLUSH_ACCOUNT_SETTINGS = 'user/accounts/settings flush 🚽'

export const TRANSFER_CREDIT_LIMIT = 'user/credit/limit/transfer request 💬'
export const TRANSFER_CREDIT_LIMIT_SUCCESS = 'user/credit/limit/transfer success ✅'
export const TRANSFER_CREDIT_LIMIT_FAILED = 'user/credit/limit/transfer failed ❌'
export const FLUSH_TRANSFER_CREDIT_LIMIT = 'user/credit/limit/transfer flush 🚽'

export const INITIATE_MFA = 'user/initiateMFA/totp request 💬'
export const INITIATE_MFA_COMPLETED = 'user/initiateMFA/totp success ✅'
export const INITIATE_MFA_FAILED = 'user/initiateMFA/totp failed ❌'

export const DISABLE_MFA = 'user/disableMFA request 💬'
export const DISABLE_MFA_COMPLETED = 'user/disableMFA success ✅'
export const DISABLE_MFA_FAILED = 'user/disableMFA failed ❌'

export const VERIFY_MFASOFTWARE_TOKEN = 'user/verifyMFASoftware request 💬'
export const VERIFY_MFASOFTWARE_TOKEN_COMPLETED = 'user/verifyMFASoftware success ✅'
export const VERIFY_MFASOFTWARE_TOKEN_FAILED = 'user/verifyMFASoftware failed ❌'

export const VERIFY_AUTH_CHALLENGE_REQUESTED = 'user/verifyAuthChallenge request 💬'
export const VERIFY_AUTH_CHALLENGE_COMPLETED = 'user/verifyAuthChallenge success ✅'
export const VERIFY_AUTH_CHALLENGE_FAILED = 'user/verifyAuthChallenge failed ❌'

export const SHOW_OTPAUTH = 'user/showOTPAuth'

export const REPLACE_DUPLICATE_EMAIL = 'user/login/replaceDuplicateEmail request 💬'
export const REPLACE_DUPLICATE_EMAIL_COMPLETED = 'user/login/replaceDuplicateEmail success ✅'
export const REPLACE_DUPLICATE_EMAIL_FAILED = 'user/login/replaceDuplicateEmail failed ❌'
export const FLUSH_REPLACE_DUPLICATE_EMAIL_DATA = 'user/login/replaceDuplicateEmail flush 🚽'

export const FETCH_ACCOUNT_SERVICES = 'user/accountServices request 💬'
export const FETCH_ACCOUNT_SERVICES_COMPLETED = 'user/accountServices success ✅'
export const FETCH_ACCOUNT_SERVICES_FAILED = 'user/accountServices failed ❌'

export const FETCH_USER_NAME = 'user/fetchName request 💬'
export const FETCH_USER_NAME_COMPLETED = 'user/fetchName success ✅'
export const FETCH_USER_NAME_FAILED = 'user/fetchName failed ❌'

export const INITIATE_MFA_SMS = 'user/initiateMFA/sms/step1 request 💬'
export const INITIATE_MFA_SMS_COMPLETED = 'user/initiateMFA/sms/step1 success ✅'
export const INITIATE_MFA_SMS_FAILED = 'user/initiateMFA/sms/step1 failed ❌'

export const DISABLE_MFA_SMS = 'user/disableMfaSms request 💬'
export const DISABLE_MFA_SMS_COMPLETED = 'user/disableMfaSms success ✅'
export const DISABLE_MFA_SMS_FAILED = 'user/disableMfaSms failed ❌'

export const FLUSH_INITIATE_MFA_WITH_SMS = 'user/initiateMFA/sms flush 🚽'

export const INITIATE_MFA_WITH_SMS_STEP_TWO = 'user/initiateMFA/sms/step2 request 💬'
export const INITIATE_MFA_WITH_SMS_STEP_TWO_COMPLETED = 'user/initiateMFA/sms/step2 success ✅'
export const INITIATE_MFA_WITH_SMS_STEP_TWO_FAILED = 'user/initiateMFA/sms/step2 failed ❌'

export const VERIFY_MFA_SMS_CODE = 'user/verifyMfaSms request 💬'
export const VERIFY_MFA_SMS_CODE_COMPLETED = 'user/verifyMfaSms success ✅'
export const VERIFY_MFA_SMS_CODE_FAILED = 'user/verifyMfaSms failed ❌'

export const CHOOSE_MFA_VIEW = 'user/chooseMFA request 💬'
export const CHOOSE_MFA_VIEW_COMPLETED = 'user/chooseMFA success ✅'

export const SHOW_OTPAUTH_FOR_SMS = 'user/showOTPAuthForSms'

export const LOGIN_USING_GOOGLE_TOKEN = 'user/loginUsingGoogleToken ✍'

export const SEND_FEDERATION_LOGIN_SIGN_UP_FORM = 'user/sendFederationLoginSignUpForm request 💬'
export const SEND_FEDERATION_LOGIN_SIGN_UP_FORM_COMPLETED = 'user/sendFederationLoginSignUpForm success ✅'
export const SEND_FEDERATION_LOGIN_SIGN_UP_FORM_FAILED = 'user/sendFederationLoginSignUpForm failed ❌'

export const GET_FEDERATION_TOKEN = 'user/getFederationToken request 💬'
export const GET_FEDERATION_TOKEN_COMPLETED = 'user/getFederationToken success ✅'
export const GET_FEDERATION_TOKEN_FAILED = 'user/getFederationToken failed ❌'

export const GET_TOKEN_AFTER_FEDERATION_SIGNUP = 'user/getTokenAfterFederationSignup request 💬'
export const GET_TOKEN_AFTER_FEDERATION_SIGNUP_COMPLETED = 'user/getTokenAfterFederationSignup success ✅'
export const GET_TOKEN_AFTER_FEDERATION_SIGNUP_FAILED = 'user/getTokenAfterFederationSignup failed ❌'

export const SAVE_LOGIN_SESSION = 'user/saveLoginSession/ request 💬'
export const SAVE_LOGIN_SESSION_COMPLETED = 'user/saveLoginSession/ success ✅'
export const SAVE_LOGIN_SESSION_FAILED = 'user/saveLoginSession/ failed ❌'

export const GET_LOGIN_SESSIONS = 'user/getLoginSessions/ request 💬'
export const GET_LOGIN_SESSIONS_COMPLETED = 'user/getLoginSessions/ success ✅'
export const GET_LOGIN_SESSIONS_FAILED = 'user/getLoginSessions/ failed ❌'
