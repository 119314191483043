export const actionsTypes = {
  APP_THROW_ERROR: 'app/error ⛔',
  APP_CURRENT_UTC__GET: 'app/currentTime UTC 🐶 💬',
  APP_CURRENT_UTC__SET: 'app/currentTime UTC 🐶 ✅',
}


export const actions = {
  throwError,
}


export function throwError(errorKey, action) {
  return {
    type: actionsTypes.APP_THROW_ERROR,
    errorKey,
    failedAction: action,
  }
}
