import styled from 'styled-components'

/**
 *
 * @param {object} props
 * @param {boolen} props.warn - warning highlight
 * @param {boolen} props.error - Error highlight
 *
 * @example
 * <Input warn />
 */
export const Card = styled.div`
  width: calc(33.33% - 10px);
  background: ${({ theme }) => theme.bgLighter};
  display:inline-block;
  margin-${({ theme }) => theme.dirFloatRev}:15px;
  Margin-bottom:20px;
  float:${({ theme }) => theme.dirFloat};
  color:${({ theme }) => theme.textDark};

  opacity:0.9;
  border-radius:${({ theme }) => theme.roundCorner};
  &:hover {
 opacity:1;
 i {
  color: ${({ theme }) => theme.btnLinkHover};

 }
  }

a{
  display:block;
  padding:15px;
  i {
    color: ${({ theme }) => theme.var.colors.helper.greys.grey50};
  }
  & :hover {
    i, svg, [class^="fa-"] {
      color: ${({ theme }) => theme.brandMain};
    }
  }
}
  i{
    display:block;
    float:${({ theme }) => theme.dirFloat};
    margin-${({ theme }) => theme.dirFloatRev}:20px;
    font-size:30px;
    text-align:center;
    margin-top:-3px;
  }
  i span{
    margin-top:-3px;
    font-size:30px;
  }

  p{
    color: ${({ theme }) => theme.textNormal};
    span{
      color: ${({ theme }) => theme.textNormal};
      margin-${({ theme }) => theme.dirFloat}: 5px;
    }
  }
  span{
    line-height:20px;
    color: ${({ theme }) => theme.textDark};
    font-size: 14px;
    font-weight:600;
  }
`
