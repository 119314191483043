import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function updateRaghiAccounts(payload) {
  const usingSplit = payload.split(',')
  const raghiAccounts = {
    users: usingSplit,
  }
  return new Promise((resolve, reject) => {
    request
      .post(`${API_URL}/api/v1/global-config/rajhi-users`)
      .set('Authorization', `bearer ${getToken()}`)
      .send(raghiAccounts)
      .then((resp) => {
        resolve(raghiAccounts)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
