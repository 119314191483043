import {
  get as _get,
  isFunction as _isFunction,
} from 'lodash'

export default function methodsRiders(target, riders) {
  const recoveryObj = {}

  Object.entries(target)
    .forEach(([k, v]) => {
      recoveryObj[k] = v

      const replacerFn = _isFunction(riders)
        ? riders
        : _get(riders, k, riders)

      target[k] = _isFunction(v) // eslint-disable-line
        ? (...args) => replacerFn.apply(target, args)
        : v
    })

  return {
    ...recoveryObj,
    __recover_target: () => {
      Object.entries(recoveryObj)
        .forEach(([k, v]) => {
          target[k] = v  // eslint-disable-line
        })
    },
  }
}
