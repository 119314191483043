/* eslint-disable import/no-cycle */
import samplesData from './getSamples'
import getSamplesDataById from './getSampleById'
import deleteSamples from './deleteSample'
import prefixesWithSamples from './getPrefixsWithSamples'
import runSample from './createSample'

export default {
  samplesData,
  getSamplesDataById,
  deleteSamples,
  prefixesWithSamples,
  runSample,
}
