import React from 'react'
// import { Icon } from 'fa-shorthand.macro'

// collection
import * as Styled from './styled'

// factory
// import cqsListing from './CQSListing'

// stateful
import BodyStyle from './BodyStyle'
// import Bundle from './Bundle/Bundle'
import ControlBar from './ControlBar'
// import KeysMapInput from './KeysMapInput'
// import Mention from './Mention'
// import MessageInput from './MessageInput'

// with redux

// stateless
// import CQSCard from './Card'
// import Currency from './Currency'
// import DataTable from './DataTable'
import ErrorPages from './ErrorPages'
// import UploadFile from './FilesUpload'
// import Tooltip from './CQSToolTip/Tooltip'
// import SchedulerInput from './SchedulerInput'
// import JoiValidationMessage from './joiValidationMessages'

// import { SearchInput } from './SearchInput'


// import {
//   Wizard,
//   wizardStep,
//   WizardAction,
//   WizardControls,
// } from './Wizard'

// import { Form } from './Form'

// import {
//   TextSpinner,
// } from './Spinner'


// const JoiIntl = JoiValidationMessage
const BasicSpinner = () => (<icon/>)


export {
  BasicSpinner,

  // collection
  Styled,

  // component factory func
  // cqsListing,

  // stateful
  BodyStyle,
  // Bundle,
  ControlBar,
  // KeysMapInput,
  // Mention,
  // MessageInput,
  // SearchInput,

  // stateful with redux
  // Wizard,
  // WizardAction,
  // WizardControls,
  // wizardStep,

  // Upload File=
  // UploadFile,

  // Form
  // Form,

  // stateless
  // CQSCard,
  // Tooltip,
  // DataTable,
  ErrorPages,
  // JoiIntl,
  // JoiValidationMessage,
  // SchedulerInput,
  // TextSpinner,
  // Currency,
}
