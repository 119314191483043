const merge = Object.assign

// Colors
const yellow200 = '#fff59d'
const deepOrange600 = '#f4511e'
const lime300 = '#dce775'
const lightGreen500 = '#8bc34a'
const teal700 = '#00796b'
const cyan900 = '#006064'
const blueGrey50 = '#eceff1'
const blueGrey300 = '#90a4ae'
const blueGrey700 = '#455a64'
const grey900 = '#212121'
const colors = [
  deepOrange600,
  yellow200,
  lime300,
  lightGreen500,
  teal700,
  cyan900,
]

// Typography
const sansSerif = 'Roboto, "Helvetica Neue", Helvetica, "sans-serif"'
const letterSpacing = 'normal'
const fontSize = 12

// Layout
const padding = 8
const baseProps = {
  width: 350,
  height: 350,
  padding: 50,
}

// * Labels
const baseLabelStyles = {
  fontFamily: sansSerif,
  fontSize,
  letterSpacing,
  padding,
  fill: blueGrey700,
  stroke: 'transparent',
  strokeWidth: 0,
}

const centeredLabelStyles = withBaseProps({ textAnchor: 'middle' }, baseLabelStyles)

// Strokes
const strokeDasharray = '10, 5'
const strokeLinecap = 'round'
const strokeLinejoin = 'round'


function withBaseProps(styles, ...otherStyles) {
  return merge({ styles }, ...otherStyles, baseProps)
}

// Put it all together...
export default {
  area: withBaseProps({
    data: {
      fill: grey900,
    },
    labels: centeredLabelStyles,
  }),
  axis: withBaseProps({
    axis: {
      fill: 'transparent',
      stroke: blueGrey300,
      strokeWidth: 2,
      strokeLinecap,
      strokeLinejoin,
    },
    axisLabel: merge({}, centeredLabelStyles, {
      padding,
      stroke: 'transparent',
    }),
    grid: {
      fill: 'none',
      strokeLinecap,
      strokeLinejoin,
      strokeDasharray,
      stroke: blueGrey50,
      pointerEvents: 'painted',
    },
    ticks: {
      fill: 'transparent',
      size: 5,
      stroke: blueGrey300,
      strokeWidth: 1,
      strokeLinecap,
      strokeLinejoin,
    },
    tickLabels: merge({}, baseLabelStyles, {
      fill: blueGrey700,
    }),
  }),
  bar: withBaseProps({
    data: {
      fill: blueGrey700,
      padding,
      strokeWidth: 0,
    },
    labels: baseLabelStyles,
  }),
  boxplot: withBaseProps({
    max: {
      padding,
      stroke: blueGrey700,
      strokeWidth: 1,
    },
    maxLabels: baseLabelStyles,
    median: {
      padding,
      stroke: blueGrey700,
      strokeWidth: 1,
    },
    medianLabels: baseLabelStyles,
    min: {
      padding,
      stroke: blueGrey700,
      strokeWidth: 1,
    },
    minLabels: baseLabelStyles,
    q1: {
      padding,
      fill: blueGrey700,
    },
    q1Labels: baseLabelStyles,
    q3: {
      padding,
      fill: blueGrey700,
    },
    q3Labels: baseLabelStyles,
  }, { boxWidth: 20 }),
  candlestick: withBaseProps({
    data: {
      stroke: blueGrey700,
    },
    labels: centeredLabelStyles,
  }, {
    candleColors: {
      positive: '#ffffff',
      negative: blueGrey700,
    },
  }),
  chart: baseProps,
  errorbar: withBaseProps({
    borderWidth: 8,
    style: {
      data: {
        fill: 'transparent',
        opacity: 1,
        stroke: blueGrey700,
        strokeWidth: 2,
      },
      labels: centeredLabelStyles,
    },
  }),
  group: withBaseProps({
    colorScale: colors,
  }),
  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: 'vertical',
    titleOrientation: 'top',
    style: {
      data: {
        type: 'circle',
      },
      labels: baseLabelStyles,
      title: { ...baseLabelStyles, padding: 5 },
    },
  },
  line: withBaseProps({
    data: {
      fill: 'transparent',
      opacity: 1,
      stroke: blueGrey700,
      strokeWidth: 2,
    },
    labels: centeredLabelStyles,
  }),
  pie: withBaseProps({
    data: {
      padding,
      stroke: blueGrey50,
      strokeWidth: 1,
    },
    labels: withBaseProps({}, baseLabelStyles, { padding: 20 }),
  }, {
    colorScale: colors,
  }),
  scatter: withBaseProps({
    data: {
      fill: blueGrey700,
      opacity: 1,
      stroke: 'transparent',
      strokeWidth: 0,
    },
    labels: centeredLabelStyles,
  }),
  stack: withBaseProps({
    colorScale: colors,
  }),
  tooltip: {
    style: {
      ...centeredLabelStyles,
      padding: 5,
      pointerEvents: 'none',
    },
    flyoutStyle: {
      stroke: grey900,
      strokeWidth: 1,
      fill: '#f0f0f0',
      pointerEvents: 'none',
    },
    cornerRadius: 5,
    pointerLength: 10,
  },
  voronoi: withBaseProps({
    data: {
      strokeWidth: 0,
      fill: 'transparent',
      stroke: 'transparent',
    },
    labels: withBaseProps({}, centeredLabelStyles, {
      padding: 5,
      pointerEvents: 'none',
    }),
    flyout: {
      stroke: grey900,
      strokeWidth: 1,
      fill: '#f0f0f0',
      pointerEvents: 'none',
    },
  }),
}
