import { camelCase as _camelCase, get as _get } from 'lodash'
import { renameFunc } from 'CQS/plumbing/utils'

export const actionsTypes = {
  FETCH_ALL_NETWORKS_DATA_LIST: 'discovery/Requests/NetworksDataList',
  FETCH_ALL_NETWORKS_DATA_LIST_SUCCESS: 'discovery/Requests/NetworksDataListSucess',
  FETCH_ALL_NETWORKS_DATA_LIST_FAILED: 'discovery/Requests/NetworksDataListFail',
  FETCH_FILTERED_DATA: 'discovery/Requests/FilteredData',
  FETCH_FILTERED_DATA_SUCCESS: 'discovery/Requests/FilteredDataSucess',
  FETCH_FILTERED_DATA_FAILED: 'discovery/Requests/FilteredDataFail',
}
// helper function automatically generate ACTION CREATORS!
export const actions = Object.keys(actionsTypes)
  .reduce((accum, id) => {
    const creatorName = _camelCase(id)

    const creatorFunction = function _(payload, meta) {
      return {
        type: _get(actionsTypes, id),
        payload,
        meta,
      }
    }

    // eslint-disable-next-line no-param-reassign
    accum[creatorName] = renameFunc(creatorFunction, creatorName)
    return accum
  }, {})
