import styled from 'styled-components'

/**
 *
 * @param {object} props
 *
 * @example
 * <Paragraph />
 * <Paragraph />
 */

export const Paragraph = styled.p`
  font-size:14px;
  margin-bottom:20px;
  margin-top:10px;
  line-height: 16px;
  color:${({ theme }) => theme.textNormal} ;
`
