import Cookies from 'js-cookie'
import locales from '~plumbing/i18n'

import { inCase } from '../index'

export { default as reactIntlWrapper } from './reactIntlWrapper'

export function getUserLocale() {
  const languageRegionCode = navigator
    ?.languages
    ?.[0]
    || navigator.language
    || navigator.userLanguage

  // Split i18n with a region code
  const languageCode = languageRegionCode.toLowerCase().split(/[_-]+/)[0]
  // TODO: check browser lang/reg if exists in i18n
  const userLocale = Cookies.get('userLocale')
    || languageCode
    || locales?.default?.code
  return userLocale
}

export function getUserLocaleDir() {
  const userlocale = getUserLocale()
  return inCase
    .check(userlocale.toLowerCase())
    .otherwise('ltr')
    .cases({
      're/^ar/i': 'rtl',
    })
    .value()
}

export function loadUserStyles(styles) {
  try {
    return styles[getUserLocaleDir()]()
  } catch (e) {
    console.error('loadUserStyles', e) // eslint-disable-line
    return e
  }
}

export function toggleOptionForUserLocaleDir(opt, fallback = null) {
  try {
    return opt[getUserLocaleDir()]
  } catch (e) {
    return fallback
  }
}

export function isRTL() {
  return (getUserLocaleDir() === 'rtl')
}

export function isLTR() {
  return (getUserLocaleDir() === 'ltr')
}
