import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
// import { Icon } from 'fa-shorthand.macro'

const CloseButton = styled.button`
    float: ${({ theme }) => theme.dirFloatRev};
    position: relative;
    margin-bottom: -100px;
    margin-top: 10px;
    margin-${({ theme }) => theme.dirFloatRev}: 20px;
    z-index: 2;
    background: transparent;
    font-weight: bolder;
    padding: 3px 6px;
    line-height: normal;
    border: 1px solid transparent;
    /* border-${({ theme }) => theme.dirFloat}-color: ${({ theme }) => theme.btnAltHover}; */
    color: ${({ theme }) => theme.btnLink};
    
    cursor: pointer;
   
  i {
    font-size: 16px;
   
  }

  &:focus {
    outline: none;
    border: 1px solid transparent;
    color: ${({ theme }) => theme.btnLink};
  }
  &:hover {
    outline: none;
    border: 1px solid ${({ theme }) => theme.btnAltHover};
    color: ${({ theme }) => theme.btnLink};
    background: ${({ theme }) => theme.btnAltHover};
    border-radius: ${({ theme }) => theme.roundCorner};
  }
`
export default function ControlBarTools(props) {
  if (props.hideClose) { // fixMe: close button will be in header as optional props
    return null
  }
  return (
    <div>
      <CloseButton isOpen={props.isOpen} onClick={props.handleClose}>
        {/* <i><Icon icon={Icon.regular.times} /></i> */}
      </CloseButton>
    </div>
  )
}

ControlBarTools.propTypes = {
  handleClose: PropTypes.func, //eslint-disable-line
  isOpen: PropTypes.bool, //eslint-disable-line
  hideClose: PropTypes.bool, //eslint-disable-line

}
