// eslint-disable-next-line no-unused-vars
/* global FS */
import Rx , {of} from 'rxjs' // eslint-disable-line
import
{
  catchError, map, switchMap, tap,
}
  from
  'rxjs/operators'
import {
  observableFromPromiseProperties,
} from 'CQS/plumbing/utils'
import { actions as appActions } from '~CON/App/appActions'

import { actionsTypes, actions as prefixsActions } from './ConfigActions'
// eslint-disable-next-line import/no-cycle
import apis from '../apis'

const prefixsApis = observableFromPromiseProperties(apis, Rx)

export default [
  getPrefixsData,
  updatePrefix,
  deletePrefix,
  getCountriesData,
  getNetworksData,
  getCustomerConfigData,
  addCustomerDestnation,
  exportPrefix,
  defDestanation,
  getCustomerHLRConfigData,
  getCustomerMNPConfigData,
]

export function getPrefixsData(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_ALL_PREFIXS)
    .switchMap(({ payload }) => {
      const { configType } = payload
      return prefixsApis
        .getPrefixes(configType)
        .map((res) => {
          return prefixsActions.fetchAllPrefixsSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_ALL_PREFIXS_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getCustomerConfigData(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_CUSTOMER_CONFIG)
    .switchMap(({ payload, meta }) => {
      return prefixsApis
        .CustomerConfig(payload, meta)
        .map((res) => {
          return prefixsActions.fetchCustomerConfigSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_CUSTOMER_CONFIG_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}

export function getCustomerHLRConfigData(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_CUSTOMER_HLR_CONFIG)
    .switchMap(({ payload, meta }) => {
      return prefixsApis
        .CustomerConfig(payload, meta)
        .map((res) => {
          return prefixsActions.fetchCustomerHlrConfigSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_CUSTOMER_HLR_CONFIG_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getCustomerMNPConfigData(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_CUSTOMER_MNP_CONFIG)
    .switchMap(({ payload, meta }) => {
      return prefixsApis
        .CustomerConfig(payload, meta)
        .map((res) => {
          return prefixsActions.fetchCustomerMnpConfigSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_CUSTOMER_MNP_CONFIG_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getCountriesData(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_ALL_COUNTRIES)
    .switchMap(({ action }) => {
      return prefixsApis
        .getCountries()
        .map((res) => {
          return prefixsActions.fetchAllCountriesSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_ALL_COUNTRIES_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getNetworksData(action$, { getState }) {
  return action$.ofType(actionsTypes.ALL_NETWORKS)
    .switchMap(({ payload }) => {
      return prefixsApis
        .getNetwork(payload)
        .map((res) => {
          return prefixsActions.allNetworksSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.ALL_NETWORKS_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function updatePrefix(action$, { getState }) {
  return action$.ofType(actionsTypes.UPDATE_PREFIX)
    .switchMap(({ payload, meta }) => {
      return prefixsApis
        .updatePrefix(payload, meta)
        .map((res) => {
          return prefixsActions.updatePrefixSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.UPDATE_PREFIX_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function addCustomerDestnation(action$, { getState }) {
  return action$.ofType(actionsTypes.ADD_DESTNATION)
    .switchMap(({ payload, meta }) => {
      return prefixsApis
        .addCustomerDestnation(payload, meta)
        .map((res) => {
          return prefixsActions.addDestnationSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.ADD_DESTNATION_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function deletePrefix(action$, { getState }) {
  return action$.ofType(actionsTypes.DELETE_PREFIX)
    .switchMap(({ payload, meta }) => {
      return prefixsApis
        .deletePrefixes(payload, meta)
        .map((res) => {
          return prefixsActions.deletePrefixSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.DELETE_PREFIX_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function exportPrefix(action$, { getState }) {
  return action$.ofType(actionsTypes.EXPORT_PREFIXES)
    .switchMap(({ payload }) => {
      const { configType } = payload
      return prefixsApis
        .extractPrefixes({ configType })
        .map((res) => {
          return prefixsActions.exportPrefixesSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.EXPORT_PREFIXES_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function defDestanation(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_DEFAULT_CONFIG)
    .switchMap(({ payload, meta }) => {
      return prefixsApis
        .defaultConfig(payload, meta)
        .map((res) => {
          return prefixsActions.fetchDefaultConfigSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_DEFAULT_CONFIG_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
