import moment from 'moment'
import {
  get as _get,
  isNaN as _isNaN,
  isEmpty as _isEmpty,
  isObject as _isObject,
} from 'lodash'

import jwtDecode from '~plumbing/utils/jwtDecoder'
import base64 from '~plumbing/utils/base64'
import { conKVS } from '~utils/kvStore'


/**
 * get meta data from JWT token or fallback Obj
 *
 * @param fallback
 *
 * @returns {Object}
 */
export function getUserJWTObject(fallback = { }) {
  const token = getUserToken()
  try {
    if (typeof token === 'string' && token.length > 90) {
      const jwtToken = jwtDecode(token)
      const userRole = jwtToken
        ?.['cognito:groups'][0]
        ?.replace(/\s/g, '')
        || 'admin'

      const aclRole = _isEmpty(userRole)
        ? 'admin'
        : userRole

      return {
        ...jwtDecode(token),
        aclRole,
      }
    }
  } catch (e) {
    // console.error('[userHelper]', 'getUserJWTObject', e)

    return fallback
  }

  return fallback
}

/**
 * get jwt String or fallback
 *
 * @param fallback
 *
 * @returns {*}
 */
export function getUserToken(fallback) {
  try {
    const token = conKVS.loginToken.get()
    return token
  } catch (e) {
    conKVS.loginToken.clear()

    return fallback
  }
}

/**
 * set user jwt token
 *
 * @param {String} token
 */
export function setUserToken(token) {
  conKVS.loginToken.set(token)
  persistUserInfo(token)
}

/**
 * set refresh token for user
 *
 * @param token
 */
export function setUserRefreshToken(token) {
  conKVS.refreshToken.set(token)
}

/**
 * get user refresh token
 *
 * @param fallback
 * @returns {*}
 */
export function getUserRefreshToken(fallback) {
  try {
    const token = conKVS.refreshToken.get()

    return token
  } catch (e) {
    conKVS.refreshToken.clear()
  }

  return fallback
}

export function userSession() {
  const rd = Math.floor((Math.random() * 4564897) + 13465)

  const on = rd % 2 // even
    ? rd
    : rd + 1

  const off = rd % 2 // old
    ? rd + 1
    : rd

  return {
    getKey: () => {
      const activeSession = localStorage.getItem('s')
      return activeSession
    },
    get: () => {
      const activeSession = !!(localStorage.getItem('s') % 2)
      return activeSession
    },
    lock: () => {
      localStorage.setItem('s', off)
    },
    unlock: () => {
      localStorage.setItem('s', on)
      // localStorage.setItem('qe', 'on')
    },
  }
}

export function windowFocus() {
  const key = 'f'

  return {
    hasFocus: () => {
      return !!windowFocus().get() && document.hasFocus()
    },
    canLock: () => {
      return !windowFocus().get() || windowFocus().hasFocus()
    },
    get: () => {
      const val = parseInt(localStorage.getItem(key), 10)

      if (_isNaN(val)) {
        windowFocus().set(document.hasFocus() ? 1 : 0)
        return windowFocus().get()
      }

      return val
    },
    set: (value) => {
      localStorage.setItem(key, value)
    },
    inc: () => {
      windowFocus().set(windowFocus().get() + 1)
    },
    dec: () => {
      const _val = windowFocus().get() - 1
      const val = (_val < 0)
        ? 0
        : _val

      windowFocus().set(val)
    },
  }
}

export function persistUserInfo(token) {
  const usrJWT = jwtDecode(token || conKVS.loginToken.get())

  if (_isObject(usrJWT)) {
    const data = {
      email: usrJWT.email,
      userId: usrJWT.sub,
      savedOn: moment().toISOString(),
      roles: _get(usrJWT, 'cognito:groups'),
      userName: _get(usrJWT, 'cognito:username'),
      accountId: _get(usrJWT, 'custom:AccountID'),
      accountName: _get(usrJWT, 'custom:AccountName'),
      displayName: _get(usrJWT, 'cognito:DisplayName'),
    }

    const account = do {
      /* eslint-disable no-unused-expressions */
      if (!_isEmpty(data.accountName)) {
        `${data.accountName}`
      } else if (!_isEmpty(data.accountId)) {
        `${data.accountId}`
      }
      /* eslint-enable no-unused-expressions */
    }

    const user = do {
      /* eslint-disable no-unused-expressions */
      if (!_isEmpty(data.displayName)) {
        `${data.displayName}`
      } else if (!_isEmpty(data.username)) {
        `${data.username}`
      } else if (!_isEmpty(data.email)) {
        `${data.email}`
      } else if (!_isEmpty(data.userId)) {
        `${data.userId}`
      }
      /* eslint-enable no-unused-expressions */
    }

    data.displayName = `${user} (${account})`

    conKVS.userDigest.set(data)

    // localStorage.setItem('_con:ud', base64.encodeURI(data))))
  }
}

export function getUserInfo(token) {
  let userData = {}

  try {
    userData = base64.decode(token)
  } catch (er1) {
    try {
      userData = base64.decode(localStorage.getItem('_con:ud'))
    } catch (er2) {
      userData = {}
    }
  }

  if (userData) {
    userData.hasUser = !_isEmpty(userData?.displayName)
  }

  return userData
}
