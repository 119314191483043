import React from 'react'

import ControlBarPortal from './Portal'


/**
 * Control Bar Toggle
 *
 * @example
 * <BarToggle>
 *  {({ BarPortal, toggleBar, isOpen }) => (
 *    <React.Fragment>
 *      <button onClick={toggleBar}>Close</button>
 *      <BarPortal open={isOpen} onClose={toggleBar}>Close</BarPortal>
 *    </React.Fragment>
 *  )}
 * </BarToggle>
 */
export default class ControlBarToggle extends React.Component {
  state = {
    open: false,
  }

  handleToggleBar = () => {
    this.setState((prevState) => ({ open: !prevState.open }))
  }

  handleOpenBar = () => {
    this.setState({ open: true })
  }

  handleCloseBar = () => {
    this.setState({ open: false })
  }

  render() {
    const { render, children } = this.props // eslint-disable-line

    const renderFunc = typeof render === 'function'
    const childrenFunc = typeof children === 'function'

    const renderParams = {
      BarPortal: ControlBarPortal,
      toggleBar: this.handleToggleBar,
      openBar: this.handleOpenBar,
      closeBar: this.handleCloseBar,
      isOpen: this.state.open,
    }
    if (childrenFunc) { return children(renderParams) }

    if (renderFunc) { return render(renderParams) }

    return null
  }
}
