import { combineReducers } from 'redux'

import { actionTypes } from '~plumbing/api/actions'
import { getToken } from '~plumbing/api/apiUtils'
import jwtDecode from '~plumbing/utils/jwtDecoder'
import {
  setUserToken,
  setUserRefreshToken,
} from '~CON/User/userHelper'
import { inCase } from '~plumbing/utils'

const defaultState = {
  errors: null,
  token: null,
  tokenString: null,
  username: undefined,
  userId: undefined,
}

export const userCurrentReducer = inCase.curry('userReducer')
  .check((s, action) => action.type)
  .otherwise((state) => state || defaultState)
  .cases({
    [actionTypes.LOGOUT_SUCCESS]: logoutRedirect,
    [actionTypes.USER_LOGIN]: login,
    [actionTypes.USER_LOGIN_PASS]: loginSuccesses,
    [actionTypes.USER_LOGIN_FAIL]: loginError,
    [actionTypes.USER_INFO_FETCH]: fetchUserInfo,
    [actionTypes.USER_INFO_FETCH_PASS]: fetchUserInfoSuccesses,
    [actionTypes.USER_TOKEN_SET]: setUserAuthToken,
    [actionTypes.USER_TOKEN_SET__PASS]: userAuthValidated,
  })

function logoutRedirect(store, action) {
  setUserToken('')
  setUserRefreshToken('')

  return {
    ...defaultState,
    isLoggedOut: true,
    loadingMessage: 'Login Required, Redirecting',
  }
}


function login(state, action) {
  return {
    ...state,
    errCode: null,
    isLoading: true,
    isLoggedOut: false,
    invalidCredentials: false,
  }
}
function loginSuccesses(state, action) {
  const jwtToken = jwtDecode(action.payload.authToken)

  return {
    ...state,
    errCode: null,
    isLoading: false,
    isLoggedOut: false,
    invalidCredentials: false,
    loadingMessage: 'Loging In',
    tokenString: action.payload.authToken,
    token: {
      userId: jwtToken.id, // nameId
    },
  }
}
function loginError(state, { type, errCode }) {
  return {
    ...state,
    errCode,
    invalidCredentials: true,
    isLoading: false,
  }
}

function fetchUserInfo(state, action) {
  return {
    ...state,
    requestUserInfo: 'loading',
  }
}


function fetchUserInfoSuccesses(state, action) {
  setUserToken(state.tokenString)

  // const userInfo = _get(action, 'userInfo', {})
  // const userTokenInfo = getUserInfo(state.tokenString)

  // const FS = (window.FS || { setUserVars: () => console.error('FS: not loaded') })

  const jwtToken = jwtDecode(state.tokenString || getToken())
  return {
    ...state,
    requestUserInfo: 'done',
    loadingMessage: 'Logged In',
    invalidCredentials: false,
    info: {
      ...action.userInfo,
    },
    token: {
      userId: jwtToken?.id, // nameId
    },
  }
}

function setUserAuthToken(store, action) {
  const userInfo = {
    userId: action?.token?.userId, // nameId
  }

  // const sessionUpdateOn = store.sessionUpdateOn
  //   ? store.sessionUpdateOn
  //   : moment().unix()

  return {
    ...defaultState,
    info: userInfo,
    tokenString: action.tokenString,
    loadingMessage: 'Authenticating',
    ...userInfo,
  }
}

function userAuthValidated(store, action) {
  setUserToken(store.tokenString)

  const jwtToken = jwtDecode(store.tokenString)

  return {
    ...store,
    loadingMessage: 'Loging In',
    userId: jwtToken.id, // nameId
  }
}

export default combineReducers({
  current: userCurrentReducer,
  // users: manageUsersReducer,
})
