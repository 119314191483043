import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { withThemed } from '~utils/themed'
//
import UserMenu from '~CON/App/Header/HeaderControls/UserMenu'

import SideMenu from './SideMenu'
//
export default function Navigator(props) {
  const ref = React.useRef(null)
  return (
    <NavContainer>
      <NavHeader>
        <UserMenu/>
      </NavHeader>
      <div ref={ref}>
        <NavSide>
          <SideMenu/>
        </NavSide>
      </div>
      <NavContent>
        {props.children}
      </NavContent>
    </NavContainer>
  )
}
const NavContainer = styled.div`
  /* element styls */
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x:hidden;

  /* Grid Container */
  display: grid;
  align-items: stretch;
  grid-row-gap: 0px;
  grid-template-areas:
      "navHeader navHeader"
      "navBar appContent";
      min-height: 100vh;
  grid-template-rows:
      45px
      auto;
  grid-template-columns: 183px calc(100vw - 200px);
`
const NavHeader = styled.div`
  /* element Styles */
  height: 45px;
  z-index: 998;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background: #fff;
  border-bottom: 1px #EAE4E4 solid;

  /* Grid Item */
  grid-area: navHeader;
`
const NavSide = withThemed((props) => {
  return (
    <motion.div
      ref={props.innerRef}
      key="NavSideMenu"
      className={props.className}
    >
      {props.children}
    </motion.div>
  )
})`
    /* Element Styles */
    //left: 0;
    width: 183px;
    height: 100vh;
    position: fixed;
    background: #fff;
    border-right: 1px #EAE4E4 solid;
    z-index: 998;
    top: 0;
    // padding-top: 45px;

    /* Grid Item */
    grid-area: navBar;

    /* flex container */
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-content: flex-start;
    align-items: center;

`
const NavContent = styled.div`
  /* Element Styles */
  //margin-left: 45px;
  margin-left: 0px;
  margin-right: 0px;
  /* Grid Item */
  grid-area: appContent;
`
