/* eslint-disable no-unused-vars */
import { css } from 'styled-components'

import {
  withAccents,
} from '../utils'


/* ***************************************************
   /\   /\__ _ _ __ ___
   \ \ / / _` | '__/ __|
    \ V / (_| | |  \__ \
     \_/ \__,_|_|  |___/
*************************************************** */

export default function layoutVars(vars) {
  const layout = vars?.layout || {}

  layout.header = {
    logo: {
      img: {
        height: '20px',
        marginTop: '10px',
      },
    },
  }

  layout.Login = {
    logo: {
      img: {
        height: '20px',
      },
    },
  }

  layout.roundCorner = {
    xs: withAccents('2px', {
      left: '2px 0px 0px 2px',
      right: '0px 2px 2px 0px',
      top: '2px 2px 0px 0px',
      bottom: '0px 0px 2px 2px',
    }),
    sm: withAccents('4px', {
      left: '4px 0px 0px 4px',
      right: '0px 4px 4px 0px',
      top: '4px 4px 0px 0px',
      bottom: '0px 0px 4px 4px',
    }),
    md: withAccents('8px', {
      left: '8px 0px 0px 8px',
      right: '0px 8px 8px 0px',
      top: '8px 8px 0px 0px',
      bottom: '0px 0px 8px 8px',
    }),
    lg: withAccents('16px', {
      left: '16px 0px 0px 16px',
      right: '0px 16px 16px 0px',
      top: '16px 16px 0px 0px',
      bottom: '0px 0px 16px 16px',
    }),
    xl: withAccents('20px', {
      left: '20px 0px 0px 20px',
      right: '0px 20px 20px 0px',
      top: '20px 20px 0px 0px',
      bottom: '0px 0px 20px 20px',
    }),
    xxl: withAccents('30px', {
      left: '30px 0px 0px 30px',
      right: '0px 30px 30px 0px',
      top: '30px 30px 0px 0px',
      bottom: '0px 0px 30px 30px',
    }),
  }

  layout.boxShadow = {
    cards: '0px 12px 16px 0px rgba(0,0,0,0.16)',
  }

  return layout
}
