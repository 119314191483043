import styled, { css } from 'styled-components'

/**
 *
 *
 * @example
 * <EmptyCard  />
 */

const withSep = css`
  border-bottom: 1px solid ${({ theme }) => theme.bgContrast};;
 `

const noSep = css`
  border: none;
`

export const FormGroup = styled.div`
    ${({ noSeprator }) => (noSeprator ? noSep : withSep)}
    margin-top: 20px;
    padding-bottom: ${({ noSeprator }) => (noSeprator ? '0' : '15px')} ;
`
