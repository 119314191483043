import styled, { css } from 'styled-components'

/**
 *
 * @param {object} props
 * @param {boolen} props.warn - warning highlight
 * @param {boolen} props.error - Error highlight
 *
 * @example
 * <Input warn />
 */

export const Label = styled.span`
  padding: 5px 10px;
  display: inline-block;
  font-size:12px !important;
  border-radius: ${({ theme }) => theme.roundCorner};
  font-weight: 700;
  p{
    font-size: 12px;
    text-align: ${({ theme }) => theme.toggleDir({ rtl: 'right', ltr: 'left' })};
  }

  ${({ success }) => success
    && css`
      background: ${({ theme }) => theme.hintGreenBg};
      color: #3C763D;
      p {
        color: ${({ theme }) => theme.hintGreenTxtColor};
      }
  `};

${({ info }) => info
  && css`
     background: ${({ theme }) => theme.hintYellowBg};
     color: ${({ theme }) => theme.hintYellowBorder};
     p {
        color: ${({ theme }) => theme.hintYellowTxtColor};
     }
  `};

${({ danger }) => danger
  && css`
     background: ${({ theme }) => theme.hintRedBg};
     color: ${({ theme }) => theme.hintRedBorder};
     color: ${({ theme }) => theme.hintRedTxtColor};
     p {
      color: ${({ theme }) => theme.hintRedTxtColor};
     }
  `};
`
