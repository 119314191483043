import request from 'superagent'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function extractPrefixes(payload, configType) {
  return new Promise((resolve, reject) => {
    request
      .get(`${API_URL}/api/v1/global-config/prefixes/${configType}/csv`)
      .set('Authorization', `bearer ${getToken()}`)
      .set('Content-Type', 'text/csv')
      .send(payload)
      .then((resp) => {
        const newResp = resp?.text
        resolve(newResp)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
