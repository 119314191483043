import { createTheme } from '~plumbing/themes/utils'

import cequensRed from '../cequensRed'

import colorVars from './colors.var'

import kfmcLogo from './images/kfmcLogoAlt.png'
import kfmcLogoAlt from './images/kfmcLogo.png'
import kfmcLogoDark from './images/kfmcLogoDark.png'

const images = {
  logo: kfmcLogo,
  logoAlt: kfmcLogoAlt,
  logoDark: kfmcLogoDark,
}

export default createTheme('KFMC')
  .extends(cequensRed)
  .var({
    images,
    colors: colorVars,
  })
  .create()
