import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function updateproviderData(payload) {
  const providerName = payload.name
  const { name, prefixes, ...rest } = payload
  return new Promise((resolve, reject) => {
    request
      .post(`${API_URL}/api/v1/global-config/providers/${providerName}`)
      .set('Authorization', `bearer ${getToken()}`)
      .send(rest)
      .then((resp) => {
      })
      .catch((err) => {
        reject(err)
      })
  })
}
