import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function getPrefixes(configType) {
  return new Promise((resolve, reject) => {
    const config = configType.toLowerCase()
    request
      .get(`${API_URL}/api/v1/global-config/prefixes/${config}`)
      .set('Authorization', `bearer ${getToken()}`)
      .then((resp) => {
        const newResp = resp?.body?.value
        resolve(newResp)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
