import { camelCase as _camelCase, get as _get } from 'lodash'
import { renameFunc } from 'CQS/plumbing/utils'

export const actionsTypes = {
  ADD_RULES: 'discovery/Rules/AddRules',
  ADD_RULES_SUCCESS: 'discovery/Rules/AddRulesSucess',
  ADD_RULES_FAILED: 'discovery/Rules/AddRulesFail',
  EDIT_RULE: 'discovery/Rules/EditRule',
  EDIT_RULE_SUCCESS: 'discovery/Rules/EditRuleSucess',
  EDIT_RULE_FAILED: 'discovery/Rules/EditRuleFail',
  FETCH_ALL_RULES_LIST: 'discovery/Rules/RulesList',
  FETCH_ALL_RULES_LIST_SUCCESS: 'discovery/Rules/RulesListSucess',
  FETCH_ALL_RULES_LIST_FAILED: 'discovery/Rules/RulesListFail',
  DELETE_RULE: 'discovery/Rules/DeleteRule',
  DELETE_RULE_SUCCESS: 'discovery/Rules/DeleteRuleSucess',
  DELETE_RULE_FAILED: 'discovery/Rules/DeleteRuleFail',
  FETCH_DEF_RULE: 'discovery/Rules/DefRule',
  FETCH_DEF_RULE_SUCCESS: 'discovery/Rules/DefRuleSucess',
  FETCH_DEF_RULE_FAILED: 'discovery/Rules/DefRuleFail',
  UPDATE_DEF_RULE: 'discovery/Rules/UpdateDefRule',
  UPDATE_DEF_RULE_SUCCESS: 'discovery/Rules/UpdateDefRuleSucess',
  UPDATE_DEF_RULE_FAILED: 'discovery/Rules/UpdateDefRuleFail',
  REFRESH_RULE: 'discovery/Rules/RefreshRule',
  REFRESH_RULE_SUCCESS: 'discovery/Rules/RefreshRuleSucess',
  REFRESH_RULE_FAILED: 'discovery/Rules/RefreshRuleFail',
}
// helper function automatically generate ACTION CREATORS!
export const actions = Object.keys(actionsTypes)
  .reduce((accum, id) => {
    const creatorName = _camelCase(id)

    const creatorFunction = function _(payload, meta) {
      return {
        type: _get(actionsTypes, id),
        payload,
        meta,
      }
    }

    // eslint-disable-next-line no-param-reassign
    accum[creatorName] = renameFunc(creatorFunction, creatorName)
    return accum
  }, {})
