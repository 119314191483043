import request from 'superagent'
import moment from 'moment'
// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'qs'

// eslint-disable-next-line import/no-cycle
import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function masterDetailsData(payload) {
  return new Promise((resolve, reject) => {
    const query = qs.stringify(payload)
    request
      .get(`${API_URL}/api/v1/master-details?${query}`)
      .set('Authorization', `bearer ${getToken()}`)
      .then((resp) => {
        resolve(resp.body)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
