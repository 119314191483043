/* eslint-disable */
import {
  has as _has,
  isObject as _isObject
} from 'lodash'

import { getUserInfo } from '~CON/User/userHelper'


export function integrateHotjar() {
  if (process.env.REACT_APP__DEV || process.env.REACT_APP__QCToolBar) {
    return null
  }

  const uat_hjid = 1020043
  const grn_hjid = 587531

  const hjid = process.env.REACT_APP__UAT
      ? uat_hjid
      : grn_hjid

  (function (h, o, t, j, a, r) {
    h.hj = h.hj || function () {(h.hj.q = h.hj.q || []).push(arguments)};
    h._hjSettings = { hjid, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}

export  function integrateFullstory() {
  if (process.env.REACT_APP__DEV || process.env.REACT_APP__QCToolBar) {
    window.FS = {
      setUserVarsMemo: () => null
    }

    return null
  }

  window['_fs_debug'] = false;
  window['_fs_host'] = 'fullstory.com';
  window['_fs_org'] = 'FN65T';
  window['_fs_namespace'] = 'FS';

  (function (m, n, e, t, l, o, g, y) {
    if (e in m) {
      if (m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');}
      return;
    }
    g = m[e] = function (a, b) {g.q ? g.q.push([a, b]) : g._api(a, b);};
    g.q = [];
    o = n.createElement(t);
    o.async = 1;
    o.src = 'https://' + _fs_host + '/s/fs.js';
    y = n.getElementsByTagName(t)[0];
    // y.parentNode.insertBefore(o, y);
    g.identify = function (i, v) {
      g(l, { uid: i });
      if (v) g(l, v)
    };
    g.setUserVars = function (v) {g(l, v)};
    g.event = function (i, v, s) {g('event', { n: i, p: v, s: s })};
    g.shutdown = function () {g("rec", !1)};
    g.restart = function () {g("rec", !0)};
    g.consent = function (a) {g("consent", !arguments.length || a)};
    g.identifyAccount = function (i, v) {
      o = 'account';
      v = v || {};
      v.acctId = i;
      g(o, v)
    };
    g.clearUserCookie = function () {};
  })(window, document, window['_fs_namespace'], 'script', 'user');

  const { hasUser, ...u } = getUserInfo()

  if (hasUser) {
    u.appVersion = `v${VERSION}` // eslint-disable-line
    u.appRevision = GIT_REV?.long || GIT_REV?.short // eslint-disable-line
    FS.setUserVars(u)
  }

  const __setUserVarsMemo__ = {}
  FS.setUserVarsMemo = (data) => {
    const key = Object
      .keys(data)
      .sort()
      .map((k) => `${k}:${data[k]}`)
      .join(';')

    if (_has(__setUserVarsMemo__, key)) {
      // console.debug('[setUserVarsMemo] match found, return null', key, __setUserVarsMemo__)
      return null
    }

    // console.debug('[setUserVarsMemo] match not found, calling setUserVars', data)

    __setUserVarsMemo__[key] = true
    FS.setUserVars(data)
  }
}
