import React from 'react'
import ReactDOM from 'react-dom' //eslint-disable-line
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux' //eslint-disable-line
import Content from './Content'
import Toolbar from './Toolbar'

export default class ControlBar extends React.Component { //eslint-disable-line
  state = {
    config: {},  //eslint-disable-line
    render: [], //eslint-disable-line
  }

  getChildContext() {
    return {
      ControlBar: {
        upsertContent: this.upsertContent,
        removeContent: this.removeContent,
      },
    }
  }

  getConfig = () => {
    const barConf = this.state.render.reduce((accum, key) => {
      const cfg = this.state.config[key]

      const isOpen = cfg.open
      const hasClose = typeof cfg.onClose === 'function'

      const onClose = (isOpen && hasClose)
        ? [cfg.onClose]
        : []

      return {
        ...accum,
        ...(isOpen ? cfg : {}),
        open: (cfg.open !== false) || accum.open,
        onClose: [...accum.onClose, ...onClose],
      }
    }, {
      onClose: [],
    })

    barConf.children = undefined

    return barConf
  }

  upsertContent = (params, key) => {
    const k = key || Date.now().toString()
    this.setState((prevState) => {
      return ({
        config: {
          ...prevState.config,
          [k]: {
            ...(prevState.config[key] || {}),
            ...params,
          },
        },
        render: [
          ...prevState.render.filter((item) => item !== k),
          k,
        ],
      })
    })
    return k
  }

  removeContent = (key) => {
    this.setState((prevState) => {
      const config = {
        ...prevState.config,
      }
      delete config[key]
      return ({
        config,
        render: prevState.render
          .filter((item) => item !== key),
      })
    })
  }

  handleClose = () => {
    const conf = this.getConfig()
    conf.onClose.forEach((onCloseCallback) => onCloseCallback())
  }

  render() {
    const conf = this.getConfig()

    const toolbar = (
      <Toolbar
        isOpen={conf.open}
        handleClose={this.handleClose}
        hideClose={conf.hideClose} // fixMe: close button will be in header as optional props
      />
    )

    return (
      <React.Fragment>
        <Content
          {...this.state}
          conf={conf}
          footer={null}
          handleClose={this.handleClose}
          header={toolbar}
        />
        {this.props.children}
      </React.Fragment>
    )
  }
}

ControlBar.childContextTypes = {
  ControlBar: PropTypes.shape({
    upsertContent: PropTypes.func.isRequired,
    removeContent: PropTypes.func.isRequired,
  }).isRequired,
}

ControlBar.propTypes = {
  children: PropTypes.object.isRequired, //eslint-disable-line
  hideClose: PropTypes.bool, // eslint-disable-line
}
