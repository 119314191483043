import { camelCase as _camelCase, get as _get } from 'lodash'
import { renameFunc } from 'CQS/plumbing/utils'

export const actionsTypes = {
  FETCH_ALL_PREFIXS: 'discovery/Config/Prefixes',
  FETCH_ALL_PREFIXS_SUCCESS: 'discovery/Config/PrefixesSucess',
  FETCH_ALL_PREFIXS_FAILED: 'discovery/Config/PrefixesFail',
  FETCH_CUSTOMER_CONFIG: 'discovery/CustomerConfig/CustomerConfig',
  FETCH_CUSTOMER_HLR_CONFIG: 'discovery/CustomerConfig/CustomerHLRConfig',
  FETCH_CUSTOMER_MNP_CONFIG: 'discovery/CustomerConfig/CustomerMNPConfig',
  FETCH_CUSTOMER_CONFIG_SUCCESS: 'discovery/CustomerConfig/CustomerConfigSucess',
  FETCH_CUSTOMER_HLR_CONFIG_SUCCESS: 'discovery/CustomerConfig/CustomerConfigHLRSucess',
  FETCH_CUSTOMER_MNP_CONFIG_SUCCESS: 'discovery/CustomerConfig/CustomerConfigMNPSucess',
  FETCH_CUSTOMER_CONFIG_FAILED: 'discovery/CustomerConfig/CustomerConfigFail',
  FETCH_CUSTOMER_HLR_CONFIG_FAILED: 'discovery/CustomerConfig/CustomerHLRConfigFail',
  FETCH_CUSTOMER_MNP_CONFIG_FAILED: 'discovery/CustomerConfig/CustomerMNPConfigFail',
  FETCH_DEFAULT_CONFIG: 'discovery/CustomerConfig/DefaultConfig',
  FETCH_DEFAULT_CONFIG_SUCCESS: 'discovery/CustomerConfig/DefaultConfigSucess',
  FETCH_DEFAULT_CONFIG_FAILED: 'discovery/CustomerConfig/DefaultConfigFail',
  FETCH_ALL_COUNTRIES: 'discovery/Config/Countries',
  FETCH_ALL_COUNTRIES_SUCCESS: 'discovery/Config/CountriesSucess',
  FETCH_ALL_COUNTRIES_FAILED: 'discovery/Config/CountriesFail',
  ALL_NETWORKS: 'discovery/Config/Networks',
  ALL_NETWORKS_SUCCESS: 'discovery/Config/NetworksSucess',
  ALL_NETWORKS_FAILED: 'discovery/Config/NetworksFail',
  UPDATE_PREFIX: 'discovery/Config/UpdatePrefixes',
  UPDATE_PREFIX_SUCCESS: 'discovery/Config/UpdatePrefixesSucess',
  UPDATE_PREFIX_FAILED: 'discovery/Config/UpdatePrefixesFail',
  ADD_DESTNATION: 'discovery/CustomerConfig/AddDestnation',
  ADD_DESTNATION_SUCCESS: 'discovery/CustomerConfig/AddDestnationSucess',
  ADD_DESTNATION_FAILED: 'discovery/CustomerConfig/AddDestnationFail',
  DELETE_PREFIX: 'discovery/Config/DeletePrefixes',
  DELETE_PREFIX_SUCCESS: 'discovery/Config/DeletePrefixesSucess',
  DELETE_PREFIX_FAILED: 'discovery/Config/DeletePrefixesFail',
  EXPORT_PREFIXES: 'discovery/Provider/ExportPrefixes',
  EXPORT_PREFIXES_SUCCESS: 'discovery/Provider/ExportPrefixesSuccess',
  EXPORT_PREFIXES_FAILED: 'discovery/Provider/ExportPrefixesFail',
}
export const actions = Object.keys(actionsTypes)
  .reduce((accum, id) => {
    const creatorName = _camelCase(id)
    const creatorFunction = function _(payload, meta) {
      return {
        type: _get(actionsTypes, id),
        payload,
        meta,
      }
    }
    // eslint-disable-next-line no-param-reassign
    accum[creatorName] = renameFunc(creatorFunction, creatorName)
    return accum
  }, {})
