import { camelCase as _camelCase, get as _get } from 'lodash'
import { renameFunc } from 'CQS/plumbing/utils'

export const actionsTypes = {
  FETCH_ALL_COVERAGES: 'discovery/Coverages/GetCoverage',
  FETCH_ALL_COVERAGES_SUCCESS: 'discovery/Coverages/GetCoverageSucess',
  FETCH_ALL_COVERAGES_FAILED: 'discovery/Coverages/GetCoverageFail',
  FETCH_ALL_PREFIXES_SAMPLES: 'discovery/Coverages/GetPrefixSamples',
  FETCH_ALL_PREFIXES_SAMPLES_SUCCESS: 'discovery/Coverages/GetPrefixSamplesSucess',
  FETCH_ALL_PREFIXES_SAMPLES_FAILED: 'discovery/Coverages/GetPrefixSamplesFail',
  COVERAGES_BY_ID: 'discovery/Coverages/GetCoveragesById',
  COVERAGES_BY_ID_SUCCESS: 'discovery/Coverages/GetCoveragesByIdSucess',
  COVERAGES_BY_ID_FAILED: 'discovery/Coverages/GetCoveragesByIdFail',
  DELETE_COVERAGES: 'discovery/Coverages/DeleteCoverages',
  DELETE_COVERAGES_SUCCESS: 'discovery/Coverages/DeleteCoveragesSucess',
  DELETE_COVERAGES_FAILED: 'discovery/Coverages/DeleteCoveragesFail',
  CREATE_SAMPLE: 'discovery/Coverages/CreateSample',
  CREATE_SAMPLE_SUCCESS: 'discovery/Coverages/CreateSampleSucess',
  CREATE_SAMPLE_FAILED: 'discovery/Coverages/CreateSampleFail',
}
export const actions = Object.keys(actionsTypes)
  .reduce((accum, id) => {
    const creatorName = _camelCase(id)

    const creatorFunction = function _(payload, meta) {
      return {
        type: _get(actionsTypes, id),
        payload,
        meta,
      }
    }

    // eslint-disable-next-line no-param-reassign
    accum[creatorName] = renameFunc(creatorFunction, creatorName)
    return accum
  }, {})
