import { camelCase as _camelCase, get as _get } from 'lodash'
import { renameFunc } from 'CQS/plumbing/utils'

export const actionsTypes = {
  FETCH_ALL_RaghiAccounts: 'discovery/GlobalManagement/RaghiAccounts',
  FETCH_ALL_RaghiAccounts_SUCCESS: 'discovery/GlobalManagement/RaghiAccountsSucess',
  FETCH_ALL_RaghiAccounts_FAILED: 'discovery/GlobalManagement/RaghiAccountsFail',
  FETCH_DEFAULT_ACCOUNT: 'discovery/GlobalManagement/DefaultAccount',
  FETCH_DEFAULT_ACCOUNT_SUCCESS: 'discovery/GlobalManagement/DefaultAccountSucess',
  FETCH_DEFAULT_ACCOUNT_FAILED: 'discovery/GlobalManagement/DefaultAccountFail',
  FETCH_RAGHI_DATABASE: 'discovery/GlobalManagement/RaghiDatabase',
  FETCH_RAGHI_DATABASE_SUCCESS: 'discovery/GlobalManagement/RaghiDatabaseSucess',
  FETCH_RAGHI_DATABASE_FAILED: 'discovery/GlobalManagement/RaghiDatabaseFail',
  FETCH_HLR_DATABASE: 'discovery/GlobalManagement/HLRDatabase',
  FETCH_HLR_DATABASE_SUCCESS: 'discovery/GlobalManagement/HLRDatabaseSucess',
  FETCH_HLR_DATABASE_FAILED: 'discovery/GlobalManagement/HLRDatabaseFail',
  FETCH_MNP_DATABASE: 'discovery/GlobalManagement/MNPDatabase',
  FETCH_MNP_DATABASE_SUCCESS: 'discovery/GlobalManagement/MNPDatabaseSucess',
  FETCH_MNP_DATABASE_FAILED: 'discovery/GlobalManagement/MNPDatabaseFail',
  FETCH_KSA_DATABASE: 'discovery/GlobalManagement/KSADatabase',
  FETCH_KSA_DATABASE_SUCCESS: 'discovery/GlobalManagement/KSADatabaseSucess',
  FETCH_KSA_DATABASE_FAILED: 'discovery/GlobalManagement/KSADatabaseFail',
  UPDATE_ALL_RAGHIACCOUNTS: 'discovery/GlobalManagement/UpdateRaghiAccounts',
  UPDATE_ALL_RAGHIACCOUNTS_SUCCESS: 'discovery/GlobalManagement/UpdateRaghiAccountsSucess',
  UPDATE_ALL_RAGHIACCOUNTS_FAILED: 'discovery/GlobalManagement/UpdateRaghiAccountsFail',
  UPDATE_DEFAULT__ACCOUNT: 'discovery/GlobalManagement/UpdateDefaultAccount',
  UPDATE_DEFAULT__ACCOUNT_SUCCESS: 'discovery/GlobalManagement/UpdateDefaultAccountSucess',
  UPDATE_DEFAULT__ACCOUNT_FAILED: 'discovery/GlobalManagement/UpdateDefaultAccountFail',
  UPDATE_HLR: 'discovery/GlobalManagement/UpdateHLR',
  UPDATE_HLR_SUCCESS: 'discovery/GlobalManagement/UpdateHLRSucess',
  UPDATE_HLR_FAILED: 'discovery/GlobalManagement/UpdateHLRFail',
  UPDATE_KSA: 'discovery/GlobalManagement/UpdateKSA',
  UPDATE_KSA_SUCCESS: 'discovery/GlobalManagement/UpdateKSASucess',
  UPDATE_KSA_FAILED: 'discovery/GlobalManagement/UpdateKSAFail',
  UPDATE_MNP: 'discovery/GlobalManagement/UpdateMNP',
  UPDATE_MNP_SUCCESS: 'discovery/GlobalManagement/UpdateMNPSucess',
  UPDATE_MNP_FAILED: 'discovery/GlobalManagement/UpdateMNPFail',
  UPDATE_RAGHI: 'discovery/GlobalManagement/UpdateRaghi',
  UPDATE_RAGHI_SUCCESS: 'discovery/GlobalManagement/UpdateRaghiSucess',
  UPDATE_RAGHI_FAILED: 'discovery/GlobalManagement/UpdateRaghiFail',
  FETCH_MNP_PLUS: 'discovery/GlobalManagement/MnpPlus',
  FETCH_MNP_PLUS_SUCCESS: 'discovery/GlobalManagement/MnpPlusSucess',
  FETCH_MNP_PLUS_FAILED: 'discovery/GlobalManagement/MnpPlusFail',
  UPDATE_MNP_PLUS: 'discovery/GlobalManagement/UpdateMnpPlus',
  UPDATE_MNP_PLUS_SUCCESS: 'discovery/GlobalManagement/UpdateMnpPlussSucess',
  UPDATE_MNP_PLUS_FAILED: 'discovery/GlobalManagement/UpdateMnpPlusFail',
}
export const actions = Object.keys(actionsTypes)
  .reduce((accum, id) => {
    const creatorName = _camelCase(id)

    const creatorFunction = function _(payload, meta) {
      return {
        type: _get(actionsTypes, id),
        payload,
        meta,
      }
    }

    // eslint-disable-next-line no-param-reassign
    accum[creatorName] = renameFunc(creatorFunction, creatorName)
    return accum
  }, {})
