
import { inCase } from '~plumbing/utils'

import { actions as actionsTypes } from './appActions'

const defaultState = {
  hasError: false,
  errorKey: null,
}

export default inCase.curry('appReducer')
  .check((s, action) => action.type)
  .otherwise((state) => state || defaultState)
  .cases({
    '@@APP_UPDATE_NEW': appUpdateNew,
    '@@APP_UPDATE_SUCCESSFUL': appUpdateSuccessful,
    '@@APP_UPDATE_SUCCESSFUL_DONE': appUpdateSuccessfulDone,
    [actionsTypes.APP_THROW_ERROR]: appErrorMutation,
  })


function appErrorMutation(state, action) {
  return {
    ...state,
    hasError: true,
    errorKey: action.errorKey,
  }
}


function appUpdateNew(state, action) {
  return {
    ...state,
    ...action.payload,
    handleAppUpdate: action.updateApp,
  }
}


function appUpdateSuccessful(state, action) {
  return {
    ...state,
    ...action.payload,
    hasNewAppUpdate: false,
    handleAppUpdate: undefined,
    isAppUpdateSuccessful: true,
    hasNewAppUpdateForced: false,
  }
}


function appUpdateSuccessfulDone(state, action) {
  return {
    ...state,
    ...action.payload,
    hasNewAppUpdate: undefined,
    handleAppUpdate: undefined,
    isAppUpdateSuccessful: undefined,
    hasNewAppUpdateForced: undefined,
  }
}
