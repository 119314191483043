import { getUserLocaleDir } from '~plumbing/utils/i18n'

import processTemplate from './processTemplate'


/**
 *
 * @param {function} styledTagged -- styled-component tagged string function
 */
export default function themed(styledTagged, extend) {
  return (_stringParts, ..._expressions) => {
    const r = processTemplate(_stringParts, _expressions)
    const { strs, exps } = r
    if (extend) {
      return styledTagged(strs, ...exps)
        .withComponent(extend)
    }

    return styledTagged(strs, ...exps)
  }
}


themed.__getUserLocaleDir = getUserLocaleDir
themed.getUserLocaleDir = () => themed.__getUserLocaleDir()
themed.setUserLocaleDirGetter = (func) => { themed.__getUserLocaleDir = func }
