import { camelCase as _camelCase, get as _get } from 'lodash'
import { renameFunc } from 'CQS/plumbing/utils'

export const actionsTypes = {
  FETCH_ALL_USERS: 'discovery/UserMangement/Users',
  FETCH_ALL_USERS_SUCCESS: 'discovery/UserMangement/UsersSucess',
  FETCH_ALL_USERS_FAILED: 'discovery/UserMangement/UsersFail',
  DELETE_USER: 'discovery/UserMangement/DeleteUser',
  DELETE_USER_SUCCESS: 'discovery/UserMangement/DeleteUserSuccess',
  DELETE_USER_FAILED: 'discovery/UserMangement/DeleteUserFail',
  UPDATE_USER: 'discovery/UserMangement/UpdateUser',
  UPDATE_USER_SUCCESS: 'discovery/UserMangement/UpdateUserSuccess',
  UPDATE_USER_FAILED: 'discovery/UserMangement/UpdateUserFail',
  ADD_USER: 'discovery/UserMangement/AddUser',
  ADD_USER_SUCCESS: 'discovery/UserMangement/AddUserSuccess',
  ADD_USER_FAILED: 'discovery/UserMangement/AddUserFail',
}
export const actions = Object.keys(actionsTypes)
  .reduce((accum, id) => {
    const creatorName = _camelCase(id)

    const creatorFunction = function _(payload, meta) {
      return {
        type: _get(actionsTypes, id),
        payload,
        meta,
      }
    }

    // eslint-disable-next-line no-param-reassign
    accum[creatorName] = renameFunc(creatorFunction, creatorName)
    return accum
  }, {})
