import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function getSamplesDataById(payload) {
  return new Promise((resolve, reject) => {
    request
      .get(`${API_URL}/api/v1/providers/sample-queries/${payload}`)
      .set('Authorization', `bearer ${getToken()}`)
      .then((resp) => {
        const newResp = resp?.body.query
        resolve(newResp)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
