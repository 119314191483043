import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function updateDefRule(payload) {
  const Name = payload.ruleName
  const { maxRequest, blockerTime } = payload
  const form = new FormData()
  form.append('maxRequests', maxRequest)
  form.append('blockTime', blockerTime)
  return new Promise((resolve, reject) => {
    request
      .post(`${API_URL}/api/v1/flooding-rules/${Name}`)
      .set('Authorization', `bearer ${getToken()}`)
      .send(form)
      .then((resp) => {
      })
      .catch((err) => {
        reject(err)
      })
  })
}
