/* eslint-disable import/no-cycle */
import Rx from 'rxjs' // eslint-disable-line

import {
  observableFromPromiseProperties,
} from 'CQS/plumbing/utils'
import { actions as appActions } from '~CON/App/appActions'

import { actionsTypes, actions as usageActions } from './UsageActions'
import apis from '../apis'

const usagesApis = observableFromPromiseProperties(apis, Rx)

export default [
  getUsageData,
]

export function getUsageData(action$, { getState }) {
  return action$.ofType(actionsTypes.GET_USAGE)
    .switchMap(({ payload }) => {
      return usagesApis
        .getUsages(payload)
        .map((res) => {
          return usageActions.getUsageSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.GET_USAGE_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
