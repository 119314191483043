import styled from 'styled-components'
import { inCase } from '~CON/plumbing/utils'

/**
 *
 *
 * @example
 * <Indicator  />
 */
export const Indicator = styled.i`
  color: ${({ type, theme }) => {
    return inCase
      .curry('indicatorType')
      .condition(type)
      .otherwise()
      .cases({
        error: theme.redColor,
        success: theme.greenColor,
        warning: theme.orangeColor,
        info: theme.var.colors.helper.greys.grey80,
        brand: theme.brandMain,
      })
  }} !important;
  margin-${({ theme }) => theme.toggleDir({ rtl: 'left', ltr: 'right' })}: ${({ withText }) => (withText ? '5px' : '0px')};
  font-size:14px;
  font-weight: bolder;
`
