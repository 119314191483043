import React from 'react'
import PropTypes from 'prop-types'
// import { Icon, IconsLayers } from 'fa-shorthand.macro'

import styled from '~utils/themed'

const MaintainanceStateWrapper = styled.div`
  width: 60%;
  height: 100vh;
  margin: auto;
  font-size: 16px;
  padding-top: 30vh;
  text-align: center;

  [class^="fa-"] {
    font-size: 30px;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.brandBg};
  }
`

export default function MaintainanceState(props) {
  if (props.hide) {
    return null
  }
  return (
    <MaintainanceStateWrapper>
      {/* <IconsLayers style={{ width: '100%' }}>
        <Icon icon={Icon.solid.cloud} style={{ fontSize: '70px', opacity: '0.4' }}/>
        <Icon icon={Icon.solid.tools}/>
      </IconsLayers> */}
      <h1>{props.hint}</h1>
      {props.children}
      <h2>{props.title}</h2>
      <p>{props.description}</p>
    </MaintainanceStateWrapper>
  )
}


MaintainanceState.propTypes = {
  hide: PropTypes.bool,
  title: PropTypes.string,
  hint: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node, // eslint-disable-line
}


MaintainanceState.defaultProps = {
  hide: false,
  title: null,
  hint: null,
  description: null,
}
