import styled, { css } from 'styled-components'

/**
 *
 * @param {object} props
 *
 *
 * @example
 * <Button />
 * <WiredButton />
 */
export const Button = styled.button`
  display: inline-block;
  border: 1px solid ${({ isTransparent }) => (!isTransparent ? '#e6e8e9' : 'transparent')};
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  padding: ${({ sm }) => (sm ? '0px 10px' : '5px 10px')};
  font-size:  12px;
  border-radius: ${({ theme }) => theme.roundCorner};
  height: ${({ sm }) => (sm ? '32px' : '40px')};
  user-select: none;
  position: relative;
  outline: none;
  text-decoration: none;
  background-color: ${({ theme, isTransparent }) => (!isTransparent ? theme.btnPrimary : 'transparent')};
  color: ${({ theme, isTransparent }) => (!isTransparent ? theme.btnPrimaryLabel : theme.btnPrimary)};
  margin-${({ theme }) => theme.dirFloat}:${({ noMargin }) => (noMargin ? '0' : '10px')};
  transition: ease-in-out all .3s;
  text-align:center;
  float: ${({ theme, centered }) => !centered && theme.toggleDir({ rtl: 'left', ltr: 'right' })};
  height: auto !important;
  line-height: ${({ sm }) => (sm ? '32px' : '27px')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  // have off is optional via props
  ${({ noHoverStyle }) => (noHoverStyle ? null : css`
     &:hover {
      background-color: ${({ theme }) => theme.btnPrimaryHover};
      border-color: ${({ theme }) => theme.btnPrimaryHover};
     }
  `)}
  // have off is optional via props
  ${({ isTransparent }) => (isTransparent && css`
     &:hover {
        color: ${({ theme }) => theme.btnPrimaryHover} !important;
        background: transparent !important;
        border-color: transparent !important;
     }
  `)}
  ${({ disabled }) => (!disabled ? null : css`
  background-color: ${({ theme }) => theme.btnDisabled} !important;
  color: ${({ theme }) => theme.btnDisabledLabel} !important;
  border-color: ${({ theme, isTransparent }) => (!isTransparent ? theme.btnDisabled : 'transparent')} !important;
  &:hover {
    background-color: ${({ theme }) => theme.btnDisabled} !important;
  color: ${({ theme }) => theme.btnDisabledLabel} !important;
  border-color: ${({ theme }) => theme.btnDisabled} !important;
  text-decoration: none;
  }
  `)}

  ${({ isVisible }) => (isVisible ? css`
    opacity:1
  ` : css`
    opacity:0
  `)}

   &:focus {
    text-decoration: none;
    }

i {
  margin-${({ theme }) => theme.dirFloatRev}: 10px;
  display:inline-block;
  font-size:14px;
  float:${({ theme }) => theme.dirFloat};
  line-height: 25px;
  //margin-top: 6px;
}

.spin{
  -webkit-animation:spin .7s linear infinite;
  -moz-animation:spin .7s linear infinite;
  animation:spin .7s linear infinite;
  font-size:14px;
  float: none;
}

  @-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
  }

  @-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
    }
  }
`

export const WiredButton = Button.extend`
  background-color: ${({ theme }) => theme.btnAlt};
  color: ${({ theme }) => theme.btnAltLabel};
  border-color: ${({ theme }) => theme.btnAltHover};
  i{
    margin-${({ theme }) => theme.dirFloatRev}: 10px;
    display:inline-block;
    font-size:14px;
    float:${({ theme }) => theme.dirFloat};
    //margin-top: 6px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.btnAltHover};
    border-color: ${({ theme }) => theme.btnAltHover};
  }
  &:focus {
  text-decoration: none;
  }
`

export const WiredLink = WiredButton.extend`
/* wiredLink styles */
  i {
    line-height: 32px;
  }
&:hover, &:active {
  text-decoration: none;
}
`.withComponent('a')
