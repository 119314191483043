import 'moment/locale/en-gb'
import moment from 'moment'
import locale from 'react-intl/locale-data/en'

import messages from './en.messages.json'
import antd from './en.antd'

moment.locale('en-gb')

export default {
  messages,
  locale,
  antd,
  code: 'en',
}
