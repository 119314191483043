import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function updatepassword(payload) {
  return new Promise((resolve, reject) => {
    request
      .patch(`${API_URL}/api/v1/auth/users/update-password`)
      .set('Authorization', `bearer ${getToken()}`)
      .send(payload)
      .then((resp) => {
        resolve(resp)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
