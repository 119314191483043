/* eslint-disable max-len */
import React, { Suspense, lazy } from 'react'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isEmpty as _isEmpty } from 'lodash'
import { withRouter, Switch, Route } from 'react-router-dom'
import { getUserToken, getUserJWTObject } from '~CON/User/userHelper'
import { userActions } from '~plumbing/api/actions'
import jwtDecode from '~utils/jwtDecoder'
import withFeatCtrl from './FeatCtrl/FeatCtrl'
import './Discovery/helpers/Assets/shared.less'

const ConfigComponent = lazy(() => import('~CON/Discovery/ConfigList/ConfigRoutes'))
const ViewConfig = lazy(() => import('~CON/Discovery/Sharedcomponents/Components/ViewConfig'))
const EditConfig = lazy(() => import('~CON/Discovery/Sharedcomponents/Components/EditConfig'))
const AddConfig = lazy(() => import('~CON/Discovery/Sharedcomponents/Components/AddConfig'))
const ConfirmConfig = lazy(() => import('~CON/Discovery/Sharedcomponents/Components/ConfirmConfig'))
const CustomerManagement = lazy(() => import('~CON/Discovery/CustomerManagement/CustomerManagementRoutes'))
const GlobalManagement = lazy(() => import('~CON/Discovery/GlobalManagement/GlobalManagementRoutes'))
const Provider = lazy(() => import('~CON/Discovery/Provider/ProviderRoutes'))
const Request = lazy(() => import('~CON/Discovery/Requests/RequestRoutes'))
const Rules = lazy(() => import('~CON/Discovery/Rules/RulesRoutes'))
const MasterDetails = lazy(() => import('~CON/Discovery/MasterDetails/MasterDetailsRoutes'))
const Coverage = lazy(() => import('~CON/Discovery/CoverageTesting/Components/Coverage'))
const AddCoverage = lazy(() => import('~CON/Discovery/CoverageTesting/Components/AddCoverage'))
const ViewCoverage = lazy(() => import('~CON/Discovery/CoverageTesting/Components/ViewCoverage'))
const UserManagement = lazy(() => import('~CON/Discovery/UserManagement/UserManagementRoutes'))
const Usage = lazy(() => import('~CON/Discovery/Usage/UsageRoutes'))
const Audit = lazy(() => import('~CON/Discovery/AuditTrail/AuditRoutes'))
const changepassword = lazy(() => import('~CON/Discovery/ProfileSetting/ChangePassword/Components/changepassword'))
const ErrorPage = lazy(() => import('~plumbing/components/ErrorPages/ErrorPages'))
const cleansing = lazy(() => import('~CON/Discovery/Cleansing/Components/Cleansing'))
const cleansingProcess = lazy(() => import('~CON/Discovery/Cleansing/Components/cleansingProcess'))

function AppRoutes() {
  return (
    <>
      {/* <meta charset="utf-8" /> */}
      <Switch>
        <Suspense fallback>
          <Route exact path="/" component={ConfigComponent} />
          <Route exact path="/Discovery/:configType" component={ConfigComponent} />
          <Route path="/Discovery/ViewConfig" component={ViewConfig} />
          <Route path="/Discovery/EditConfig" component={EditConfig} />
          <Route path="/Discovery/AddConfig" component={AddConfig} />
          <Route path="/Discovery/ConfimConfig" component={ConfirmConfig} />
          <Route path="/Discovery/CustomerManagement" component={CustomerManagement} />
          <Route path="/Discovery/GlobalManagement" component={GlobalManagement} />
          <Route path="/Discovery/Provider" component={Provider} />
          <Route path="/Discovery/Requests" component={Request} />
          <Route path="/Discovery/Rules" component={Rules} />
          <Route path="/Discovery/NumberHistory" component={MasterDetails} />
          <Route path="/Discovery/CoverageTesting" component={Coverage} />
          <Route path="/Discovery/AddCoverage" component={AddCoverage} />
          <Route path="/Discovery/ViewCoverage" component={ViewCoverage} />
          <Route path="/Discovery/UserManagement" component={UserManagement} />
          <Route path="/Discovery/Usage" component={Usage} />
          <Route path="/Discovery/Audits" component={Audit} />
          <Route path="/Discovery/Profile" component={changepassword} />
          <Route path="/Discovery/Cleansing" component={cleansing} />
          <Route path="/Discovery/cleansingProcess" component={cleansingProcess} />
          <Route path="/Discovery/404" component={ErrorPage.NotFound} />
        </Suspense>
      </Switch>
    </>
  )
}

function mapStateToProps(store) {
  // eslint-disable-line
  const currentUserInfo = store.User?.current?.info
  const currentUserToken = store.User?.current?.token
  return {
    currentUserInfo,
    currentUserToken,
  }
}

function mapFeatToProps(flags) {
  const withVoiceAlert = flags?.VOICE_ALERT_CAMPAIGN || false

  return {
    withVoiceAlert,
  }
}

export default withRouter(connect(mapStateToProps)(withFeatCtrl(mapFeatToProps, AppRoutes)))

export function requireAuth() {
  // eslint-disable-line
  // eslint-disable-next-line no-shadow
  function mapStateToProps(store) {
    // eslint-disable-line
    return store
  }

  function mapDispatchToProps(dispatch, ownProps) {
    // eslint-disable-line
    return {
      userActions: bindActionCreators(userActions, dispatch),
      fetchAccountServices: (...args) => {
        dispatch(userActions.fetchAccountServices(...args))

        // eslint-disable-next-line no-unused-expressions
        ownProps?.fetchAccountServices?.(...args)
      },
      fetchSubAccounts() {
        dispatch(userActions.fetchAccountSubsListing())

        // eslint-disable-next-line no-unused-expressions
        ownProps?.fetchSubAccounts?.()
      },
    }
  }

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthRequired))
}

// extras

function AuthRequired(props) {
  const { User, history } = props
  const { search, pathname } = history.location

  if (search) {
    const { k } = queryString.parse(search)

    if (k) {
      const jwtToken = jwtDecode(k) || {}

      props.userActions.authUserToken(k, jwtToken)

      props.history.push(pathname)
    }
  } else {
    const userToken = getUserToken()
    const userJWK = getUserJWTObject()
    const hasToken = !_isEmpty(userToken)
    const notLoggedIn = !User?.current?.token?.userId && !User.current.tokenString

    if (hasToken && notLoggedIn) {
      props.userActions.authUserToken(userToken, userJWK)
    } else if (!hasToken && notLoggedIn) {
      const notLoginRoute = window.location.pathname !== '/login'

      if (notLoginRoute && !AuthRequired.__willRedirect) {
        AuthRequired.__willRedirect = true

        props.userActions.authUserTokenFailed('No Token')

        window.location = '/login'
      }
    }

    if (User.current.tokenString) {
      if (!props.User.current.requestUserInfo) {
        props.userActions.fetchUserInfo()
        props.fetchAccountServices()
        props.fetchSubAccounts()
      }
    }
    return props.children
  }
}
