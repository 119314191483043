import styled from 'styled-components'

/**
 *
 * @param {object} props
 *
 *
 * @example
 * <Text />
 * <Text />
 */

export const Text = styled.span`
  color : ${({ theme }) => theme.brandMain};
`
