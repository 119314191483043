/* global VERSION */
import uuid1 from 'uuid/v1'
import uuid4 from 'uuid/v4'
import moment from 'moment'

import base64 from '~utils/base64'

import lkOps from './lsOps'


export const con = (function conStore(ls, prefix) {
  const createItem = lkOps(prefix, {
    scramble: true,
    encrypt: true,
  })

  return {
    version: createItem(
      'web.version',
      `v${VERSION}`,
      {
        encrypt: false,
        scramble: false,
        plainValue: true,
      },
    ),

    featflgs: createItem(
      'feat.flg',
      null,
      {
        encrypt: false,
        scramble: false,
        plainValue: true,
      },
    ),

    appUpdatePending: createItem(
      'app.update.pending',
    ),

    loginToken: createItem(
      'usr.login.token.digest',
      { loggedout: true },
      {
        preClear: (/* prevValue */) => {
          return { loggedout: true }
        },
        postGet: (currentValue) => {
          if (currentValue?.loggedout) {
            return null
          }

          return currentValue
        },
        extensions: {
          logout({ actions: { clear } }) {
            clear()
            if (window.location.pathname !== '/login') {
              window.location = '/login'
            }
          },
        },
      },
    ),

    screenLock: createItem(
      'usr.session.lock',
      { isLocked: false },
      {
        extensions: ({ itemKey, actions }) => {
          const eventHandlers = {}
          const key = itemKey.active

          function addEventHandler(handler) {
            const ts = new Date().getTime()
            eventHandlers[ts] = handler

            return () => {
              eventHandlers[ts] = null

              delete eventHandlers[ts]
            }
          }

          window.addEventListener('message', (evt) => {
            /* eslint-disable no-restricted-globals */
            const isOrigin = true || location.origin === event.origin
            const isTarget = evt?.data?.key === key

            if (isOrigin && isTarget) {
              Object.values(eventHandlers)
                .forEach((h) => {
                  try {
                    // eslint-disable-next-line no-unused-expressions
                    h?.(evt)
                    // eslint-disable-next-line no-empty
                  } catch (e) {
                    console.error('Error: [KVS] usr.session.lock::extensions::handler', h)
                    console.error(e)
                  }
                })
            }
          })

          return {
            set() {
              console.error(new Error('[KVS] usr.session.lock::extensions:set   "not allowed use lock/unlock"'))
            },
            lock(_, { from }) {
              const data = {
                from,
                ts: new Date(),
                isLocked: true,
              }


              actions.set(data)

              window.postMessage({ key, operation: 'lock', payload: data }, location.origin)
            },
            unlock(_, { from }) {
              const data = {
                from,
                ts: new Date(),
                isLocked: false,
              }

              actions.set(data)

              window.postMessage({ key, operation: 'unlock', payload: data }, location.origin)
            },
            onChange(_, currentCB) {
              return addEventHandler(currentCB)
            },
          }
        },
      },
    ),

    tabs: createItem(
      'usr.tabs',
      {
        isBlur: false,
        isFocuse: false,
        isLocked: false,
      },
      {
        scopeId: uuid1(),
        hintKey: 'ust',
        encrypt: false,
        scramble: false,
        plainValue: true,
        removeOnBlur: false,
        removeOnUnload: true,
        resetOnFocus: false,
        resetOnLoad: true,
        onWindowBlur({ actions }) {
          const v = actions.get()
          actions.set({
            ...v,
            isBlur: true,
            isFocuse: false,
            updatedOn: Date(),
            updateBy: 'onWindowBlur',
          })
        },
        onWindowFocus({ actions }) {
          const v = actions.get()

          actions.set({
            ...v,
            isBlur: false,
            isFocuse: true,
            updatedOn: Date(),
            updateBy: 'onWindowFocus',
          })
        },
        extensions: {
          // get() {
          //   console.warn('[KVS]', 'con.tabs', 'get() not allowed')
          // },
          // set() { console.warn('[KVS]', 'con.tabs', 'set() not allowed')  },
          // reset() { console.warn('[KVS]', 'con.tabs', 'reset not allowed') },
          // clear() { console.warn('[KVS]', 'con.tabs', 'clear not allowed') },
          // remove() { console.warn('[KVS]', 'con.tabs', 'remove not allowed') },
          // getAll() { console.warn('[KVS]', 'con.tabs', 'getAll not allowed') },
          // getAll({ actions: { getAll }}) {
          //   const { items, count, ...rest } = getAll()
          //
          //   const allItems = items
          //     .filter(([k,v]) => {
          //       const val = parseJSON(v)
          //
          //       const isExpired = moment(val.updatedOn).isBefore()
          //
          //       return [k, val]
          //     })
          //
          //   return { ...rest, }
          // },
          setFocused({ actions: { get, set } }) {
            const v = get()


            set({
              ...v,
              isBlur: false,
              isFocuse: true,
              // isLocked: false,
              updatedOn: Date(),
              updateBy: 'setFocused',
            })
          },
          setLocked({ actions: { get, set } }) {
            const v = get()

            set({
              ...v,
              isLocked: true,
              updatedOn: Date(),
              updateBy: 'setLocked',
            })
          },
          setUnlock({ actions: { get, set } }) {
            const v = get()

            set({
              ...v,
              isLocked: false,
              updatedOn: Date(),
              updateBy: 'setUnlock',
            })
          },
          // setAllUnlock({ actions }) {
          //
          // },
          isLocked({ actions: { getAll } }) {
            const { thisItem } = getAll()

            const [, value] = thisItem ?? []

            const {
              isLocked,
            } = parseJSON(value, null, { isLocked: true })

            return isLocked
          },
          isFocuse({ actions: { getAll } }) {
            const { thisItem } = getAll()

            const [, value] = thisItem ?? []

            const {
              isFocuse,
            } = parseJSON(value, null, { isFocuse: null })

            return isFocuse
          },
          isOtherFocuse({ actions: { getAll } }) {
            const { items, thisItem: [thisKey] } = getAll()

            return items
              .filter(([k]) => k !== thisKey)
              .reduce((a, [, v]) => {
                const {
                  isFocuse,
                } = parseJSON(v, null, { isFocuse: false })

                // debugger // eslint-disable-line

                return a || isFocuse
              }, false)
          },
          isOtherLocked({ actions: { getAll } }) {
            const { count, items, thisItem: [thisKey] } = getAll()

            if (count === 1) {
              return false
            }

            return items
              .filter(([k]) => k !== thisKey)
              .reduce((a, [, v]) => {
                const {
                  isLocked,
                } = parseJSON(v, null, { isLocked: true })

                return a && isLocked
              }, true)
          },
          isAnyLocked({ actions: { getAll } }) {
            const { items, thisItem: [thisKey] } = getAll()

            return items
              .filter(([k]) => k !== thisKey)
              .reduce((a, [, v]) => {
                const {
                  isLocked,
                } = parseJSON(v, null, { isLocked: true })

                return a || isLocked
              }, false)
          },
          isAllLocked({ actions: { getAll } }) {
            const { items } = getAll()

            return items
              .reduce((a, [, v]) => {
                const {
                  isLocked,
                } = parseJSON(v, null, { isLocked: true })

                return a && isLocked
              }, true)
          },
        },
      },
    ),

    refreshToken: createItem(
      'usr.refresh.token.digest',
      base64.encodeURI(uuid4()),
    ),

    permaDigest: createItem(
      'usr.perma.digest',
      base64.encodeURI(uuid4()),
    ),

    userDigest: createItem(
      'usr.digest',
      '',
    ),

    visitorDigest: createItem(
      'visitor.digest',
      '',
      { scramble: false },
    ),
  }
}(localStorage, '_con_:'))

export const cqs = (function cqsStore(ls, prefix) {
  const createItem = lkOps(prefix)

  return {
    tDigest: createItem(
      't',
      base64.encodeURI(uuid4()),
    ),

    testCaseId: createItem(
      'tcK',
      '',
      {
        encrypt: false,
        scramble: false,
        plainValue: true,
      },
    ),

    debugtools: createItem(
      'debugTools',
      '',
      { readOnly: true },
    ),

    consoleLog: createItem(
      'consoleLog',
      '',
      { readOnly: true },
    ),
  }
}(localStorage, '_.cqs:'))


function parseJSON(text, reviver, fallback = null) {
  try {
    return JSON.parse(text, reviver)
  } catch (e) {
    console.error(e)
    return fallback
  }
}


if (process.env.REACT_APP__DEV && process.env.REACT_APP__KVS_DEBUG) {
  window.__cqs_con_kvs__ = {
    con,
    cqs,
  }
}
