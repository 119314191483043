import styled from 'styled-components'

/**
 *
 * @param {object} props
 *
 *
 * @example
 * <Title />
 * <Title />
 */

export const Tag = styled.span`
display:inline-block;
/* float: ${({ theme }) => theme.dirFloat}; */
margin: 2.5px 0;
margin-${({ theme }) => theme.dirFloatRev}: 5px;
background: ${({ theme }) => theme.bgContrast};
padding:0 10px;
color:${({ theme }) => theme.textDark};
font-size: 12px;
line-height: 30px;
height: 30px;
  &:hover{
    /* background: ${({ theme }) => theme.bgHoverHighlight}; */
  }
  a{
    cursor: pointer;
    font-size:12px;
    margin-${({ theme }) => theme.dirFloatRev} : 5px;
    color:${({ theme }) => theme.brandBg};
  }
`
export const TagName = Tag.extend`
  font-size:11px;
  height: auto;
    padding: 3px 5px;
    line-height: normal;
    border-radius:${({ theme }) => theme.roundCorner};
  color: ${({ theme }) => theme.textDark};
  unicode-bidi: plaintext;
  
  &:hover {
    background-color: ${({ theme }) => theme.bgContrast};


  }
  `
