import { Base64 } from 'js-base64'

export const { stringify, parse } = JSON

export const b64EncodeURI = (arg) => Base64.encodeURI(stringify(arg))
export const b64Encode = (arg) => Base64.encode(stringify(arg))
export const b64Decode = (arg) => parse(Base64.decode(arg))

const safely = {
  encodeURI(arg, catchCB) {
    try {
      return Base64.encode(stringify(arg))
    } catch (e) {
      return catchCB?.(e)
    }
  },

  encode(arg, catchCB) {
    try {
      return Base64.encode(stringify(arg))
    } catch (e) {
      return catchCB?.(e)
    }
  },

  decode(arg, catchCB) {
    try {
      return parse(Base64.decode(arg))
    } catch (e) {
      return catchCB?.(e)
    }
  },
}

export default {
  encodeURI: b64EncodeURI,
  encode: b64Encode,
  decode: b64Decode,
  safely,
}
