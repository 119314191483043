import { combineReducers } from 'redux'
import { qctbReducer } from '~plumbing/components/QCToolbar'
import appReducer from '~CON/App/appReducer'
import userReducer from '~CON/User/userReducer'
import providerReducer from '~CON/Discovery/Provider/Redux/ProviderReducer'
import globalmanagementReducer from '~CON/Discovery/GlobalManagement/Redux/GlobalManagementReducer'
import customerReducer from '~CON/Discovery/CustomerManagement/Redux/CustomerManagementReducer'
import prefixReducer from '~CON/Discovery/ConfigList/Redux/ConfigReducer'
import masterDetails from '~CON/Discovery/MasterDetails/Redux/MasterDetailsReducer'
import sampleReducer from '~CON/Discovery/CoverageTesting/Redux/CoverageReducer'
import requestReducer from '~CON/Discovery/Requests/Redux/RequestReducer'
import rulesReducer from '~CON/Discovery/Rules/Redux/RulesReducer'
import users from '../../Discovery/UserManagement/Redux/UserManagementReducer'
import usgaeReducer from '../../Discovery/Usage/Redux/UsageReducer'
import audits from '../../Discovery/AuditTrail/Redux/AuditReducer'
import profiles from '../../Discovery/ProfileSetting/ChangePassword/Redux/profileReducer'
import cleansing from '../../Discovery/Cleansing/Redux/CleansingReducer'

const rootReducer = combineReducers({
  // jPlayers,
  App: appReducer,
  User: userReducer,
  QCToolbar: qctbReducer,
  ProviderReducer: providerReducer,
  GlobalmanagementReducer: globalmanagementReducer,
  CustomerReducer: customerReducer,
  PrefixReducer: prefixReducer,
  MasterDetailsReducer: masterDetails,
  CoverageReducer: sampleReducer,
  RequestReducer: requestReducer,
  RulesReducer: rulesReducer,
  Users: users,
  UsageReducer: usgaeReducer,
  Audits: audits,
  Profiles: profiles,
  Cleansing: cleansing,

})

export default rootReducer
