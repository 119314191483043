/* global FS */
import Rx from 'rxjs' // eslint-disable-line

import { observableFromPromiseProperties } from 'CQS/plumbing/utils'
import { actions as appActions } from '~CON/App/appActions'

import { actionsTypes, actions as auditActions } from './AuditActions'
import apis from '../apis'

const auditsApis = observableFromPromiseProperties(apis, Rx)

export default [getAuditsData, getEntitiesData, getUserNamesData]

export function getAuditsData(action$, { getState }) {
  return action$
    .ofType(actionsTypes.FETCH_ALL_AUDITS)
    .switchMap(({ payload }) => {
      return auditsApis
        .auditData(payload)
        .map((res) => {
          return auditActions.fetchAllAuditsSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_ALL_AUDITS_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getEntitiesData(action$, { getState }) {
  return action$
    .ofType(actionsTypes.FETCH_ALL_ENTITIES)
    .switchMap(({ payload }) => {
      return auditsApis
        .entitiesData(payload)
        .map((res) => {
          return auditActions.fetchAllEntitiesSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_ALL_ENTITIES_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getUserNamesData(action$, { getState }) {
  return action$
    .ofType(actionsTypes.FETCH_ALL_USERNAMES)
    .switchMap(({ payload }) => {
      return auditsApis
        .userNamesData(payload)
        .map((res) => {
          return auditActions.fetchAllUsernamesSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_ALL_USERNAMES_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
