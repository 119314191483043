// const unicodeRTLBlocks = {
//   "Hebrew": ["0x0590", "0x05FF"],
//   "Arabic": ["0x0600", "0x06FF"],
//   "Syriac": ["0x0700", "0x074F"],
//   "Arabic Supplement": ["0x0750", "0x077F"],
//   "Thaana": ["0x0780", "0x07BF"],
//   "NKo": ["0x07C0", "0x07FF"],
//   "Samaritan": ["0x0800", "0x083F"],
//   "Mandaic": ["0x0840", "0x085F"],
//   "Syriac Supplement": ["0x0860", "0x086F"],
//   "Arabic Extended-A": ["0x08A0", "0x08FF"],
// }

export default function textDirection(text) {
  const unicodeRTLRange = [0x0590, 0x08FF]
  const notDirRanges = [
    [0x1F600, 0x1F64F], // Emoticons
    [0x1F3FB, 0x1F3FF], // Skin tones
    [0x1F900, 0x1F9FF], // Supplemental Symbols
    [0x1F300, 0x1F5FF], // Miscellaneous Symbols
    [0x1F680, 0x1F6FF], // Transport and Map Symbols
    [0xE0000, 0xE007F], // Tags
    [0x1F300, 0x1FAFF], // Misc. Symbols, Pictographs ... Symbols, Pictographs Ext-A
    [0x1F000, 0x1F1FF], // Mahjong Tiles ... Enclosed Alphanumeric Supplement
    [0x1EC70, 0x1ED4F], // Indic Siyaq Numbers ... Ottoman Siyaq Numbers
    [0x1D2E0, 0x1D7FF], // Mayan Numerals ... Mathematical Alphanumeric Symbols
    [0x1D100, 0x1D1FF], // Musical Symbols
    [0x1D000, 0x1D0FF], // Byzantine Musical Symbols
    [0x1BCA0, 0x1BCAF], // Shorthand Format Controls
    [0x13430, 0x1343F], // Egyptian Hieroglyph Format Controls
    [0x13000, 0x1342F], // Egyptian Hieroglyphs
    [0x102E0, 0x102FF], // Coptic Epact Numbers
    [0x101D0, 0x101FF], // Phaistos Disc
    [0x10100, 0x1013F], // Aegean Numbers
    [0x2600, 0x26FF], // Miscellaneous Symbols
    [0x2700, 0x27BF], // Dingbats
    [0x02B0, 0x02FF], // Spacing Modifier Letters
    [0x0300, 0x036F], // Combining Diacritical Marks
    [0x2000, 0x206F], // General Punctuation
    [0x20A0, 0x20CF], // Currency Symbols
    [0x2150, 0x218F], // Number Forms
    [0x2190, 0x21FF], // Arrows
    [0x2200, 0x2BFF], // Mathematical Operators ... Miscellaneous Symbols and Arrows
    [0x2E00, 0x2E7F], // Supplemental Punctuation
    [0x2FF0, 0x2FFF], // Ideographic Description Characters
    [0x3190, 0x319F], // Kanbun
    [0x31C0, 0x31EF], // CJK Strokes
    [0x4DC0, 0x4DFF], // Yijing Hexagram Symbols
    [0xA700, 0xA71F], // Modifier Tone Letters
    [0xA830, 0xA83F], // Common Indic Number Forms
    [0xFE10, 0xFE1F], // Vertical Forms
    [0xFE30, 0xFE4F], // CJK Compatibility Forms
    [0xFE50, 0xFE6F], // Small Form Variants
    [0xFFF0, 0xFFFF], // Specials
  ]

  let char
  let charCode
  let isInRange
  let isNoDirRange

  /* eslint-disable no-restricted-syntax */
  for (const _char of text) {
    char = _char
    charCode = _char.codePointAt(0)

    const isRTLRangeMinimum = (charCode >= unicodeRTLRange[0])
    const isRTLRangeMaximum = (charCode <= unicodeRTLRange[1])

    isInRange = isRTLRangeMinimum && isRTLRangeMaximum

    if (isInRange) {
      break
    }

    isNoDirRange = char.match(/\s/g)

    for (const [noDirMin, noDirMax] of notDirRanges) {
      const isNoDirRangeMinimum = (charCode >= noDirMin)
      const isNoDirRangeMaximum = (charCode <= noDirMax)

      isNoDirRange = isNoDirRange
        || (isNoDirRangeMinimum && isNoDirRangeMaximum)
    }

    if (!isNoDirRange) {
      break
    }
  }
  /* eslint-enable no-restricted-syntax */

  return {
    char,
    charCode,
    isRTL: isInRange,
    dir: isInRange ? 'rtl' : 'ltr',
  }
}

window.__cqs_utils__ = {
  ...(window.__cqs_utils__ || {}),
  textDirection,
}
