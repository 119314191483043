import { camelCase as _camelCase, get as _get } from 'lodash'
import { renameFunc } from 'CQS/plumbing/utils'

export const actionsTypes = {
  FETCH_ALL_AUDITS: 'discovery/Audit/Audits',
  FETCH_ALL_AUDITS_SUCCESS: 'discovery/Audit/AuditsSucess',
  FETCH_ALL_AUDITS_FAILED: 'discovery/Audit/AuditsFail',
  FETCH_ALL_ENTITIES: 'discovery/Audit/Entities',
  FETCH_ALL_ENTITIES_SUCCESS: 'discovery/Audit/EntitiesSucess',
  FETCH_ALL_ENTITIES_FAILED: 'discovery/Audit/EntitiesFail',
  FETCH_ALL_USERNAMES: 'discovery/Audit/UserNames',
  FETCH_ALL_USERNAMES_SUCCESS: 'discovery/Audit/UserNamesSucess',
  FETCH_ALL_USERNAMES_FAILED: 'discovery/Audit/UserNamesFail',
}
export const actions = Object.keys(actionsTypes).reduce((accum, id) => {
  const creatorName = _camelCase(id)

  const creatorFunction = function _(payload, meta) {
    return {
      type: _get(actionsTypes, id),
      payload,
      meta,
    }
  }

  // eslint-disable-next-line no-param-reassign
  accum[creatorName] = renameFunc(creatorFunction, creatorName)
  return accum
}, {})
