import { inCase } from '~plumbing/utils'
import {
  actions as cleansingActions,
  actionsTypes,
} from './CleansingAction'
// import create from '@ant-design/icons/lib/components/IconFont'

const defaultState = {
  cleansing: {
    cleansingData: [],
    CountriesData: [],
    NetworksData: [],
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
}
const cleansing = inCase.curry('cleansing')
  .check((s, action) => action.type)
  .otherwise((state) => state || defaultState)
  .cases({
    [actionsTypes.FETCH_ALL_CLEANSING]: fetchAllCleansing,
    [actionsTypes.FETCH_ALL_CLEANSING_SUCCESS]: fetchAllCleansingSuccess,
    [actionsTypes.FETCH_ALL_CLEANSING_FAILED]: fetchAllCleansingFailed,
    [actionsTypes.FETCH_ALL_COUNTRIES]: fetchAllCountries,
    [actionsTypes.FETCH_ALL_COUNTRIES_SUCCESS]: fetchAllCountriesSuccess,
    [actionsTypes.FETCH_ALL_COUNTRIES_FAILED]: fetchAllCountriesFailed,
    [actionsTypes.FETCH_ALL_NETWORKS]: fetchAllNetworks,
    [actionsTypes.FETCH_ALL_NETWORKS_SUCCESS]: fetchAllNetworksSuccess,
    [actionsTypes.FETCH_ALL_NETWORKS_FAILED]: fetchAllNetworksFailed,
    [actionsTypes.CREATE_CLEANSING]: createCleansing,
    [actionsTypes.CREATE_CLEANSING_SUCCESS]: createCleansingSuccess,
    [actionsTypes.CREATE_CLEANSING_FAILED]: createCleansingFailed,
  })

function fetchAllCleansing(state, action) {
  return {
    ...state,
  }
}
function fetchAllCleansingSuccess(state, action) {
  return {
    ...state,
    cleansing: {
      cleansingData:
        action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchAllCleansingFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchAllCountries(state, action) {
  return {
    ...state,
  }
}
function fetchAllCountriesSuccess(state, action) {
  return {
    ...state,
    cleansing: {
      CountriesData: action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: false,
        errorMessage: '',
      },
    },
  }
}
function fetchAllCountriesFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchAllNetworks(state, action) {
  return {
    ...state,
  }
}
function fetchAllNetworksSuccess(state, action) {
  return {
    ...state,
    cleansing: {
      NetworksData: action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: false,
        errorMessage: '',
      },
    },
  }
}
function fetchAllNetworksFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function createCleansing(state, action) {
  return {
    ...state,
    errCode: undefined,
    isSuccess: false,
  }
}
function createCleansingSuccess(state, action) {
  return {
    ...state,
    errCode: undefined,
    isSuccess: true,
    cleansing: {
      cleansingData: action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function createCleansingFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
    isSuccess: false,
  }
}

export default cleansing
