import React from 'react'
import styled, { css } from 'styled-components'
import { Button, Form } from 'antd'
// import { Icon } from 'fa-shorthand.macro'
import { Link } from 'react-router-dom'
// import theme from '~plumbing/themes' // eslint-disable-line

export const Container = styled.div`
`
export const PasswordWithRevealWrap = styled.div`
  position: relative;

  button {
    top: 0;
    @-dirInv: 0;
    width: auto;
    height: 32px;
    padding: 7px 9px;
    position: absolute;
    background: transparent !important;
    line-height: normal;

    i {
      color: @var-colors.brand.primary;
      margin: 0;
      font-size: 13px;
      line-height: normal;
    }
  }

  input {
    @padding-dirInv: 30px;
  }
`
export const WhiteWrapper = styled.div`
  background: #fff;
`

export const ErrorMsg = styled.span`
  color: ${({ theme }) => theme.redColor};
  display: ${(props) => (props.visible ? 'block;' : 'none;')};
  margin-bottom: 15px;
  font-size:12px;


  p {
    color: ${({ theme }) => theme.textDark};
  }


`
ErrorMsg.Error = ErrorMsg.extend`
  color: ${({ theme }) => theme.redColor};
  display: ${(props) => (props.visible ? 'block;' : 'none;')};
  margin-top: 0px;
  margin-bottom: 0px;

`
/*
display: grid;
  grid-template-columns: 22% auto 22%;
  grid-template-rows: 22% auto 22%;
  grid-row-gap: 1px;
  grid-column-gap: 9px;
*/
export const LoginContainer = styled.div`
  height: 100%;
  width: ${(props) => (props.insideApp ? 'auto' : '100%')};
`

export const TableItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-right: 100px;
  @media (max-height: 600px) {
    top: 0;
  }
`

/* align-content: center; */

export const StyledLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`

// margin-top: 66px;
// margin-left: -150px;

export const TableContainer = styled.div`
  display: table;
  position: absolute;
  height: 100%;
  /*width: ${(props) => (props.insideApp ? 'auto' : '100%')};*/
  width:100%;
  //margin-${({ theme }) => theme.dirFloat}:-100px;
  ${({ theme }) => theme.dirFloat}: 0;
  top: 0;

  /* &:before {
    background: ${({ theme }) => theme.brandBg};
    content: " ";
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    right: 0;
    z-index: 0;
  } */
`

export const Hero = styled.img`
  width: auto;
  height: auto;
  margin: auto;
  display: block;
`


export const LockPanel = {}


LockPanel.AppWrapper = styled.div`
  ${({ blur }) => (blur
    ? css`filter: blur(8px);`
    : null
  )}
`


LockPanel.Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: ${({ noScroll }) => (noScroll ? 'hidden' : 'auto')};

  & .login-wrapper {
    padding: 15vh;
    width: 100vw;
    height: 100vh;
    z-index: 1000000000;
    position: absolute;
    background: ${({ theme }) => theme.shadowPopBg};
    .panel-body {
      padding:10px;
      padding-top:0px;
      padding-bottom:30px;
      text-align:center;
      border-radius: 20px;
    }
  }

  & .login-button {
    margin-top: 0 !important;
  }
`


LockPanel.Icon = styled.i`
    color: ${({ theme }) => theme.textHighlightBg};
    font-size: 40px;
    text-align: center;
    display: inline-block;
    background: ${({ theme }) => theme.textDark};
    padding: 8px 19px;
    padding-top: 4px;
    border-radius: 100px;
    margin-top: -35px;
    font-weight:bold;
    border: 5px solid #fff;
`
  .withComponent((props) => (<i/>))


LockPanel.Title = styled.h3`
  display: block;
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 30px;
  color: ${({ theme }) => theme.helperDarker};
`


LockPanel.UserName = styled.h2`
    margin: 0 15px;
    display: block;
    padding: 8px 0px;
    text-align: ${({ theme }) => theme.dirFloat};
    color: ${({ theme }) => theme.textNormal};
    font-weight: 600;

    margin-bottom: 10px;
    margin-top:10px;
    /*
    border-top:1px dashed  ${({ theme }) => theme.hintBorderNormal};
    border-bottom:1px dashed  ${({ theme }) => theme.hintBorderNormal};
    background:  ${({ theme }) => theme.hintBgNormal}; */
    span{
    color: ${({ theme }) => theme.textAlt};
    position: relative;
    display: inline-block;
    overflow: hidden;
    font-weight: bold;
    font-size: 35px;
    margin-bottom: -20px;
    margin-${({ theme }) => theme.dirFloatRev}: 10px;
    }
`


LockPanel.NotMe = styled.a`
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
  display: block;
`


LockPanel.Form = styled.form`
  text-align: center;
  padding: 0 15px;
  margin-bottom:0px;
  .form-group {
    margin-bottom: 0px;
}
`.withComponent(Form)


LockPanel.UnlockButton = styled.button.attrs({
  type: 'primary',
  htmlType: 'submit',
  className: 'login-form-button login-button',
})`
  /* Unlock Button */
`.withComponent(Button)


LockPanel.ErrorMessage = ErrorMsg.extend`
margin-top:5px;
text-align:${({ theme }) => theme.dirFloat};
`


LockPanel.Error = LockPanel.ErrorMessage.extend`
  margin: 15px 10px 10px;
  text-align: ${({ theme }) => theme.dirFloat};
`

export const PopupAlert = styled.div`
  padding: 20px;
  i {
    display: block;
    width: 70px;
    height: 70px;
    background: green;
    border-radius: 50%;
    border: 5px solid #fff;
    position: absolute;
    top: -35px;
    right: 0;
    left: 0;
    text-align: center;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    line-height: 60px;
    font-size: 24px;
    font-weight: bolder;
  }
  div {
  margin-bottom: 20px;
   span {
      color: ${({ theme }) => theme.brandBg};
      margin:0 3px;
    }
  }
  h2{
    text-align: center;
    margin: 10px;
  }
  p{
     text-align: center;
    font-size: 13px;
    display: block;
  }
  button {
    width: 100%;
    display: block;
  }

`


export const StyledLink = styled(Link)`
  color: #0a87ff;
  &:hover {
    color : #0a87ff !important;
  }
`
export const StyledLinkTag = styled.a`
  color: #0a87ff;
  &:hover {
    color : #0a87ff !important;
  }
`
export const StyledButton = styled(Button)`
  background: #0a87ff !important;
  font-weight: bold;
    &:hover {
    background : #0a87ff !important;
  }
  &:focus {
   background : #0a87ff !important;
  }
`
/*
export const StyledTitle = styled.h1`
  font-size: 24px;
  margin-left: 20px;
  padding-bottom: 10px;
  font-weight: bold;
`
 */

// margin-right: 20px;
// padding-right: 35px;
import theme from '~plumbing/themes' // eslint-disable-line
export const VerticalLine = styled.div`
  ${theme.isRTL ? `
  margin-left: 20px;
  padding-left: 35px;
  &::after{
    content: "";
    height: 330px;
    width: 1px;
    background: #ddd;
    position: absolute;
    left: 15px !important;
    top: 0px;
    display: flex;
    margin-top: 25px;
  }
  `
    : `
  padding-right: 35px
  margin-right: 20px
  &::after{
    content: "";
    height: 330px;
    width: 1px;
    background: #ddd;
    position: absolute;
    right: 15px !important;
    top: 0px;
    display: flex;
    margin-top: 25px;
  }
  `} // eslint-disable-line
`

export const LoginView = styled.div`
  ${theme.isRTL ? 'padding-right: 15px ' : null} // eslint-disable-line
`

export const StyledTitle = styled.h1`
  color: #464646;
  font-size: 24px;
  padding-bottom: 10px;
  font-weight: bold;
  ${theme.isRTL ? 'margin-right: 35px' : 'margin-left: 20px'} // eslint-disable-line
`
export const LoginContent = styled.div`
  display: inline-block;
`
//   width: 50% !important;
export const WrapperButton = styled(Button)`
 &&& {
  width: calc(100% - 40px) !important;
  margin: 0 20px !important;
 }
`
export const StyledFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: 5px;
  &&& {
    ${window.screen.width > 768 ? null : 'padding-left: 100px'}
  }
  ul {
    font-weight: bold;
    color: #909090;
  }
`

export const StyledDotSpan = styled.span`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
`
export const WrapperListItem = styled.li`
   color: #909090;
   font-weight: normal;
     span {
        font-weight: bold !important;
     }
`

export const StyledGoogleLogo = styled.img`
  height: 18px;
  width: 18px;
  object-fit: contain;
`

export const ButtonTextStyles = styled.span`
  position: relative ;
  top: -4px;
  color: #565656;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.71;
  letter-spacing: normal;
  text-align: center;
  height: 10px;
  padding-left: 15px;
  padding-top: 2px;
  background: transparent !important;
  ${theme.isRTL ? 'padding-right: 10px' : null}
`
// margin: 7px 0 0 25px !important;
// width: 374px;
export const StyledAntButton = styled(Button)`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background: #0073e6 !important;
  &:hover{
    background: #0073e6 !important;
  }
  &:focus{
    background: #0073e6 !important;
    &:hover{
      background: #0073e6 !important;
    }
  }
`
export const Logo = styled.img`
  margin-bottom:20px;
  height: @var-layout.Login.logo.img.hight;
  margin-top: 20px;
  ${theme.isRTL ? 'padding-right: 20px' : 'padding-left: 20px'} // eslint-disable-line
`
export const StyledLabel = styled.label`
  font-size: 16px !important;
  ${theme.isRTL ? 'margin-right: 20px' : 'margin-left: 20px'} // eslint-disable-line
`
//   margin-left: 20px;

export const StyledWelcome = styled.h3`
  font-size: 24px;
  margin-left: 20px;
  margin-top: 5px;
  font-weight: bold;
  color: #464646;
`

export const ContentLogo = styled.img`
  padding: 5px;
  &&& {
    opacity: 100% !important;
  }
`
export const MainContentLogo = styled.img`
  padding: 15px;
  max-width: 54px;
  &&& {
    color: #f03034 !important;
    margin-top: 12px !important;
  }
`
export const CQSMainContent = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledSpan = styled.span`
  margin-left: 20px;
  font-size: 14px;
  color: #6f6f6f;
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const TextWrapper = styled.div`
  padding-top: 45px;
`
export const WrapperH4 = styled.h4`
  font-size: 14px;
  font-weight: bold;
  color: #3d3d3d;
`
export const WrapperParagraph = styled.p`
  font-size: 14px;
  padding-top: 3px;
  color: #3d3d3d;
`
export const FooterIcons = styled.div`
  padding-top: 5px;
  text-align: center;
  display: inline-block;
`

export const HorizontalLine = styled.hr`
  &&& {
    color: #dcdcdc !important;
    background: #dcdcdc !important;
  }
`
