import { inCase } from '~plumbing/utils'
import { actionsTypes } from './UsageActions'

const defaultState = {
  Usage: {
    UsageList: {},
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
}
const usgaeReducer = inCase
  .curry('usgaeReducer')
  .check((s, action) => action.type)
  .otherwise((state) => state || defaultState)
  .cases({
    [actionsTypes.GET_USAGE]: fetchUsageData,
    [actionsTypes.GET_USAGE_SUCCESS]: fetchUsageDataSuccess,
    [actionsTypes.GET_USAGE_FAILED]: fetchUsageDataFailed,
  })

function fetchUsageData(state, action) {
  return {
    ...state,
  }
}
function fetchUsageDataSuccess(state, action) {
  return {
    ...state,
    Usage: {
      ...state.Usage,
      UsageList: action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchUsageDataFailed(state, action) {
  return {
    ...state,
    Usage: {
      ...state.Usage,
      meta: {
        isLoading: false,
        isFailed: true,
        isSuccess: false,
        errorMessage: action.payload,
      },
    },
  }
}

export default usgaeReducer
