
export function renameFunc(func, name) {
  /* eslint-disable no-param-reassign */
  Object.defineProperty(func, 'name', { writable: true })
  func.name = name
  Object.defineProperty(func, 'name', { writable: false })
  return func
  /* eslint-enable no-param-reassign */
}


export function parseCssVal(val, key) {
  if (val) {
    const fallback = `-ref-val-key: ${key};\n`

    return `${val};\n ${fallback}`
  }

  const fallback = `-error-val-key: ${key};\n`

  return `0;\n ${fallback}`
}


export function parseCssProp(prop, key) {
  if (prop) {
    const fallback = `-ref-prop-key: ${key};\n`

    return `${fallback} ${prop}: `
  }

  return `-error-prop-key--${key}: `
}
