import styled from 'styled-components'

export const IconBtn = styled.a`
  ${({ theme }) => theme.toggleDir({ rtl: 'left', ltr: 'right' })}: 8px;
  position: absolute;
  bottom: 10px;
  padding: 0px;
  background: transparent;
  font-size: 11px;
  color: ${({ theme }) => theme.btnLink} ;
  &:hover {
  color: ${({ theme }) => theme.btnLinkHover} ;
  }
`
