import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function updateUser(payload) {
  const {
    createdAt, superAdmin, updatedAt, _id, ...rest
  } = payload
  return new Promise((resolve, reject) => {
    request
      .put(`${API_URL}/api/v1/auth/users/${_id}`)
      .set('Authorization', `bearer ${getToken()}`)
      .send(rest)
      .then((resp) => {
        resolve(resp.body)  // eslint-disable-line
      })
      .catch((err) => {
        reject(err)
      })
  })
}
