import usersData from './FetchUsers'
import deleteUser from './DeleteUser'
import updateUser from './UpdateUser'
import addUser from './AddUser'

export default {
  usersData,
  deleteUser,
  updateUser,
  addUser,
}
