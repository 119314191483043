import { inCase } from '~plumbing/utils'
import {
  actions as providerActions,
  actionsTypes,
} from './ProviderActions'

const defaultState = {
  providers: {
    ProviderData: {},
    exportData: '',
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
}
const providerReducer = inCase.curry('providerReducer')
  .check((s, action) => action.type)
  .otherwise((state) => state || defaultState)
  .cases({
    [actionsTypes.FETCH_ALL_PROVIDERS]: fetchAllProviders,
    [actionsTypes.FETCH_ALL_PROVIDERS_SUCCESS]: fetchAllProvidersSuccess,
    [actionsTypes.FETCH_ALL_PROVIDERS_FAILED]: fetchAllProvidersFailed,
    [actionsTypes.UPDATE_PROVIDER]: updateProvider,
    [actionsTypes.UPDATE_PROVIDER_SUCCESS]: updateProviderSuccess,
    [actionsTypes.UPDATE_PROVIDER_FAILED]: updateProviderFailed,
    [actionsTypes.EXPORT_PROVIDERS]: exportProviders,
    [actionsTypes.EXPORT_PROVIDERS_SUCCESS]: exportProvidersSuccess,
    [actionsTypes.EXPORT_PROVIDERS_FAILED]: exportProvidersFailed,
  })

function fetchAllProviders(state, action) {
  return {
    ...state,
  }
}
function fetchAllProvidersSuccess(state, action) {
  return {
    ...state,
    providers: {
      ProviderData:
        action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchAllProvidersFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function updateProvider(state, action) {
  return {
    ...state,
  }
}
function updateProviderSuccess(state, action) {
  return {
    ...state,
    providers: {
      ProviderData:
        action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function updateProviderFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function exportProviders(state, action) {
  return {
    ...state,
  }
}
function exportProvidersSuccess(state, action) {
  return {
    ...state,
    providers: {
      exportData:
        action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function exportProvidersFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
export default providerReducer
