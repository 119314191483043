import styled, { css } from 'styled-components'

/**
 *
 * @param {object} props
 * @param {boolen} props.warn - warning highlight
 * @param {boolen} props.error - Error highlight
 *
 * @example
 * <Input warn />
 */
const FeildStyle = css`
  position: relative;
  display: inline-block;
  padding: 4px 6px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.textDark};
  background-color: ${({ theme }) => theme.inputBackground};
  background-image: none;
  border: 1px solid ${({ theme }) => theme.var.colors.helper.greys.grey20};
  border-radius: ${({ theme }) => theme.roundCorner};
  transition: all .3s;
  outline: none !important;
  transition: all ease-in-out .3s;

  &:focus{
    border: 1px solid ${({ theme }) => theme.var.colors.helper.greys.grey40};
    background-color: ${({ theme }) => theme.var.colors.helper.greys.grey10};
  }
  &:hover{
    border: 1px solid ${({ theme }) => theme.var.colors.helper.greys.grey40};
  }
`
export default styled.div`
  position: relative;
  margin-left: 0;
  margin-right: 0;
  height: auto;
  zoom: 1;
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  vertical-align: top;
  label{
    line-height: 1.5;
    display: block;
    text-align: ${({ theme }) => theme.dirFloat};
    overflow: hidden;
    margin-bottom:5px;
    font-weight:100;
  }
  > input{
    ${FeildStyle}
  }
  p{
    color: ${({ theme }) => theme.textDark};
  }
  textarea{
    ${FeildStyle}
    height:150px;
    resize: none;
  }
  &.has-error{
    input{
      border:1px solid ${({ theme }) => theme.redColor};
    }
    textarea{
      border:1px solid ${({ theme }) => theme.redColor};
    }
    &>span{
      color: ${({ theme }) => theme.redColor};
      span{
        color: ${({ theme }) => theme.redColor};
      }
    }
  }
  &.has-error{
    input{
      border:1px solid ${({ theme }) => theme.redColor};
    }
    textarea{
      border:1px solid ${({ theme }) => theme.redColor};
    }
    .validation_Msg{
      color: ${({ theme }) => theme.redColor};
      span{
        color: ${({ theme }) => theme.redColor};
      }
    }
  }
  &.has-error{
    input{
      border:1px solid ${({ theme }) => theme.redColor};
    }
    textarea{
      border:1px solid ${({ theme }) => theme.redColor};
    }
    .validation_Msg{
      color: ${({ theme }) => theme.redColor};
    }
  }
`

function borderColor({ warn, error, theme }) {
  if (error) {
    return theme.redColor
  }

  if (warn) {
    return theme.orangeColor
  }

  return theme.inputNormal
}
