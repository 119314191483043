import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function deleteRule(payload) {
  return new Promise((resolve, reject) => {
    request
      .delete(`${API_URL}/api/v1/flooding-rules/${payload}`)
      .set('Authorization', `bearer ${getToken()}`)
      .then((resp) => {
      })
      .catch((err) => {
        reject(err)
      })
  })
}
