/* eslint-disable import/no-cycle */
import Rx from 'rxjs' // eslint-disable-line

import {
  observableFromPromiseProperties,
} from 'CQS/plumbing/utils'
import { actions as appActions } from '~CON/App/appActions'

import { actionsTypes, actions as requestActions } from './RequestAction'
import apis from '../apis'

const requestApis = observableFromPromiseProperties(apis, Rx)

export default [
  getNetworksData,
  getFilteredList,
]

export function getNetworksData(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_ALL_NETWORKS_DATA_LIST)
    .switchMap(({ payload }) => {
      return requestApis
        .getNetwoksList()
        .map((res) => {
          return requestActions.fetchAllNetworksDataListSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_ALL_NETWORKS_DATA_LIST_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getFilteredList(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_FILTERED_DATA)
    .switchMap(({ payload }) => {
      return requestApis
        .getFilteredData(payload)
        .map((res) => {
          return requestActions.fetchFilteredDataSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_FILTERED_DATA_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
