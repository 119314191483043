import request from 'superagent'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function addCustomerDestnation(payload, meta) {
  return new Promise((resolve, reject) => {
    const prefix = {
      providers: payload.provider,
    }
    if (payload && payload.provider.length > 0) {
      request
        .post(`${API_URL}/api/v1/customers/prefixes/${meta.toLowerCase()}/${payload.customer.toLowerCase()}/default`)
        .set('Authorization', `bearer ${getToken()}`)
        .send(prefix)
        .then((resp) => {})
        .catch((err) => {
          reject(err)
        })
    } else {
      request.delete(`${API_URL}/api/v1/customers/prefixes/${meta.toLowerCase()}/${payload.customer.toLowerCase()}/default`).set('Authorization', `bearer ${getToken()}`)
        .send(prefix)
        .then((resp) => {})
        .catch((err) => {
          reject(err)
        })
    }
  })
}
