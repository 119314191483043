import styled from 'styled-components'


// to do vrs : ibraheem

export const DropdownBtn = styled.div`
  position: relative;
  float: ${({ theme }) => theme.dirFloatRev} ;
  margin-${({ theme }) => theme.dirFloat} : 10px;

  button {
    margin: 0;
  }

  button:hover {
    margin: 0 !important;
  }

  button {
    border-radius:${({ theme }) => theme.toggleDir({ rtl: theme.roundCornerRight, ltr: theme.roundCornerLeft })};

  i {
      display:inline-block;
      float: ${({ theme }) => theme.dirFloat} ;
      margin-${({ theme }) => theme.dirFloatRev}: 10px ;
    }
  }

  b {
    float:${({ theme }) => theme.dirFloatRev};
    padding: 11px 15px;
    font-size:11px;
    font-weight:bold;
    background:${({ theme }) => theme.btnPrimary};
    color:${({ theme }) => theme.btnPrimaryLabel};
    border-${({ theme }) => theme.dirFloat}:1px solid ${({ theme }) => theme.btnPrimaryLabel};
    border-radius:${({ theme }) => theme.toggleDir({ rtl: theme.roundCornerLeft, ltr: theme.roundCornerRight })};

    &:hover{
      background:${({ theme }) => theme.btnPrimaryHover};
    }
  }

  div {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    z-index: 100;
    opacity: ${({ show }) => (show ? '1' : '0')};
    display: ${({ show }) => (show ? 'block' : 'none')};
    transition: all ease-in-out .3s;
    box-shadow: 0 3px 6px -6px ${({ theme }) => theme.shadowPrimary};

    a {
      position: relative;
      background: ${({ theme }) => theme.bgLighter};
      padding: 10px 20px;
      display: block;
      width: 100%;
      height: 100%;
      font-size:12px;
      color: ${({ theme }) => theme.inputDropdownLabel};
      transition: all linear 0.3s;
      float: ${({ theme }) => theme.dirFloatRev};
      width: 100%;

      &:hover {
        background: ${({ theme }) => theme.inputDropdownHover};
        color:${({ theme }) => theme.inputDropdownHoverLabel} !important;
      }

      i{
        margin-${({ theme }) => theme.dirFloat}: -10px;
        margin-${({ theme }) => theme.dirFloatRev}: 10px;
      }
    }
`
