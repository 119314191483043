/* eslint-disable no-unused-vars */
import chroma from 'chroma-js'
import { darken, lighten } from 'polished'

import {
  withAccents,
} from '../utils'

/* ***************************************************
   /\   /\__ _ _ __ ___
   \ \ / / _` | '__/ __|
    \ V / (_| | |  \__ \
     \_/ \__,_|_|  |___/
     Usage Sample
     @var-color.brand.alpha;
*************************************************** */


export default function colorVars(vars) {
  const colors = vars?.colors || {}

  colors.brand = {
    alpha: '#FF0000',
    beta: '#6E6E6E',
    gamma: '#343642',
    psi: '#8B8B8B',
    delta: '#3D3D3D',
    omega: '#000',
  }

  colors.helper = {
    alpha: '#ffffff',
    beta: '#fff0b6',
    psi: '#DCDCDC',
    gamma: '#A4A4A4',
    delta: '#E6E8E9',
    omega: '#F3F3F3',
    zeta: '#E6E6E6',
    greys: {
      grey10: '#F3F3F3',
      grey10Hover: '#E6E8E9',
      grey20: '#DCDCDC',
      grey30: '#BDBDBD',
      grey40: '#A4A4A4',
      grey50: '#8B8B8B',
      grey60: '#6E6E6E',
      grey70: '#555555',
      grey80: '#3D3D3D',
      grey90: '#171717',
      grey100: '#000000',
    },

    x: 'rgba(0, 0, 0, 0)', // Test color replace Ant colors
  }

  colors.state = {
    success: '#0eca0e',
    warning: '#ffc100',
    error: '#ff7f7f',
    disabled: '#d3d3d3',
    tag: '#ffbf00',
  }

  colors.shadow = {
    alpha: '#ffffff',
    beta: '#dcdcdc',
    psi: 'rgba(78, 78, 78, 0.8)',
    gamma: 'rgba(255,255,255,.9)',
  }

  colors.icon = {
    alpha: colors.brand.alpha,
    beta: colors.helper.psi,
  }


  colors.typo = {
    title: {
      alpha: colors.brand.beta,
      beta: colors.helper.alpha,
      gamma: '',
      psi: colors.helper.psi,
      omega: '',
    },
    text: {
      alpha: colors.brand.beta,
      beta: colors.helper.alpha,
      gamma: '',
      psi: colors.helper.psi,
      omega: '',
    },
    label: colors.helper.gamma,
    value: colors.brand.beta,
  }

  colors.btn = {
    alpha: {
      background: colors.brand.alpha,
      border: colors.brand.alpha,
      label: colors.helper.alpha,
      hover: {
        background: darken(0.1, colors.brand.alpha),
        border: colors.brand.alpha,
        label: colors.helper.alpha,
      },
    },
    beta: {
      background: colors.helper.omega,
      border: 'transparent',
      label: colors.brand.delta,
      hover: {
        background: colors.helper.delta,
        border: 'transparent',
        label: colors.brand.delta,
      },
    },
    gamma: {
      background: colors.helper.alpha,
      border: colors.helper.psi,
      label: colors.brand.alpha,
      hover: {
        background: colors.helper.psi,
        border: colors.helper.psi,
        label: colors.brand.alpha,
      },
    },
    psi: {
      background: lighten(0.7, colors.brand.beta),
      border: '',
      label: colors.brand.alpha,
      hover: {
        background: lighten(0.6, colors.brand.beta),
        border: '',
        label: colors.brand.alpha,
      },
    },
    link: {
      label: colors.brand.alpha,
      hover: {
        label: darken(0.1, colors.brand.alpha),
      },
    },
    icon: {
      background: colors.brand.alpha,
      border: '',
      label: colors.helper.lighter,
      hover: {
        background: darken(0.1, colors.brand.alpha),
        border: '',
        label: colors.helper.lighter,
      },
    },
    state: {
      error: {
        background: colors.state.error,
        border: colors.state.error,
        label: colors.helper.alpha,
        hover: {
          background: colors.state.error,
          border: colors.state.error,
          label: colors.helper.alpha,
        },
      },
      warning: {
        background: colors.state.warning,
        border: colors.state.warning,
        label: colors.helper.alpha,
        hover: {
          background: colors.state.warning,
          border: colors.state.warning,
          label: colors.helper.alpha,
        },
      },
      success: {
        background: colors.state.success,
        border: colors.state.success,
        label: colors.helper.alpha,
        hover: {
          background: colors.state.success,
          border: colors.state.success,
          label: colors.helper.alpha,
        },
      },
    },
    selected: {
      background: colors.helper.greys.grey80,
      border: 'transparent',
      label: '#fff',
    },
    disabled: {
      background: colors.state.disabled,
      border: colors.state.disabled,
      label: darken(0.3, colors.state.disabled),
    },
  }

  colors.table = {
    header: {
      background: colors.brand.gamma,
      label: colors.helper.alpha,
      border: colors.brand.gamma,
      hover: {
        background: darken(0.1, colors.brand.alpha),
        label: darken(0.1, colors.brand.alpha),
        border: darken(0.1, colors.brand.alpha),
      },
      selected: {
        background: darken(0.1, colors.brand.alpha),
        label: darken(0.1, colors.brand.alpha),
        border: darken(0.1, colors.brand.alpha),
      },
    },
    body: {
      background: colors.helper.alpha,
      label: '#4a5669',
      border: colors.helper.alpha,
      hover: {
        background: '#fffce9',
        label: colors.helper.gamma,
        border: darken(0.1, colors.brand.alpha),
      },
      selected: {
        background: colors.brand.alpha,
        label: colors.helper.alpha,
        border: darken(0.1, colors.brand.alpha),
      },
      action: {
        background: '',
        label: colors.brand.alpha,
        border: '',
        hover: {
          background: colors.helper.psi,
          label: colors.brand.alpha,
          border: '',
        },
        disabled: {
          background: lighten(0.5, colors.helper.gamma),
          label: lighten(0.35, colors.helper.gamma),
          border: lighten(0.5, colors.helper.gamma),
        },
      },
      link: {
        label: colors.brand.alpha,
        hover: {
          label: darken(0.1, colors.brand.alpha),
        },
      },
    },
  }

  colors.input = {
    background: colors.helper.alpha,
    border: colors.helper.psi,
    value: colors.brand.beta,
    placeholder: lighten(0.4, colors.helper.gamma),
    list: {
      background: colors.helper.alpha,
      border: darken(0.1, colors.brand.alpha),
      value: darken(0.1, colors.brand.alpha),
      hover: {
        background: '#fffcf2',
        border: darken(0.1, colors.brand.alpha),
        value: darken(0.1, colors.brand.alpha),
      },
    },
    hover: {
      border: colors.helper.gamma,
    },
    focus: {
      background: colors.helper.omega,
      border: '',
      value: '',
      shadow: '0', // `0px 0px 6px ${lighten(0.2, colors.helper.gamma)}`,
    },
    disabled: {
      background: lighten(0.5, colors.helper.gamma),
      border: '',
      value: lighten(0.35, colors.helper.gamma),
    },
  }

  colors.card = {
    defult: {
      background: '#fff',
      hover: {
        background: colors.helper.omega,
      },
    },
    beta: {
      background: '#fffce9',
      border: '#ececea',
    },
  }

  colors.alerts = {
    defult: {
      background: '#fffce9',
      border: '#fff0b6',
      title: darken(0.1, colors.brand.alpha),
      msg: darken(0.1, colors.brand.alpha),
    },
    success: {
      background: '#dff0d8',
      border: '#3c763d',
      title: darken(0.1, colors.brand.alpha),
      msg: darken(0.1, colors.brand.alpha),
    },
  }

  colors.tag = {
    alpha: {
      background: lighten(0.7, colors.brand.beta),
      border: '',
      value: colors.brand.beta,
    },
    beta: {
      background: '#dcd3c5',
      border: '',
      value: '#fff',
    },
    success: {
      background: '#0eca0e',
      border: '',
      value: '#fff',
    },
    error: {
      background: '#ff7f7f',
      border: '',
      value: '#fff',
    },
  }

  colors.hint = {
    defult: {
      background: '#fffce9',
      border: '#fff0b6',
      title: darken(0.1, colors.brand.alpha),
      msg: darken(0.1, colors.brand.alpha),
    },
    success: {
      background: '#dff0d8',
      border: '#3c763d',
      title: darken(0.1, colors.brand.alpha),
      msg: darken(0.1, colors.brand.alpha),
    },
  }

  colors.wizard = {
    defult: {
      label: darken(0.1, colors.brand.alpha),
      background: darken(0.1, colors.brand.alpha),
      border: darken(0.1, colors.brand.alpha),
    },
    active: {
      label: darken(0.1, colors.brand.alpha),
      background: darken(0.1, colors.brand.alpha),
      border: darken(0.1, colors.brand.alpha),
    },
    done: {
      label: darken(0.1, colors.brand.alpha),
      background: darken(0.1, colors.brand.alpha),
      border: darken(0.1, colors.brand.alpha),
    },
  }

  colors.hr = {
    alpha: '#ffffff',
    beta: '#f5f5f5',
    psi: '',
    gamma: '',
  }

  colors.layout = {
    body: '#F7F7F7',
    footerBorderNormal: darken(0.1, colors.brand.alpha),
  }

  colors.footer = {
    background: '#f9f6f0',
    border: darken(0.1, colors.brand.alpha),
  }

  return colors
}

/* ************************************************ */
