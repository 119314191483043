// eslint-disable-next-line import/no-cycle
import masterDetailsData from './Fetchmasterdetails'
// eslint-disable-next-line import/no-cycle
import fetchKSADatabase from './FetchKsaDB'
// eslint-disable-next-line import/no-cycle
import fetchMnpDatabase from './FetchMnpDB'
// eslint-disable-next-line import/no-cycle
import fetchRaghiDatebase from './FetchRaghiDB'
import exportMasterDetails from './ExportCSV'

export default {
  masterDetailsData,
  fetchKSADatabase,
  fetchMnpDatabase,
  fetchRaghiDatebase,
  exportMasterDetails,
}
