/* eslint-disable no-unused-vars */
import { css } from 'styled-components'

import {
  withAccents,
} from '../utils'


/* ***************************************************
   /\   /\__ _ _ __ ___
   \ \ / / _` | '__/ __|
    \ V / (_| | |  \__ \
     \_/ \__,_|_|  |___/
*************************************************** */

export default function layoutVars(vars) {
  const layout = vars?.layout || {}

  layout.header = {
    logo: {
      img: {
        height: '20px',
        marginTop: '10px',
      },
    },
  }

  layout.Login = {
    logo: {
      img: {
        height: '20px',
      },
    },
  }

  layout.roundCorner = {
    xs: withAccents('1px', {
      left: '1px 0px 0px 1px',
      right: '0px 1px 1px 0px',
      top: '1px 1px 0px 0px',
      bottom: '0px 0px 1px 1px',
    }),
    sm: withAccents('3px', {
      left: '3px 0px 0px 3px',
      right: '0px 3px 3px 0px',
      top: '3px 3px 0px 0px',
      bottom: '0px 0px 3px 3px',
    }),
    md: withAccents('5px', {
      left: '5px 0px 0px 5px',
      right: '0px 5px 5px 0px',
      top: '5px 5px 0px 0px',
      bottom: '0px 0px 5px 5px',
    }),
    lg: withAccents('15px', {
      left: '15px 0px 0px 15px',
      right: '0px 15px 15px 0px',
      top: '15px 15px 0px 0px',
      bottom: '0px 0px 15px 15px',
    }),
    xl: withAccents('20px', {
      left: '20px 0px 0px 20px',
      right: '0px 20px 20px 0px',
      top: '20px 20px 0px 0px',
      bottom: '0px 0px 20px 20px',
    }),
    xxl: withAccents('30px', {
      left: '30px 0px 0px 30px',
      right: '0px 30px 30px 0px',
      top: '30px 30px 0px 0px',
      bottom: '0px 0px 30px 30px',
    }),
  }

  return layout
}
