import { defineMessages } from 'react-intl'

import { reactIntl } from '~plumbing/utils'

export const LoginMessages = reactIntl.i18nMessages(defineMessages({
  usernameLabel: {
    id: 'cqs.wizard.controls.login.username.label',
    defaultMessage: 'Email',
  },
  usernamePlaceholder: {
    id: 'cqs.wizard.controls.login.username.placeholder',
    defaultMessage: 'Username or Email',
  },
  passwordLabel: {
    id: 'cqs.wizard.controls.login.password.label',
    defaultMessage: 'Password',
  },
  oldPasswordLabel: {
    id: 'cqs.wizard.controls.login.oldpassword.label',
    defaultMessage: 'Old Password',
  },
  passwordConfirmLabel: {
    id: 'cqs.wizard.controls.login.passwordConfirm.label',
    defaultMessage: 'Password Confirm',
  },
  usernameValidationMsg: {
    id: 'cqs.wizard.controls.login.username.required',
    defaultMessage: 'Username is required',
  },
  passwordValidationMsg: {
    id: 'cqs.wizard.controls.login.password.required',
    defaultMessage: 'Password is required',
  },
  passwordConfirmValidationMsg: {
    id: 'cqs.wizard.controls.login.passwordConfirm.required',
    defaultMessage: 'Confirm Password is required',
  },
  resetPasswordHintTitle: {
    id: 'cqs.wizard.controls.login.resetPassword.hint',
    defaultMessage: 'Password must include:',
  },
  resetPasswordHintDesc1: {
    id: 'cqs.wizard.controls.login.resetPassword.hintDesc1',
    defaultMessage: '6-30 characters',
  },
  resetPasswordHintDesc2: {
    id: 'cqs.wizard.controls.login.resetPassword.hintDesc2',
    defaultMessage: 'At least one letter',
  },
  resetPasswordHintDesc3: {
    id: 'cqs.wizard.controls.login.resetPassword.hintDesc3',
    defaultMessage: 'At least one capital letter',
  },
  resetPasswordHintDesc4: {
    id: 'cqs.wizard.controls.login.resetPassword.hintDesc4',
    defaultMessage: 'At least one number',
  },
  resetPasswordHintDesc5: {
    id: 'cqs.wizard.controls.login.resetPassword.hintDesc5',
    defaultMessage: 'At least one special character',
  },
  resetPasswordHintDesc6: {
    id: 'cqs.wizard.controls.login.resetPassword.hintDesc6',
    defaultMessage: 'No spaces',
  },
  loginButton: {
    id: 'cqs.wizard.controls.login.Button.signIn',
    defaultMessage: 'Login',
  },
  forget_Password: {
    id: 'cqs.wizard.controls.login.forget',
    defaultMessage: 'Forget your password?',
  },
  SendEmail: {
    id: 'cqs.wizard.controls.login.send',
    defaultMessage: 'Send',
  },
  successMsg: {
    id: 'cqs.wizard.controls.forgetPassword.success',
    defaultMessage: 'If the username exists, an email will be sent with further instructions',
  },
  resetButton: {
    id: 'cqs.wizard.controls.login.reset',
    defaultMessage: 'Reset',
  },
  userNameMSG: {
    id: 'cqs.wizard.controls.login.usernameMsg',
    defaultMessage: 'Enter a new password for your account ',
  },
  backLogin: {
    id: 'cqs.wizard.controls.login.back',
    defaultMessage: 'Back to Login',
  },
  resetPasswordSuc: {
    id: 'cqs.wizard.controls.reset.successMsg',
    defaultMessage: 'Your Password has changed successfully! \n Please Login with your new credentials, You will redirect in',
  },
  changePassword: {
    id: 'cqs.wizard.controls.passwor.change',
    defaultMessage: 'Change Password',
  },
  forgetDesc: {
    id: 'cqs.wizard.controls.forget.desc',
    defaultMessage: 'You can send your username and an email will be sent with further instructions',
  },
  success: {
    id: 'cqs.wizard.controls.change.success',
    defaultMessage: 'Success',
  },
  seconds: {
    id: 'cqs.wizard.controls.change.seconds',
    defaultMessage: 'sec ..',
  },
  loginNow: {
    id: 'cqs.wizard.controls.change.loginNow',
    defaultMessage: 'Login Now',
  },
  newToCQS: {
    id: 'cqs.login.newToCQS',
    defaultMessage: 'New to CEQUENS?',
  },
  signup: {
    id: 'cqs.login.signup',
    defaultMessage: 'Sign up',
  },
  replaceEmailDesc: {
    id: 'cqs.login.replaceEmailDesc',
    defaultMessage: 'it seems that you are using separate usernames for the registered email. To avoid duplication, please enter a different email below to be assigned to your username. You may login using either username or email. This is to ensure your account’s security.',
  },
  signupLink: {
    id: 'cqs.login.signupLink',
    defaultMessage: 'Sign up for free',
  },
  signupText: {
    id: 'cqs.login.signupText',
    defaultMessage: 'Don\'t have an account yet?  ',
  },
  welcomeText: {
    id: 'cqs.login.welcomeText',
    defaultMessage: 'Welcome to Discovery Portal',
  },
  campaigns: {
    id: 'cqs.login.campaigns',
    defaultMessage: 'HLR Configuration',
  },
  campaignsContent: {
    id: 'cqs.login.campaignsContent',
    defaultMessage: 'Create and send SMS, Voice & Email campaigns to all your client base.',
  },
  chats: {
    id: 'cqs.login.chats',
    defaultMessage: 'Global Configuration',
  },
  chatsContent: {
    id: 'cqs.login.chatsContent',
    defaultMessage: 'Create and send SMS, Voice & Email campaigns to all your client base.',
  },
  channels: {
    id: 'cqs.login.channels',
    defaultMessage: 'Providers Configuration',
  },
  channelsContent: {
    id: 'cqs.login.channelsContent',
    defaultMessage: 'Create and send SMS, Voice & Email campaigns to all your client base.',
  },
  contentInfo: {
    id: 'cqs.login.contentInfo',
    defaultMessage: ' ',
  },
  or: {
    id: 'cqs.login.or',
    defaultMessage: 'Or',
  },
  loginWithGoogle: {
    id: 'cqs.login.loginWithGoogle',
    defaultMessage: 'Login with Google',
  },
  signupWithGoogle: {
    id: 'cqs.login.signupWithGoogle',
    defaultMessage: 'Signup with Google',
  },
}))

export const loginErr = reactIntl.i18nMessages(defineMessages({
  userInvalid: {
    id: 'cqs.wizard.controls.login.user.invalid',
    defaultMessage: 'Invalid username or password',
  },
  userLocked: {
    id: 'cqs.wizard.controls.login.user.locked',
    defaultMessage: 'User is locked',
  },
  userExpired: {
    id: 'cqs.wizard.controls.login.user.expired',
    defaultMessage: 'User is expired',
  },
  userDeleted: {
    id: 'cqs.wizard.controls.login.user.deleted',
    defaultMessage: 'User is Deleted',
  },
  accountExpired: {
    id: 'cqs.wizard.controls.login.account.expired',
    defaultMessage: 'Account Expired',
  },
  accountInactive: {
    id: 'cqs.wizard.controls.login.account.inactive',
    defaultMessage: 'Account is inactive',
  },
  accountDeleted: {
    id: 'cqs.wizard.controls.login.account.deleted',
    defaultMessage: 'Account is deleted',
  },
  apiNotAllowed: {
    id: 'cqs.wizard.controls.login.api.notAllowed',
    defaultMessage: 'API access not allowed',
  },
  serverError: {
    id: 'cqs.account.registration.verify.confirmationCode.validation.generic',
    defaultMessage: 'contact support',
  },
  opps: {
    id: 'cqs.wizard.controls.login.error.opps',
    defaultMessage: 'Oops',
  },
  errCTA: {
    id: 'cqs.wizard.controls.login.error.help',
    defaultMessage: 'Kindly contact your administrator or',
  },
  welcome: {
    id: 'cqs.wizard.controls.login.welcome',
    defaultMessage: 'Welcome',
  },
  support: {
    id: 'cqs.wizard.controls.login.support',
    defaultMessage: 'Application Support',
  },
  tryAgain: {
    id: 'cqs.wizard.controls.login.tryAgain',
    defaultMessage: 'Please try again',
  },
  oldPassword: {
    id: 'cqs.wizard.controls.login.oldPassword',
    defaultMessage: 'New password should not be one of the last 6 - months passwords.',
  },
  invalidToken: {
    id: 'cqs.wizard.controls.login.invalidToken',
    defaultMessage: 'Token is invalid.',
  },
  InvalidCurrentPassword: {
    id: 'cqs.wizard.controls.login.invalidCurrent',
    defaultMessage: 'The current password is invalid.',
  },
  resetPasswordLimit: {
    id: 'cqs.wizard.controls.login.resetPasswordLimit',
    defaultMessage: 'You exceed the limit of reset password.',
  },
  duplicatedPassword: {
    id: 'cqs.wizard.controls.login.duplicatedPassword',
    defaultMessage: 'New password should differ from current password.',
  },
  userDeactivated: {
    id: 'cqs.wizard.controls.login.userDeactivated',
    defaultMessage: 'Your user is deactivated. To reactivate it, contact your account admin or [click here](https://support.cequens.com/support/tickets/new) to get support.',
  },
  accountDeactivated: {
    id: 'cqs.wizard.controls.login.accountDeactivated',
    defaultMessage: 'Your account is deactivated. To reactivate it, contact your account admin or [click here](https://support.cequens.com/support/tickets/new) to get support.',
  },
  accountNotConfirmed: {
    id: 'cqs.wizard.controls.login.AccountNotConfirmed',
    defaultMessage: 'Your account is not activated yet. To reactivate it, contact your account admin or [click here](https://support.cequens.com/support/tickets/new) to get support.',
  },
  userexceedResendConfirmationEmail: {
    id: 'cqs.wizard.controls.login.userexceedResendConfirmationEmail',
    defaultMessage: 'You have reached your limit, to resend again please try again in 1 hour',
  },
  deewanUsersNotAuthorizedHere: {
    id: 'cqs.wizard.controls.login.deewanUsersNotAuthorizedHere',
    defaultMessage: 'You are not authorized to enter this domain, please check Deewan domain.',
  },
  resendVerificationEmailSuccess: {
    id: 'cqs.wizard.controls.login.resendVerificationEmailSuccess',
    defaultMessage: 'Verification email has just been sent to you.',
  },
  PhoneExisted: {
    id: 'cqs.wizard.controls.login.PhoneExisted',
    defaultMessage: 'This mobile number already exists.',
  },
  resendEmail: {
    id: 'cqs.wizard.controls.login.resendEmail',
    defaultMessage: 'Resend Email',
  },
  generalError: {
    id: 'cqs.account.registration.verify.confirmationCode.validation.generic',
    defaultMessage: 'contact support',
  },
}))

export const LockMessages = reactIntl.i18nMessages(defineMessages({
  unlockPanelTitle: {
    id: 'cqs.login.lockScreen.unlockPanel.title',
    defaultMessage: 'Unlock',
  },

  unlockPanelMessage: {
    id: 'cqs.login.lockScreen.unlockPanel.message',
    defaultMessage: 'enter your password to unlock',
  },

  notMeButtonLabel: {
    id: 'cqs.login.lockScreen.notMeButton.label',
    defaultMessage: 'Login with another account',
  },
  unlockButtonLabel: {
    id: 'cqs.login.lockScreen.unlockButton.label',
    defaultMessage: 'Unlock',
  },
}))

export const changePassword = reactIntl.i18nMessages(defineMessages({
  'string.regex.base': {
    id: 'cqs.password.change.invalid',
    description: 'Please enter a valid phone number',
    defaultMessage: 'Password doesn`t match criteria',
  },
  'string.max': {
    id: 'cqs.password.change.invalid',
    description: 'Please enter a valid phone number',
    defaultMessage: 'Password doesn`t match criteria',
  },
  'string.base': {
    id: 'cqs.password.change.invalid',
    description: 'Please enter a valid phone number',
    defaultMessage: 'Password doesn`t match criteria',
  },
  'any.required': {
    id: 'cqs.wizard.controls.login.password.required',
    defaultMessage: 'Password is required',
  },
  'any.allowOnly': {
    id: 'cqs.wizard.controls.login.password.match',
    defaultMessage: 'Confirm password must match the password',
  },
  'any.empty': {
    id: 'cqs.wizard.controls.login.password.empty',
    defaultMessage: 'Password is required',
  },
  'any.invalid': {
    id: 'cqs.password.change.oldAndNewValidation',
    description: 'Password validation incase old pwd equals new one',
    defaultMessage: 'New Password can`t be the same as the old one',
  },
}))
