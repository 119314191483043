import styled from 'styled-components'

/**
 *
 * @param {object} props
 *
 *
 * @example
 * <Title />
 * <Title />
 */

export const Collapse = styled.div`
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  border-bottom: ${({ theme }) => theme.hrNormal} 1px solid;
  height:40px;
  margin-top:15px;
 /* box-shadow: inset 0 -8px 7px -11px ${({ theme }) => theme.shadowPrimary};*/

  h4 {
    line-height: 40px;
    color: ${({ theme }) => theme.textDark};
    font-size: 14px;
    font-weight:600;

  }
  a {
    position: absolute;
    font-size: 14px;
    line-height: 40px;
    font-weight:bolder;
    color: ${({ theme }) => theme.btnLink};
    ${({ theme }) => theme.dirFloatRev}: 0;
  }
`
