import request from 'superagent'
import moment from 'moment'

import { API_URL, getToken } from '~plumbing/api/apiUtils'

export default function createRule(payload) {
  const {
    newRuleName,
    customer,
    destinations,
    dateFrom,
    dateTo,
    recipients,
    csvFile,
  } = payload
  const form = new FormData()
  if (customer && customer.length > 0) {
    for (let i = 0; i < customer.length; i += 1) {
      form.append(`customers[${i}]`, customer[i])
    }
  }
  if (destinations && destinations.length > 0) {
    for (let i = 0; i < destinations.length; i += 1) {
      form.append(`destinations[${i}][countryId]`, destinations[i].countryId)
      form.append(`destinations[${i}][networkId]`, destinations[i].networkId)
    }
  }
  if (recipients && recipients.length > 0) {
    for (let i = 0; i < recipients.length; i += 1) {
      form.append(`recipients[${i}]`, recipients[i])
    }
    if (csvFile) {
      form.append('csvFile', csvFile)
    }
  }
  if (dateFrom) form.append('range_time[from]', dateFrom)
  if (dateTo) form.append('range_time[to]', dateTo)
  return new Promise((resolve, reject) => {
    request
      .post(`${API_URL}/api/v1/flooding-rules/${newRuleName}`)
      .set('Authorization', `bearer ${getToken()}`)
      .send(form)
      .then((resp) => {
      })
      .catch((err) => {
        reject(err)
      })
  })
}
