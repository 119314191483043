import styled from 'styled-components'

/**
 *
 * @param {object} props
 *
 *
 * @example
 * <Button />
 * <WiredButton />
 */
export const Badge = styled.b`
  background: ${({ theme }) => theme.badgeBgNormal};
  font-size:11px;
  border:1px solid ${({ theme }) => theme.badgeColorNormal};
  margin:0px 5px;
  color:${({ theme }) => theme.badgeColorNormal} !important;
  border-radius:${({ theme }) => theme.roundCornerXl};
  padding:0px 5px;
  font-weight:normal;
  height: 15px;
  display: inline-block;
  line-height: 12px;
`

Badge.Soon = Badge.extend`
  &:before{
    content:"soon";
  }
`
