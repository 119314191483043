import {
  isObject as _isObject,
} from 'lodash'

import createTheme from './createTheme'

export {
  createTheme,
}

/**
 * wraps `value` in an object with `accents` as properties
 * with value is available on property `value` and returned
 * as string from `toString`
 *
 * @param value - value to be wrapped
 * @param {object} accents - props to be attached [`value` is reserved property]
 *
 * @return {object}
 */
export const withAccents = (value, accents) => (_isObject(accents)
  ? ({
    value, ...accents, __value__: value, toString: () => value.toString(),
  })
  : value
)
