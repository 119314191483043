import React from 'react'
import PropTypes from 'prop-types'

export default class BodyStyle extends React.Component {
  static propTypes = {
    style: PropTypes.object, // eslint-disable-line
    reset: PropTypes.bool, // eslint-disable-line
    apply: PropTypes.bool, // eslint-disable-line
    children: PropTypes.any, // eslint-disable-line
    unmountStyle: PropTypes.object, // eslint-disable-line
  }

  static defaultProps = {
    unmountStyle: {},
    style: {},
    reset: false,
    apply: true,

  }

  constructor(props) {
    super(props)

    this.styleBkp = {}
    this.state = { ...props }
    this.init = false
    this.id = new Date().getMilliseconds()
  }

  componentWillMount() {
    const label = this.init ? 'update' : 'init'
    this.applyBodyStyles(this.props.style, label, !this.init)
    this.init = true
  }

  componentWillReceiveProps(nextProps) {
    this.applyBodyStyles(nextProps.style, 'props-update', true)
  }

  componentWillUnmount() {
    if (this.props.reset) {
      this.resetBodyStyles()
    }

    if (this.props.unmountStyle) {
      this.applyBodyStyles(this.props.unmountStyle, 'unmount-style', false)
    }
  }

  applyBodyStyles = (style: Object = {}, lable = 'apply', bkp = true) => {
    Object
      .entries(style)
      .forEach(([key, val]) => {
        const xVal = document.body.style[key]

        if (bkp) this.styleBkp[key] = xVal

        document.body.style[key] = val
      })
  }

  resetBodyStyles = () => {
    const { styleBkp } = this
    Object
      .entries(styleBkp)
      .forEach(([key, val]) => {
        const xVal = document.body.style[key]
        document.body.style[key] = val
      })
  }

  render() {
    return this.props.children || null
  }
}
