import { inCase } from '~plumbing/utils'
import {
  actions as auditActions,
  actionsTypes,
} from './AuditActions'

const defaultState = {
  audits: {
    auditsData: [],
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
  entities: {
    entitiesData: [],
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
  usernames: {
    usernamesData: [],
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
}
const audits = inCase.curry('audits')
  .check((s, action) => action.type)
  .otherwise((state) => state || defaultState)
  .cases({
    [actionsTypes.FETCH_ALL_AUDITS]: fetchAllAudits,
    [actionsTypes.FETCH_ALL_AUDITS_SUCCESS]: fetchAllAuditsSuccess,
    [actionsTypes.FETCH_ALL_AUDITS_FAILED]: fetchAllAuditsFailed,
    [actionsTypes.FETCH_ALL_ENTITIES]: fetchAllEntities,
    [actionsTypes.FETCH_ALL_ENTITIES_SUCCESS]: fetchAllEntitiesSuccess,
    [actionsTypes.FETCH_ALL_ENTITIES_FAILED]: fetchAllEntitiesFailed,
    [actionsTypes.FETCH_ALL_USERNAMES]: fetchAllUsernames,
    [actionsTypes.FETCH_ALL_USERNAMES_SUCCESS]: fetchAllUsernamesSuccess,
    [actionsTypes.FETCH_ALL_USERNAMES_FAILED]: fetchAllUsernamesFailed,
  })

function fetchAllAudits(state, action) {
  return {
    ...state,
  }
}
function fetchAllAuditsSuccess(state, action) {
  return {
    ...state,
    audits: {
      auditsData:
        action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchAllAuditsFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchAllEntities(state, action) {
  return {
    ...state,
  }
}
function fetchAllEntitiesSuccess(state, action) {
  return {
    ...state,
    entities: {
      entitiesData:
        action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchAllEntitiesFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
function fetchAllUsernames(state, action) {
  return {
    ...state,
  }
}
function fetchAllUsernamesSuccess(state, action) {
  return {
    ...state,
    usernames: {
      usernamesData:
        action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchAllUsernamesFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
  }
}
export default audits
