import jwtDecode from 'jwt-decode'

export default function jwtDecoder(token) {
  try {
    const newToken = jwtDecode(token)
    return newToken
  } catch (error) {
    console.error('[jwtDecoder]', error, token)
    return token
  }
}
