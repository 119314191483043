import raghiAccounts from './FetchRaghiAccounts'
import defaultAccount from './FetchDefaultAccount'
import fetchHLRDatabase from './FetchHLR'
import fetchMnpDatabase from './FetchMNP'
import fetchRaghiDatebase from './FetchRaghi'
import fetchKSADatabase from './FetchKsa'
import updateRaghiAccounts from './UpdateRaghiAccounts'
import updatedefaultAccount from './UpdateDefaultAccount'
import updatehlr from './UpdateHLR'
import updateksa from './UpdateKSA'
import updatemnp from './UpdateMNP'
import updateraghi from './UpdateRaghi'
import mnpPlus from './FetchMnpPlus'
import updateMnpPlus from './UpdateMnpPlus'

export default {
  raghiAccounts,
  defaultAccount,
  fetchHLRDatabase,
  fetchMnpDatabase,
  fetchRaghiDatebase,
  fetchKSADatabase,
  updateRaghiAccounts,
  updatedefaultAccount,
  updatehlr,
  updateksa,
  updatemnp,
  updateraghi,
  mnpPlus,
  updateMnpPlus,
}
