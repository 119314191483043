import { inCase } from '~plumbing/utils'
import { actions as userActions, actionsTypes } from './UserManagementActions'

const defaultState = {
  users: {
    usersData: [],
    meta: {
      isLoading: false,
      isFailed: false,
      isSuccess: false,
      errorMessage: '',
    },
  },
}
const users = inCase
  .curry('users')
  .check((s, action) => action.type)
  .otherwise((state) => state || defaultState)
  .cases({
    [actionsTypes.FETCH_ALL_USERS]: fetchAllUsers,
    [actionsTypes.FETCH_ALL_USERS_SUCCESS]: fetchAllUsersSuccess,
    [actionsTypes.FETCH_ALL_USERS_FAILED]: fetchAllUsersFailed,
    [actionsTypes.DELETE_USER]: deleteUsers,
    [actionsTypes.DELETE_USER_SUCCESS]: deleteUsersSuccess,
    [actionsTypes.DELETE_USER_FAILED]: deleteUsersFailed,
    [actionsTypes.UPDATE_USER]: updateUsers,
    [actionsTypes.UPDATE_USER_SUCCESS]: updateUsersSuccess,
    [actionsTypes.UPDATE_USER_FAILED]: updateUsersFailed,
    [actionsTypes.ADD_USER]: addUser,
    [actionsTypes.ADD_USER_SUCCESS]: addUserSuccess,
    [actionsTypes.ADD_USER_FAILED]: addUserFailed,
  })

function fetchAllUsers(state, action) {
  return {
    ...state,
    errCode: undefined,
    err: undefined,
    updateErr: undefined,
    deleteErr: undefined,
    isSuccess: false,
    isDeleted: false,
    isUpdated: false,
  }
}
function fetchAllUsersSuccess(state, action) {
  return {
    ...state,
    users: {
      usersData: action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
        errorMessage: '',
      },
    },
  }
}
function fetchAllUsersFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
    err: action.err,
    updateErr: undefined,
    deleteErr: undefined,
    isSuccess: false,
    isDeleted: false,
    isUpdated: false,
  }
}
function deleteUsers(state, action) {
  return {
    ...state,
    errCode: undefined,
    err: undefined,
    updateErr: undefined,
    deleteErr: undefined,
    isSuccess: false,
    isDeleted: false,
    isUpdated: false,
  }
}
function deleteUsersSuccess(state, action) {
  return {
    ...state,
    isDeleted: true,
    isSuccess: false,
    deleteErr: undefined,
    updateErr: undefined,
    err: undefined,
    isUpdated: false,
  }
}
function deleteUsersFailed(state, action) {
  return {
    ...state,
    deleteErr: action.err,
    err: undefined,
    isDeleted: false,
    isSuccess: false,
    isUpdated: false,
    updateErr: undefined,
  }
}
function updateUsers(state, action) {
  return {
    ...state,
    errCode: undefined,
    err: undefined,
    updateErr: undefined,
    deleteErr: undefined,
    isSuccess: false,
    isDeleted: false,
    isUpdated: false,
  }
}
function updateUsersSuccess(state, action) {
  return {
    isUpdated: true,
    isSuccess: false,
    deleteErr: undefined,
    updateErr: undefined,
    err: undefined,
    isDeleted: false,
    users: {
      usersData: action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
      },
    },
  }
}
function updateUsersFailed(state, action) {
  return {
    ...state,
    updateErr: action.err,
    err: action.err,
    isUpdated: false,
    isSuccess: false,
    deleteErr: undefined,
    isDeleted: false,
  }
}
function addUser(state, action) {
  return {
    ...state,
    errCode: undefined,
    err: undefined,
    updateErr: undefined,
    deleteErr: undefined,
    isSuccess: false,
    isDeleted: false,
    isUpdated: false,
  }
}
function addUserSuccess(state, action) {
  return {
    isSuccess: true,
    deleteErr: undefined,
    updateErr: undefined,
    err: undefined,
    isDeleted: false,
    isUpdated: false,
    users: {
      usersData: action.payload,
      meta: {
        isLoading: false,
        isFailed: false,
        isSuccess: true,
      },
    },
  }
}
function addUserFailed(state, action) {
  return {
    ...state,
    errCode: action.err,
    isSuccess: false,
    deleteErr: undefined,
    updateErr: undefined,
    err: undefined,
    isDeleted: false,
    isUpdated: false,
  }
}
export default users
