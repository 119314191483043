import uuid from 'uuid'
import {
  range as _range,
  random as _random,
  isObject as _isObject,
  isFunction as _isFunction,
} from 'lodash'

import { cqsKVS } from '../kvStore'


export default function debugToolsOps(opts) {
  const {
    ctx: _ctx,
    name,
    onSetup,
    onEnable,
    onDisable,
    onConfirmed,
  } = opts

  let confirmToken
  const ctx = _isObject(_ctx)
    ? _ctx
    : {}

  const exec = (fn, fb) => (...args) => (_isFunction(fn)
    ? fn(...args)
    : fb)

  cqsKVS.debugtools.remove()

  if (!_isFunction(onConfirmed)) {
    return
  }

  exec(onSetup)(ctx)

  window.addEventListener('storage', async () => {
    switch (cqsKVS.debugtools.get()) {
      case confirmToken:
        cqsKVS.debugtools.remove()
        exec(onConfirmed)(ctx, { confirmToken })
        break
      case `turn off ${name}`:
        cqsKVS.debugtools.remove()
        exec(onDisable)(ctx, { confirmToken })
        break
      case `turn on ${name}`:
        confirmToken = debugToolsOps.__getConfirmDigest()
        confirmToken = exec(onEnable)(ctx, { confirmToken }) || confirmToken
        debugToolsOps.prompt(confirmToken)
        break
      default:
        break
    }
  })
}

debugToolsOps.__log = console.info

debugToolsOps.prompt = (digest) => {
  debugToolsOps.__log('token:', digest)
}

debugToolsOps.__getConfirmDigest = () => {
  const t = cqsKVS.tDigest.read() || uuid()
  const len = t.length - 1

  return _range(_random(len / 18, len / 8))
    .map(() => t[_random(len)])
    .join('')
}
