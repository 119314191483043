/* eslint-disable no-unused-vars */
import chroma from 'chroma-js'
import { darken, lighten } from 'polished'

import {
  withAccents,
} from '../utils'

/* ***************************************************
   /\   /\__ _ _ __ ___
   \ \ / / _` | '__/ __|
    \ V / (_| | |  \__ \
     \_/ \__,_|_|  |___/
*************************************************** */

export default function colorVars(vars) {
  const colors = vars?.colors || {}

  colors.brand = {
    alpha: '#b47e34',
    beta: '#1f4128',
    gamma: '#1f4128',
    // psi
    // omega
  }

  colors.helper = {
    alpha: '#ffffff',
    beta: '#fff0b6',
    psi: '#dcd3c5',
    gamma: '#606970',

    x: 'rgba(0, 0, 0, 0)', // Test color replace Ant colors
  }

  colors.state = {
    success: '#0eca0e',
    warning: '#ffc100',
    error: '#ff7f7f',
    disabled: '#d3d3d3',
  }

  colors.shadow = {
    alpha: '#ffffff',
    beta: '#dcdcdc',
    psi: 'rgba(78, 78, 78, 0.8)',
    gamma: 'rgba(255,255,255,.9)',
  }

  colors.typo = {
    title: {
      alpha: colors.brand.beta,
      beta: colors.helper.alpha,
      gamma: '',
      psi: '',
      omega: '',
    },
    label: colors.helper.gamma,
    value: colors.brand.beta,
  }

  colors.btn = {
    alpha: {
      background: colors.brand.alpha,
      border: colors.brand.alpha,
      label: colors.helper.alpha,
      hover: {
        background: darken(0.1, colors.brand.alpha),
        border: colors.brand.alpha,
        label: colors.helper.alpha,
      },
    },
    beta: {
      background: colors.helper.alpha,
      border: colors.helper.psi,
      label: colors.brand.alpha,
      hover: {
        background: colors.helper.psi,
        border: colors.helper.psi,
        label: colors.brand.alpha,
      },
    },
    gamma: {
      background: colors.helper.alpha,
      border: colors.helper.psi,
      label: colors.brand.alpha,
      hover: {
        background: colors.helper.psi,
        border: colors.helper.psi,
        label: colors.brand.alpha,
      },
    },
    psi: {
      background: lighten(0.7, colors.brand.beta),
      border: '',
      label: colors.brand.alpha,
      hover: {
        background: lighten(0.6, colors.brand.beta),
        border: '',
        label: colors.brand.alpha,
      },
    },
    link: {
      label: colors.brand.alpha,
      hover: {
        label: darken(0.1, colors.brand.alpha),
      },
    },
    icon: {
      background: colors.brand.alpha,
      border: '',
      label: colors.helper.lighter,
      hover: {
        background: darken(0.1, colors.brand.alpha),
        border: '',
        label: colors.helper.lighter,
      },
    },
    state: {
      error: {
        background: colors.state.error,
        border: colors.state.error,
        label: colors.helper.alpha,
        hover: {
          background: colors.state.error,
          border: colors.state.error,
          label: colors.helper.alpha,
        },
      },
      warning: {
        background: colors.state.warning,
        border: colors.state.warning,
        label: colors.helper.alpha,
        hover: {
          background: colors.state.warning,
          border: colors.state.warning,
          label: colors.helper.alpha,
        },
      },
      success: {
        background: colors.state.success,
        border: colors.state.success,
        label: colors.helper.alpha,
        hover: {
          background: colors.state.success,
          border: colors.state.success,
          label: colors.helper.alpha,
        },
      },
    },
    disabled: {
      background: colors.state.disabled,
      border: colors.state.disabled,
      label: darken(0.3, colors.state.disabled),
    },
  }

  colors.table = {
    header: {
      background: colors.brand.gamma,
      label: colors.helper.alpha,
      border: colors.brand.gamma,
      hover: {
        background: darken(0.1, colors.brand.alpha),
        label: darken(0.1, colors.brand.alpha),
        border: darken(0.1, colors.brand.alpha),
      },
      selected: {
        background: darken(0.1, colors.brand.alpha),
        label: darken(0.1, colors.brand.alpha),
        border: darken(0.1, colors.brand.alpha),
      },
    },
    body: {
      background: colors.helper.alpha,
      label: '#4a5669',
      border: colors.helper.alpha,
      hover: {
        background: colors.helper.beta,
        label: colors.helper.gamma,
        border: darken(0.1, colors.brand.alpha),
      },
      selected: {
        background: colors.brand.alpha,
        label: colors.helper.alpha,
        border: darken(0.1, colors.brand.alpha),
      },
      action: {
        background: '',
        label: colors.brand.alpha,
        border: '',
        hover: {
          background: colors.helper.psi,
          label: colors.brand.alpha,
          border: '',
        },
        disabled: {
          background: lighten(0.5, colors.helper.gamma),
          label: lighten(0.35, colors.helper.gamma),
          border: lighten(0.5, colors.helper.gamma),
        },
      },
      link: {
        label: colors.brand.alpha,
        hover: {
          label: darken(0.1, colors.brand.alpha),
        },
      },
    },
  }

  colors.input = {
    background: colors.helper.alpha,
    border: lighten(0.5, colors.helper.gamma),
    value: colors.brand.beta,
    placeholder: lighten(0.4, colors.helper.gamma),
    list: {
      background: colors.helper.alpha,
      border: darken(0.1, colors.brand.alpha),
      value: darken(0.1, colors.brand.alpha),
      hover: {
        background: '#fffcf2',
        border: darken(0.1, colors.brand.alpha),
        value: darken(0.1, colors.brand.alpha),
      },
    },
    focus: {
      background: '',
      border: '',
      value: '',
      shadow: `0px 0px 6px ${lighten(0.2, colors.helper.gamma)}`,
    },
    disabled: {
      background: lighten(0.5, colors.helper.gamma),
      border: '',
      value: lighten(0.35, colors.helper.gamma),
    },
  }

  colors.card = {
    background: '#fffce9',
    border: '#ececea',
  }

  colors.tag = {
    alpha: {
      background: lighten(0.7, colors.brand.beta),
      border: '',
      value: colors.brand.beta,
    },
  }

  colors.hint = {
    defult: {
      background: '#fffce9',
      border: '#fff0b6',
      title: darken(0.1, colors.brand.alpha),
      msg: darken(0.1, colors.brand.alpha),
    },
    success: {
      background: '#dff0d8',
      border: '#3c763d',
      title: darken(0.1, colors.brand.alpha),
      msg: darken(0.1, colors.brand.alpha),
    },
  }

  colors.wizard = {
    defult: {
      label: darken(0.1, colors.brand.alpha),
      background: darken(0.1, colors.brand.alpha),
      border: darken(0.1, colors.brand.alpha),
    },
    active: {
      label: darken(0.1, colors.brand.alpha),
      background: darken(0.1, colors.brand.alpha),
      border: darken(0.1, colors.brand.alpha),
    },
    done: {
      label: darken(0.1, colors.brand.alpha),
      background: darken(0.1, colors.brand.alpha),
      border: darken(0.1, colors.brand.alpha),
    },
  }

  colors.hr = {
    alpha: '#ffffff',
    beta: '#f5f5f5',
    psi: '',
    gamma: '',
  }

  colors.layout = {
    body: '#f5f4f2',
    footerBorderNormal: darken(0.1, colors.brand.alpha),
  }

  colors.footer = {
    background: '#f9f6f0',
    border: darken(0.1, colors.brand.alpha),
  }

  return colors
}

/* ************************************************ */
