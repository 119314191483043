/* global FS */
import Rx from 'rxjs' // eslint-disable-line

import {
  observableFromPromiseProperties,
} from 'CQS/plumbing/utils'
import { actions as appActions } from '~CON/App/appActions'

import { actionsTypes, actions as MasterDetailsActions } from './MasterDetailsAction'
import apis from '../apis'

const masterDetailsApis = observableFromPromiseProperties(apis, Rx)

export default [
  getMasterDetailsData,
  exportMasterDetails,
  getRaghiDatabase,
  getKsaDatabase,
  getMnpDatabase,
]

export function getMasterDetailsData(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_MASTERDETAILS)
    .switchMap(({ action, payload }) => {
      return masterDetailsApis
        .masterDetailsData(payload)
        .map((res) => {
          return MasterDetailsActions.fetchMasterdetailsSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_MASTERDETAILS_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function exportMasterDetails(action$, { getState }) {
  return action$.ofType(actionsTypes.EXPORT_MASTERDETAILS)
    .switchMap(({ action, payload }) => {
      return masterDetailsApis
        .exportMasterDetails(payload)
        .map((res) => {
          return MasterDetailsActions.exportMasterDetailsSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.EXPORT_MASTERDETAILS_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getRaghiDatabase(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_RAGHI_DATABASE)
    .switchMap(({ action }) => {
      return masterDetailsApis
        .fetchRaghiDatebase()
        .map((res) => {
          return MasterDetailsActions.fetchRaghiDatabaseSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_RAGHI_DATABASE_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getMnpDatabase(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_MNP_DATABASE)
    .switchMap(({ action }) => {
      return masterDetailsApis
        .fetchMnpDatabase()
        .map((res) => {
          return MasterDetailsActions.fetchMnpDatabaseSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_MNP_DATABASE_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
export function getKsaDatabase(action$, { getState }) {
  return action$.ofType(actionsTypes.FETCH_KSA_DATABASE)
    .switchMap(({ action }) => {
      return masterDetailsApis
        .fetchKSADatabase()
        .map((res) => {
          return MasterDetailsActions.fetchKsaDatabaseSuccess(res)
        })
        .catch((err) => {
          return Rx.Observable.of({
            err: err?.response?.body,
            type: actionsTypes.FETCH_KSA_DATABASE_FAILED,
          })
        })
    })
    .catch((error) => Rx.Observable.of(appActions.throwError(error)))
}
